/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignmentResultResponse,
  LicenseOverviewResponse,
  UnassignmentResultResponse,
  VoucherResultResponse,
} from '../models/index';
import {
    AssignmentResultResponseFromJSON,
    AssignmentResultResponseToJSON,
    LicenseOverviewResponseFromJSON,
    LicenseOverviewResponseToJSON,
    UnassignmentResultResponseFromJSON,
    UnassignmentResultResponseToJSON,
    VoucherResultResponseFromJSON,
    VoucherResultResponseToJSON,
} from '../models/index';

export interface AssignLicenseToDeviceUsingPUTRequest {
    virtualFridgeId: string;
    voucherCode: string;
}

export interface AssignLicenseUsingPUTRequest {
    entitlementId: string;
    virtualFridgeId: string;
}

export interface RedeemVoucherCodeUsingPOSTRequest {
    voucherCode: string;
    isCompanyVoucherCode?: boolean;
}

export interface RedeemVoucherUsingPOSTRequest {
    voucherCode: string;
    virtualFridgeId?: string;
}

export interface UnassignLicenseUsingPUTRequest {
    virtualFridgeId: string;
}

/**
 * LicenseControllerApi - interface
 * 
 * @export
 * @interface LicenseControllerApiInterface
 */
export interface LicenseControllerApiInterface {
    /**
     * assignLicenseToDevice
     * @summary Assigns the license to the VFID. Returns a new LicenseOverviewResponse within.
     * @param {string} virtualFridgeId 
     * @param {string} voucherCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseControllerApiInterface
     */
    assignLicenseToDeviceUsingPUTRaw(requestParameters: AssignLicenseToDeviceUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssignmentResultResponse>>;

    /**
     * assignLicenseToDevice
     * Assigns the license to the VFID. Returns a new LicenseOverviewResponse within.
     */
    assignLicenseToDeviceUsingPUT(requestParameters: AssignLicenseToDeviceUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssignmentResultResponse>;

    /**
     * assignLicense
     * @summary Assigns the license by entitlement to the VFID. Returns a new LicenseOverviewResponse within.
     * @param {string} entitlementId 
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseControllerApiInterface
     */
    assignLicenseUsingPUTRaw(requestParameters: AssignLicenseUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssignmentResultResponse>>;

    /**
     * assignLicense
     * Assigns the license by entitlement to the VFID. Returns a new LicenseOverviewResponse within.
     */
    assignLicenseUsingPUT(requestParameters: AssignLicenseUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssignmentResultResponse>;

    /**
     * getLicenseOverview
     * @summary Gets the license overview for the user\'s appliances.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseControllerApiInterface
     */
    getLicenseOverviewUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LicenseOverviewResponse>>;

    /**
     * getLicenseOverview
     * Gets the license overview for the user\'s appliances.
     */
    getLicenseOverviewUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LicenseOverviewResponse>;

    /**
     * redeemVoucherCode
     * @summary Redeems the given voucher code (measure site / company) to retrieve new license entitlement. Returns a new LicenseOverviewResponse within.
     * @param {string} voucherCode 
     * @param {boolean} [isCompanyVoucherCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseControllerApiInterface
     */
    redeemVoucherCodeUsingPOSTRaw(requestParameters: RedeemVoucherCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VoucherResultResponse>>;

    /**
     * redeemVoucherCode
     * Redeems the given voucher code (measure site / company) to retrieve new license entitlement. Returns a new LicenseOverviewResponse within.
     */
    redeemVoucherCodeUsingPOST(requestParameters: RedeemVoucherCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VoucherResultResponse>;

    /**
     * redeemVoucher
     * @summary Redeems the given voucher code and assigns the license to the VFID. Returns a new LicenseOverviewResponse within.
     * @param {string} voucherCode 
     * @param {string} [virtualFridgeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseControllerApiInterface
     */
    redeemVoucherUsingPOSTRaw(requestParameters: RedeemVoucherUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VoucherResultResponse>>;

    /**
     * redeemVoucher
     * Redeems the given voucher code and assigns the license to the VFID. Returns a new LicenseOverviewResponse within.
     */
    redeemVoucherUsingPOST(requestParameters: RedeemVoucherUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VoucherResultResponse>;

    /**
     * unassignLicense
     * @summary Unassigns the license by VFID. Returns a new LicenseOverviewResponse within.
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseControllerApiInterface
     */
    unassignLicenseUsingPUTRaw(requestParameters: UnassignLicenseUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnassignmentResultResponse>>;

    /**
     * unassignLicense
     * Unassigns the license by VFID. Returns a new LicenseOverviewResponse within.
     */
    unassignLicenseUsingPUT(requestParameters: UnassignLicenseUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnassignmentResultResponse>;

}

/**
 * 
 */
export class LicenseControllerApi extends runtime.BaseAPI implements LicenseControllerApiInterface {

    /**
     * assignLicenseToDevice
     * Assigns the license to the VFID. Returns a new LicenseOverviewResponse within.
     */
    async assignLicenseToDeviceUsingPUTRaw(requestParameters: AssignLicenseToDeviceUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssignmentResultResponse>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling assignLicenseToDeviceUsingPUT().'
            );
        }

        if (requestParameters['voucherCode'] == null) {
            throw new runtime.RequiredError(
                'voucherCode',
                'Required parameter "voucherCode" was null or undefined when calling assignLicenseToDeviceUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/license/voucher/{voucherCode}/assign/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))).replace(`{${"voucherCode"}}`, encodeURIComponent(String(requestParameters['voucherCode']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignmentResultResponseFromJSON(jsonValue));
    }

    /**
     * assignLicenseToDevice
     * Assigns the license to the VFID. Returns a new LicenseOverviewResponse within.
     */
    async assignLicenseToDeviceUsingPUT(requestParameters: AssignLicenseToDeviceUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssignmentResultResponse> {
        const response = await this.assignLicenseToDeviceUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * assignLicense
     * Assigns the license by entitlement to the VFID. Returns a new LicenseOverviewResponse within.
     */
    async assignLicenseUsingPUTRaw(requestParameters: AssignLicenseUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssignmentResultResponse>> {
        if (requestParameters['entitlementId'] == null) {
            throw new runtime.RequiredError(
                'entitlementId',
                'Required parameter "entitlementId" was null or undefined when calling assignLicenseUsingPUT().'
            );
        }

        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling assignLicenseUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/license/assign/{entitlementId}/{virtualFridgeId}`.replace(`{${"entitlementId"}}`, encodeURIComponent(String(requestParameters['entitlementId']))).replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignmentResultResponseFromJSON(jsonValue));
    }

    /**
     * assignLicense
     * Assigns the license by entitlement to the VFID. Returns a new LicenseOverviewResponse within.
     */
    async assignLicenseUsingPUT(requestParameters: AssignLicenseUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssignmentResultResponse> {
        const response = await this.assignLicenseUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getLicenseOverview
     * Gets the license overview for the user\'s appliances.
     */
    async getLicenseOverviewUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LicenseOverviewResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/license/overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LicenseOverviewResponseFromJSON(jsonValue));
    }

    /**
     * getLicenseOverview
     * Gets the license overview for the user\'s appliances.
     */
    async getLicenseOverviewUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LicenseOverviewResponse> {
        const response = await this.getLicenseOverviewUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * redeemVoucherCode
     * Redeems the given voucher code (measure site / company) to retrieve new license entitlement. Returns a new LicenseOverviewResponse within.
     */
    async redeemVoucherCodeUsingPOSTRaw(requestParameters: RedeemVoucherCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VoucherResultResponse>> {
        if (requestParameters['voucherCode'] == null) {
            throw new runtime.RequiredError(
                'voucherCode',
                'Required parameter "voucherCode" was null or undefined when calling redeemVoucherCodeUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['isCompanyVoucherCode'] != null) {
            queryParameters['isCompanyVoucherCode'] = requestParameters['isCompanyVoucherCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/license/redeem/voucher/{voucherCode}`.replace(`{${"voucherCode"}}`, encodeURIComponent(String(requestParameters['voucherCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VoucherResultResponseFromJSON(jsonValue));
    }

    /**
     * redeemVoucherCode
     * Redeems the given voucher code (measure site / company) to retrieve new license entitlement. Returns a new LicenseOverviewResponse within.
     */
    async redeemVoucherCodeUsingPOST(requestParameters: RedeemVoucherCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VoucherResultResponse> {
        const response = await this.redeemVoucherCodeUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * redeemVoucher
     * Redeems the given voucher code and assigns the license to the VFID. Returns a new LicenseOverviewResponse within.
     */
    async redeemVoucherUsingPOSTRaw(requestParameters: RedeemVoucherUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VoucherResultResponse>> {
        if (requestParameters['voucherCode'] == null) {
            throw new runtime.RequiredError(
                'voucherCode',
                'Required parameter "voucherCode" was null or undefined when calling redeemVoucherUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['virtualFridgeId'] != null) {
            queryParameters['virtualFridgeId'] = requestParameters['virtualFridgeId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/license/redeem/{voucherCode}`.replace(`{${"voucherCode"}}`, encodeURIComponent(String(requestParameters['voucherCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VoucherResultResponseFromJSON(jsonValue));
    }

    /**
     * redeemVoucher
     * Redeems the given voucher code and assigns the license to the VFID. Returns a new LicenseOverviewResponse within.
     */
    async redeemVoucherUsingPOST(requestParameters: RedeemVoucherUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VoucherResultResponse> {
        const response = await this.redeemVoucherUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * unassignLicense
     * Unassigns the license by VFID. Returns a new LicenseOverviewResponse within.
     */
    async unassignLicenseUsingPUTRaw(requestParameters: UnassignLicenseUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnassignmentResultResponse>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling unassignLicenseUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/license/unassign/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnassignmentResultResponseFromJSON(jsonValue));
    }

    /**
     * unassignLicense
     * Unassigns the license by VFID. Returns a new LicenseOverviewResponse within.
     */
    async unassignLicenseUsingPUT(requestParameters: UnassignLicenseUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnassignmentResultResponse> {
        const response = await this.unassignLicenseUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
