/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Granularity } from './Granularity';
import {
    GranularityFromJSON,
    GranularityFromJSONTyped,
    GranularityToJSON,
    GranularityToJSONTyped,
} from './Granularity';

/**
 * 
 * @export
 * @interface AutomatedReportSettingsRequest
 */
export interface AutomatedReportSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsRequest
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsRequest
     */
    frequency?: AutomatedReportSettingsRequestFrequencyEnum;
    /**
     * 
     * @type {Granularity}
     * @memberof AutomatedReportSettingsRequest
     */
    granularity?: Granularity;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsRequest
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsRequest
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AutomatedReportSettingsRequest
     */
    recipientEmails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsRequest
     */
    reportVariant?: AutomatedReportSettingsRequestReportVariantEnum;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsRequest
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsRequest
     */
    timezone?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AutomatedReportSettingsRequest
     */
    virtualFridgeIds?: Array<string>;
}


/**
 * @export
 */
export const AutomatedReportSettingsRequestFrequencyEnum = {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly'
} as const;
export type AutomatedReportSettingsRequestFrequencyEnum = typeof AutomatedReportSettingsRequestFrequencyEnum[keyof typeof AutomatedReportSettingsRequestFrequencyEnum];

/**
 * @export
 */
export const AutomatedReportSettingsRequestReportVariantEnum = {
    HACCP: 'HACCP',
    TEMPCONFORMITY: 'TEMPCONFORMITY'
} as const;
export type AutomatedReportSettingsRequestReportVariantEnum = typeof AutomatedReportSettingsRequestReportVariantEnum[keyof typeof AutomatedReportSettingsRequestReportVariantEnum];


/**
 * Check if a given object implements the AutomatedReportSettingsRequest interface.
 */
export function instanceOfAutomatedReportSettingsRequest(value: object): value is AutomatedReportSettingsRequest {
    return true;
}

export function AutomatedReportSettingsRequestFromJSON(json: any): AutomatedReportSettingsRequest {
    return AutomatedReportSettingsRequestFromJSONTyped(json, false);
}

export function AutomatedReportSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomatedReportSettingsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'frequency': json['frequency'] == null ? undefined : json['frequency'],
        'granularity': json['granularity'] == null ? undefined : GranularityFromJSON(json['granularity']),
        'language': json['language'] == null ? undefined : json['language'],
        'name': json['name'] == null ? undefined : json['name'],
        'recipientEmails': json['recipientEmails'] == null ? undefined : json['recipientEmails'],
        'reportVariant': json['reportVariant'] == null ? undefined : json['reportVariant'],
        'startDate': json['startDate'] == null ? undefined : json['startDate'],
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
        'virtualFridgeIds': json['virtualFridgeIds'] == null ? undefined : json['virtualFridgeIds'],
    };
}

export function AutomatedReportSettingsRequestToJSON(json: any): AutomatedReportSettingsRequest {
    return AutomatedReportSettingsRequestToJSONTyped(json, false);
}

export function AutomatedReportSettingsRequestToJSONTyped(value?: AutomatedReportSettingsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'createdBy': value['createdBy'],
        'frequency': value['frequency'],
        'granularity': GranularityToJSON(value['granularity']),
        'language': value['language'],
        'name': value['name'],
        'recipientEmails': value['recipientEmails'],
        'reportVariant': value['reportVariant'],
        'startDate': value['startDate'],
        'timezone': value['timezone'],
        'virtualFridgeIds': value['virtualFridgeIds'],
    };
}

