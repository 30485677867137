/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserPermissions } from './UserPermissions';
import {
    UserPermissionsFromJSON,
    UserPermissionsFromJSONTyped,
    UserPermissionsToJSON,
    UserPermissionsToJSONTyped,
} from './UserPermissions';

/**
 * 
 * @export
 * @interface UpdatePermissionsRequest
 */
export interface UpdatePermissionsRequest {
    /**
     * 
     * @type {Array<UserPermissions>}
     * @memberof UpdatePermissionsRequest
     */
    userPermissions?: Array<UserPermissions>;
}

/**
 * Check if a given object implements the UpdatePermissionsRequest interface.
 */
export function instanceOfUpdatePermissionsRequest(value: object): value is UpdatePermissionsRequest {
    return true;
}

export function UpdatePermissionsRequestFromJSON(json: any): UpdatePermissionsRequest {
    return UpdatePermissionsRequestFromJSONTyped(json, false);
}

export function UpdatePermissionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePermissionsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userPermissions': json['userPermissions'] == null ? undefined : ((json['userPermissions'] as Array<any>).map(UserPermissionsFromJSON)),
    };
}

export function UpdatePermissionsRequestToJSON(json: any): UpdatePermissionsRequest {
    return UpdatePermissionsRequestToJSONTyped(json, false);
}

export function UpdatePermissionsRequestToJSONTyped(value?: UpdatePermissionsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'userPermissions': value['userPermissions'] == null ? undefined : ((value['userPermissions'] as Array<any>).map(UserPermissionsToJSON)),
    };
}

