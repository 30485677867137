/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ZoneAlarmSettingsResponse } from './ZoneAlarmSettingsResponse';
import {
    ZoneAlarmSettingsResponseFromJSON,
    ZoneAlarmSettingsResponseFromJSONTyped,
    ZoneAlarmSettingsResponseToJSON,
    ZoneAlarmSettingsResponseToJSONTyped,
} from './ZoneAlarmSettingsResponse';

/**
 * 
 * @export
 * @interface SmartModuleAlarmSettingsResponse
 */
export interface SmartModuleAlarmSettingsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SmartModuleAlarmSettingsResponse
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SmartModuleAlarmSettingsResponse
     */
    fridgeSerialNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof SmartModuleAlarmSettingsResponse
     */
    offlineAlarmDelayInSeconds?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SmartModuleAlarmSettingsResponse
     */
    offlineAlarmEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SmartModuleAlarmSettingsResponse
     */
    virtualFridgeId?: string;
    /**
     * 
     * @type {Array<ZoneAlarmSettingsResponse>}
     * @memberof SmartModuleAlarmSettingsResponse
     */
    zoneAlarmSettingsResponses?: Array<ZoneAlarmSettingsResponse>;
}

/**
 * Check if a given object implements the SmartModuleAlarmSettingsResponse interface.
 */
export function instanceOfSmartModuleAlarmSettingsResponse(value: object): value is SmartModuleAlarmSettingsResponse {
    return true;
}

export function SmartModuleAlarmSettingsResponseFromJSON(json: any): SmartModuleAlarmSettingsResponse {
    return SmartModuleAlarmSettingsResponseFromJSONTyped(json, false);
}

export function SmartModuleAlarmSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmartModuleAlarmSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'fridgeSerialNumber': json['fridgeSerialNumber'] == null ? undefined : json['fridgeSerialNumber'],
        'offlineAlarmDelayInSeconds': json['offlineAlarmDelayInSeconds'] == null ? undefined : json['offlineAlarmDelayInSeconds'],
        'offlineAlarmEnabled': json['offlineAlarmEnabled'] == null ? undefined : json['offlineAlarmEnabled'],
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
        'zoneAlarmSettingsResponses': json['zoneAlarmSettingsResponses'] == null ? undefined : ((json['zoneAlarmSettingsResponses'] as Array<any>).map(ZoneAlarmSettingsResponseFromJSON)),
    };
}

export function SmartModuleAlarmSettingsResponseToJSON(json: any): SmartModuleAlarmSettingsResponse {
    return SmartModuleAlarmSettingsResponseToJSONTyped(json, false);
}

export function SmartModuleAlarmSettingsResponseToJSONTyped(value?: SmartModuleAlarmSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'enabled': value['enabled'],
        'fridgeSerialNumber': value['fridgeSerialNumber'],
        'offlineAlarmDelayInSeconds': value['offlineAlarmDelayInSeconds'],
        'offlineAlarmEnabled': value['offlineAlarmEnabled'],
        'virtualFridgeId': value['virtualFridgeId'],
        'zoneAlarmSettingsResponses': value['zoneAlarmSettingsResponses'] == null ? undefined : ((value['zoneAlarmSettingsResponses'] as Array<any>).map(ZoneAlarmSettingsResponseToJSON)),
    };
}

