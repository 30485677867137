/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OnboardedAppliance
 */
export interface OnboardedAppliance {
  /**
   *
   * @type {string}
   * @memberof OnboardedAppliance
   */
  modbusAddress?: string;
  /**
   *
   * @type {string}
   * @memberof OnboardedAppliance
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof OnboardedAppliance
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof OnboardedAppliance
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof OnboardedAppliance
   */
  virtualFridgeId?: string;
}

/**
 * Check if a given object implements the OnboardedAppliance interface.
 */
export function instanceOfOnboardedAppliance(value: object): value is OnboardedAppliance {
  return true;
}

export function OnboardedApplianceFromJSON(json: any): OnboardedAppliance {
  return OnboardedApplianceFromJSONTyped(json, false);
}

export function OnboardedApplianceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardedAppliance {
  if (json == null) {
    return json;
  }
  return {
    modbusAddress: json['modbusAddress'] == null ? undefined : json['modbusAddress'],
    model: json['model'] == null ? undefined : json['model'],
    name: json['name'] == null ? undefined : json['name'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function OnboardedApplianceToJSON(json: any): OnboardedAppliance {
  return OnboardedApplianceToJSONTyped(json, false);
}

export function OnboardedApplianceToJSONTyped(
  value?: OnboardedAppliance | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    modbusAddress: value['modbusAddress'],
    model: value['model'],
    name: value['name'],
    serialNumber: value['serialNumber'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
