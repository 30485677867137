/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AutomatedReportSettingsRequest,
  AutomatedReportSettingsResponse,
  ContinuationReportToken,
  FilteredReportDetailsResponse,
  MultipleReportRequest,
  ReportDetails,
  ReportZipResponse,
  UpdateAutoReportSettings,
} from '../models/index';
import {
    AutomatedReportSettingsRequestFromJSON,
    AutomatedReportSettingsRequestToJSON,
    AutomatedReportSettingsResponseFromJSON,
    AutomatedReportSettingsResponseToJSON,
    ContinuationReportTokenFromJSON,
    ContinuationReportTokenToJSON,
    FilteredReportDetailsResponseFromJSON,
    FilteredReportDetailsResponseToJSON,
    MultipleReportRequestFromJSON,
    MultipleReportRequestToJSON,
    ReportDetailsFromJSON,
    ReportDetailsToJSON,
    ReportZipResponseFromJSON,
    ReportZipResponseToJSON,
    UpdateAutoReportSettingsFromJSON,
    UpdateAutoReportSettingsToJSON,
} from '../models/index';

export interface CreateAutomatedReportUsingPOSTRequest {
    automatedReportSettingsRequest: AutomatedReportSettingsRequest;
}

export interface CreateReportsUsingPOSTRequest {
    reportType: CreateReportsUsingPOSTReportTypeEnum;
    multipleReportRequest: MultipleReportRequest;
}

export interface DeleteReportsUsingDELETERequest {
    reportIds: Array<string>;
}

export interface GetAllReportsUsingGETRequest {
    from: number;
    to: number;
}

export interface GetFilteredAllReportsUsingPUTRequest {
    continuationReportToken: ContinuationReportToken;
    deviceFilter?: string;
    filterGroupIds?: Array<string>;
    filterMSIds?: Array<string>;
    userNames?: Array<string>;
}

export interface GetFilteredReportsByMeasureSiteUsingPUTRequest {
    virtualFridgeId: string;
    continuationReportToken: ContinuationReportToken;
}

export interface GetZippedReportsUsingPUTRequest {
    requestBody: Array<string>;
}

export interface ReplaceAutomatedReportUsingPOSTRequest {
    automatedReportSettingsRequest: AutomatedReportSettingsRequest;
}

export interface UpdateAutomatedReportUsingPUTRequest {
    updateAutoReportSettings: UpdateAutoReportSettings;
}

/**
 * ReportingControllerApi - interface
 * 
 * @export
 * @interface ReportingControllerApiInterface
 */
export interface ReportingControllerApiInterface {
    /**
     * createAutomatedReport
     * @summary Creates automated reports setting.
     * @param {AutomatedReportSettingsRequest} automatedReportSettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApiInterface
     */
    createAutomatedReportUsingPOSTRaw(requestParameters: CreateAutomatedReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutomatedReportSettingsResponse>>;

    /**
     * createAutomatedReport
     * Creates automated reports setting.
     */
    createAutomatedReportUsingPOST(requestParameters: CreateAutomatedReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutomatedReportSettingsResponse>;

    /**
     * createReports
     * @summary Creates a report request
     * @param {'HACCP' | 'TEMPCONFORMITY'} reportType 
     * @param {MultipleReportRequest} multipleReportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApiInterface
     */
    createReportsUsingPOSTRaw(requestParameters: CreateReportsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReportDetails>>>;

    /**
     * createReports
     * Creates a report request
     */
    createReportsUsingPOST(requestParameters: CreateReportsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReportDetails>>;

    /**
     * deleteAutomatedReport
     * @summary Deletes automated report.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApiInterface
     */
    deleteAutomatedReportUsingDELETERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * deleteAutomatedReport
     * Deletes automated report.
     */
    deleteAutomatedReportUsingDELETE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * deleteReports
     * @summary Deletes reports of given IDs
     * @param {Array<string>} reportIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApiInterface
     */
    deleteReportsUsingDELETERaw(requestParameters: DeleteReportsUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * deleteReports
     * Deletes reports of given IDs
     */
    deleteReportsUsingDELETE(requestParameters: DeleteReportsUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * getAllReports
     * @summary Gets all reports
     * @param {number} from 
     * @param {number} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApiInterface
     */
    getAllReportsUsingGETRaw(requestParameters: GetAllReportsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReportDetails>>>;

    /**
     * getAllReports
     * Gets all reports
     */
    getAllReportsUsingGET(requestParameters: GetAllReportsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReportDetails>>;

    /**
     * getAutomatedReport
     * @summary Gets automated reports setting.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApiInterface
     */
    getAutomatedReportUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutomatedReportSettingsResponse>>;

    /**
     * getAutomatedReport
     * Gets automated reports setting.
     */
    getAutomatedReportUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutomatedReportSettingsResponse>;

    /**
     * getFilteredAllReports
     * @summary Gets all reports
     * @param {ContinuationReportToken} continuationReportToken 
     * @param {string} [deviceFilter] 
     * @param {Array<string>} [filterGroupIds] 
     * @param {Array<string>} [filterMSIds] 
     * @param {Array<string>} [userNames] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApiInterface
     */
    getFilteredAllReportsUsingPUTRaw(requestParameters: GetFilteredAllReportsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilteredReportDetailsResponse>>;

    /**
     * getFilteredAllReports
     * Gets all reports
     */
    getFilteredAllReportsUsingPUT(requestParameters: GetFilteredAllReportsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilteredReportDetailsResponse>;

    /**
     * getFilteredReportsByMeasureSite
     * @summary Gets reports by appliance
     * @param {string} virtualFridgeId 
     * @param {ContinuationReportToken} continuationReportToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApiInterface
     */
    getFilteredReportsByMeasureSiteUsingPUTRaw(requestParameters: GetFilteredReportsByMeasureSiteUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilteredReportDetailsResponse>>;

    /**
     * getFilteredReportsByMeasureSite
     * Gets reports by appliance
     */
    getFilteredReportsByMeasureSiteUsingPUT(requestParameters: GetFilteredReportsByMeasureSiteUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilteredReportDetailsResponse>;

    /**
     * getZippedReports
     * @summary Download multiple reports as a zip file.
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApiInterface
     */
    getZippedReportsUsingPUTRaw(requestParameters: GetZippedReportsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportZipResponse>>;

    /**
     * getZippedReports
     * Download multiple reports as a zip file.
     */
    getZippedReportsUsingPUT(requestParameters: GetZippedReportsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportZipResponse>;

    /**
     * replaceAutomatedReport
     * @summary Creates automated reports setting.
     * @param {AutomatedReportSettingsRequest} automatedReportSettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApiInterface
     */
    replaceAutomatedReportUsingPOSTRaw(requestParameters: ReplaceAutomatedReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutomatedReportSettingsResponse>>;

    /**
     * replaceAutomatedReport
     * Creates automated reports setting.
     */
    replaceAutomatedReportUsingPOST(requestParameters: ReplaceAutomatedReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutomatedReportSettingsResponse>;

    /**
     * updateAutomatedReport
     * @summary Updates automated reports setting.
     * @param {UpdateAutoReportSettings} updateAutoReportSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingControllerApiInterface
     */
    updateAutomatedReportUsingPUTRaw(requestParameters: UpdateAutomatedReportUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * updateAutomatedReport
     * Updates automated reports setting.
     */
    updateAutomatedReportUsingPUT(requestParameters: UpdateAutomatedReportUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class ReportingControllerApi extends runtime.BaseAPI implements ReportingControllerApiInterface {

    /**
     * createAutomatedReport
     * Creates automated reports setting.
     */
    async createAutomatedReportUsingPOSTRaw(requestParameters: CreateAutomatedReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutomatedReportSettingsResponse>> {
        if (requestParameters['automatedReportSettingsRequest'] == null) {
            throw new runtime.RequiredError(
                'automatedReportSettingsRequest',
                'Required parameter "automatedReportSettingsRequest" was null or undefined when calling createAutomatedReportUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/create/autoreport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AutomatedReportSettingsRequestToJSON(requestParameters['automatedReportSettingsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutomatedReportSettingsResponseFromJSON(jsonValue));
    }

    /**
     * createAutomatedReport
     * Creates automated reports setting.
     */
    async createAutomatedReportUsingPOST(requestParameters: CreateAutomatedReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutomatedReportSettingsResponse> {
        const response = await this.createAutomatedReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * createReports
     * Creates a report request
     */
    async createReportsUsingPOSTRaw(requestParameters: CreateReportsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReportDetails>>> {
        if (requestParameters['reportType'] == null) {
            throw new runtime.RequiredError(
                'reportType',
                'Required parameter "reportType" was null or undefined when calling createReportsUsingPOST().'
            );
        }

        if (requestParameters['multipleReportRequest'] == null) {
            throw new runtime.RequiredError(
                'multipleReportRequest',
                'Required parameter "multipleReportRequest" was null or undefined when calling createReportsUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/create/{reportType}`.replace(`{${"reportType"}}`, encodeURIComponent(String(requestParameters['reportType']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleReportRequestToJSON(requestParameters['multipleReportRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportDetailsFromJSON));
    }

    /**
     * createReports
     * Creates a report request
     */
    async createReportsUsingPOST(requestParameters: CreateReportsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReportDetails>> {
        const response = await this.createReportsUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteAutomatedReport
     * Deletes automated report.
     */
    async deleteAutomatedReportUsingDELETERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/delete/autoreport`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteAutomatedReport
     * Deletes automated report.
     */
    async deleteAutomatedReportUsingDELETE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAutomatedReportUsingDELETERaw(initOverrides);
    }

    /**
     * deleteReports
     * Deletes reports of given IDs
     */
    async deleteReportsUsingDELETERaw(requestParameters: DeleteReportsUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['reportIds'] == null) {
            throw new runtime.RequiredError(
                'reportIds',
                'Required parameter "reportIds" was null or undefined when calling deleteReportsUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['reportIds'] != null) {
            queryParameters['reportIds'] = requestParameters['reportIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteReports
     * Deletes reports of given IDs
     */
    async deleteReportsUsingDELETE(requestParameters: DeleteReportsUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReportsUsingDELETERaw(requestParameters, initOverrides);
    }

    /**
     * getAllReports
     * Gets all reports
     */
    async getAllReportsUsingGETRaw(requestParameters: GetAllReportsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReportDetails>>> {
        if (requestParameters['from'] == null) {
            throw new runtime.RequiredError(
                'from',
                'Required parameter "from" was null or undefined when calling getAllReportsUsingGET().'
            );
        }

        if (requestParameters['to'] == null) {
            throw new runtime.RequiredError(
                'to',
                'Required parameter "to" was null or undefined when calling getAllReportsUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/all/{from}/{to}`.replace(`{${"from"}}`, encodeURIComponent(String(requestParameters['from']))).replace(`{${"to"}}`, encodeURIComponent(String(requestParameters['to']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportDetailsFromJSON));
    }

    /**
     * getAllReports
     * Gets all reports
     */
    async getAllReportsUsingGET(requestParameters: GetAllReportsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReportDetails>> {
        const response = await this.getAllReportsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAutomatedReport
     * Gets automated reports setting.
     */
    async getAutomatedReportUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutomatedReportSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/autoreport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutomatedReportSettingsResponseFromJSON(jsonValue));
    }

    /**
     * getAutomatedReport
     * Gets automated reports setting.
     */
    async getAutomatedReportUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutomatedReportSettingsResponse> {
        const response = await this.getAutomatedReportUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getFilteredAllReports
     * Gets all reports
     */
    async getFilteredAllReportsUsingPUTRaw(requestParameters: GetFilteredAllReportsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilteredReportDetailsResponse>> {
        if (requestParameters['continuationReportToken'] == null) {
            throw new runtime.RequiredError(
                'continuationReportToken',
                'Required parameter "continuationReportToken" was null or undefined when calling getFilteredAllReportsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['deviceFilter'] != null) {
            queryParameters['deviceFilter'] = requestParameters['deviceFilter'];
        }

        if (requestParameters['filterGroupIds'] != null) {
            queryParameters['filterGroupIds'] = requestParameters['filterGroupIds'];
        }

        if (requestParameters['filterMSIds'] != null) {
            queryParameters['filterMSIds'] = requestParameters['filterMSIds'];
        }

        if (requestParameters['userNames'] != null) {
            queryParameters['userNames'] = requestParameters['userNames'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/filtered/all`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContinuationReportTokenToJSON(requestParameters['continuationReportToken']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilteredReportDetailsResponseFromJSON(jsonValue));
    }

    /**
     * getFilteredAllReports
     * Gets all reports
     */
    async getFilteredAllReportsUsingPUT(requestParameters: GetFilteredAllReportsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilteredReportDetailsResponse> {
        const response = await this.getFilteredAllReportsUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getFilteredReportsByMeasureSite
     * Gets reports by appliance
     */
    async getFilteredReportsByMeasureSiteUsingPUTRaw(requestParameters: GetFilteredReportsByMeasureSiteUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilteredReportDetailsResponse>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getFilteredReportsByMeasureSiteUsingPUT().'
            );
        }

        if (requestParameters['continuationReportToken'] == null) {
            throw new runtime.RequiredError(
                'continuationReportToken',
                'Required parameter "continuationReportToken" was null or undefined when calling getFilteredReportsByMeasureSiteUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/filtered/appliance/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContinuationReportTokenToJSON(requestParameters['continuationReportToken']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilteredReportDetailsResponseFromJSON(jsonValue));
    }

    /**
     * getFilteredReportsByMeasureSite
     * Gets reports by appliance
     */
    async getFilteredReportsByMeasureSiteUsingPUT(requestParameters: GetFilteredReportsByMeasureSiteUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilteredReportDetailsResponse> {
        const response = await this.getFilteredReportsByMeasureSiteUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getZippedReports
     * Download multiple reports as a zip file.
     */
    async getZippedReportsUsingPUTRaw(requestParameters: GetZippedReportsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportZipResponse>> {
        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling getZippedReportsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/report/zipped`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportZipResponseFromJSON(jsonValue));
    }

    /**
     * getZippedReports
     * Download multiple reports as a zip file.
     */
    async getZippedReportsUsingPUT(requestParameters: GetZippedReportsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportZipResponse> {
        const response = await this.getZippedReportsUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * replaceAutomatedReport
     * Creates automated reports setting.
     */
    async replaceAutomatedReportUsingPOSTRaw(requestParameters: ReplaceAutomatedReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AutomatedReportSettingsResponse>> {
        if (requestParameters['automatedReportSettingsRequest'] == null) {
            throw new runtime.RequiredError(
                'automatedReportSettingsRequest',
                'Required parameter "automatedReportSettingsRequest" was null or undefined when calling replaceAutomatedReportUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/replace/autoreport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AutomatedReportSettingsRequestToJSON(requestParameters['automatedReportSettingsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AutomatedReportSettingsResponseFromJSON(jsonValue));
    }

    /**
     * replaceAutomatedReport
     * Creates automated reports setting.
     */
    async replaceAutomatedReportUsingPOST(requestParameters: ReplaceAutomatedReportUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AutomatedReportSettingsResponse> {
        const response = await this.replaceAutomatedReportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateAutomatedReport
     * Updates automated reports setting.
     */
    async updateAutomatedReportUsingPUTRaw(requestParameters: UpdateAutomatedReportUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['updateAutoReportSettings'] == null) {
            throw new runtime.RequiredError(
                'updateAutoReportSettings',
                'Required parameter "updateAutoReportSettings" was null or undefined when calling updateAutomatedReportUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/update/autoreport`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAutoReportSettingsToJSON(requestParameters['updateAutoReportSettings']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * updateAutomatedReport
     * Updates automated reports setting.
     */
    async updateAutomatedReportUsingPUT(requestParameters: UpdateAutomatedReportUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAutomatedReportUsingPUTRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const CreateReportsUsingPOSTReportTypeEnum = {
    HACCP: 'HACCP',
    TEMPCONFORMITY: 'TEMPCONFORMITY'
} as const;
export type CreateReportsUsingPOSTReportTypeEnum = typeof CreateReportsUsingPOSTReportTypeEnum[keyof typeof CreateReportsUsingPOSTReportTypeEnum];
