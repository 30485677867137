/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UUIDListRequest
 */
export interface UUIDListRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof UUIDListRequest
   */
  listOfUUIDs: Array<string>;
}

/**
 * Check if a given object implements the UUIDListRequest interface.
 */
export function instanceOfUUIDListRequest(value: object): value is UUIDListRequest {
  if (!('listOfUUIDs' in value) || value['listOfUUIDs'] === undefined) return false;
  return true;
}

export function UUIDListRequestFromJSON(json: any): UUIDListRequest {
  return UUIDListRequestFromJSONTyped(json, false);
}

export function UUIDListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UUIDListRequest {
  if (json == null) {
    return json;
  }
  return {
    listOfUUIDs: json['listOfUUIDs']
  };
}

export function UUIDListRequestToJSON(json: any): UUIDListRequest {
  return UUIDListRequestToJSONTyped(json, false);
}

export function UUIDListRequestToJSONTyped(value?: UUIDListRequest | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    listOfUUIDs: value['listOfUUIDs']
  };
}
