/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ConnectKeyResponse
 */
export interface ConnectKeyResponse {
  /**
   *
   * @type {string}
   * @memberof ConnectKeyResponse
   */
  connectKey?: string;
  /**
   *
   * @type {string}
   * @memberof ConnectKeyResponse
   */
  internalOrganizationId?: string;
}

/**
 * Check if a given object implements the ConnectKeyResponse interface.
 */
export function instanceOfConnectKeyResponse(value: object): value is ConnectKeyResponse {
  return true;
}

export function ConnectKeyResponseFromJSON(json: any): ConnectKeyResponse {
  return ConnectKeyResponseFromJSONTyped(json, false);
}

export function ConnectKeyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectKeyResponse {
  if (json == null) {
    return json;
  }
  return {
    connectKey: json['connectKey'] == null ? undefined : json['connectKey'],
    internalOrganizationId: json['internalOrganizationId'] == null ? undefined : json['internalOrganizationId']
  };
}

export function ConnectKeyResponseToJSON(json: any): ConnectKeyResponse {
  return ConnectKeyResponseToJSONTyped(json, false);
}

export function ConnectKeyResponseToJSONTyped(
  value?: ConnectKeyResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    connectKey: value['connectKey'],
    internalOrganizationId: value['internalOrganizationId']
  };
}
