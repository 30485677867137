/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplianceZone } from './ApplianceZone';
import {
    ApplianceZoneFromJSON,
    ApplianceZoneFromJSONTyped,
    ApplianceZoneToJSON,
    ApplianceZoneToJSONTyped,
} from './ApplianceZone';
import type { SensorMeasureSite } from './SensorMeasureSite';
import {
    SensorMeasureSiteFromJSON,
    SensorMeasureSiteFromJSONTyped,
    SensorMeasureSiteToJSON,
    SensorMeasureSiteToJSONTyped,
} from './SensorMeasureSite';

/**
 * 
 * @export
 * @interface MeasureSite
 */
export interface MeasureSite {
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    building?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    compartmentType?: MeasureSiteCompartmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    department?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    deviceUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    measureSiteType: MeasureSiteMeasureSiteTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    onboardingState?: MeasureSiteOnboardingStateEnum;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    roomNumber?: string;
    /**
     * 
     * @type {Array<SensorMeasureSite>}
     * @memberof MeasureSite
     */
    sensors?: Array<SensorMeasureSite>;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    serialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    thingId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeasureSite
     */
    twoZoneModel?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeasureSite
     */
    virtualFridgeId?: string;
    /**
     * 
     * @type {Array<ApplianceZone>}
     * @memberof MeasureSite
     */
    zones?: Array<ApplianceZone>;
}


/**
 * @export
 */
export const MeasureSiteCompartmentTypeEnum = {
    COOLER: 'COOLER',
    FREEZER: 'FREEZER'
} as const;
export type MeasureSiteCompartmentTypeEnum = typeof MeasureSiteCompartmentTypeEnum[keyof typeof MeasureSiteCompartmentTypeEnum];

/**
 * @export
 */
export const MeasureSiteMeasureSiteTypeEnum = {
    MODBUS_FRIDGE: 'MODBUS_FRIDGE',
    BINDER_FRIDGE: 'BINDER_FRIDGE',
    SMART_MODULE_FRIDGE: 'SMART_MODULE_FRIDGE',
    LOGGER: 'LOGGER'
} as const;
export type MeasureSiteMeasureSiteTypeEnum = typeof MeasureSiteMeasureSiteTypeEnum[keyof typeof MeasureSiteMeasureSiteTypeEnum];

/**
 * @export
 */
export const MeasureSiteOnboardingStateEnum = {
    VOID: 'VOID',
    AWAIT_ONBOARDING: 'AWAIT_ONBOARDING',
    ONBOARDING_STARTED: 'ONBOARDING_STARTED',
    ONBOARDED: 'ONBOARDED'
} as const;
export type MeasureSiteOnboardingStateEnum = typeof MeasureSiteOnboardingStateEnum[keyof typeof MeasureSiteOnboardingStateEnum];


/**
 * Check if a given object implements the MeasureSite interface.
 */
export function instanceOfMeasureSite(value: object): value is MeasureSite {
    if (!('measureSiteType' in value) || value['measureSiteType'] === undefined) return false;
    return true;
}

export function MeasureSiteFromJSON(json: any): MeasureSite {
    return MeasureSiteFromJSONTyped(json, false);
}

export function MeasureSiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasureSite {
    if (json == null) {
        return json;
    }
    return {
        
        'building': json['building'] == null ? undefined : json['building'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'compartmentType': json['compartmentType'] == null ? undefined : json['compartmentType'],
        'department': json['department'] == null ? undefined : json['department'],
        'deviceUuid': json['deviceUuid'] == null ? undefined : json['deviceUuid'],
        'level': json['level'] == null ? undefined : json['level'],
        'manufacturer': json['manufacturer'] == null ? undefined : json['manufacturer'],
        'measureSiteType': json['measureSiteType'],
        'model': json['model'] == null ? undefined : json['model'],
        'name': json['name'] == null ? undefined : json['name'],
        'onboardingState': json['onboardingState'] == null ? undefined : json['onboardingState'],
        'roomNumber': json['roomNumber'] == null ? undefined : json['roomNumber'],
        'sensors': json['sensors'] == null ? undefined : ((json['sensors'] as Array<any>).map(SensorMeasureSiteFromJSON)),
        'serialNumber': json['serialNumber'] == null ? undefined : json['serialNumber'],
        'thingId': json['thingId'] == null ? undefined : json['thingId'],
        'twoZoneModel': json['twoZoneModel'] == null ? undefined : json['twoZoneModel'],
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
        'zones': json['zones'] == null ? undefined : ((json['zones'] as Array<any>).map(ApplianceZoneFromJSON)),
    };
}

export function MeasureSiteToJSON(json: any): MeasureSite {
    return MeasureSiteToJSONTyped(json, false);
}

export function MeasureSiteToJSONTyped(value?: MeasureSite | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'building': value['building'],
        'comment': value['comment'],
        'compartmentType': value['compartmentType'],
        'department': value['department'],
        'deviceUuid': value['deviceUuid'],
        'level': value['level'],
        'manufacturer': value['manufacturer'],
        'measureSiteType': value['measureSiteType'],
        'model': value['model'],
        'name': value['name'],
        'onboardingState': value['onboardingState'],
        'roomNumber': value['roomNumber'],
        'sensors': value['sensors'] == null ? undefined : ((value['sensors'] as Array<any>).map(SensorMeasureSiteToJSON)),
        'serialNumber': value['serialNumber'],
        'thingId': value['thingId'],
        'twoZoneModel': value['twoZoneModel'],
        'virtualFridgeId': value['virtualFridgeId'],
        'zones': value['zones'] == null ? undefined : ((value['zones'] as Array<any>).map(ApplianceZoneToJSON)),
    };
}

