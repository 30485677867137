/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotificationSetting } from './NotificationSetting';
import {
    NotificationSettingFromJSON,
    NotificationSettingFromJSONTyped,
    NotificationSettingToJSON,
    NotificationSettingToJSONTyped,
} from './NotificationSetting';
import type { ReminderSetting } from './ReminderSetting';
import {
    ReminderSettingFromJSON,
    ReminderSettingFromJSONTyped,
    ReminderSettingToJSON,
    ReminderSettingToJSONTyped,
} from './ReminderSetting';

/**
 * 
 * @export
 * @interface NotificationSettingsResponse
 */
export interface NotificationSettingsResponse {
    /**
     * 
     * @type {Array<NotificationSetting>}
     * @memberof NotificationSettingsResponse
     */
    notificationSettings?: Array<NotificationSetting>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationSettingsResponse
     */
    recipientUpns?: Array<string>;
    /**
     * 
     * @type {ReminderSetting}
     * @memberof NotificationSettingsResponse
     */
    reminderSetting?: ReminderSetting;
    /**
     * 
     * @type {string}
     * @memberof NotificationSettingsResponse
     */
    virtualFridgeId?: string;
}

/**
 * Check if a given object implements the NotificationSettingsResponse interface.
 */
export function instanceOfNotificationSettingsResponse(value: object): value is NotificationSettingsResponse {
    return true;
}

export function NotificationSettingsResponseFromJSON(json: any): NotificationSettingsResponse {
    return NotificationSettingsResponseFromJSONTyped(json, false);
}

export function NotificationSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'notificationSettings': json['notificationSettings'] == null ? undefined : ((json['notificationSettings'] as Array<any>).map(NotificationSettingFromJSON)),
        'recipientUpns': json['recipientUpns'] == null ? undefined : json['recipientUpns'],
        'reminderSetting': json['reminderSetting'] == null ? undefined : ReminderSettingFromJSON(json['reminderSetting']),
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
    };
}

export function NotificationSettingsResponseToJSON(json: any): NotificationSettingsResponse {
    return NotificationSettingsResponseToJSONTyped(json, false);
}

export function NotificationSettingsResponseToJSONTyped(value?: NotificationSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'notificationSettings': value['notificationSettings'] == null ? undefined : ((value['notificationSettings'] as Array<any>).map(NotificationSettingToJSON)),
        'recipientUpns': value['recipientUpns'],
        'reminderSetting': ReminderSettingToJSON(value['reminderSetting']),
        'virtualFridgeId': value['virtualFridgeId'],
    };
}

