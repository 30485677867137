/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeasureSiteAddress } from './MeasureSiteAddress';
import {
    MeasureSiteAddressFromJSON,
    MeasureSiteAddressFromJSONTyped,
    MeasureSiteAddressToJSON,
    MeasureSiteAddressToJSONTyped,
} from './MeasureSiteAddress';
import type { ZoneInfo } from './ZoneInfo';
import {
    ZoneInfoFromJSON,
    ZoneInfoFromJSONTyped,
    ZoneInfoToJSON,
    ZoneInfoToJSONTyped,
} from './ZoneInfo';

/**
 * 
 * @export
 * @interface UpdateMeasureSiteRequest
 */
export interface UpdateMeasureSiteRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    additionalInformation?: string;
    /**
     * 
     * @type {MeasureSiteAddress}
     * @memberof UpdateMeasureSiteRequest
     */
    address?: MeasureSiteAddress;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    building?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    comment?: string;
    /**
     * 
     * @type {ZoneInfo}
     * @memberof UpdateMeasureSiteRequest
     */
    coolerZone?: ZoneInfo;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    countryIsoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    department?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    externalId?: string;
    /**
     * 
     * @type {ZoneInfo}
     * @memberof UpdateMeasureSiteRequest
     */
    freezerZone?: ZoneInfo;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    inventoryNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMeasureSiteRequest
     */
    latitude?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    level?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMeasureSiteRequest
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    mappingType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMeasureSiteRequest
     */
    measureSiteAddress?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateMeasureSiteRequest
     */
    orderDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    room?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    serialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    stateRegion?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    virtualFridgeId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeasureSiteRequest
     */
    zipCode?: string;
}

/**
 * Check if a given object implements the UpdateMeasureSiteRequest interface.
 */
export function instanceOfUpdateMeasureSiteRequest(value: object): value is UpdateMeasureSiteRequest {
    return true;
}

export function UpdateMeasureSiteRequestFromJSON(json: any): UpdateMeasureSiteRequest {
    return UpdateMeasureSiteRequestFromJSONTyped(json, false);
}

export function UpdateMeasureSiteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMeasureSiteRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'additionalInformation': json['additionalInformation'] == null ? undefined : json['additionalInformation'],
        'address': json['address'] == null ? undefined : MeasureSiteAddressFromJSON(json['address']),
        'addressLine1': json['addressLine1'] == null ? undefined : json['addressLine1'],
        'addressLine2': json['addressLine2'] == null ? undefined : json['addressLine2'],
        'building': json['building'] == null ? undefined : json['building'],
        'city': json['city'] == null ? undefined : json['city'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'coolerZone': json['coolerZone'] == null ? undefined : ZoneInfoFromJSON(json['coolerZone']),
        'countryIsoCode': json['countryIsoCode'] == null ? undefined : json['countryIsoCode'],
        'department': json['department'] == null ? undefined : json['department'],
        'externalId': json['externalId'] == null ? undefined : json['externalId'],
        'freezerZone': json['freezerZone'] == null ? undefined : ZoneInfoFromJSON(json['freezerZone']),
        'inventoryNumber': json['inventoryNumber'] == null ? undefined : json['inventoryNumber'],
        'latitude': json['latitude'] == null ? undefined : json['latitude'],
        'level': json['level'] == null ? undefined : json['level'],
        'longitude': json['longitude'] == null ? undefined : json['longitude'],
        'manufacturer': json['manufacturer'] == null ? undefined : json['manufacturer'],
        'mappingType': json['mappingType'] == null ? undefined : json['mappingType'],
        'measureSiteAddress': json['measureSiteAddress'] == null ? undefined : json['measureSiteAddress'],
        'model': json['model'] == null ? undefined : json['model'],
        'name': json['name'] == null ? undefined : json['name'],
        'orderDate': json['orderDate'] == null ? undefined : (new Date(json['orderDate'])),
        'phone': json['phone'] == null ? undefined : json['phone'],
        'room': json['room'] == null ? undefined : json['room'],
        'serialNumber': json['serialNumber'] == null ? undefined : json['serialNumber'],
        'stateRegion': json['stateRegion'] == null ? undefined : json['stateRegion'],
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
        'zipCode': json['zipCode'] == null ? undefined : json['zipCode'],
    };
}

export function UpdateMeasureSiteRequestToJSON(json: any): UpdateMeasureSiteRequest {
    return UpdateMeasureSiteRequestToJSONTyped(json, false);
}

export function UpdateMeasureSiteRequestToJSONTyped(value?: UpdateMeasureSiteRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'additionalInformation': value['additionalInformation'],
        'address': MeasureSiteAddressToJSON(value['address']),
        'addressLine1': value['addressLine1'],
        'addressLine2': value['addressLine2'],
        'building': value['building'],
        'city': value['city'],
        'comment': value['comment'],
        'coolerZone': ZoneInfoToJSON(value['coolerZone']),
        'countryIsoCode': value['countryIsoCode'],
        'department': value['department'],
        'externalId': value['externalId'],
        'freezerZone': ZoneInfoToJSON(value['freezerZone']),
        'inventoryNumber': value['inventoryNumber'],
        'latitude': value['latitude'],
        'level': value['level'],
        'longitude': value['longitude'],
        'manufacturer': value['manufacturer'],
        'mappingType': value['mappingType'],
        'measureSiteAddress': value['measureSiteAddress'],
        'model': value['model'],
        'name': value['name'],
        'orderDate': value['orderDate'] == null ? undefined : ((value['orderDate']).toISOString()),
        'phone': value['phone'],
        'room': value['room'],
        'serialNumber': value['serialNumber'],
        'stateRegion': value['stateRegion'],
        'virtualFridgeId': value['virtualFridgeId'],
        'zipCode': value['zipCode'],
    };
}

