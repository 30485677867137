/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DisplayTemperature
 */
export interface DisplayTemperature {
  /**
   *
   * @type {number}
   * @memberof DisplayTemperature
   */
  celsius?: number;
  /**
   *
   * @type {number}
   * @memberof DisplayTemperature
   */
  fahrenheit?: number;
}

/**
 * Check if a given object implements the DisplayTemperature interface.
 */
export function instanceOfDisplayTemperature(value: object): value is DisplayTemperature {
  return true;
}

export function DisplayTemperatureFromJSON(json: any): DisplayTemperature {
  return DisplayTemperatureFromJSONTyped(json, false);
}

export function DisplayTemperatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayTemperature {
  if (json == null) {
    return json;
  }
  return {
    celsius: json['celsius'] == null ? undefined : json['celsius'],
    fahrenheit: json['fahrenheit'] == null ? undefined : json['fahrenheit']
  };
}

export function DisplayTemperatureToJSON(json: any): DisplayTemperature {
  return DisplayTemperatureToJSONTyped(json, false);
}

export function DisplayTemperatureToJSONTyped(
  value?: DisplayTemperature | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    celsius: value['celsius'],
    fahrenheit: value['fahrenheit']
  };
}
