/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimeZone } from './TimeZone';
import {
    TimeZoneFromJSON,
    TimeZoneFromJSONTyped,
    TimeZoneToJSON,
    TimeZoneToJSONTyped,
} from './TimeZone';

/**
 * 
 * @export
 * @interface TimeZoneList
 */
export interface TimeZoneList {
    /**
     * 
     * @type {TimeZone}
     * @memberof TimeZoneList
     */
    defaultZone?: TimeZone;
    /**
     * 
     * @type {Array<TimeZone>}
     * @memberof TimeZoneList
     */
    zones?: Array<TimeZone>;
}

/**
 * Check if a given object implements the TimeZoneList interface.
 */
export function instanceOfTimeZoneList(value: object): value is TimeZoneList {
    return true;
}

export function TimeZoneListFromJSON(json: any): TimeZoneList {
    return TimeZoneListFromJSONTyped(json, false);
}

export function TimeZoneListFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeZoneList {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultZone': json['defaultZone'] == null ? undefined : TimeZoneFromJSON(json['defaultZone']),
        'zones': json['zones'] == null ? undefined : ((json['zones'] as Array<any>).map(TimeZoneFromJSON)),
    };
}

export function TimeZoneListToJSON(json: any): TimeZoneList {
    return TimeZoneListToJSONTyped(json, false);
}

export function TimeZoneListToJSONTyped(value?: TimeZoneList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultZone': TimeZoneToJSON(value['defaultZone']),
        'zones': value['zones'] == null ? undefined : ((value['zones'] as Array<any>).map(TimeZoneToJSON)),
    };
}

