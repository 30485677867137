/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SupportInfoLicense
 */
export interface SupportInfoLicense {
  /**
   *
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license1?: string;
  /**
   *
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license2?: string;
  /**
   *
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license3?: string;
  /**
   *
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license4?: string;
  /**
   *
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license5?: string;
  /**
   *
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license6?: string;
}

/**
 * Check if a given object implements the SupportInfoLicense interface.
 */
export function instanceOfSupportInfoLicense(value: object): value is SupportInfoLicense {
  return true;
}

export function SupportInfoLicenseFromJSON(json: any): SupportInfoLicense {
  return SupportInfoLicenseFromJSONTyped(json, false);
}

export function SupportInfoLicenseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportInfoLicense {
  if (json == null) {
    return json;
  }
  return {
    license1: json['license1'] == null ? undefined : json['license1'],
    license2: json['license2'] == null ? undefined : json['license2'],
    license3: json['license3'] == null ? undefined : json['license3'],
    license4: json['license4'] == null ? undefined : json['license4'],
    license5: json['license5'] == null ? undefined : json['license5'],
    license6: json['license6'] == null ? undefined : json['license6']
  };
}

export function SupportInfoLicenseToJSON(json: any): SupportInfoLicense {
  return SupportInfoLicenseToJSONTyped(json, false);
}

export function SupportInfoLicenseToJSONTyped(
  value?: SupportInfoLicense | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    license1: value['license1'],
    license2: value['license2'],
    license3: value['license3'],
    license4: value['license4'],
    license5: value['license5'],
    license6: value['license6']
  };
}
