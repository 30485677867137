/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoggerNotificationMatrix } from './LoggerNotificationMatrix';
import {
    LoggerNotificationMatrixFromJSON,
    LoggerNotificationMatrixFromJSONTyped,
    LoggerNotificationMatrixToJSON,
    LoggerNotificationMatrixToJSONTyped,
} from './LoggerNotificationMatrix';
import type { ReminderSetting } from './ReminderSetting';
import {
    ReminderSettingFromJSON,
    ReminderSettingFromJSONTyped,
    ReminderSettingToJSON,
    ReminderSettingToJSONTyped,
} from './ReminderSetting';

/**
 * 
 * @export
 * @interface LoggerNotificationSettings
 */
export interface LoggerNotificationSettings {
    /**
     * 
     * @type {Array<LoggerNotificationMatrix>}
     * @memberof LoggerNotificationSettings
     */
    notificationSettings?: Array<LoggerNotificationMatrix>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoggerNotificationSettings
     */
    recipientUpns?: Array<string>;
    /**
     * 
     * @type {ReminderSetting}
     * @memberof LoggerNotificationSettings
     */
    reminderSetting?: ReminderSetting;
    /**
     * 
     * @type {string}
     * @memberof LoggerNotificationSettings
     */
    virtualFridgeId?: string;
}

/**
 * Check if a given object implements the LoggerNotificationSettings interface.
 */
export function instanceOfLoggerNotificationSettings(value: object): value is LoggerNotificationSettings {
    return true;
}

export function LoggerNotificationSettingsFromJSON(json: any): LoggerNotificationSettings {
    return LoggerNotificationSettingsFromJSONTyped(json, false);
}

export function LoggerNotificationSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggerNotificationSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'notificationSettings': json['notificationSettings'] == null ? undefined : ((json['notificationSettings'] as Array<any>).map(LoggerNotificationMatrixFromJSON)),
        'recipientUpns': json['recipientUpns'] == null ? undefined : json['recipientUpns'],
        'reminderSetting': json['reminderSetting'] == null ? undefined : ReminderSettingFromJSON(json['reminderSetting']),
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
    };
}

export function LoggerNotificationSettingsToJSON(json: any): LoggerNotificationSettings {
    return LoggerNotificationSettingsToJSONTyped(json, false);
}

export function LoggerNotificationSettingsToJSONTyped(value?: LoggerNotificationSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'notificationSettings': value['notificationSettings'] == null ? undefined : ((value['notificationSettings'] as Array<any>).map(LoggerNotificationMatrixToJSON)),
        'recipientUpns': value['recipientUpns'],
        'reminderSetting': ReminderSettingToJSON(value['reminderSetting']),
        'virtualFridgeId': value['virtualFridgeId'],
    };
}

