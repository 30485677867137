/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductName
 */
export interface ProductName {
  /**
   *
   * @type {string}
   * @memberof ProductName
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof ProductName
   */
  name?: string;
}

/**
 * Check if a given object implements the ProductName interface.
 */
export function instanceOfProductName(value: object): value is ProductName {
  return true;
}

export function ProductNameFromJSON(json: any): ProductName {
  return ProductNameFromJSONTyped(json, false);
}

export function ProductNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductName {
  if (json == null) {
    return json;
  }
  return {
    language: json['language'] == null ? undefined : json['language'],
    name: json['name'] == null ? undefined : json['name']
  };
}

export function ProductNameToJSON(json: any): ProductName {
  return ProductNameToJSONTyped(json, false);
}

export function ProductNameToJSONTyped(value?: ProductName | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    language: value['language'],
    name: value['name']
  };
}
