/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Appliance } from './Appliance';
import {
    ApplianceFromJSON,
    ApplianceFromJSONTyped,
    ApplianceToJSON,
    ApplianceToJSONTyped,
} from './Appliance';

/**
 * 
 * @export
 * @interface ApplianceZone
 */
export interface ApplianceZone {
    /**
     * 
     * @type {Appliance}
     * @memberof ApplianceZone
     */
    appliance?: Appliance;
    /**
     * 
     * @type {string}
     * @memberof ApplianceZone
     */
    applianceType?: ApplianceZoneApplianceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplianceZone
     */
    applianceZoneType?: ApplianceZoneApplianceZoneTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ApplianceZone
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplianceZone
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceZone
     */
    sensorType?: ApplianceZoneSensorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplianceZone
     */
    thingId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceZone
     */
    virtualFridgeId?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplianceZone
     */
    zoneIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplianceZone
     */
    zonePosition?: ApplianceZoneZonePositionEnum;
}


/**
 * @export
 */
export const ApplianceZoneApplianceTypeEnum = {
    MODBUS_FRIDGE: 'MODBUS_FRIDGE',
    BINDER_FRIDGE: 'BINDER_FRIDGE',
    SMART_MODULE_FRIDGE: 'SMART_MODULE_FRIDGE',
    LCV_FRIDGE: 'LCV_FRIDGE',
    LOGGER: 'LOGGER'
} as const;
export type ApplianceZoneApplianceTypeEnum = typeof ApplianceZoneApplianceTypeEnum[keyof typeof ApplianceZoneApplianceTypeEnum];

/**
 * @export
 */
export const ApplianceZoneApplianceZoneTypeEnum = {
    COOLER: 'COOLER',
    FREEZER: 'FREEZER'
} as const;
export type ApplianceZoneApplianceZoneTypeEnum = typeof ApplianceZoneApplianceZoneTypeEnum[keyof typeof ApplianceZoneApplianceZoneTypeEnum];

/**
 * @export
 */
export const ApplianceZoneSensorTypeEnum = {
    DOOR: 'DOOR',
    TEMPERATURE: 'TEMPERATURE',
    PRODUCT_TEMPERATURE: 'PRODUCT_TEMPERATURE'
} as const;
export type ApplianceZoneSensorTypeEnum = typeof ApplianceZoneSensorTypeEnum[keyof typeof ApplianceZoneSensorTypeEnum];

/**
 * @export
 */
export const ApplianceZoneZonePositionEnum = {
    TOP: 'TOP',
    MIDDLE: 'MIDDLE',
    BOTTOM: 'BOTTOM'
} as const;
export type ApplianceZoneZonePositionEnum = typeof ApplianceZoneZonePositionEnum[keyof typeof ApplianceZoneZonePositionEnum];


/**
 * Check if a given object implements the ApplianceZone interface.
 */
export function instanceOfApplianceZone(value: object): value is ApplianceZone {
    return true;
}

export function ApplianceZoneFromJSON(json: any): ApplianceZone {
    return ApplianceZoneFromJSONTyped(json, false);
}

export function ApplianceZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceZone {
    if (json == null) {
        return json;
    }
    return {
        
        'appliance': json['appliance'] == null ? undefined : ApplianceFromJSON(json['appliance']),
        'applianceType': json['applianceType'] == null ? undefined : json['applianceType'],
        'applianceZoneType': json['applianceZoneType'] == null ? undefined : json['applianceZoneType'],
        'created': json['created'] == null ? undefined : (new Date(json['created'])),
        'name': json['name'] == null ? undefined : json['name'],
        'sensorType': json['sensorType'] == null ? undefined : json['sensorType'],
        'thingId': json['thingId'] == null ? undefined : json['thingId'],
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
        'zoneIndex': json['zoneIndex'] == null ? undefined : json['zoneIndex'],
        'zonePosition': json['zonePosition'] == null ? undefined : json['zonePosition'],
    };
}

export function ApplianceZoneToJSON(json: any): ApplianceZone {
    return ApplianceZoneToJSONTyped(json, false);
}

export function ApplianceZoneToJSONTyped(value?: ApplianceZone | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'appliance': ApplianceToJSON(value['appliance']),
        'applianceType': value['applianceType'],
        'applianceZoneType': value['applianceZoneType'],
        'created': value['created'] == null ? undefined : ((value['created']).toISOString()),
        'name': value['name'],
        'sensorType': value['sensorType'],
        'thingId': value['thingId'],
        'virtualFridgeId': value['virtualFridgeId'],
        'zoneIndex': value['zoneIndex'],
        'zonePosition': value['zonePosition'],
    };
}

