/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LicenseInfo
 */
export interface LicenseInfo {
  /**
   *
   * @type {string}
   * @memberof LicenseInfo
   */
  assigneeType?: string;
  /**
   *
   * @type {Date}
   * @memberof LicenseInfo
   */
  endDate?: Date;
  /**
   *
   * @type {string}
   * @memberof LicenseInfo
   */
  entitlementId?: string;
  /**
   *
   * @type {string}
   * @memberof LicenseInfo
   */
  licenseKey?: string;
  /**
   *
   * @type {string}
   * @memberof LicenseInfo
   */
  licenseType?: LicenseInfoLicenseTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof LicenseInfo
   */
  locked?: boolean;
  /**
   *
   * @type {string}
   * @memberof LicenseInfo
   */
  productId?: string;
  /**
   *
   * @type {Date}
   * @memberof LicenseInfo
   */
  startDate?: Date;
  /**
   *
   * @type {Set<string>}
   * @memberof LicenseInfo
   */
  virtualFridgeIds?: Set<string>;
}

/**
 * @export
 */
export const LicenseInfoLicenseTypeEnum = {
  TEMPORARY: 'TEMPORARY',
  UNKNOWN: 'UNKNOWN'
} as const;
export type LicenseInfoLicenseTypeEnum = typeof LicenseInfoLicenseTypeEnum[keyof typeof LicenseInfoLicenseTypeEnum];

/**
 * Check if a given object implements the LicenseInfo interface.
 */
export function instanceOfLicenseInfo(value: object): value is LicenseInfo {
  return true;
}

export function LicenseInfoFromJSON(json: any): LicenseInfo {
  return LicenseInfoFromJSONTyped(json, false);
}

export function LicenseInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenseInfo {
  if (json == null) {
    return json;
  }
  return {
    assigneeType: json['assigneeType'] == null ? undefined : json['assigneeType'],
    endDate: json['endDate'] == null ? undefined : new Date(json['endDate']),
    entitlementId: json['entitlementId'] == null ? undefined : json['entitlementId'],
    licenseKey: json['licenseKey'] == null ? undefined : json['licenseKey'],
    licenseType: json['licenseType'] == null ? undefined : json['licenseType'],
    locked: json['locked'] == null ? undefined : json['locked'],
    productId: json['productId'] == null ? undefined : json['productId'],
    startDate: json['startDate'] == null ? undefined : new Date(json['startDate']),
    virtualFridgeIds: json['virtualFridgeIds'] == null ? undefined : new Set(json['virtualFridgeIds'])
  };
}

export function LicenseInfoToJSON(json: any): LicenseInfo {
  return LicenseInfoToJSONTyped(json, false);
}

export function LicenseInfoToJSONTyped(value?: LicenseInfo | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    assigneeType: value['assigneeType'],
    endDate: value['endDate'] == null ? undefined : value['endDate'].toISOString(),
    entitlementId: value['entitlementId'],
    licenseKey: value['licenseKey'],
    licenseType: value['licenseType'],
    locked: value['locked'],
    productId: value['productId'],
    startDate: value['startDate'] == null ? undefined : value['startDate'].toISOString(),
    virtualFridgeIds: value['virtualFridgeIds'] == null ? undefined : Array.from(value['virtualFridgeIds'] as Set<any>)
  };
}
