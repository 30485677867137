/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface GatewayAlarmProfileUpdateRequest
 */
export interface GatewayAlarmProfileUpdateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof GatewayAlarmProfileUpdateRequest
     */
    alarmActivated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GatewayAlarmProfileUpdateRequest
     */
    alarmDelay?: number;
    /**
     * 
     * @type {string}
     * @memberof GatewayAlarmProfileUpdateRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayAlarmProfileUpdateRequest
     */
    gatewayId?: string;
    /**
     * 
     * @type {number}
     * @memberof GatewayAlarmProfileUpdateRequest
     */
    repetitionTime?: number;
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof GatewayAlarmProfileUpdateRequest
     */
    userProfiles?: Array<UserProfile>;
}

/**
 * Check if a given object implements the GatewayAlarmProfileUpdateRequest interface.
 */
export function instanceOfGatewayAlarmProfileUpdateRequest(value: object): value is GatewayAlarmProfileUpdateRequest {
    return true;
}

export function GatewayAlarmProfileUpdateRequestFromJSON(json: any): GatewayAlarmProfileUpdateRequest {
    return GatewayAlarmProfileUpdateRequestFromJSONTyped(json, false);
}

export function GatewayAlarmProfileUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GatewayAlarmProfileUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'alarmActivated': json['alarmActivated'] == null ? undefined : json['alarmActivated'],
        'alarmDelay': json['alarmDelay'] == null ? undefined : json['alarmDelay'],
        'description': json['description'] == null ? undefined : json['description'],
        'gatewayId': json['gatewayId'] == null ? undefined : json['gatewayId'],
        'repetitionTime': json['repetitionTime'] == null ? undefined : json['repetitionTime'],
        'userProfiles': json['userProfiles'] == null ? undefined : ((json['userProfiles'] as Array<any>).map(UserProfileFromJSON)),
    };
}

export function GatewayAlarmProfileUpdateRequestToJSON(json: any): GatewayAlarmProfileUpdateRequest {
    return GatewayAlarmProfileUpdateRequestToJSONTyped(json, false);
}

export function GatewayAlarmProfileUpdateRequestToJSONTyped(value?: GatewayAlarmProfileUpdateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alarmActivated': value['alarmActivated'],
        'alarmDelay': value['alarmDelay'],
        'description': value['description'],
        'gatewayId': value['gatewayId'],
        'repetitionTime': value['repetitionTime'],
        'userProfiles': value['userProfiles'] == null ? undefined : ((value['userProfiles'] as Array<any>).map(UserProfileToJSON)),
    };
}

