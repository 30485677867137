/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportDetails } from './ReportDetails';
import {
    ReportDetailsFromJSON,
    ReportDetailsFromJSONTyped,
    ReportDetailsToJSON,
    ReportDetailsToJSONTyped,
} from './ReportDetails';
import type { ContinuationReportToken } from './ContinuationReportToken';
import {
    ContinuationReportTokenFromJSON,
    ContinuationReportTokenFromJSONTyped,
    ContinuationReportTokenToJSON,
    ContinuationReportTokenToJSONTyped,
} from './ContinuationReportToken';

/**
 * 
 * @export
 * @interface FilteredReportDetailsResponse
 */
export interface FilteredReportDetailsResponse {
    /**
     * 
     * @type {Array<ReportDetails>}
     * @memberof FilteredReportDetailsResponse
     */
    reportDetails?: Array<ReportDetails>;
    /**
     * 
     * @type {ContinuationReportToken}
     * @memberof FilteredReportDetailsResponse
     */
    token?: ContinuationReportToken;
}

/**
 * Check if a given object implements the FilteredReportDetailsResponse interface.
 */
export function instanceOfFilteredReportDetailsResponse(value: object): value is FilteredReportDetailsResponse {
    return true;
}

export function FilteredReportDetailsResponseFromJSON(json: any): FilteredReportDetailsResponse {
    return FilteredReportDetailsResponseFromJSONTyped(json, false);
}

export function FilteredReportDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilteredReportDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'reportDetails': json['reportDetails'] == null ? undefined : ((json['reportDetails'] as Array<any>).map(ReportDetailsFromJSON)),
        'token': json['token'] == null ? undefined : ContinuationReportTokenFromJSON(json['token']),
    };
}

export function FilteredReportDetailsResponseToJSON(json: any): FilteredReportDetailsResponse {
    return FilteredReportDetailsResponseToJSONTyped(json, false);
}

export function FilteredReportDetailsResponseToJSONTyped(value?: FilteredReportDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'reportDetails': value['reportDetails'] == null ? undefined : ((value['reportDetails'] as Array<any>).map(ReportDetailsToJSON)),
        'token': ContinuationReportTokenToJSON(value['token']),
    };
}

