/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DisplayTemperature } from './DisplayTemperature';
import {
    DisplayTemperatureFromJSON,
    DisplayTemperatureFromJSONTyped,
    DisplayTemperatureToJSON,
    DisplayTemperatureToJSONTyped,
} from './DisplayTemperature';

/**
 * 
 * @export
 * @interface DeviceTemperatureAlarmSettingsResponse
 */
export interface DeviceTemperatureAlarmSettingsResponse {
    /**
     * 
     * @type {DisplayTemperature}
     * @memberof DeviceTemperatureAlarmSettingsResponse
     */
    lowerTemperatureAlarmLimit?: DisplayTemperature;
    /**
     * 
     * @type {number}
     * @memberof DeviceTemperatureAlarmSettingsResponse
     */
    lowerTemperatureAlarmLimitDelay?: number;
    /**
     * 
     * @type {DisplayTemperature}
     * @memberof DeviceTemperatureAlarmSettingsResponse
     */
    setTemperature?: DisplayTemperature;
    /**
     * 
     * @type {DisplayTemperature}
     * @memberof DeviceTemperatureAlarmSettingsResponse
     */
    upperTemperatureAlarmLimit?: DisplayTemperature;
    /**
     * 
     * @type {number}
     * @memberof DeviceTemperatureAlarmSettingsResponse
     */
    upperTemperatureAlarmLimitDelay?: number;
}

/**
 * Check if a given object implements the DeviceTemperatureAlarmSettingsResponse interface.
 */
export function instanceOfDeviceTemperatureAlarmSettingsResponse(value: object): value is DeviceTemperatureAlarmSettingsResponse {
    return true;
}

export function DeviceTemperatureAlarmSettingsResponseFromJSON(json: any): DeviceTemperatureAlarmSettingsResponse {
    return DeviceTemperatureAlarmSettingsResponseFromJSONTyped(json, false);
}

export function DeviceTemperatureAlarmSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceTemperatureAlarmSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'lowerTemperatureAlarmLimit': json['lowerTemperatureAlarmLimit'] == null ? undefined : DisplayTemperatureFromJSON(json['lowerTemperatureAlarmLimit']),
        'lowerTemperatureAlarmLimitDelay': json['lowerTemperatureAlarmLimitDelay'] == null ? undefined : json['lowerTemperatureAlarmLimitDelay'],
        'setTemperature': json['setTemperature'] == null ? undefined : DisplayTemperatureFromJSON(json['setTemperature']),
        'upperTemperatureAlarmLimit': json['upperTemperatureAlarmLimit'] == null ? undefined : DisplayTemperatureFromJSON(json['upperTemperatureAlarmLimit']),
        'upperTemperatureAlarmLimitDelay': json['upperTemperatureAlarmLimitDelay'] == null ? undefined : json['upperTemperatureAlarmLimitDelay'],
    };
}

export function DeviceTemperatureAlarmSettingsResponseToJSON(json: any): DeviceTemperatureAlarmSettingsResponse {
    return DeviceTemperatureAlarmSettingsResponseToJSONTyped(json, false);
}

export function DeviceTemperatureAlarmSettingsResponseToJSONTyped(value?: DeviceTemperatureAlarmSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'lowerTemperatureAlarmLimit': DisplayTemperatureToJSON(value['lowerTemperatureAlarmLimit']),
        'lowerTemperatureAlarmLimitDelay': value['lowerTemperatureAlarmLimitDelay'],
        'setTemperature': DisplayTemperatureToJSON(value['setTemperature']),
        'upperTemperatureAlarmLimit': DisplayTemperatureToJSON(value['upperTemperatureAlarmLimit']),
        'upperTemperatureAlarmLimitDelay': value['upperTemperatureAlarmLimitDelay'],
    };
}

