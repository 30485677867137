/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SmartModuleRequest
 */
export interface SmartModuleRequest {
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  building?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  countryIsoCode?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  department?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  inventoryNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  ipAddress: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  level?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  macAddress: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  roomNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  serialNumber: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleRequest
   */
  timezone: string;
}

/**
 * Check if a given object implements the SmartModuleRequest interface.
 */
export function instanceOfSmartModuleRequest(value: object): value is SmartModuleRequest {
  if (!('ipAddress' in value) || value['ipAddress'] === undefined) return false;
  if (!('macAddress' in value) || value['macAddress'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('serialNumber' in value) || value['serialNumber'] === undefined) return false;
  if (!('timezone' in value) || value['timezone'] === undefined) return false;
  return true;
}

export function SmartModuleRequestFromJSON(json: any): SmartModuleRequest {
  return SmartModuleRequestFromJSONTyped(json, false);
}

export function SmartModuleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmartModuleRequest {
  if (json == null) {
    return json;
  }
  return {
    building: json['building'] == null ? undefined : json['building'],
    comment: json['comment'] == null ? undefined : json['comment'],
    countryIsoCode: json['countryIsoCode'] == null ? undefined : json['countryIsoCode'],
    department: json['department'] == null ? undefined : json['department'],
    inventoryNumber: json['inventoryNumber'] == null ? undefined : json['inventoryNumber'],
    ipAddress: json['ipAddress'],
    level: json['level'] == null ? undefined : json['level'],
    macAddress: json['macAddress'],
    manufacturer: json['manufacturer'] == null ? undefined : json['manufacturer'],
    model: json['model'] == null ? undefined : json['model'],
    name: json['name'],
    roomNumber: json['roomNumber'] == null ? undefined : json['roomNumber'],
    serialNumber: json['serialNumber'],
    timezone: json['timezone']
  };
}

export function SmartModuleRequestToJSON(json: any): SmartModuleRequest {
  return SmartModuleRequestToJSONTyped(json, false);
}

export function SmartModuleRequestToJSONTyped(
  value?: SmartModuleRequest | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    building: value['building'],
    comment: value['comment'],
    countryIsoCode: value['countryIsoCode'],
    department: value['department'],
    inventoryNumber: value['inventoryNumber'],
    ipAddress: value['ipAddress'],
    level: value['level'],
    macAddress: value['macAddress'],
    manufacturer: value['manufacturer'],
    model: value['model'],
    name: value['name'],
    roomNumber: value['roomNumber'],
    serialNumber: value['serialNumber'],
    timezone: value['timezone']
  };
}
