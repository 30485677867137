/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserMobileRequest
 */
export interface UserMobileRequest {
  /**
   *
   * @type {string}
   * @memberof UserMobileRequest
   */
  mobile: string;
  /**
   *
   * @type {string}
   * @memberof UserMobileRequest
   */
  upn?: string;
}

/**
 * Check if a given object implements the UserMobileRequest interface.
 */
export function instanceOfUserMobileRequest(value: object): value is UserMobileRequest {
  if (!('mobile' in value) || value['mobile'] === undefined) return false;
  return true;
}

export function UserMobileRequestFromJSON(json: any): UserMobileRequest {
  return UserMobileRequestFromJSONTyped(json, false);
}

export function UserMobileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMobileRequest {
  if (json == null) {
    return json;
  }
  return {
    mobile: json['mobile'],
    upn: json['upn'] == null ? undefined : json['upn']
  };
}

export function UserMobileRequestToJSON(json: any): UserMobileRequest {
  return UserMobileRequestToJSONTyped(json, false);
}

export function UserMobileRequestToJSONTyped(
  value?: UserMobileRequest | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    mobile: value['mobile'],
    upn: value['upn']
  };
}
