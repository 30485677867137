<template>
  <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="matrix(1.5, 0, 0, 1.5, 0, 0)">
      <g>
        <g style="fill: var(--v-bus_red-base)">
          <circle cx="16" cy="16" r="16" />
        </g>
        <g transform="scale(0.55,0.55)">
          <g transform="translate(10.000000, 10.000000)">
            <path
              d="M36.5898438,30.2441406 L0,30.2441406 L18.2949219,0 L36.5898438,30.2441406 Z M2.15234375,29.0566406 L34.5117188,29.0566406 L18.2949219,2.33789062 L2.15234375,29.0566406 Z M19.7792969,11.3554688 L19.1113281,20.6328125 L17.3300781,20.6328125 L16.7363281,11.3554688 L19.7792969,11.3554688 Z M19.7050781,25.6054688 L16.9960938,25.6054688 L16.9960938,22.8222656 L19.7050781,22.8222656 L19.7050781,25.6054688 Z"
              style="fill: var(--v-bus_white-base)"
            />
            <g transform="translate(9.300000, 6.000000)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
