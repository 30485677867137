/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConnectivityResponse } from './ConnectivityResponse';
import {
    ConnectivityResponseFromJSON,
    ConnectivityResponseFromJSONTyped,
    ConnectivityResponseToJSON,
    ConnectivityResponseToJSONTyped,
} from './ConnectivityResponse';

/**
 * 
 * @export
 * @interface MasterDataApplianceInfoResponse
 */
export interface MasterDataApplianceInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof MasterDataApplianceInfoResponse
     */
    articleNumber?: string;
    /**
     * 
     * @type {ConnectivityResponse}
     * @memberof MasterDataApplianceInfoResponse
     */
    connectivityResponse?: ConnectivityResponse;
    /**
     * 
     * @type {string}
     * @memberof MasterDataApplianceInfoResponse
     */
    foreignDesignation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MasterDataApplianceInfoResponse
     */
    free?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MasterDataApplianceInfoResponse
     */
    heavyDuty?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MasterDataApplianceInfoResponse
     */
    modelDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof MasterDataApplianceInfoResponse
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof MasterDataApplianceInfoResponse
     */
    pictureUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MasterDataApplianceInfoResponse
     */
    twoZoneModel?: boolean;
}

/**
 * Check if a given object implements the MasterDataApplianceInfoResponse interface.
 */
export function instanceOfMasterDataApplianceInfoResponse(value: object): value is MasterDataApplianceInfoResponse {
    return true;
}

export function MasterDataApplianceInfoResponseFromJSON(json: any): MasterDataApplianceInfoResponse {
    return MasterDataApplianceInfoResponseFromJSONTyped(json, false);
}

export function MasterDataApplianceInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterDataApplianceInfoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'articleNumber': json['articleNumber'] == null ? undefined : json['articleNumber'],
        'connectivityResponse': json['connectivityResponse'] == null ? undefined : ConnectivityResponseFromJSON(json['connectivityResponse']),
        'foreignDesignation': json['foreignDesignation'] == null ? undefined : json['foreignDesignation'],
        'free': json['free'] == null ? undefined : json['free'],
        'heavyDuty': json['heavyDuty'] == null ? undefined : json['heavyDuty'],
        'modelDescription': json['modelDescription'] == null ? undefined : json['modelDescription'],
        'organizationId': json['organizationId'] == null ? undefined : json['organizationId'],
        'pictureUrl': json['pictureUrl'] == null ? undefined : json['pictureUrl'],
        'twoZoneModel': json['twoZoneModel'] == null ? undefined : json['twoZoneModel'],
    };
}

export function MasterDataApplianceInfoResponseToJSON(json: any): MasterDataApplianceInfoResponse {
    return MasterDataApplianceInfoResponseToJSONTyped(json, false);
}

export function MasterDataApplianceInfoResponseToJSONTyped(value?: MasterDataApplianceInfoResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'articleNumber': value['articleNumber'],
        'connectivityResponse': ConnectivityResponseToJSON(value['connectivityResponse']),
        'foreignDesignation': value['foreignDesignation'],
        'free': value['free'],
        'heavyDuty': value['heavyDuty'],
        'modelDescription': value['modelDescription'],
        'organizationId': value['organizationId'],
        'pictureUrl': value['pictureUrl'],
        'twoZoneModel': value['twoZoneModel'],
    };
}

