/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ContinuationAlarmToken
 */
export interface ContinuationAlarmToken {
  /**
   *
   * @type {number}
   * @memberof ContinuationAlarmToken
   */
  deviceAlarms?: number;
  /**
   *
   * @type {number}
   * @memberof ContinuationAlarmToken
   */
  gatewayAlarms?: number;
  /**
   *
   * @type {number}
   * @memberof ContinuationAlarmToken
   */
  loggerAlarms?: number;
  /**
   *
   * @type {boolean}
   * @memberof ContinuationAlarmToken
   */
  more?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ContinuationAlarmToken
   */
  moreDeviceAlarms?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ContinuationAlarmToken
   */
  moreGatewayAlarms?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ContinuationAlarmToken
   */
  moreLoggerAlarms?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ContinuationAlarmToken
   */
  moreSmartModuleAlarms?: boolean;
  /**
   *
   * @type {number}
   * @memberof ContinuationAlarmToken
   */
  smartModuleAlarms?: number;
}

/**
 * Check if a given object implements the ContinuationAlarmToken interface.
 */
export function instanceOfContinuationAlarmToken(value: object): value is ContinuationAlarmToken {
  return true;
}

export function ContinuationAlarmTokenFromJSON(json: any): ContinuationAlarmToken {
  return ContinuationAlarmTokenFromJSONTyped(json, false);
}

export function ContinuationAlarmTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContinuationAlarmToken {
  if (json == null) {
    return json;
  }
  return {
    deviceAlarms: json['deviceAlarms'] == null ? undefined : json['deviceAlarms'],
    gatewayAlarms: json['gatewayAlarms'] == null ? undefined : json['gatewayAlarms'],
    loggerAlarms: json['loggerAlarms'] == null ? undefined : json['loggerAlarms'],
    more: json['more'] == null ? undefined : json['more'],
    moreDeviceAlarms: json['moreDeviceAlarms'] == null ? undefined : json['moreDeviceAlarms'],
    moreGatewayAlarms: json['moreGatewayAlarms'] == null ? undefined : json['moreGatewayAlarms'],
    moreLoggerAlarms: json['moreLoggerAlarms'] == null ? undefined : json['moreLoggerAlarms'],
    moreSmartModuleAlarms: json['moreSmartModuleAlarms'] == null ? undefined : json['moreSmartModuleAlarms'],
    smartModuleAlarms: json['smartModuleAlarms'] == null ? undefined : json['smartModuleAlarms']
  };
}

export function ContinuationAlarmTokenToJSON(json: any): ContinuationAlarmToken {
  return ContinuationAlarmTokenToJSONTyped(json, false);
}

export function ContinuationAlarmTokenToJSONTyped(
  value?: ContinuationAlarmToken | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    deviceAlarms: value['deviceAlarms'],
    gatewayAlarms: value['gatewayAlarms'],
    loggerAlarms: value['loggerAlarms'],
    more: value['more'],
    moreDeviceAlarms: value['moreDeviceAlarms'],
    moreGatewayAlarms: value['moreGatewayAlarms'],
    moreLoggerAlarms: value['moreLoggerAlarms'],
    moreSmartModuleAlarms: value['moreSmartModuleAlarms'],
    smartModuleAlarms: value['smartModuleAlarms']
  };
}
