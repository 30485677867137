/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GatewayNetworkState } from './GatewayNetworkState';
import {
    GatewayNetworkStateFromJSON,
    GatewayNetworkStateFromJSONTyped,
    GatewayNetworkStateToJSON,
    GatewayNetworkStateToJSONTyped,
} from './GatewayNetworkState';

/**
 * 
 * @export
 * @interface ExtendedGatewayState
 */
export interface ExtendedGatewayState {
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedGatewayState
     */
    connected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExtendedGatewayState
     */
    gatewayId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ExtendedGatewayState
     */
    lastActivityTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExtendedGatewayState
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedGatewayState
     */
    name?: string;
    /**
     * 
     * @type {GatewayNetworkState}
     * @memberof ExtendedGatewayState
     */
    network?: GatewayNetworkState;
    /**
     * 
     * @type {string}
     * @memberof ExtendedGatewayState
     */
    schSoftwareUpdateState?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedGatewayState
     */
    schSoftwareVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedGatewayState
     */
    serialNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof ExtendedGatewayState
     */
    stateUpdatedTime?: Date;
}

/**
 * Check if a given object implements the ExtendedGatewayState interface.
 */
export function instanceOfExtendedGatewayState(value: object): value is ExtendedGatewayState {
    return true;
}

export function ExtendedGatewayStateFromJSON(json: any): ExtendedGatewayState {
    return ExtendedGatewayStateFromJSONTyped(json, false);
}

export function ExtendedGatewayStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendedGatewayState {
    if (json == null) {
        return json;
    }
    return {
        
        'connected': json['connected'] == null ? undefined : json['connected'],
        'gatewayId': json['gatewayId'] == null ? undefined : json['gatewayId'],
        'lastActivityTime': json['lastActivityTime'] == null ? undefined : (new Date(json['lastActivityTime'])),
        'model': json['model'] == null ? undefined : json['model'],
        'name': json['name'] == null ? undefined : json['name'],
        'network': json['network'] == null ? undefined : GatewayNetworkStateFromJSON(json['network']),
        'schSoftwareUpdateState': json['schSoftwareUpdateState'] == null ? undefined : json['schSoftwareUpdateState'],
        'schSoftwareVersion': json['schSoftwareVersion'] == null ? undefined : json['schSoftwareVersion'],
        'serialNumber': json['serialNumber'] == null ? undefined : json['serialNumber'],
        'stateUpdatedTime': json['stateUpdatedTime'] == null ? undefined : (new Date(json['stateUpdatedTime'])),
    };
}

export function ExtendedGatewayStateToJSON(json: any): ExtendedGatewayState {
    return ExtendedGatewayStateToJSONTyped(json, false);
}

export function ExtendedGatewayStateToJSONTyped(value?: ExtendedGatewayState | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'connected': value['connected'],
        'gatewayId': value['gatewayId'],
        'lastActivityTime': value['lastActivityTime'] == null ? undefined : ((value['lastActivityTime']).toISOString()),
        'model': value['model'],
        'name': value['name'],
        'network': GatewayNetworkStateToJSON(value['network']),
        'schSoftwareUpdateState': value['schSoftwareUpdateState'],
        'schSoftwareVersion': value['schSoftwareVersion'],
        'serialNumber': value['serialNumber'],
        'stateUpdatedTime': value['stateUpdatedTime'] == null ? undefined : ((value['stateUpdatedTime']).toISOString()),
    };
}

