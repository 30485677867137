/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReminderSetting
 */
export interface ReminderSetting {
  /**
   *
   * @type {boolean}
   * @memberof ReminderSetting
   */
  enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof ReminderSetting
   */
  intervalInMinutes?: number;
  /**
   *
   * @type {number}
   * @memberof ReminderSetting
   */
  limit?: number;
}

/**
 * Check if a given object implements the ReminderSetting interface.
 */
export function instanceOfReminderSetting(value: object): value is ReminderSetting {
  return true;
}

export function ReminderSettingFromJSON(json: any): ReminderSetting {
  return ReminderSettingFromJSONTyped(json, false);
}

export function ReminderSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReminderSetting {
  if (json == null) {
    return json;
  }
  return {
    enabled: json['enabled'] == null ? undefined : json['enabled'],
    intervalInMinutes: json['intervalInMinutes'] == null ? undefined : json['intervalInMinutes'],
    limit: json['limit'] == null ? undefined : json['limit']
  };
}

export function ReminderSettingToJSON(json: any): ReminderSetting {
  return ReminderSettingToJSONTyped(json, false);
}

export function ReminderSettingToJSONTyped(value?: ReminderSetting | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    enabled: value['enabled'],
    intervalInMinutes: value['intervalInMinutes'],
    limit: value['limit']
  };
}
