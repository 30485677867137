/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplianceZone } from './ApplianceZone';
import {
    ApplianceZoneFromJSON,
    ApplianceZoneFromJSONTyped,
    ApplianceZoneToJSON,
    ApplianceZoneToJSONTyped,
} from './ApplianceZone';

/**
 * 
 * @export
 * @interface Appliance
 */
export interface Appliance {
    /**
     * 
     * @type {string}
     * @memberof Appliance
     */
    applianceType: ApplianceApplianceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Appliance
     */
    gatewayId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Appliance
     */
    lcvAppliance?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Appliance
     */
    loggerAppliance?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Appliance
     */
    loggerType?: ApplianceLoggerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Appliance
     */
    model?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Appliance
     */
    multiZone?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Appliance
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Appliance
     */
    onboardingState: ApplianceOnboardingStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Appliance
     */
    serialNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Appliance
     */
    smartModuleAppliance?: boolean;
    /**
     * 
     * @type {Set<string>}
     * @memberof Appliance
     */
    thingIds?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof Appliance
     */
    timezone?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof Appliance
     */
    virtualFridgeIds?: Set<string>;
    /**
     * 
     * @type {Array<ApplianceZone>}
     * @memberof Appliance
     */
    zones: Array<ApplianceZone>;
}


/**
 * @export
 */
export const ApplianceApplianceTypeEnum = {
    MODBUS_FRIDGE: 'MODBUS_FRIDGE',
    BINDER_FRIDGE: 'BINDER_FRIDGE',
    SMART_MODULE_FRIDGE: 'SMART_MODULE_FRIDGE',
    LCV_FRIDGE: 'LCV_FRIDGE',
    LOGGER: 'LOGGER'
} as const;
export type ApplianceApplianceTypeEnum = typeof ApplianceApplianceTypeEnum[keyof typeof ApplianceApplianceTypeEnum];

/**
 * @export
 */
export const ApplianceLoggerTypeEnum = {
    GENERIC_LOGGER: 'GENERIC_LOGGER',
    BASIC_LOGGER: 'BASIC_LOGGER',
    BASIC_LOGGER_TRIPLE: 'BASIC_LOGGER_TRIPLE',
    ULT_LOGGER: 'ULT_LOGGER'
} as const;
export type ApplianceLoggerTypeEnum = typeof ApplianceLoggerTypeEnum[keyof typeof ApplianceLoggerTypeEnum];

/**
 * @export
 */
export const ApplianceOnboardingStateEnum = {
    VOID: 'VOID',
    AWAIT_ONBOARDING: 'AWAIT_ONBOARDING',
    ONBOARDING_STARTED: 'ONBOARDING_STARTED',
    ONBOARDED: 'ONBOARDED'
} as const;
export type ApplianceOnboardingStateEnum = typeof ApplianceOnboardingStateEnum[keyof typeof ApplianceOnboardingStateEnum];


/**
 * Check if a given object implements the Appliance interface.
 */
export function instanceOfAppliance(value: object): value is Appliance {
    if (!('applianceType' in value) || value['applianceType'] === undefined) return false;
    if (!('onboardingState' in value) || value['onboardingState'] === undefined) return false;
    if (!('zones' in value) || value['zones'] === undefined) return false;
    return true;
}

export function ApplianceFromJSON(json: any): Appliance {
    return ApplianceFromJSONTyped(json, false);
}

export function ApplianceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Appliance {
    if (json == null) {
        return json;
    }
    return {
        
        'applianceType': json['applianceType'],
        'gatewayId': json['gatewayId'] == null ? undefined : json['gatewayId'],
        'lcvAppliance': json['lcvAppliance'] == null ? undefined : json['lcvAppliance'],
        'loggerAppliance': json['loggerAppliance'] == null ? undefined : json['loggerAppliance'],
        'loggerType': json['loggerType'] == null ? undefined : json['loggerType'],
        'model': json['model'] == null ? undefined : json['model'],
        'multiZone': json['multiZone'] == null ? undefined : json['multiZone'],
        'name': json['name'] == null ? undefined : json['name'],
        'onboardingState': json['onboardingState'],
        'serialNumber': json['serialNumber'] == null ? undefined : json['serialNumber'],
        'smartModuleAppliance': json['smartModuleAppliance'] == null ? undefined : json['smartModuleAppliance'],
        'thingIds': json['thingIds'] == null ? undefined : new Set(json['thingIds']),
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
        'virtualFridgeIds': json['virtualFridgeIds'] == null ? undefined : new Set(json['virtualFridgeIds']),
        'zones': ((json['zones'] as Array<any>).map(ApplianceZoneFromJSON)),
    };
}

export function ApplianceToJSON(json: any): Appliance {
    return ApplianceToJSONTyped(json, false);
}

export function ApplianceToJSONTyped(value?: Appliance | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'applianceType': value['applianceType'],
        'gatewayId': value['gatewayId'],
        'lcvAppliance': value['lcvAppliance'],
        'loggerAppliance': value['loggerAppliance'],
        'loggerType': value['loggerType'],
        'model': value['model'],
        'multiZone': value['multiZone'],
        'name': value['name'],
        'onboardingState': value['onboardingState'],
        'serialNumber': value['serialNumber'],
        'smartModuleAppliance': value['smartModuleAppliance'],
        'thingIds': value['thingIds'] == null ? undefined : Array.from(value['thingIds'] as Set<any>),
        'timezone': value['timezone'],
        'virtualFridgeIds': value['virtualFridgeIds'] == null ? undefined : Array.from(value['virtualFridgeIds'] as Set<any>),
        'zones': ((value['zones'] as Array<any>).map(ApplianceZoneToJSON)),
    };
}

