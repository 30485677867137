/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplianceState } from './ApplianceState';
import {
    ApplianceStateFromJSON,
    ApplianceStateFromJSONTyped,
    ApplianceStateToJSON,
    ApplianceStateToJSONTyped,
} from './ApplianceState';

/**
 * 
 * @export
 * @interface ApplianceStatesResponse
 */
export interface ApplianceStatesResponse {
    /**
     * 
     * @type {Set<ApplianceState>}
     * @memberof ApplianceStatesResponse
     */
    states?: Set<ApplianceState>;
}

/**
 * Check if a given object implements the ApplianceStatesResponse interface.
 */
export function instanceOfApplianceStatesResponse(value: object): value is ApplianceStatesResponse {
    return true;
}

export function ApplianceStatesResponseFromJSON(json: any): ApplianceStatesResponse {
    return ApplianceStatesResponseFromJSONTyped(json, false);
}

export function ApplianceStatesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceStatesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'states': json['states'] == null ? undefined : (new Set((json['states'] as Array<any>).map(ApplianceStateFromJSON))),
    };
}

export function ApplianceStatesResponseToJSON(json: any): ApplianceStatesResponse {
    return ApplianceStatesResponseToJSONTyped(json, false);
}

export function ApplianceStatesResponseToJSONTyped(value?: ApplianceStatesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'states': value['states'] == null ? undefined : (Array.from(value['states'] as Set<any>).map(ApplianceStateToJSON)),
    };
}

