/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ApplianceInfo
 */
export interface ApplianceInfo {
  /**
   *
   * @type {string}
   * @memberof ApplianceInfo
   */
  articleNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceInfo
   */
  foreignDesignation?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceInfo
   */
  modelDescription?: string;
}

/**
 * Check if a given object implements the ApplianceInfo interface.
 */
export function instanceOfApplianceInfo(value: object): value is ApplianceInfo {
  return true;
}

export function ApplianceInfoFromJSON(json: any): ApplianceInfo {
  return ApplianceInfoFromJSONTyped(json, false);
}

export function ApplianceInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceInfo {
  if (json == null) {
    return json;
  }
  return {
    articleNumber: json['articleNumber'] == null ? undefined : json['articleNumber'],
    foreignDesignation: json['foreignDesignation'] == null ? undefined : json['foreignDesignation'],
    modelDescription: json['modelDescription'] == null ? undefined : json['modelDescription']
  };
}

export function ApplianceInfoToJSON(json: any): ApplianceInfo {
  return ApplianceInfoToJSONTyped(json, false);
}

export function ApplianceInfoToJSONTyped(value?: ApplianceInfo | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    articleNumber: value['articleNumber'],
    foreignDesignation: value['foreignDesignation'],
    modelDescription: value['modelDescription']
  };
}
