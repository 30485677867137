/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MessageStats
 */
export interface MessageStats {
  /**
   *
   * @type {number}
   * @memberof MessageStats
   */
  readMessageCount?: number;
  /**
   *
   * @type {number}
   * @memberof MessageStats
   */
  unreadMessageCount?: number;
}

/**
 * Check if a given object implements the MessageStats interface.
 */
export function instanceOfMessageStats(value: object): value is MessageStats {
  return true;
}

export function MessageStatsFromJSON(json: any): MessageStats {
  return MessageStatsFromJSONTyped(json, false);
}

export function MessageStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageStats {
  if (json == null) {
    return json;
  }
  return {
    readMessageCount: json['readMessageCount'] == null ? undefined : json['readMessageCount'],
    unreadMessageCount: json['unreadMessageCount'] == null ? undefined : json['unreadMessageCount']
  };
}

export function MessageStatsToJSON(json: any): MessageStats {
  return MessageStatsToJSONTyped(json, false);
}

export function MessageStatsToJSONTyped(value?: MessageStats | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    readMessageCount: value['readMessageCount'],
    unreadMessageCount: value['unreadMessageCount']
  };
}
