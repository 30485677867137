/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ContinuationReportToken
 */
export interface ContinuationReportToken {
  /**
   *
   * @type {number}
   * @memberof ContinuationReportToken
   */
  fetched?: number;
  /**
   *
   * @type {boolean}
   * @memberof ContinuationReportToken
   */
  more?: boolean;
  /**
   *
   * @type {number}
   * @memberof ContinuationReportToken
   */
  reports?: number;
}

/**
 * Check if a given object implements the ContinuationReportToken interface.
 */
export function instanceOfContinuationReportToken(value: object): value is ContinuationReportToken {
  return true;
}

export function ContinuationReportTokenFromJSON(json: any): ContinuationReportToken {
  return ContinuationReportTokenFromJSONTyped(json, false);
}

export function ContinuationReportTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContinuationReportToken {
  if (json == null) {
    return json;
  }
  return {
    fetched: json['fetched'] == null ? undefined : json['fetched'],
    more: json['more'] == null ? undefined : json['more'],
    reports: json['reports'] == null ? undefined : json['reports']
  };
}

export function ContinuationReportTokenToJSON(json: any): ContinuationReportToken {
  return ContinuationReportTokenToJSONTyped(json, false);
}

export function ContinuationReportTokenToJSONTyped(
  value?: ContinuationReportToken | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    fetched: value['fetched'],
    more: value['more'],
    reports: value['reports']
  };
}
