/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ZoneInfo
 */
export interface ZoneInfo {
  /**
   *
   * @type {string}
   * @memberof ZoneInfo
   */
  virtualFridgeId?: string;
  /**
   *
   * @type {string}
   * @memberof ZoneInfo
   */
  zoneName?: string;
}

/**
 * Check if a given object implements the ZoneInfo interface.
 */
export function instanceOfZoneInfo(value: object): value is ZoneInfo {
  return true;
}

export function ZoneInfoFromJSON(json: any): ZoneInfo {
  return ZoneInfoFromJSONTyped(json, false);
}

export function ZoneInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneInfo {
  if (json == null) {
    return json;
  }
  return {
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
    zoneName: json['zoneName'] == null ? undefined : json['zoneName']
  };
}

export function ZoneInfoToJSON(json: any): ZoneInfo {
  return ZoneInfoToJSONTyped(json, false);
}

export function ZoneInfoToJSONTyped(value?: ZoneInfo | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    virtualFridgeId: value['virtualFridgeId'],
    zoneName: value['zoneName']
  };
}
