/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SupportInfo,
} from '../models/index';
import {
    SupportInfoFromJSON,
    SupportInfoToJSON,
} from '../models/index';

export interface GetSupportInfoUsingGETRequest {
    countryCode: string;
}

/**
 * SupportControllerApi - interface
 * 
 * @export
 * @interface SupportControllerApiInterface
 */
export interface SupportControllerApiInterface {
    /**
     * getSupportInfo
     * @param {string} countryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportControllerApiInterface
     */
    getSupportInfoUsingGETRaw(requestParameters: GetSupportInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupportInfo>>;

    /**
     * getSupportInfo
     */
    getSupportInfoUsingGET(requestParameters: GetSupportInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupportInfo>;

}

/**
 * 
 */
export class SupportControllerApi extends runtime.BaseAPI implements SupportControllerApiInterface {

    /**
     * getSupportInfo
     */
    async getSupportInfoUsingGETRaw(requestParameters: GetSupportInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupportInfo>> {
        if (requestParameters['countryCode'] == null) {
            throw new runtime.RequiredError(
                'countryCode',
                'Required parameter "countryCode" was null or undefined when calling getSupportInfoUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/support/info/{countryCode}`.replace(`{${"countryCode"}}`, encodeURIComponent(String(requestParameters['countryCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupportInfoFromJSON(jsonValue));
    }

    /**
     * getSupportInfo
     */
    async getSupportInfoUsingGET(requestParameters: GetSupportInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupportInfo> {
        const response = await this.getSupportInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
