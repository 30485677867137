/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CloudAlarmSettingsResponse } from './CloudAlarmSettingsResponse';
import {
    CloudAlarmSettingsResponseFromJSON,
    CloudAlarmSettingsResponseFromJSONTyped,
    CloudAlarmSettingsResponseToJSON,
    CloudAlarmSettingsResponseToJSONTyped,
} from './CloudAlarmSettingsResponse';
import type { DataPointsObject } from './DataPointsObject';
import {
    DataPointsObjectFromJSON,
    DataPointsObjectFromJSONTyped,
    DataPointsObjectToJSON,
    DataPointsObjectToJSONTyped,
} from './DataPointsObject';

/**
 * 
 * @export
 * @interface HistoryResponse
 */
export interface HistoryResponse {
    /**
     * 
     * @type {DataPointsObject}
     * @memberof HistoryResponse
     */
    applianceData?: DataPointsObject;
    /**
     * 
     * @type {CloudAlarmSettingsResponse}
     * @memberof HistoryResponse
     */
    cloudAlarmSettings?: CloudAlarmSettingsResponse;
    /**
     * 
     * @type {number}
     * @memberof HistoryResponse
     */
    cutOffDays?: number;
}

/**
 * Check if a given object implements the HistoryResponse interface.
 */
export function instanceOfHistoryResponse(value: object): value is HistoryResponse {
    return true;
}

export function HistoryResponseFromJSON(json: any): HistoryResponse {
    return HistoryResponseFromJSONTyped(json, false);
}

export function HistoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'applianceData': json['applianceData'] == null ? undefined : DataPointsObjectFromJSON(json['applianceData']),
        'cloudAlarmSettings': json['cloudAlarmSettings'] == null ? undefined : CloudAlarmSettingsResponseFromJSON(json['cloudAlarmSettings']),
        'cutOffDays': json['cutOffDays'] == null ? undefined : json['cutOffDays'],
    };
}

export function HistoryResponseToJSON(json: any): HistoryResponse {
    return HistoryResponseToJSONTyped(json, false);
}

export function HistoryResponseToJSONTyped(value?: HistoryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'applianceData': DataPointsObjectToJSON(value['applianceData']),
        'cloudAlarmSettings': CloudAlarmSettingsResponseToJSON(value['cloudAlarmSettings']),
        'cutOffDays': value['cutOffDays'],
    };
}

