/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConnectKeyResponse,
  ConnectivityResponse,
  LoggerAlarmSettings,
  LoggerInfoResponse,
  LoggerLiveState,
  LoggerNotificationSettings,
  LoggerOnboardingRequest,
  LoggerOnboardingResponse,
  MultipleLoggersSettingsRequest,
  ProductSimulationSettingsResponse,
  SensorInfo,
  UpdateProductSimulationRequest,
} from '../models/index';
import {
    ConnectKeyResponseFromJSON,
    ConnectKeyResponseToJSON,
    ConnectivityResponseFromJSON,
    ConnectivityResponseToJSON,
    LoggerAlarmSettingsFromJSON,
    LoggerAlarmSettingsToJSON,
    LoggerInfoResponseFromJSON,
    LoggerInfoResponseToJSON,
    LoggerLiveStateFromJSON,
    LoggerLiveStateToJSON,
    LoggerNotificationSettingsFromJSON,
    LoggerNotificationSettingsToJSON,
    LoggerOnboardingRequestFromJSON,
    LoggerOnboardingRequestToJSON,
    LoggerOnboardingResponseFromJSON,
    LoggerOnboardingResponseToJSON,
    MultipleLoggersSettingsRequestFromJSON,
    MultipleLoggersSettingsRequestToJSON,
    ProductSimulationSettingsResponseFromJSON,
    ProductSimulationSettingsResponseToJSON,
    SensorInfoFromJSON,
    SensorInfoToJSON,
    UpdateProductSimulationRequestFromJSON,
    UpdateProductSimulationRequestToJSON,
} from '../models/index';

export interface CheckLoggerAlreadyOnboardedUsingGETRequest {
    serialNumber: string;
}

export interface GetLoggerAlarmSettingsUsingGETRequest {
    virtualFridgeId: string;
}

export interface GetLoggerInfoUsingGETRequest {
    virtualFridgeId: string;
}

export interface GetLoggerLiveStateUsingGETRequest {
    virtualFridgeId: string;
}

export interface GetLoggerNotificationSettingsUsingGETRequest {
    virtualFridgeId: string;
}

export interface GetSensorInfoUsingGETRequest {
    thingId: string;
}

export interface OffboardLoggerUsingDELETERequest {
    virtualFridgeId: string;
}

export interface ReadProductSimulationSettingsUsingGETRequest {
    thingId: string;
}

export interface SaveLoggerAlarmSettingsUsingPUTRequest {
    virtualFridgeId: string;
    loggerAlarmSettings: LoggerAlarmSettings;
}

export interface StartLoggerOnboardingUsingPOSTRequest {
    groupId: string;
    serialNumber: string;
    loggerOnboardingRequest: LoggerOnboardingRequest;
}

export interface UpdateLoggerNotificationSettingsUsingPUTRequest {
    loggerNotificationSettings: LoggerNotificationSettings;
}

export interface UpdateMultipleLoggersNotificationSettingsUsingPUTRequest {
    multipleLoggersSettingsRequest: MultipleLoggersSettingsRequest;
}

export interface UpdateProductSimulationSettingsUsingPUTRequest {
    updateProductSimulationRequest: UpdateProductSimulationRequest;
}

/**
 * LoggerControllerApi - interface
 * 
 * @export
 * @interface LoggerControllerApiInterface
 */
export interface LoggerControllerApiInterface {
    /**
     * checkLoggerAlreadyOnboarded
     * @summary Check if logger is already onboarded.
     * @param {string} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    checkLoggerAlreadyOnboardedUsingGETRaw(requestParameters: CheckLoggerAlreadyOnboardedUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectivityResponse>>;

    /**
     * checkLoggerAlreadyOnboarded
     * Check if logger is already onboarded.
     */
    checkLoggerAlreadyOnboardedUsingGET(requestParameters: CheckLoggerAlreadyOnboardedUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectivityResponse>;

    /**
     * getConnectKey
     * @summary Get connect key.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    getConnectKeyUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectKeyResponse>>;

    /**
     * getConnectKey
     * Get connect key.
     */
    getConnectKeyUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectKeyResponse>;

    /**
     * getLoggerAlarmSettings
     * @summary Get the logger alarm settings by VFID.
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    getLoggerAlarmSettingsUsingGETRaw(requestParameters: GetLoggerAlarmSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerAlarmSettings>>;

    /**
     * getLoggerAlarmSettings
     * Get the logger alarm settings by VFID.
     */
    getLoggerAlarmSettingsUsingGET(requestParameters: GetLoggerAlarmSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerAlarmSettings>;

    /**
     * getLoggerInfo
     * @summary Get logger info by virtual fridge id
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    getLoggerInfoUsingGETRaw(requestParameters: GetLoggerInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerInfoResponse>>;

    /**
     * getLoggerInfo
     * Get logger info by virtual fridge id
     */
    getLoggerInfoUsingGET(requestParameters: GetLoggerInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerInfoResponse>;

    /**
     * getLoggerLiveState
     * @summary Gets the live state for a logger.
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    getLoggerLiveStateUsingGETRaw(requestParameters: GetLoggerLiveStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerLiveState>>;

    /**
     * getLoggerLiveState
     * Gets the live state for a logger.
     */
    getLoggerLiveStateUsingGET(requestParameters: GetLoggerLiveStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerLiveState>;

    /**
     * getLoggerNotificationSettings
     * @summary Gets Logger notification settings by given virtualFridgeId.
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    getLoggerNotificationSettingsUsingGETRaw(requestParameters: GetLoggerNotificationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerNotificationSettings>>;

    /**
     * getLoggerNotificationSettings
     * Gets Logger notification settings by given virtualFridgeId.
     */
    getLoggerNotificationSettingsUsingGET(requestParameters: GetLoggerNotificationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerNotificationSettings>;

    /**
     * getSensorInfo
     * @summary Get common sensor info.
     * @param {string} thingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    getSensorInfoUsingGETRaw(requestParameters: GetSensorInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SensorInfo>>;

    /**
     * getSensorInfo
     * Get common sensor info.
     */
    getSensorInfoUsingGET(requestParameters: GetSensorInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SensorInfo>;

    /**
     * offboardLogger
     * @summary Off-boards a logger
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    offboardLoggerUsingDELETERaw(requestParameters: OffboardLoggerUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * offboardLogger
     * Off-boards a logger
     */
    offboardLoggerUsingDELETE(requestParameters: OffboardLoggerUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * readProductSimulationSettings
     * @summary Reads product simulation settings
     * @param {string} thingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    readProductSimulationSettingsUsingGETRaw(requestParameters: ReadProductSimulationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductSimulationSettingsResponse>>;

    /**
     * readProductSimulationSettings
     * Reads product simulation settings
     */
    readProductSimulationSettingsUsingGET(requestParameters: ReadProductSimulationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductSimulationSettingsResponse>;

    /**
     * saveLoggerAlarmSettings
     * @summary Saves the alarm settings for a logger and returns the saved object again
     * @param {string} virtualFridgeId 
     * @param {LoggerAlarmSettings} loggerAlarmSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    saveLoggerAlarmSettingsUsingPUTRaw(requestParameters: SaveLoggerAlarmSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerAlarmSettings>>;

    /**
     * saveLoggerAlarmSettings
     * Saves the alarm settings for a logger and returns the saved object again
     */
    saveLoggerAlarmSettingsUsingPUT(requestParameters: SaveLoggerAlarmSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerAlarmSettings>;

    /**
     * startLoggerOnboarding
     * @summary Launch logger onboarding flow.
     * @param {string} groupId 
     * @param {string} serialNumber 
     * @param {LoggerOnboardingRequest} loggerOnboardingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    startLoggerOnboardingUsingPOSTRaw(requestParameters: StartLoggerOnboardingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerOnboardingResponse>>;

    /**
     * startLoggerOnboarding
     * Launch logger onboarding flow.
     */
    startLoggerOnboardingUsingPOST(requestParameters: StartLoggerOnboardingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerOnboardingResponse>;

    /**
     * updateLoggerNotificationSettings
     * @summary Create/Update logger notification settings by virtualFridgeId.
     * @param {LoggerNotificationSettings} loggerNotificationSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    updateLoggerNotificationSettingsUsingPUTRaw(requestParameters: UpdateLoggerNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>>;

    /**
     * updateLoggerNotificationSettings
     * Create/Update logger notification settings by virtualFridgeId.
     */
    updateLoggerNotificationSettingsUsingPUT(requestParameters: UpdateLoggerNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean>;

    /**
     * updateMultipleLoggersNotificationSettings
     * @summary Multiplies logger notification settings by logger Id.
     * @param {MultipleLoggersSettingsRequest} multipleLoggersSettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    updateMultipleLoggersNotificationSettingsUsingPUTRaw(requestParameters: UpdateMultipleLoggersNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>>;

    /**
     * updateMultipleLoggersNotificationSettings
     * Multiplies logger notification settings by logger Id.
     */
    updateMultipleLoggersNotificationSettingsUsingPUT(requestParameters: UpdateMultipleLoggersNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean>;

    /**
     * updateProductSimulationSettings
     * @summary Update product simulation settings
     * @param {UpdateProductSimulationRequest} updateProductSimulationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApiInterface
     */
    updateProductSimulationSettingsUsingPUTRaw(requestParameters: UpdateProductSimulationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductSimulationSettingsResponse>>;

    /**
     * updateProductSimulationSettings
     * Update product simulation settings
     */
    updateProductSimulationSettingsUsingPUT(requestParameters: UpdateProductSimulationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductSimulationSettingsResponse>;

}

/**
 * 
 */
export class LoggerControllerApi extends runtime.BaseAPI implements LoggerControllerApiInterface {

    /**
     * checkLoggerAlreadyOnboarded
     * Check if logger is already onboarded.
     */
    async checkLoggerAlreadyOnboardedUsingGETRaw(requestParameters: CheckLoggerAlreadyOnboardedUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectivityResponse>> {
        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling checkLoggerAlreadyOnboardedUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logger/check/{serialNumber}`.replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectivityResponseFromJSON(jsonValue));
    }

    /**
     * checkLoggerAlreadyOnboarded
     * Check if logger is already onboarded.
     */
    async checkLoggerAlreadyOnboardedUsingGET(requestParameters: CheckLoggerAlreadyOnboardedUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectivityResponse> {
        const response = await this.checkLoggerAlreadyOnboardedUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getConnectKey
     * Get connect key.
     */
    async getConnectKeyUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectKeyResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logger/connectkey`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectKeyResponseFromJSON(jsonValue));
    }

    /**
     * getConnectKey
     * Get connect key.
     */
    async getConnectKeyUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectKeyResponse> {
        const response = await this.getConnectKeyUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getLoggerAlarmSettings
     * Get the logger alarm settings by VFID.
     */
    async getLoggerAlarmSettingsUsingGETRaw(requestParameters: GetLoggerAlarmSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerAlarmSettings>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getLoggerAlarmSettingsUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logger/{virtualFridgeId}/alarmsettings`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggerAlarmSettingsFromJSON(jsonValue));
    }

    /**
     * getLoggerAlarmSettings
     * Get the logger alarm settings by VFID.
     */
    async getLoggerAlarmSettingsUsingGET(requestParameters: GetLoggerAlarmSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerAlarmSettings> {
        const response = await this.getLoggerAlarmSettingsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getLoggerInfo
     * Get logger info by virtual fridge id
     */
    async getLoggerInfoUsingGETRaw(requestParameters: GetLoggerInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerInfoResponse>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getLoggerInfoUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logger/{virtualFridgeId}/info`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggerInfoResponseFromJSON(jsonValue));
    }

    /**
     * getLoggerInfo
     * Get logger info by virtual fridge id
     */
    async getLoggerInfoUsingGET(requestParameters: GetLoggerInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerInfoResponse> {
        const response = await this.getLoggerInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getLoggerLiveState
     * Gets the live state for a logger.
     */
    async getLoggerLiveStateUsingGETRaw(requestParameters: GetLoggerLiveStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerLiveState>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getLoggerLiveStateUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logger/{virtualFridgeId}/livestate`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggerLiveStateFromJSON(jsonValue));
    }

    /**
     * getLoggerLiveState
     * Gets the live state for a logger.
     */
    async getLoggerLiveStateUsingGET(requestParameters: GetLoggerLiveStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerLiveState> {
        const response = await this.getLoggerLiveStateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getLoggerNotificationSettings
     * Gets Logger notification settings by given virtualFridgeId.
     */
    async getLoggerNotificationSettingsUsingGETRaw(requestParameters: GetLoggerNotificationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerNotificationSettings>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getLoggerNotificationSettingsUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logger/notification/settings/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggerNotificationSettingsFromJSON(jsonValue));
    }

    /**
     * getLoggerNotificationSettings
     * Gets Logger notification settings by given virtualFridgeId.
     */
    async getLoggerNotificationSettingsUsingGET(requestParameters: GetLoggerNotificationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerNotificationSettings> {
        const response = await this.getLoggerNotificationSettingsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSensorInfo
     * Get common sensor info.
     */
    async getSensorInfoUsingGETRaw(requestParameters: GetSensorInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SensorInfo>> {
        if (requestParameters['thingId'] == null) {
            throw new runtime.RequiredError(
                'thingId',
                'Required parameter "thingId" was null or undefined when calling getSensorInfoUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logger/sensor/{thingId}/info`.replace(`{${"thingId"}}`, encodeURIComponent(String(requestParameters['thingId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SensorInfoFromJSON(jsonValue));
    }

    /**
     * getSensorInfo
     * Get common sensor info.
     */
    async getSensorInfoUsingGET(requestParameters: GetSensorInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SensorInfo> {
        const response = await this.getSensorInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * offboardLogger
     * Off-boards a logger
     */
    async offboardLoggerUsingDELETERaw(requestParameters: OffboardLoggerUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling offboardLoggerUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logger/{virtualFridgeId}/offboarding`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * offboardLogger
     * Off-boards a logger
     */
    async offboardLoggerUsingDELETE(requestParameters: OffboardLoggerUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.offboardLoggerUsingDELETERaw(requestParameters, initOverrides);
    }

    /**
     * readProductSimulationSettings
     * Reads product simulation settings
     */
    async readProductSimulationSettingsUsingGETRaw(requestParameters: ReadProductSimulationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductSimulationSettingsResponse>> {
        if (requestParameters['thingId'] == null) {
            throw new runtime.RequiredError(
                'thingId',
                'Required parameter "thingId" was null or undefined when calling readProductSimulationSettingsUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logger/sensor/settings/{thingId}`.replace(`{${"thingId"}}`, encodeURIComponent(String(requestParameters['thingId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductSimulationSettingsResponseFromJSON(jsonValue));
    }

    /**
     * readProductSimulationSettings
     * Reads product simulation settings
     */
    async readProductSimulationSettingsUsingGET(requestParameters: ReadProductSimulationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductSimulationSettingsResponse> {
        const response = await this.readProductSimulationSettingsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveLoggerAlarmSettings
     * Saves the alarm settings for a logger and returns the saved object again
     */
    async saveLoggerAlarmSettingsUsingPUTRaw(requestParameters: SaveLoggerAlarmSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerAlarmSettings>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling saveLoggerAlarmSettingsUsingPUT().'
            );
        }

        if (requestParameters['loggerAlarmSettings'] == null) {
            throw new runtime.RequiredError(
                'loggerAlarmSettings',
                'Required parameter "loggerAlarmSettings" was null or undefined when calling saveLoggerAlarmSettingsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logger/{virtualFridgeId}/alarmsettings`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LoggerAlarmSettingsToJSON(requestParameters['loggerAlarmSettings']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggerAlarmSettingsFromJSON(jsonValue));
    }

    /**
     * saveLoggerAlarmSettings
     * Saves the alarm settings for a logger and returns the saved object again
     */
    async saveLoggerAlarmSettingsUsingPUT(requestParameters: SaveLoggerAlarmSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerAlarmSettings> {
        const response = await this.saveLoggerAlarmSettingsUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * startLoggerOnboarding
     * Launch logger onboarding flow.
     */
    async startLoggerOnboardingUsingPOSTRaw(requestParameters: StartLoggerOnboardingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggerOnboardingResponse>> {
        if (requestParameters['groupId'] == null) {
            throw new runtime.RequiredError(
                'groupId',
                'Required parameter "groupId" was null or undefined when calling startLoggerOnboardingUsingPOST().'
            );
        }

        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling startLoggerOnboardingUsingPOST().'
            );
        }

        if (requestParameters['loggerOnboardingRequest'] == null) {
            throw new runtime.RequiredError(
                'loggerOnboardingRequest',
                'Required parameter "loggerOnboardingRequest" was null or undefined when calling startLoggerOnboardingUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logger/onboarding/{serialNumber}/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters['groupId']))).replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoggerOnboardingRequestToJSON(requestParameters['loggerOnboardingRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggerOnboardingResponseFromJSON(jsonValue));
    }

    /**
     * startLoggerOnboarding
     * Launch logger onboarding flow.
     */
    async startLoggerOnboardingUsingPOST(requestParameters: StartLoggerOnboardingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggerOnboardingResponse> {
        const response = await this.startLoggerOnboardingUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateLoggerNotificationSettings
     * Create/Update logger notification settings by virtualFridgeId.
     */
    async updateLoggerNotificationSettingsUsingPUTRaw(requestParameters: UpdateLoggerNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['loggerNotificationSettings'] == null) {
            throw new runtime.RequiredError(
                'loggerNotificationSettings',
                'Required parameter "loggerNotificationSettings" was null or undefined when calling updateLoggerNotificationSettingsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/logger/notification/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LoggerNotificationSettingsToJSON(requestParameters['loggerNotificationSettings']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * updateLoggerNotificationSettings
     * Create/Update logger notification settings by virtualFridgeId.
     */
    async updateLoggerNotificationSettingsUsingPUT(requestParameters: UpdateLoggerNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.updateLoggerNotificationSettingsUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateMultipleLoggersNotificationSettings
     * Multiplies logger notification settings by logger Id.
     */
    async updateMultipleLoggersNotificationSettingsUsingPUTRaw(requestParameters: UpdateMultipleLoggersNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['multipleLoggersSettingsRequest'] == null) {
            throw new runtime.RequiredError(
                'multipleLoggersSettingsRequest',
                'Required parameter "multipleLoggersSettingsRequest" was null or undefined when calling updateMultipleLoggersNotificationSettingsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/logger/multiply/notification/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleLoggersSettingsRequestToJSON(requestParameters['multipleLoggersSettingsRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * updateMultipleLoggersNotificationSettings
     * Multiplies logger notification settings by logger Id.
     */
    async updateMultipleLoggersNotificationSettingsUsingPUT(requestParameters: UpdateMultipleLoggersNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.updateMultipleLoggersNotificationSettingsUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateProductSimulationSettings
     * Update product simulation settings
     */
    async updateProductSimulationSettingsUsingPUTRaw(requestParameters: UpdateProductSimulationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductSimulationSettingsResponse>> {
        if (requestParameters['updateProductSimulationRequest'] == null) {
            throw new runtime.RequiredError(
                'updateProductSimulationRequest',
                'Required parameter "updateProductSimulationRequest" was null or undefined when calling updateProductSimulationSettingsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/logger/sensor/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProductSimulationRequestToJSON(requestParameters['updateProductSimulationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductSimulationSettingsResponseFromJSON(jsonValue));
    }

    /**
     * updateProductSimulationSettings
     * Update product simulation settings
     */
    async updateProductSimulationSettingsUsingPUT(requestParameters: UpdateProductSimulationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductSimulationSettingsResponse> {
        const response = await this.updateProductSimulationSettingsUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
