/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RegisteredBinderAppliance } from './RegisteredBinderAppliance';
import {
    RegisteredBinderApplianceFromJSON,
    RegisteredBinderApplianceFromJSONTyped,
    RegisteredBinderApplianceToJSON,
    RegisteredBinderApplianceToJSONTyped,
} from './RegisteredBinderAppliance';

/**
 * 
 * @export
 * @interface RegisteredBinderAppliancesResponse
 */
export interface RegisteredBinderAppliancesResponse {
    /**
     * 
     * @type {Array<RegisteredBinderAppliance>}
     * @memberof RegisteredBinderAppliancesResponse
     */
    appliances?: Array<RegisteredBinderAppliance>;
}

/**
 * Check if a given object implements the RegisteredBinderAppliancesResponse interface.
 */
export function instanceOfRegisteredBinderAppliancesResponse(value: object): value is RegisteredBinderAppliancesResponse {
    return true;
}

export function RegisteredBinderAppliancesResponseFromJSON(json: any): RegisteredBinderAppliancesResponse {
    return RegisteredBinderAppliancesResponseFromJSONTyped(json, false);
}

export function RegisteredBinderAppliancesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisteredBinderAppliancesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'appliances': json['appliances'] == null ? undefined : ((json['appliances'] as Array<any>).map(RegisteredBinderApplianceFromJSON)),
    };
}

export function RegisteredBinderAppliancesResponseToJSON(json: any): RegisteredBinderAppliancesResponse {
    return RegisteredBinderAppliancesResponseToJSONTyped(json, false);
}

export function RegisteredBinderAppliancesResponseToJSONTyped(value?: RegisteredBinderAppliancesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'appliances': value['appliances'] == null ? undefined : ((value['appliances'] as Array<any>).map(RegisteredBinderApplianceToJSON)),
    };
}

