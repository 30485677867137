/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DisplayTemperature } from './DisplayTemperature';
import {
    DisplayTemperatureFromJSON,
    DisplayTemperatureFromJSONTyped,
    DisplayTemperatureToJSON,
    DisplayTemperatureToJSONTyped,
} from './DisplayTemperature';

/**
 * 
 * @export
 * @interface AdditionalTemperatureAlarmSettings
 */
export interface AdditionalTemperatureAlarmSettings {
    /**
     * 
     * @type {number}
     * @memberof AdditionalTemperatureAlarmSettings
     */
    alarmDelayInSeconds?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalTemperatureAlarmSettings
     */
    enabled?: boolean;
    /**
     * 
     * @type {DisplayTemperature}
     * @memberof AdditionalTemperatureAlarmSettings
     */
    lowerTemperatureAlarmLimit?: DisplayTemperature;
    /**
     * 
     * @type {DisplayTemperature}
     * @memberof AdditionalTemperatureAlarmSettings
     */
    upperTemperatureAlarmLimit?: DisplayTemperature;
}

/**
 * Check if a given object implements the AdditionalTemperatureAlarmSettings interface.
 */
export function instanceOfAdditionalTemperatureAlarmSettings(value: object): value is AdditionalTemperatureAlarmSettings {
    return true;
}

export function AdditionalTemperatureAlarmSettingsFromJSON(json: any): AdditionalTemperatureAlarmSettings {
    return AdditionalTemperatureAlarmSettingsFromJSONTyped(json, false);
}

export function AdditionalTemperatureAlarmSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalTemperatureAlarmSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'alarmDelayInSeconds': json['alarmDelayInSeconds'] == null ? undefined : json['alarmDelayInSeconds'],
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'lowerTemperatureAlarmLimit': json['lowerTemperatureAlarmLimit'] == null ? undefined : DisplayTemperatureFromJSON(json['lowerTemperatureAlarmLimit']),
        'upperTemperatureAlarmLimit': json['upperTemperatureAlarmLimit'] == null ? undefined : DisplayTemperatureFromJSON(json['upperTemperatureAlarmLimit']),
    };
}

export function AdditionalTemperatureAlarmSettingsToJSON(json: any): AdditionalTemperatureAlarmSettings {
    return AdditionalTemperatureAlarmSettingsToJSONTyped(json, false);
}

export function AdditionalTemperatureAlarmSettingsToJSONTyped(value?: AdditionalTemperatureAlarmSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alarmDelayInSeconds': value['alarmDelayInSeconds'],
        'enabled': value['enabled'],
        'lowerTemperatureAlarmLimit': DisplayTemperatureToJSON(value['lowerTemperatureAlarmLimit']),
        'upperTemperatureAlarmLimit': DisplayTemperatureToJSON(value['upperTemperatureAlarmLimit']),
    };
}

