<template>
  <v-card flat :max-width="maxWidth">
    <v-layout align-center fill-height row pl-4>
      <v-layout wrap class="title">
        <slot name="title" />
      </v-layout>
      <Cancel v-show="buttonExit" @evtCancel="cancelProcess()" :track="trackCancel" />
    </v-layout>
    <div grid-list-lg style="max-height: 70vh; overflow-y: auto; min-height: 300px" ref="contentContainer">
      <v-flex class="subtitle-1 pt-3 pl-4 pr-4">
        <slot name="subtitle" />
      </v-flex>
      <v-flex class="bus_normaltext pt-2 pb-1 pl-4 pr-4">
        <slot name="description" />
      </v-flex>
      <v-flex class="pl-4 pr-2">
        <slot name="content" />
      </v-flex>
    </div>
    <v-flex>
      <slot name="helplink" />
    </v-flex>
  </v-card>
</template>

<script lang="ts" src="./BaseWindowItem.ts"></script>
