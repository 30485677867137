/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SaveApplianceResponse
 */
export interface SaveApplianceResponse {
  /**
   *
   * @type {boolean}
   * @memberof SaveApplianceResponse
   */
  successful?: boolean;
  /**
   *
   * @type {string}
   * @memberof SaveApplianceResponse
   */
  virtualFridgeId?: string;
}

/**
 * Check if a given object implements the SaveApplianceResponse interface.
 */
export function instanceOfSaveApplianceResponse(value: object): value is SaveApplianceResponse {
  return true;
}

export function SaveApplianceResponseFromJSON(json: any): SaveApplianceResponse {
  return SaveApplianceResponseFromJSONTyped(json, false);
}

export function SaveApplianceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveApplianceResponse {
  if (json == null) {
    return json;
  }
  return {
    successful: json['successful'] == null ? undefined : json['successful'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function SaveApplianceResponseToJSON(json: any): SaveApplianceResponse {
  return SaveApplianceResponseToJSONTyped(json, false);
}

export function SaveApplianceResponseToJSONTyped(
  value?: SaveApplianceResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    successful: value['successful'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
