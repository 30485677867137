/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface GatewayAlarmProfile
 */
export interface GatewayAlarmProfile {
    /**
     * 
     * @type {boolean}
     * @memberof GatewayAlarmProfile
     */
    alarmActivated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GatewayAlarmProfile
     */
    alarmDelay?: number;
    /**
     * 
     * @type {string}
     * @memberof GatewayAlarmProfile
     */
    alarmProfileId?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayAlarmProfile
     */
    created?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GatewayAlarmProfile
     */
    defaultProfile?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GatewayAlarmProfile
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayAlarmProfile
     */
    gatewayId?: string;
    /**
     * 
     * @type {number}
     * @memberof GatewayAlarmProfile
     */
    repetitionTime?: number;
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof GatewayAlarmProfile
     */
    userProfiles?: Array<UserProfile>;
}

/**
 * Check if a given object implements the GatewayAlarmProfile interface.
 */
export function instanceOfGatewayAlarmProfile(value: object): value is GatewayAlarmProfile {
    return true;
}

export function GatewayAlarmProfileFromJSON(json: any): GatewayAlarmProfile {
    return GatewayAlarmProfileFromJSONTyped(json, false);
}

export function GatewayAlarmProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): GatewayAlarmProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'alarmActivated': json['alarmActivated'] == null ? undefined : json['alarmActivated'],
        'alarmDelay': json['alarmDelay'] == null ? undefined : json['alarmDelay'],
        'alarmProfileId': json['alarmProfileId'] == null ? undefined : json['alarmProfileId'],
        'created': json['created'] == null ? undefined : json['created'],
        'defaultProfile': json['defaultProfile'] == null ? undefined : json['defaultProfile'],
        'description': json['description'] == null ? undefined : json['description'],
        'gatewayId': json['gatewayId'] == null ? undefined : json['gatewayId'],
        'repetitionTime': json['repetitionTime'] == null ? undefined : json['repetitionTime'],
        'userProfiles': json['userProfiles'] == null ? undefined : ((json['userProfiles'] as Array<any>).map(UserProfileFromJSON)),
    };
}

export function GatewayAlarmProfileToJSON(json: any): GatewayAlarmProfile {
    return GatewayAlarmProfileToJSONTyped(json, false);
}

export function GatewayAlarmProfileToJSONTyped(value?: GatewayAlarmProfile | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alarmActivated': value['alarmActivated'],
        'alarmDelay': value['alarmDelay'],
        'alarmProfileId': value['alarmProfileId'],
        'created': value['created'],
        'defaultProfile': value['defaultProfile'],
        'description': value['description'],
        'gatewayId': value['gatewayId'],
        'repetitionTime': value['repetitionTime'],
        'userProfiles': value['userProfiles'] == null ? undefined : ((value['userProfiles'] as Array<any>).map(UserProfileToJSON)),
    };
}

