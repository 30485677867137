/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductName } from './ProductName';
import {
    ProductNameFromJSON,
    ProductNameFromJSONTyped,
    ProductNameToJSON,
    ProductNameToJSONTyped,
} from './ProductName';

/**
 * 
 * @export
 * @interface LicenseProduct
 */
export interface LicenseProduct {
    /**
     * 
     * @type {string}
     * @memberof LicenseProduct
     */
    productId?: string;
    /**
     * 
     * @type {Array<ProductName>}
     * @memberof LicenseProduct
     */
    productNames?: Array<ProductName>;
}

/**
 * Check if a given object implements the LicenseProduct interface.
 */
export function instanceOfLicenseProduct(value: object): value is LicenseProduct {
    return true;
}

export function LicenseProductFromJSON(json: any): LicenseProduct {
    return LicenseProductFromJSONTyped(json, false);
}

export function LicenseProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenseProduct {
    if (json == null) {
        return json;
    }
    return {
        
        'productId': json['productId'] == null ? undefined : json['productId'],
        'productNames': json['productNames'] == null ? undefined : ((json['productNames'] as Array<any>).map(ProductNameFromJSON)),
    };
}

export function LicenseProductToJSON(json: any): LicenseProduct {
    return LicenseProductToJSONTyped(json, false);
}

export function LicenseProductToJSONTyped(value?: LicenseProduct | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'productId': value['productId'],
        'productNames': value['productNames'] == null ? undefined : ((value['productNames'] as Array<any>).map(ProductNameToJSON)),
    };
}

