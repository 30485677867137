/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SiteInformation } from './SiteInformation';
import {
    SiteInformationFromJSON,
    SiteInformationFromJSONTyped,
    SiteInformationToJSON,
    SiteInformationToJSONTyped,
} from './SiteInformation';

/**
 * 
 * @export
 * @interface LoggerOnboardingRequest
 */
export interface LoggerOnboardingRequest {
    /**
     * 
     * @type {string}
     * @memberof LoggerOnboardingRequest
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggerOnboardingRequest
     */
    name?: string;
    /**
     * 
     * @type {SiteInformation}
     * @memberof LoggerOnboardingRequest
     */
    siteInformation: SiteInformation;
    /**
     * 
     * @type {string}
     * @memberof LoggerOnboardingRequest
     */
    timeZone?: string;
}

/**
 * Check if a given object implements the LoggerOnboardingRequest interface.
 */
export function instanceOfLoggerOnboardingRequest(value: object): value is LoggerOnboardingRequest {
    if (!('siteInformation' in value) || value['siteInformation'] === undefined) return false;
    return true;
}

export function LoggerOnboardingRequestFromJSON(json: any): LoggerOnboardingRequest {
    return LoggerOnboardingRequestFromJSONTyped(json, false);
}

export function LoggerOnboardingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggerOnboardingRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'] == null ? undefined : json['comment'],
        'name': json['name'] == null ? undefined : json['name'],
        'siteInformation': SiteInformationFromJSON(json['siteInformation']),
        'timeZone': json['timeZone'] == null ? undefined : json['timeZone'],
    };
}

export function LoggerOnboardingRequestToJSON(json: any): LoggerOnboardingRequest {
    return LoggerOnboardingRequestToJSONTyped(json, false);
}

export function LoggerOnboardingRequestToJSONTyped(value?: LoggerOnboardingRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'comment': value['comment'],
        'name': value['name'],
        'siteInformation': SiteInformationToJSON(value['siteInformation']),
        'timeZone': value['timeZone'],
    };
}

