/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MergedAlarmStatsResponse
 */
export interface MergedAlarmStatsResponse {
  /**
   *
   * @type {number}
   * @memberof MergedAlarmStatsResponse
   */
  mergedAlarmCount?: number;
  /**
   *
   * @type {number}
   * @memberof MergedAlarmStatsResponse
   */
  mergedUnreadAlarmCount?: number;
}

/**
 * Check if a given object implements the MergedAlarmStatsResponse interface.
 */
export function instanceOfMergedAlarmStatsResponse(value: object): value is MergedAlarmStatsResponse {
  return true;
}

export function MergedAlarmStatsResponseFromJSON(json: any): MergedAlarmStatsResponse {
  return MergedAlarmStatsResponseFromJSONTyped(json, false);
}

export function MergedAlarmStatsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MergedAlarmStatsResponse {
  if (json == null) {
    return json;
  }
  return {
    mergedAlarmCount: json['mergedAlarmCount'] == null ? undefined : json['mergedAlarmCount'],
    mergedUnreadAlarmCount: json['mergedUnreadAlarmCount'] == null ? undefined : json['mergedUnreadAlarmCount']
  };
}

export function MergedAlarmStatsResponseToJSON(json: any): MergedAlarmStatsResponse {
  return MergedAlarmStatsResponseToJSONTyped(json, false);
}

export function MergedAlarmStatsResponseToJSONTyped(
  value?: MergedAlarmStatsResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    mergedAlarmCount: value['mergedAlarmCount'],
    mergedUnreadAlarmCount: value['mergedUnreadAlarmCount']
  };
}
