/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Point
 */
export interface Point {
  /**
   *
   * @type {Date}
   * @memberof Point
   */
  x?: Date | null;
  /**
   *
   * @type {string}
   * @memberof Point
   */
  y?: string | null;
}

/**
 * Check if a given object implements the Point interface.
 */
export function instanceOfPoint(value: object): value is Point {
  return true;
}

export function PointFromJSON(json: any): Point {
  return PointFromJSONTyped(json, false);
}

export function PointFromJSONTyped(json: any, ignoreDiscriminator: boolean): Point {
  if (json == null) {
    return json;
  }
  return {
    x: json['x'] === null ? null : new Date(json['x']),
    y: json['y'] === null ? null : json['y']
  };
}

export function PointToJSON(json: any): Point {
  return PointToJSONTyped(json, false);
}

export function PointToJSONTyped(value?: Point | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    x: value['x'] === null ? null : (value['x'] as any).toISOString(),
    y: value['y']
  };
}
