/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MergedAlarm } from './MergedAlarm';
import {
    MergedAlarmFromJSON,
    MergedAlarmFromJSONTyped,
    MergedAlarmToJSON,
    MergedAlarmToJSONTyped,
} from './MergedAlarm';

/**
 * 
 * @export
 * @interface MergedAlarmsResponse
 */
export interface MergedAlarmsResponse {
    /**
     * 
     * @type {Array<MergedAlarm>}
     * @memberof MergedAlarmsResponse
     */
    mergedAlarms?: Array<MergedAlarm>;
}

/**
 * Check if a given object implements the MergedAlarmsResponse interface.
 */
export function instanceOfMergedAlarmsResponse(value: object): value is MergedAlarmsResponse {
    return true;
}

export function MergedAlarmsResponseFromJSON(json: any): MergedAlarmsResponse {
    return MergedAlarmsResponseFromJSONTyped(json, false);
}

export function MergedAlarmsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergedAlarmsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'mergedAlarms': json['mergedAlarms'] == null ? undefined : ((json['mergedAlarms'] as Array<any>).map(MergedAlarmFromJSON)),
    };
}

export function MergedAlarmsResponseToJSON(json: any): MergedAlarmsResponse {
    return MergedAlarmsResponseToJSONTyped(json, false);
}

export function MergedAlarmsResponseToJSONTyped(value?: MergedAlarmsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'mergedAlarms': value['mergedAlarms'] == null ? undefined : ((value['mergedAlarms'] as Array<any>).map(MergedAlarmToJSON)),
    };
}

