/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplianceGroup } from './ApplianceGroup';
import {
    ApplianceGroupFromJSON,
    ApplianceGroupFromJSONTyped,
    ApplianceGroupToJSON,
    ApplianceGroupToJSONTyped,
} from './ApplianceGroup';

/**
 * 
 * @export
 * @interface ApplianceGroupsRequest
 */
export interface ApplianceGroupsRequest {
    /**
     * 
     * @type {ApplianceGroup}
     * @memberof ApplianceGroupsRequest
     */
    defaultGroup?: ApplianceGroup;
    /**
     * 
     * @type {Array<ApplianceGroup>}
     * @memberof ApplianceGroupsRequest
     */
    groups?: Array<ApplianceGroup>;
}

/**
 * Check if a given object implements the ApplianceGroupsRequest interface.
 */
export function instanceOfApplianceGroupsRequest(value: object): value is ApplianceGroupsRequest {
    return true;
}

export function ApplianceGroupsRequestFromJSON(json: any): ApplianceGroupsRequest {
    return ApplianceGroupsRequestFromJSONTyped(json, false);
}

export function ApplianceGroupsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceGroupsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultGroup': json['defaultGroup'] == null ? undefined : ApplianceGroupFromJSON(json['defaultGroup']),
        'groups': json['groups'] == null ? undefined : ((json['groups'] as Array<any>).map(ApplianceGroupFromJSON)),
    };
}

export function ApplianceGroupsRequestToJSON(json: any): ApplianceGroupsRequest {
    return ApplianceGroupsRequestToJSONTyped(json, false);
}

export function ApplianceGroupsRequestToJSONTyped(value?: ApplianceGroupsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultGroup': ApplianceGroupToJSON(value['defaultGroup']),
        'groups': value['groups'] == null ? undefined : ((value['groups'] as Array<any>).map(ApplianceGroupToJSON)),
    };
}

