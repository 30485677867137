/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Manuals } from './Manuals';
import {
    ManualsFromJSON,
    ManualsFromJSONTyped,
    ManualsToJSON,
    ManualsToJSONTyped,
} from './Manuals';
import type { MeasureSiteAddress } from './MeasureSiteAddress';
import {
    MeasureSiteAddressFromJSON,
    MeasureSiteAddressFromJSONTyped,
    MeasureSiteAddressToJSON,
    MeasureSiteAddressToJSONTyped,
} from './MeasureSiteAddress';
import type { ZoneInfo } from './ZoneInfo';
import {
    ZoneInfoFromJSON,
    ZoneInfoFromJSONTyped,
    ZoneInfoToJSON,
    ZoneInfoToJSONTyped,
} from './ZoneInfo';

/**
 * 
 * @export
 * @interface ApplianceResponse
 */
export interface ApplianceResponse {
    /**
     * 
     * @type {MeasureSiteAddress}
     * @memberof ApplianceResponse
     */
    address?: MeasureSiteAddress;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    articleNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    building?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    comment?: string;
    /**
     * 
     * @type {ZoneInfo}
     * @memberof ApplianceResponse
     */
    coolerZone?: ZoneInfo;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    department?: string;
    /**
     * 
     * @type {ZoneInfo}
     * @memberof ApplianceResponse
     */
    freezerZone?: ZoneInfo;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    gatewayId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    inventoryNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    ipAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    macAddress?: string;
    /**
     * 
     * @type {Manuals}
     * @memberof ApplianceResponse
     */
    manuals?: Manuals;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    measureSiteType?: ApplianceResponseMeasureSiteTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApplianceResponse
     */
    modbusAddress?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    networkType?: ApplianceResponseNetworkTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ApplianceResponse
     */
    orderDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    productImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    room?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    serialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    softwareVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceResponse
     */
    virtualFridgeId?: string;
}


/**
 * @export
 */
export const ApplianceResponseMeasureSiteTypeEnum = {
    MODBUS_FRIDGE: 'MODBUS_FRIDGE',
    BINDER_FRIDGE: 'BINDER_FRIDGE',
    SMART_MODULE_FRIDGE: 'SMART_MODULE_FRIDGE',
    LOGGER: 'LOGGER'
} as const;
export type ApplianceResponseMeasureSiteTypeEnum = typeof ApplianceResponseMeasureSiteTypeEnum[keyof typeof ApplianceResponseMeasureSiteTypeEnum];

/**
 * @export
 */
export const ApplianceResponseNetworkTypeEnum = {
    LAN: 'LAN',
    WLAN: 'WLAN',
    WIFI: 'WIFI',
    UNKNOWN: 'UNKNOWN'
} as const;
export type ApplianceResponseNetworkTypeEnum = typeof ApplianceResponseNetworkTypeEnum[keyof typeof ApplianceResponseNetworkTypeEnum];


/**
 * Check if a given object implements the ApplianceResponse interface.
 */
export function instanceOfApplianceResponse(value: object): value is ApplianceResponse {
    return true;
}

export function ApplianceResponseFromJSON(json: any): ApplianceResponse {
    return ApplianceResponseFromJSONTyped(json, false);
}

export function ApplianceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'] == null ? undefined : MeasureSiteAddressFromJSON(json['address']),
        'articleNumber': json['articleNumber'] == null ? undefined : json['articleNumber'],
        'building': json['building'] == null ? undefined : json['building'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'coolerZone': json['coolerZone'] == null ? undefined : ZoneInfoFromJSON(json['coolerZone']),
        'department': json['department'] == null ? undefined : json['department'],
        'freezerZone': json['freezerZone'] == null ? undefined : ZoneInfoFromJSON(json['freezerZone']),
        'gatewayId': json['gatewayId'] == null ? undefined : json['gatewayId'],
        'inventoryNumber': json['inventoryNumber'] == null ? undefined : json['inventoryNumber'],
        'ipAddress': json['ipAddress'] == null ? undefined : json['ipAddress'],
        'level': json['level'] == null ? undefined : json['level'],
        'macAddress': json['macAddress'] == null ? undefined : json['macAddress'],
        'manuals': json['manuals'] == null ? undefined : ManualsFromJSON(json['manuals']),
        'manufacturer': json['manufacturer'] == null ? undefined : json['manufacturer'],
        'measureSiteType': json['measureSiteType'] == null ? undefined : json['measureSiteType'],
        'modbusAddress': json['modbusAddress'] == null ? undefined : json['modbusAddress'],
        'model': json['model'] == null ? undefined : json['model'],
        'name': json['name'] == null ? undefined : json['name'],
        'networkType': json['networkType'] == null ? undefined : json['networkType'],
        'orderDate': json['orderDate'] == null ? undefined : (new Date(json['orderDate'])),
        'productImageUrl': json['productImageUrl'] == null ? undefined : json['productImageUrl'],
        'room': json['room'] == null ? undefined : json['room'],
        'serialNumber': json['serialNumber'] == null ? undefined : json['serialNumber'],
        'softwareVersion': json['softwareVersion'] == null ? undefined : json['softwareVersion'],
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
    };
}

export function ApplianceResponseToJSON(json: any): ApplianceResponse {
    return ApplianceResponseToJSONTyped(json, false);
}

export function ApplianceResponseToJSONTyped(value?: ApplianceResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'address': MeasureSiteAddressToJSON(value['address']),
        'articleNumber': value['articleNumber'],
        'building': value['building'],
        'comment': value['comment'],
        'coolerZone': ZoneInfoToJSON(value['coolerZone']),
        'department': value['department'],
        'freezerZone': ZoneInfoToJSON(value['freezerZone']),
        'gatewayId': value['gatewayId'],
        'inventoryNumber': value['inventoryNumber'],
        'ipAddress': value['ipAddress'],
        'level': value['level'],
        'macAddress': value['macAddress'],
        'manuals': ManualsToJSON(value['manuals']),
        'manufacturer': value['manufacturer'],
        'measureSiteType': value['measureSiteType'],
        'modbusAddress': value['modbusAddress'],
        'model': value['model'],
        'name': value['name'],
        'networkType': value['networkType'],
        'orderDate': value['orderDate'] == null ? undefined : ((value['orderDate']).toISOString()),
        'productImageUrl': value['productImageUrl'],
        'room': value['room'],
        'serialNumber': value['serialNumber'],
        'softwareVersion': value['softwareVersion'],
        'virtualFridgeId': value['virtualFridgeId'],
    };
}

