/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccessCheckResponse,
  ColleagueResponse,
  MobileNumberResponse,
  UserInfoResponse,
  UserMobileRequest,
  UserSettings,
} from '../models/index';
import {
    AccessCheckResponseFromJSON,
    AccessCheckResponseToJSON,
    ColleagueResponseFromJSON,
    ColleagueResponseToJSON,
    MobileNumberResponseFromJSON,
    MobileNumberResponseToJSON,
    UserInfoResponseFromJSON,
    UserInfoResponseToJSON,
    UserMobileRequestFromJSON,
    UserMobileRequestToJSON,
    UserSettingsFromJSON,
    UserSettingsToJSON,
} from '../models/index';

export interface SetOtherUserMobileUsingPOSTRequest {
    userMobileRequest: UserMobileRequest;
}

export interface SetUserMobileUsingPOSTRequest {
    userMobileRequest: UserMobileRequest;
}

export interface StoreUserSettingsUsingPUTRequest {
    userSettings: UserSettings;
}

/**
 * UserControllerApi - interface
 * 
 * @export
 * @interface UserControllerApiInterface
 */
export interface UserControllerApiInterface {
    /**
     * checkAccess
     * @summary Checks whether the current user can access the application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApiInterface
     */
    checkAccessUsingPOSTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessCheckResponse>>;

    /**
     * checkAccess
     * Checks whether the current user can access the application.
     */
    checkAccessUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessCheckResponse>;

    /**
     * checkUser
     * @summary Gets the user info about the currently logged-in user. Creates the user and the organization if needed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApiInterface
     */
    checkUserUsingPOSTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfoResponse>>;

    /**
     * checkUser
     * Gets the user info about the currently logged-in user. Creates the user and the organization if needed.
     */
    checkUserUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfoResponse>;

    /**
     * getColleagues
     * @summary Gets the colleagues information for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApiInterface
     */
    getColleaguesUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColleagueResponse>>;

    /**
     * getColleagues
     * Gets the colleagues information for a user
     */
    getColleaguesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColleagueResponse>;

    /**
     * getUserSettings
     * @summary Gets the user settings for the user specified in the UserContext.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApiInterface
     */
    getUserSettingsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettings>>;

    /**
     * getUserSettings
     * Gets the user settings for the user specified in the UserContext.
     */
    getUserSettingsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettings>;

    /**
     * setOtherUserMobile
     * @summary Add phone number to user profile.
     * @param {UserMobileRequest} userMobileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApiInterface
     */
    setOtherUserMobileUsingPOSTRaw(requestParameters: SetOtherUserMobileUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobileNumberResponse>>;

    /**
     * setOtherUserMobile
     * Add phone number to user profile.
     */
    setOtherUserMobileUsingPOST(requestParameters: SetOtherUserMobileUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobileNumberResponse>;

    /**
     * setUserMobile
     * @summary Add phone number to user profile.
     * @param {UserMobileRequest} userMobileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApiInterface
     */
    setUserMobileUsingPOSTRaw(requestParameters: SetUserMobileUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobileNumberResponse>>;

    /**
     * setUserMobile
     * Add phone number to user profile.
     */
    setUserMobileUsingPOST(requestParameters: SetUserMobileUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobileNumberResponse>;

    /**
     * storeUserSettings
     * @summary Stores the UserContext for the user.
     * @param {UserSettings} userSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApiInterface
     */
    storeUserSettingsUsingPUTRaw(requestParameters: StoreUserSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * storeUserSettings
     * Stores the UserContext for the user.
     */
    storeUserSettingsUsingPUT(requestParameters: StoreUserSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class UserControllerApi extends runtime.BaseAPI implements UserControllerApiInterface {

    /**
     * checkAccess
     * Checks whether the current user can access the application.
     */
    async checkAccessUsingPOSTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessCheckResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/access/check`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessCheckResponseFromJSON(jsonValue));
    }

    /**
     * checkAccess
     * Checks whether the current user can access the application.
     */
    async checkAccessUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessCheckResponse> {
        const response = await this.checkAccessUsingPOSTRaw(initOverrides);
        return await response.value();
    }

    /**
     * checkUser
     * Gets the user info about the currently logged-in user. Creates the user and the organization if needed.
     */
    async checkUserUsingPOSTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user/check`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoResponseFromJSON(jsonValue));
    }

    /**
     * checkUser
     * Gets the user info about the currently logged-in user. Creates the user and the organization if needed.
     */
    async checkUserUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfoResponse> {
        const response = await this.checkUserUsingPOSTRaw(initOverrides);
        return await response.value();
    }

    /**
     * getColleagues
     * Gets the colleagues information for a user
     */
    async getColleaguesUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColleagueResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user/colleagues`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ColleagueResponseFromJSON(jsonValue));
    }

    /**
     * getColleagues
     * Gets the colleagues information for a user
     */
    async getColleaguesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColleagueResponse> {
        const response = await this.getColleaguesUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getUserSettings
     * Gets the user settings for the user specified in the UserContext.
     */
    async getUserSettingsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsFromJSON(jsonValue));
    }

    /**
     * getUserSettings
     * Gets the user settings for the user specified in the UserContext.
     */
    async getUserSettingsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettings> {
        const response = await this.getUserSettingsUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * setOtherUserMobile
     * Add phone number to user profile.
     */
    async setOtherUserMobileUsingPOSTRaw(requestParameters: SetOtherUserMobileUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobileNumberResponse>> {
        if (requestParameters['userMobileRequest'] == null) {
            throw new runtime.RequiredError(
                'userMobileRequest',
                'Required parameter "userMobileRequest" was null or undefined when calling setOtherUserMobileUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/user/mobile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserMobileRequestToJSON(requestParameters['userMobileRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileNumberResponseFromJSON(jsonValue));
    }

    /**
     * setOtherUserMobile
     * Add phone number to user profile.
     */
    async setOtherUserMobileUsingPOST(requestParameters: SetOtherUserMobileUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobileNumberResponse> {
        const response = await this.setOtherUserMobileUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * setUserMobile
     * Add phone number to user profile.
     */
    async setUserMobileUsingPOSTRaw(requestParameters: SetUserMobileUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobileNumberResponse>> {
        if (requestParameters['userMobileRequest'] == null) {
            throw new runtime.RequiredError(
                'userMobileRequest',
                'Required parameter "userMobileRequest" was null or undefined when calling setUserMobileUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user/mobile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserMobileRequestToJSON(requestParameters['userMobileRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileNumberResponseFromJSON(jsonValue));
    }

    /**
     * setUserMobile
     * Add phone number to user profile.
     */
    async setUserMobileUsingPOST(requestParameters: SetUserMobileUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobileNumberResponse> {
        const response = await this.setUserMobileUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * storeUserSettings
     * Stores the UserContext for the user.
     */
    async storeUserSettingsUsingPUTRaw(requestParameters: StoreUserSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userSettings'] == null) {
            throw new runtime.RequiredError(
                'userSettings',
                'Required parameter "userSettings" was null or undefined when calling storeUserSettingsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserSettingsToJSON(requestParameters['userSettings']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * storeUserSettings
     * Stores the UserContext for the user.
     */
    async storeUserSettingsUsingPUT(requestParameters: StoreUserSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.storeUserSettingsUsingPUTRaw(requestParameters, initOverrides);
    }

}
