/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SensorInfo
 */
export interface SensorInfo {
  /**
   *
   * @type {string}
   * @memberof SensorInfo
   */
  sensorType?: SensorInfoSensorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SensorInfo
   */
  sensorUuid?: string;
  /**
   *
   * @type {number}
   * @memberof SensorInfo
   */
  slotIndex?: number;
  /**
   *
   * @type {string}
   * @memberof SensorInfo
   */
  thingId?: string;
}

/**
 * @export
 */
export const SensorInfoSensorTypeEnum = {
  DOOR: 'DOOR',
  TEMPERATURE: 'TEMPERATURE',
  PRODUCT_TEMPERATURE: 'PRODUCT_TEMPERATURE'
} as const;
export type SensorInfoSensorTypeEnum = typeof SensorInfoSensorTypeEnum[keyof typeof SensorInfoSensorTypeEnum];

/**
 * Check if a given object implements the SensorInfo interface.
 */
export function instanceOfSensorInfo(value: object): value is SensorInfo {
  return true;
}

export function SensorInfoFromJSON(json: any): SensorInfo {
  return SensorInfoFromJSONTyped(json, false);
}

export function SensorInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SensorInfo {
  if (json == null) {
    return json;
  }
  return {
    sensorType: json['sensorType'] == null ? undefined : json['sensorType'],
    sensorUuid: json['sensorUuid'] == null ? undefined : json['sensorUuid'],
    slotIndex: json['slotIndex'] == null ? undefined : json['slotIndex'],
    thingId: json['thingId'] == null ? undefined : json['thingId']
  };
}

export function SensorInfoToJSON(json: any): SensorInfo {
  return SensorInfoToJSONTyped(json, false);
}

export function SensorInfoToJSONTyped(value?: SensorInfo | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    sensorType: value['sensorType'],
    sensorUuid: value['sensorUuid'],
    slotIndex: value['slotIndex'],
    thingId: value['thingId']
  };
}
