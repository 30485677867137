/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Message,
  MessageDescription,
  MessageStats,
  ServiceEmailRequest,
} from '../models/index';
import {
    MessageFromJSON,
    MessageToJSON,
    MessageDescriptionFromJSON,
    MessageDescriptionToJSON,
    MessageStatsFromJSON,
    MessageStatsToJSON,
    ServiceEmailRequestFromJSON,
    ServiceEmailRequestToJSON,
} from '../models/index';

export interface DownloadMessageUsingGETRequest {
    messagename: string;
}

export interface SendServiceEmailUsingPOSTRequest {
    serviceEmailRequest: ServiceEmailRequest;
}

/**
 * MessagingControllerApi - interface
 * 
 * @export
 * @interface MessagingControllerApiInterface
 */
export interface MessagingControllerApiInterface {
    /**
     * downloadMessage
     * @summary Gets the current messages for the user.
     * @param {string} messagename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingControllerApiInterface
     */
    downloadMessageUsingGETRaw(requestParameters: DownloadMessageUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Message>>;

    /**
     * downloadMessage
     * Gets the current messages for the user.
     */
    downloadMessageUsingGET(requestParameters: DownloadMessageUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Message>;

    /**
     * getMessageStats
     * @summary Gets the message stats (unread messages count and read messages count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingControllerApiInterface
     */
    getMessageStatsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MessageStats>>;

    /**
     * getMessageStats
     * Gets the message stats (unread messages count and read messages count
     */
    getMessageStatsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MessageStats>;

    /**
     * listMessages
     * @summary Gets the current messages for the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingControllerApiInterface
     */
    listMessagesUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MessageDescription>>>;

    /**
     * listMessages
     * Gets the current messages for the user.
     */
    listMessagesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MessageDescription>>;

    /**
     * sendServiceEmail
     * @summary Sends a service request by email.
     * @param {ServiceEmailRequest} serviceEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingControllerApiInterface
     */
    sendServiceEmailUsingPOSTRaw(requestParameters: SendServiceEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>>;

    /**
     * sendServiceEmail
     * Sends a service request by email.
     */
    sendServiceEmailUsingPOST(requestParameters: SendServiceEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean>;

}

/**
 * 
 */
export class MessagingControllerApi extends runtime.BaseAPI implements MessagingControllerApiInterface {

    /**
     * downloadMessage
     * Gets the current messages for the user.
     */
    async downloadMessageUsingGETRaw(requestParameters: DownloadMessageUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Message>> {
        if (requestParameters['messagename'] == null) {
            throw new runtime.RequiredError(
                'messagename',
                'Required parameter "messagename" was null or undefined when calling downloadMessageUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/messaging/message/{messagename}`.replace(`{${"messagename"}}`, encodeURIComponent(String(requestParameters['messagename']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageFromJSON(jsonValue));
    }

    /**
     * downloadMessage
     * Gets the current messages for the user.
     */
    async downloadMessageUsingGET(requestParameters: DownloadMessageUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Message> {
        const response = await this.downloadMessageUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMessageStats
     * Gets the message stats (unread messages count and read messages count
     */
    async getMessageStatsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MessageStats>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/messaging/messagestats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageStatsFromJSON(jsonValue));
    }

    /**
     * getMessageStats
     * Gets the message stats (unread messages count and read messages count
     */
    async getMessageStatsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MessageStats> {
        const response = await this.getMessageStatsUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * listMessages
     * Gets the current messages for the user.
     */
    async listMessagesUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MessageDescription>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/messaging/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MessageDescriptionFromJSON));
    }

    /**
     * listMessages
     * Gets the current messages for the user.
     */
    async listMessagesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MessageDescription>> {
        const response = await this.listMessagesUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * sendServiceEmail
     * Sends a service request by email.
     */
    async sendServiceEmailUsingPOSTRaw(requestParameters: SendServiceEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['serviceEmailRequest'] == null) {
            throw new runtime.RequiredError(
                'serviceEmailRequest',
                'Required parameter "serviceEmailRequest" was null or undefined when calling sendServiceEmailUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/messaging/serviceEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceEmailRequestToJSON(requestParameters['serviceEmailRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * sendServiceEmail
     * Sends a service request by email.
     */
    async sendServiceEmailUsingPOST(requestParameters: SendServiceEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.sendServiceEmailUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
