/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AlarmComment } from './AlarmComment';
import {
    AlarmCommentFromJSON,
    AlarmCommentFromJSONTyped,
    AlarmCommentToJSON,
    AlarmCommentToJSONTyped,
} from './AlarmComment';

/**
 * 
 * @export
 * @interface AlarmCommentsResponse
 */
export interface AlarmCommentsResponse {
    /**
     * 
     * @type {Array<AlarmComment>}
     * @memberof AlarmCommentsResponse
     */
    alarmComments?: Array<AlarmComment>;
    /**
     * 
     * @type {number}
     * @memberof AlarmCommentsResponse
     */
    maxCommentsCount?: number;
}

/**
 * Check if a given object implements the AlarmCommentsResponse interface.
 */
export function instanceOfAlarmCommentsResponse(value: object): value is AlarmCommentsResponse {
    return true;
}

export function AlarmCommentsResponseFromJSON(json: any): AlarmCommentsResponse {
    return AlarmCommentsResponseFromJSONTyped(json, false);
}

export function AlarmCommentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlarmCommentsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'alarmComments': json['alarmComments'] == null ? undefined : ((json['alarmComments'] as Array<any>).map(AlarmCommentFromJSON)),
        'maxCommentsCount': json['maxCommentsCount'] == null ? undefined : json['maxCommentsCount'],
    };
}

export function AlarmCommentsResponseToJSON(json: any): AlarmCommentsResponse {
    return AlarmCommentsResponseToJSONTyped(json, false);
}

export function AlarmCommentsResponseToJSONTyped(value?: AlarmCommentsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alarmComments': value['alarmComments'] == null ? undefined : ((value['alarmComments'] as Array<any>).map(AlarmCommentToJSON)),
        'maxCommentsCount': value['maxCommentsCount'],
    };
}

