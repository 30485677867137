/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoggableContent,
  LoggingConfig,
} from '../models/index';
import {
    LoggableContentFromJSON,
    LoggableContentToJSON,
    LoggingConfigFromJSON,
    LoggingConfigToJSON,
} from '../models/index';

export interface UploadLoggingUsingPOSTRequest {
    loggableContent: LoggableContent;
}

/**
 * LogControllerApi - interface
 * 
 * @export
 * @interface LogControllerApiInterface
 */
export interface LogControllerApiInterface {
    /**
     * getLoggingConfig
     * @summary Allows the application to download a logging config for a user. Fallback is a simple standard config.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerApiInterface
     */
    getLoggingConfigUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggingConfig>>;

    /**
     * getLoggingConfig
     * Allows the application to download a logging config for a user. Fallback is a simple standard config.
     */
    getLoggingConfigUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggingConfig>;

    /**
     * uploadLogging
     * @summary Used by the logging mechanism to upload a block of logging entries.
     * @param {LoggableContent} loggableContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerApiInterface
     */
    uploadLoggingUsingPOSTRaw(requestParameters: UploadLoggingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * uploadLogging
     * Used by the logging mechanism to upload a block of logging entries.
     */
    uploadLoggingUsingPOST(requestParameters: UploadLoggingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class LogControllerApi extends runtime.BaseAPI implements LogControllerApiInterface {

    /**
     * getLoggingConfig
     * Allows the application to download a logging config for a user. Fallback is a simple standard config.
     */
    async getLoggingConfigUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggingConfig>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/loggingConfig`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingConfigFromJSON(jsonValue));
    }

    /**
     * getLoggingConfig
     * Allows the application to download a logging config for a user. Fallback is a simple standard config.
     */
    async getLoggingConfigUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggingConfig> {
        const response = await this.getLoggingConfigUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * uploadLogging
     * Used by the logging mechanism to upload a block of logging entries.
     */
    async uploadLoggingUsingPOSTRaw(requestParameters: UploadLoggingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['loggableContent'] == null) {
            throw new runtime.RequiredError(
                'loggableContent',
                'Required parameter "loggableContent" was null or undefined when calling uploadLoggingUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logging`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoggableContentToJSON(requestParameters['loggableContent']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * uploadLogging
     * Used by the logging mechanism to upload a block of logging entries.
     */
    async uploadLoggingUsingPOST(requestParameters: UploadLoggingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadLoggingUsingPOSTRaw(requestParameters, initOverrides);
    }

}
