/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GatewayState } from './GatewayState';
import {
    GatewayStateFromJSON,
    GatewayStateFromJSONTyped,
    GatewayStateToJSON,
    GatewayStateToJSONTyped,
} from './GatewayState';

/**
 * 
 * @export
 * @interface GatewayStatesResponse
 */
export interface GatewayStatesResponse {
    /**
     * 
     * @type {Array<GatewayState>}
     * @memberof GatewayStatesResponse
     */
    gatewayStates?: Array<GatewayState>;
}

/**
 * Check if a given object implements the GatewayStatesResponse interface.
 */
export function instanceOfGatewayStatesResponse(value: object): value is GatewayStatesResponse {
    return true;
}

export function GatewayStatesResponseFromJSON(json: any): GatewayStatesResponse {
    return GatewayStatesResponseFromJSONTyped(json, false);
}

export function GatewayStatesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GatewayStatesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'gatewayStates': json['gatewayStates'] == null ? undefined : ((json['gatewayStates'] as Array<any>).map(GatewayStateFromJSON)),
    };
}

export function GatewayStatesResponseToJSON(json: any): GatewayStatesResponse {
    return GatewayStatesResponseToJSONTyped(json, false);
}

export function GatewayStatesResponseToJSONTyped(value?: GatewayStatesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'gatewayStates': value['gatewayStates'] == null ? undefined : ((value['gatewayStates'] as Array<any>).map(GatewayStateToJSON)),
    };
}

