/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SmartModuleZoneTemperature } from './SmartModuleZoneTemperature';
import {
    SmartModuleZoneTemperatureFromJSON,
    SmartModuleZoneTemperatureFromJSONTyped,
    SmartModuleZoneTemperatureToJSON,
    SmartModuleZoneTemperatureToJSONTyped,
} from './SmartModuleZoneTemperature';

/**
 * 
 * @export
 * @interface SmartModuleZoneState
 */
export interface SmartModuleZoneState {
    /**
     * 
     * @type {Array<string>}
     * @memberof SmartModuleZoneState
     */
    alarmTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SmartModuleZoneState
     */
    doorState?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartModuleZoneState
     */
    state?: string;
    /**
     * 
     * @type {SmartModuleZoneTemperature}
     * @memberof SmartModuleZoneState
     */
    temperature?: SmartModuleZoneTemperature;
    /**
     * 
     * @type {string}
     * @memberof SmartModuleZoneState
     */
    thingId?: string;
}

/**
 * Check if a given object implements the SmartModuleZoneState interface.
 */
export function instanceOfSmartModuleZoneState(value: object): value is SmartModuleZoneState {
    return true;
}

export function SmartModuleZoneStateFromJSON(json: any): SmartModuleZoneState {
    return SmartModuleZoneStateFromJSONTyped(json, false);
}

export function SmartModuleZoneStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmartModuleZoneState {
    if (json == null) {
        return json;
    }
    return {
        
        'alarmTypes': json['alarmTypes'] == null ? undefined : json['alarmTypes'],
        'doorState': json['doorState'] == null ? undefined : json['doorState'],
        'state': json['state'] == null ? undefined : json['state'],
        'temperature': json['temperature'] == null ? undefined : SmartModuleZoneTemperatureFromJSON(json['temperature']),
        'thingId': json['thingId'] == null ? undefined : json['thingId'],
    };
}

export function SmartModuleZoneStateToJSON(json: any): SmartModuleZoneState {
    return SmartModuleZoneStateToJSONTyped(json, false);
}

export function SmartModuleZoneStateToJSONTyped(value?: SmartModuleZoneState | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alarmTypes': value['alarmTypes'],
        'doorState': value['doorState'],
        'state': value['state'],
        'temperature': SmartModuleZoneTemperatureToJSON(value['temperature']),
        'thingId': value['thingId'],
    };
}

