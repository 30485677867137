/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdditionalTemperatureAlarmSettings } from './AdditionalTemperatureAlarmSettings';
import {
    AdditionalTemperatureAlarmSettingsFromJSON,
    AdditionalTemperatureAlarmSettingsFromJSONTyped,
    AdditionalTemperatureAlarmSettingsToJSON,
    AdditionalTemperatureAlarmSettingsToJSONTyped,
} from './AdditionalTemperatureAlarmSettings';

/**
 * 
 * @export
 * @interface SmartModuleZoneSettingsRequest
 */
export interface SmartModuleZoneSettingsRequest {
    /**
     * 
     * @type {AdditionalTemperatureAlarmSettings}
     * @memberof SmartModuleZoneSettingsRequest
     */
    additionalTemperatureAlarmSettings?: AdditionalTemperatureAlarmSettings;
    /**
     * 
     * @type {string}
     * @memberof SmartModuleZoneSettingsRequest
     */
    thingId?: string;
}

/**
 * Check if a given object implements the SmartModuleZoneSettingsRequest interface.
 */
export function instanceOfSmartModuleZoneSettingsRequest(value: object): value is SmartModuleZoneSettingsRequest {
    return true;
}

export function SmartModuleZoneSettingsRequestFromJSON(json: any): SmartModuleZoneSettingsRequest {
    return SmartModuleZoneSettingsRequestFromJSONTyped(json, false);
}

export function SmartModuleZoneSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmartModuleZoneSettingsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'additionalTemperatureAlarmSettings': json['additionalTemperatureAlarmSettings'] == null ? undefined : AdditionalTemperatureAlarmSettingsFromJSON(json['additionalTemperatureAlarmSettings']),
        'thingId': json['thingId'] == null ? undefined : json['thingId'],
    };
}

export function SmartModuleZoneSettingsRequestToJSON(json: any): SmartModuleZoneSettingsRequest {
    return SmartModuleZoneSettingsRequestToJSONTyped(json, false);
}

export function SmartModuleZoneSettingsRequestToJSONTyped(value?: SmartModuleZoneSettingsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'additionalTemperatureAlarmSettings': AdditionalTemperatureAlarmSettingsToJSON(value['additionalTemperatureAlarmSettings']),
        'thingId': value['thingId'],
    };
}

