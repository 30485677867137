/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationGroups } from './OrganizationGroups';
import {
    OrganizationGroupsFromJSON,
    OrganizationGroupsFromJSONTyped,
    OrganizationGroupsToJSON,
    OrganizationGroupsToJSONTyped,
} from './OrganizationGroups';

/**
 * 
 * @export
 * @interface OrganizationSettings
 */
export interface OrganizationSettings {
    /**
     * 
     * @type {Date}
     * @memberof OrganizationSettings
     */
    companyCreated?: Date;
    /**
     * 
     * @type {OrganizationGroups}
     * @memberof OrganizationSettings
     */
    groups?: OrganizationGroups;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationSettings
     */
    groupsChanged?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettings
     */
    language?: OrganizationSettingsLanguageEnum;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationSettings
     */
    languageChanged?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSettings
     */
    organizationArea?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationSettings
     */
    organizationAreaChanged?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSettings
     */
    valid?: boolean;
}


/**
 * @export
 */
export const OrganizationSettingsLanguageEnum = {
    DE_DE: 'deDE',
    EN_US: 'enUS',
    EN_GB: 'enGB',
    FR_FR: 'frFR',
    ES_ES: 'esES',
    PL_PL: 'plPL',
    CS_CZ: 'csCZ',
    SL_SI: 'slSI',
    SK_SK: 'skSK',
    HU_HU: 'huHU',
    BG_BG: 'bgBG',
    RO_RO: 'roRO',
    EL_GR: 'elGR'
} as const;
export type OrganizationSettingsLanguageEnum = typeof OrganizationSettingsLanguageEnum[keyof typeof OrganizationSettingsLanguageEnum];


/**
 * Check if a given object implements the OrganizationSettings interface.
 */
export function instanceOfOrganizationSettings(value: object): value is OrganizationSettings {
    return true;
}

export function OrganizationSettingsFromJSON(json: any): OrganizationSettings {
    return OrganizationSettingsFromJSONTyped(json, false);
}

export function OrganizationSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'companyCreated': json['companyCreated'] == null ? undefined : (new Date(json['companyCreated'])),
        'groups': json['groups'] == null ? undefined : OrganizationGroupsFromJSON(json['groups']),
        'groupsChanged': json['groupsChanged'] == null ? undefined : (new Date(json['groupsChanged'])),
        'language': json['language'] == null ? undefined : json['language'],
        'languageChanged': json['languageChanged'] == null ? undefined : (new Date(json['languageChanged'])),
        'organizationArea': json['organizationArea'] == null ? undefined : json['organizationArea'],
        'organizationAreaChanged': json['organizationAreaChanged'] == null ? undefined : (new Date(json['organizationAreaChanged'])),
        'valid': json['valid'] == null ? undefined : json['valid'],
    };
}

export function OrganizationSettingsToJSON(json: any): OrganizationSettings {
    return OrganizationSettingsToJSONTyped(json, false);
}

export function OrganizationSettingsToJSONTyped(value?: OrganizationSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'companyCreated': value['companyCreated'] == null ? undefined : ((value['companyCreated']).toISOString()),
        'groups': OrganizationGroupsToJSON(value['groups']),
        'groupsChanged': value['groupsChanged'] == null ? undefined : ((value['groupsChanged']).toISOString()),
        'language': value['language'],
        'languageChanged': value['languageChanged'] == null ? undefined : ((value['languageChanged']).toISOString()),
        'organizationArea': value['organizationArea'],
        'organizationAreaChanged': value['organizationAreaChanged'] == null ? undefined : ((value['organizationAreaChanged']).toISOString()),
        'valid': value['valid'],
    };
}

