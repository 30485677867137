/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LoggingConfig
 */
export interface LoggingConfig {
  /**
   *
   * @type {number}
   * @memberof LoggingConfig
   */
  bufferSize?: number;
  /**
   *
   * @type {boolean}
   * @memberof LoggingConfig
   */
  console?: boolean;
  /**
   *
   * @type {string}
   * @memberof LoggingConfig
   */
  logLevel?: LoggingConfigLogLevelEnum;
  /**
   *
   * @type {string}
   * @memberof LoggingConfig
   */
  serverFlush?: LoggingConfigServerFlushEnum;
}

/**
 * @export
 */
export const LoggingConfigLogLevelEnum = {
  INFO: 'info',
  DEBUG: 'debug',
  WARN: 'warn',
  ERROR: 'error'
} as const;
export type LoggingConfigLogLevelEnum = typeof LoggingConfigLogLevelEnum[keyof typeof LoggingConfigLogLevelEnum];

/**
 * @export
 */
export const LoggingConfigServerFlushEnum = {
  OFF: 'off',
  BUFFER_SIZE: 'bufferSize',
  WARN: 'warn',
  ERROR: 'error'
} as const;
export type LoggingConfigServerFlushEnum = typeof LoggingConfigServerFlushEnum[keyof typeof LoggingConfigServerFlushEnum];

/**
 * Check if a given object implements the LoggingConfig interface.
 */
export function instanceOfLoggingConfig(value: object): value is LoggingConfig {
  return true;
}

export function LoggingConfigFromJSON(json: any): LoggingConfig {
  return LoggingConfigFromJSONTyped(json, false);
}

export function LoggingConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggingConfig {
  if (json == null) {
    return json;
  }
  return {
    bufferSize: json['bufferSize'] == null ? undefined : json['bufferSize'],
    console: json['console'] == null ? undefined : json['console'],
    logLevel: json['logLevel'] == null ? undefined : json['logLevel'],
    serverFlush: json['serverFlush'] == null ? undefined : json['serverFlush']
  };
}

export function LoggingConfigToJSON(json: any): LoggingConfig {
  return LoggingConfigToJSONTyped(json, false);
}

export function LoggingConfigToJSONTyped(value?: LoggingConfig | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    bufferSize: value['bufferSize'],
    console: value['console'],
    logLevel: value['logLevel'],
    serverFlush: value['serverFlush']
  };
}
