/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Gateway } from './Gateway';
import {
    GatewayFromJSON,
    GatewayFromJSONTyped,
    GatewayToJSON,
    GatewayToJSONTyped,
} from './Gateway';

/**
 * 
 * @export
 * @interface GatewayResponse
 */
export interface GatewayResponse {
    /**
     * 
     * @type {Array<Gateway>}
     * @memberof GatewayResponse
     */
    droppedGateways?: Array<Gateway>;
    /**
     * 
     * @type {Array<Gateway>}
     * @memberof GatewayResponse
     */
    gatewayIds?: Array<Gateway>;
    /**
     * 
     * @type {Array<Gateway>}
     * @memberof GatewayResponse
     */
    gateways?: Array<Gateway>;
}

/**
 * Check if a given object implements the GatewayResponse interface.
 */
export function instanceOfGatewayResponse(value: object): value is GatewayResponse {
    return true;
}

export function GatewayResponseFromJSON(json: any): GatewayResponse {
    return GatewayResponseFromJSONTyped(json, false);
}

export function GatewayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GatewayResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'droppedGateways': json['droppedGateways'] == null ? undefined : ((json['droppedGateways'] as Array<any>).map(GatewayFromJSON)),
        'gatewayIds': json['gatewayIds'] == null ? undefined : ((json['gatewayIds'] as Array<any>).map(GatewayFromJSON)),
        'gateways': json['gateways'] == null ? undefined : ((json['gateways'] as Array<any>).map(GatewayFromJSON)),
    };
}

export function GatewayResponseToJSON(json: any): GatewayResponse {
    return GatewayResponseToJSONTyped(json, false);
}

export function GatewayResponseToJSONTyped(value?: GatewayResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'droppedGateways': value['droppedGateways'] == null ? undefined : ((value['droppedGateways'] as Array<any>).map(GatewayToJSON)),
        'gatewayIds': value['gatewayIds'] == null ? undefined : ((value['gatewayIds'] as Array<any>).map(GatewayToJSON)),
        'gateways': value['gateways'] == null ? undefined : ((value['gateways'] as Array<any>).map(GatewayToJSON)),
    };
}

