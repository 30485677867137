/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LicenseOverviewResponse } from './LicenseOverviewResponse';
import {
    LicenseOverviewResponseFromJSON,
    LicenseOverviewResponseFromJSONTyped,
    LicenseOverviewResponseToJSON,
    LicenseOverviewResponseToJSONTyped,
} from './LicenseOverviewResponse';

/**
 * 
 * @export
 * @interface VoucherResultResponse
 */
export interface VoucherResultResponse {
    /**
     * 
     * @type {LicenseOverviewResponse}
     * @memberof VoucherResultResponse
     */
    licenseOverviewResponse?: LicenseOverviewResponse;
    /**
     * 
     * @type {string}
     * @memberof VoucherResultResponse
     */
    result?: VoucherResultResponseResultEnum;
}


/**
 * @export
 */
export const VoucherResultResponseResultEnum = {
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    ALREADY_USED: 'ALREADY_USED',
    ENTITLEMENT_MISSING: 'ENTITLEMENT_MISSING'
} as const;
export type VoucherResultResponseResultEnum = typeof VoucherResultResponseResultEnum[keyof typeof VoucherResultResponseResultEnum];


/**
 * Check if a given object implements the VoucherResultResponse interface.
 */
export function instanceOfVoucherResultResponse(value: object): value is VoucherResultResponse {
    return true;
}

export function VoucherResultResponseFromJSON(json: any): VoucherResultResponse {
    return VoucherResultResponseFromJSONTyped(json, false);
}

export function VoucherResultResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoucherResultResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'licenseOverviewResponse': json['licenseOverviewResponse'] == null ? undefined : LicenseOverviewResponseFromJSON(json['licenseOverviewResponse']),
        'result': json['result'] == null ? undefined : json['result'],
    };
}

export function VoucherResultResponseToJSON(json: any): VoucherResultResponse {
    return VoucherResultResponseToJSONTyped(json, false);
}

export function VoucherResultResponseToJSONTyped(value?: VoucherResultResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'licenseOverviewResponse': LicenseOverviewResponseToJSON(value['licenseOverviewResponse']),
        'result': value['result'],
    };
}

