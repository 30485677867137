/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReportZipResponse
 */
export interface ReportZipResponse {
  /**
   *
   * @type {string}
   * @memberof ReportZipResponse
   */
  zipBase64Encoded?: string;
}

/**
 * Check if a given object implements the ReportZipResponse interface.
 */
export function instanceOfReportZipResponse(value: object): value is ReportZipResponse {
  return true;
}

export function ReportZipResponseFromJSON(json: any): ReportZipResponse {
  return ReportZipResponseFromJSONTyped(json, false);
}

export function ReportZipResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportZipResponse {
  if (json == null) {
    return json;
  }
  return {
    zipBase64Encoded: json['zipBase64Encoded'] == null ? undefined : json['zipBase64Encoded']
  };
}

export function ReportZipResponseToJSON(json: any): ReportZipResponse {
  return ReportZipResponseToJSONTyped(json, false);
}

export function ReportZipResponseToJSONTyped(
  value?: ReportZipResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    zipBase64Encoded: value['zipBase64Encoded']
  };
}
