/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationGroup } from './OrganizationGroup';
import {
    OrganizationGroupFromJSON,
    OrganizationGroupFromJSONTyped,
    OrganizationGroupToJSON,
    OrganizationGroupToJSONTyped,
} from './OrganizationGroup';

/**
 * 
 * @export
 * @interface OrganizationGroups
 */
export interface OrganizationGroups {
    /**
     * 
     * @type {OrganizationGroup}
     * @memberof OrganizationGroups
     */
    defaultGroup?: OrganizationGroup;
    /**
     * 
     * @type {Array<OrganizationGroup>}
     * @memberof OrganizationGroups
     */
    groups?: Array<OrganizationGroup>;
}

/**
 * Check if a given object implements the OrganizationGroups interface.
 */
export function instanceOfOrganizationGroups(value: object): value is OrganizationGroups {
    return true;
}

export function OrganizationGroupsFromJSON(json: any): OrganizationGroups {
    return OrganizationGroupsFromJSONTyped(json, false);
}

export function OrganizationGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationGroups {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultGroup': json['defaultGroup'] == null ? undefined : OrganizationGroupFromJSON(json['defaultGroup']),
        'groups': json['groups'] == null ? undefined : ((json['groups'] as Array<any>).map(OrganizationGroupFromJSON)),
    };
}

export function OrganizationGroupsToJSON(json: any): OrganizationGroups {
    return OrganizationGroupsToJSONTyped(json, false);
}

export function OrganizationGroupsToJSONTyped(value?: OrganizationGroups | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultGroup': OrganizationGroupToJSON(value['defaultGroup']),
        'groups': value['groups'] == null ? undefined : ((value['groups'] as Array<any>).map(OrganizationGroupToJSON)),
    };
}

