/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeasureSiteSiteInformation } from './MeasureSiteSiteInformation';
import {
    MeasureSiteSiteInformationFromJSON,
    MeasureSiteSiteInformationFromJSONTyped,
    MeasureSiteSiteInformationToJSON,
    MeasureSiteSiteInformationToJSONTyped,
} from './MeasureSiteSiteInformation';

/**
 * 
 * @export
 * @interface SaveApplianceRequest
 */
export interface SaveApplianceRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    additionalInformation?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    articleNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    compartmentType?: SaveApplianceRequestCompartmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    inventoryNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof SaveApplianceRequest
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof SaveApplianceRequest
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof SaveApplianceRequest
     */
    modbusAddress: number;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    modelDescription: string;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    serialNumber: string;
    /**
     * 
     * @type {MeasureSiteSiteInformation}
     * @memberof SaveApplianceRequest
     */
    siteInformation?: MeasureSiteSiteInformation;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    stateRegion?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveApplianceRequest
     */
    zipCode?: string;
}


/**
 * @export
 */
export const SaveApplianceRequestCompartmentTypeEnum = {
    COOLER: 'COOLER',
    FREEZER: 'FREEZER'
} as const;
export type SaveApplianceRequestCompartmentTypeEnum = typeof SaveApplianceRequestCompartmentTypeEnum[keyof typeof SaveApplianceRequestCompartmentTypeEnum];


/**
 * Check if a given object implements the SaveApplianceRequest interface.
 */
export function instanceOfSaveApplianceRequest(value: object): value is SaveApplianceRequest {
    if (!('articleNumber' in value) || value['articleNumber'] === undefined) return false;
    if (!('modbusAddress' in value) || value['modbusAddress'] === undefined) return false;
    if (!('modelDescription' in value) || value['modelDescription'] === undefined) return false;
    if (!('serialNumber' in value) || value['serialNumber'] === undefined) return false;
    return true;
}

export function SaveApplianceRequestFromJSON(json: any): SaveApplianceRequest {
    return SaveApplianceRequestFromJSONTyped(json, false);
}

export function SaveApplianceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveApplianceRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'additionalInformation': json['additionalInformation'] == null ? undefined : json['additionalInformation'],
        'addressLine1': json['addressLine1'] == null ? undefined : json['addressLine1'],
        'addressLine2': json['addressLine2'] == null ? undefined : json['addressLine2'],
        'alias': json['alias'] == null ? undefined : json['alias'],
        'articleNumber': json['articleNumber'],
        'city': json['city'] == null ? undefined : json['city'],
        'compartmentType': json['compartmentType'] == null ? undefined : json['compartmentType'],
        'inventoryNumber': json['inventoryNumber'] == null ? undefined : json['inventoryNumber'],
        'latitude': json['latitude'] == null ? undefined : json['latitude'],
        'longitude': json['longitude'] == null ? undefined : json['longitude'],
        'modbusAddress': json['modbusAddress'],
        'modelDescription': json['modelDescription'],
        'name': json['name'] == null ? undefined : json['name'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'serialNumber': json['serialNumber'],
        'siteInformation': json['siteInformation'] == null ? undefined : MeasureSiteSiteInformationFromJSON(json['siteInformation']),
        'stateRegion': json['stateRegion'] == null ? undefined : json['stateRegion'],
        'zipCode': json['zipCode'] == null ? undefined : json['zipCode'],
    };
}

export function SaveApplianceRequestToJSON(json: any): SaveApplianceRequest {
    return SaveApplianceRequestToJSONTyped(json, false);
}

export function SaveApplianceRequestToJSONTyped(value?: SaveApplianceRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'additionalInformation': value['additionalInformation'],
        'addressLine1': value['addressLine1'],
        'addressLine2': value['addressLine2'],
        'alias': value['alias'],
        'articleNumber': value['articleNumber'],
        'city': value['city'],
        'compartmentType': value['compartmentType'],
        'inventoryNumber': value['inventoryNumber'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
        'modbusAddress': value['modbusAddress'],
        'modelDescription': value['modelDescription'],
        'name': value['name'],
        'phone': value['phone'],
        'serialNumber': value['serialNumber'],
        'siteInformation': MeasureSiteSiteInformationToJSON(value['siteInformation']),
        'stateRegion': value['stateRegion'],
        'zipCode': value['zipCode'],
    };
}

