/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LogEvent } from './LogEvent';
import {
    LogEventFromJSON,
    LogEventFromJSONTyped,
    LogEventToJSON,
    LogEventToJSONTyped,
} from './LogEvent';
import type { LoggableContentMetadata } from './LoggableContentMetadata';
import {
    LoggableContentMetadataFromJSON,
    LoggableContentMetadataFromJSONTyped,
    LoggableContentMetadataToJSON,
    LoggableContentMetadataToJSONTyped,
} from './LoggableContentMetadata';

/**
 * 
 * @export
 * @interface LoggableContent
 */
export interface LoggableContent {
    /**
     * 
     * @type {Array<LogEvent>}
     * @memberof LoggableContent
     */
    events: Array<LogEvent>;
    /**
     * 
     * @type {LoggableContentMetadata}
     * @memberof LoggableContent
     */
    meta: LoggableContentMetadata;
}

/**
 * Check if a given object implements the LoggableContent interface.
 */
export function instanceOfLoggableContent(value: object): value is LoggableContent {
    if (!('events' in value) || value['events'] === undefined) return false;
    if (!('meta' in value) || value['meta'] === undefined) return false;
    return true;
}

export function LoggableContentFromJSON(json: any): LoggableContent {
    return LoggableContentFromJSONTyped(json, false);
}

export function LoggableContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggableContent {
    if (json == null) {
        return json;
    }
    return {
        
        'events': ((json['events'] as Array<any>).map(LogEventFromJSON)),
        'meta': LoggableContentMetadataFromJSON(json['meta']),
    };
}

export function LoggableContentToJSON(json: any): LoggableContent {
    return LoggableContentToJSONTyped(json, false);
}

export function LoggableContentToJSONTyped(value?: LoggableContent | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'events': ((value['events'] as Array<any>).map(LogEventToJSON)),
        'meta': LoggableContentMetadataToJSON(value['meta']),
    };
}

