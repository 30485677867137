/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CloudAlarmSettingsResponse
 */
export interface CloudAlarmSettingsResponse {
  /**
   *
   * @type {boolean}
   * @memberof CloudAlarmSettingsResponse
   */
  cloudAlarmActivated?: boolean;
  /**
   *
   * @type {number}
   * @memberof CloudAlarmSettingsResponse
   */
  cloudAlarmDelay?: number;
  /**
   *
   * @type {number}
   * @memberof CloudAlarmSettingsResponse
   */
  cloudAlarmHigherTempLimit?: number;
  /**
   *
   * @type {number}
   * @memberof CloudAlarmSettingsResponse
   */
  cloudAlarmLowerTempLimit?: number;
  /**
   *
   * @type {boolean}
   * @memberof CloudAlarmSettingsResponse
   */
  cloudAlarmNotificationActivated?: boolean;
  /**
   *
   * @type {string}
   * @memberof CloudAlarmSettingsResponse
   */
  cloudAlarmSettingId?: string;
  /**
   *
   * @type {string}
   * @memberof CloudAlarmSettingsResponse
   */
  zoneThingId?: string;
}

/**
 * Check if a given object implements the CloudAlarmSettingsResponse interface.
 */
export function instanceOfCloudAlarmSettingsResponse(value: object): value is CloudAlarmSettingsResponse {
  return true;
}

export function CloudAlarmSettingsResponseFromJSON(json: any): CloudAlarmSettingsResponse {
  return CloudAlarmSettingsResponseFromJSONTyped(json, false);
}

export function CloudAlarmSettingsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CloudAlarmSettingsResponse {
  if (json == null) {
    return json;
  }
  return {
    cloudAlarmActivated: json['cloudAlarmActivated'] == null ? undefined : json['cloudAlarmActivated'],
    cloudAlarmDelay: json['cloudAlarmDelay'] == null ? undefined : json['cloudAlarmDelay'],
    cloudAlarmHigherTempLimit:
      json['cloudAlarmHigherTempLimit'] == null ? undefined : json['cloudAlarmHigherTempLimit'],
    cloudAlarmLowerTempLimit: json['cloudAlarmLowerTempLimit'] == null ? undefined : json['cloudAlarmLowerTempLimit'],
    cloudAlarmNotificationActivated:
      json['cloudAlarmNotificationActivated'] == null ? undefined : json['cloudAlarmNotificationActivated'],
    cloudAlarmSettingId: json['cloudAlarmSettingId'] == null ? undefined : json['cloudAlarmSettingId'],
    zoneThingId: json['zoneThingId'] == null ? undefined : json['zoneThingId']
  };
}

export function CloudAlarmSettingsResponseToJSON(json: any): CloudAlarmSettingsResponse {
  return CloudAlarmSettingsResponseToJSONTyped(json, false);
}

export function CloudAlarmSettingsResponseToJSONTyped(
  value?: CloudAlarmSettingsResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    cloudAlarmActivated: value['cloudAlarmActivated'],
    cloudAlarmDelay: value['cloudAlarmDelay'],
    cloudAlarmHigherTempLimit: value['cloudAlarmHigherTempLimit'],
    cloudAlarmLowerTempLimit: value['cloudAlarmLowerTempLimit'],
    cloudAlarmNotificationActivated: value['cloudAlarmNotificationActivated'],
    cloudAlarmSettingId: value['cloudAlarmSettingId'],
    zoneThingId: value['zoneThingId']
  };
}
