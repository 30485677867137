/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeasureSite } from './MeasureSite';
import {
    MeasureSiteFromJSON,
    MeasureSiteFromJSONTyped,
    MeasureSiteToJSON,
    MeasureSiteToJSONTyped,
} from './MeasureSite';

/**
 * 
 * @export
 * @interface AllMeasureSitesResponse
 */
export interface AllMeasureSitesResponse {
    /**
     * 
     * @type {Array<MeasureSite>}
     * @memberof AllMeasureSitesResponse
     */
    connectables?: Array<MeasureSite>;
    /**
     * 
     * @type {Array<MeasureSite>}
     * @memberof AllMeasureSitesResponse
     */
    loggers?: Array<MeasureSite>;
    /**
     * 
     * @type {Array<MeasureSite>}
     * @memberof AllMeasureSitesResponse
     */
    smartModuleFridges?: Array<MeasureSite>;
}

/**
 * Check if a given object implements the AllMeasureSitesResponse interface.
 */
export function instanceOfAllMeasureSitesResponse(value: object): value is AllMeasureSitesResponse {
    return true;
}

export function AllMeasureSitesResponseFromJSON(json: any): AllMeasureSitesResponse {
    return AllMeasureSitesResponseFromJSONTyped(json, false);
}

export function AllMeasureSitesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllMeasureSitesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'connectables': json['connectables'] == null ? undefined : ((json['connectables'] as Array<any>).map(MeasureSiteFromJSON)),
        'loggers': json['loggers'] == null ? undefined : ((json['loggers'] as Array<any>).map(MeasureSiteFromJSON)),
        'smartModuleFridges': json['smartModuleFridges'] == null ? undefined : ((json['smartModuleFridges'] as Array<any>).map(MeasureSiteFromJSON)),
    };
}

export function AllMeasureSitesResponseToJSON(json: any): AllMeasureSitesResponse {
    return AllMeasureSitesResponseToJSONTyped(json, false);
}

export function AllMeasureSitesResponseToJSONTyped(value?: AllMeasureSitesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'connectables': value['connectables'] == null ? undefined : ((value['connectables'] as Array<any>).map(MeasureSiteToJSON)),
        'loggers': value['loggers'] == null ? undefined : ((value['loggers'] as Array<any>).map(MeasureSiteToJSON)),
        'smartModuleFridges': value['smartModuleFridges'] == null ? undefined : ((value['smartModuleFridges'] as Array<any>).map(MeasureSiteToJSON)),
    };
}

