/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AlarmComment
 */
export interface AlarmComment {
  /**
   *
   * @type {string}
   * @memberof AlarmComment
   */
  alarmId?: string;
  /**
   *
   * @type {string}
   * @memberof AlarmComment
   */
  comment?: string;
  /**
   *
   * @type {Date}
   * @memberof AlarmComment
   */
  commentTime?: Date;
  /**
   *
   * @type {string}
   * @memberof AlarmComment
   */
  commentator?: string;
  /**
   *
   * @type {string}
   * @memberof AlarmComment
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AlarmComment
   */
  reason?: string;
  /**
   *
   * @type {string}
   * @memberof AlarmComment
   */
  upn?: string;
}

/**
 * Check if a given object implements the AlarmComment interface.
 */
export function instanceOfAlarmComment(value: object): value is AlarmComment {
  return true;
}

export function AlarmCommentFromJSON(json: any): AlarmComment {
  return AlarmCommentFromJSONTyped(json, false);
}

export function AlarmCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlarmComment {
  if (json == null) {
    return json;
  }
  return {
    alarmId: json['alarmId'] == null ? undefined : json['alarmId'],
    comment: json['comment'] == null ? undefined : json['comment'],
    commentTime: json['commentTime'] == null ? undefined : new Date(json['commentTime']),
    commentator: json['commentator'] == null ? undefined : json['commentator'],
    id: json['id'] == null ? undefined : json['id'],
    reason: json['reason'] == null ? undefined : json['reason'],
    upn: json['upn'] == null ? undefined : json['upn']
  };
}

export function AlarmCommentToJSON(json: any): AlarmComment {
  return AlarmCommentToJSONTyped(json, false);
}

export function AlarmCommentToJSONTyped(value?: AlarmComment | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    alarmId: value['alarmId'],
    comment: value['comment'],
    commentTime: value['commentTime'] == null ? undefined : value['commentTime'].toISOString(),
    commentator: value['commentator'],
    id: value['id'],
    reason: value['reason'],
    upn: value['upn']
  };
}
