/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LoggerLiveState
 */
export interface LoggerLiveState {
  /**
   *
   * @type {number}
   * @memberof LoggerLiveState
   */
  batteryLevelPercent?: number;
  /**
   *
   * @type {boolean}
   * @memberof LoggerLiveState
   */
  configInSync?: boolean;
  /**
   *
   * @type {string}
   * @memberof LoggerLiveState
   */
  lastCommunicationTime?: string;
  /**
   *
   * @type {string}
   * @memberof LoggerLiveState
   */
  nextCommunicationTime?: string;
  /**
   *
   * @type {number}
   * @memberof LoggerLiveState
   */
  radioLevelPercent?: number;
}

/**
 * Check if a given object implements the LoggerLiveState interface.
 */
export function instanceOfLoggerLiveState(value: object): value is LoggerLiveState {
  return true;
}

export function LoggerLiveStateFromJSON(json: any): LoggerLiveState {
  return LoggerLiveStateFromJSONTyped(json, false);
}

export function LoggerLiveStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggerLiveState {
  if (json == null) {
    return json;
  }
  return {
    batteryLevelPercent: json['batteryLevelPercent'] == null ? undefined : json['batteryLevelPercent'],
    configInSync: json['configInSync'] == null ? undefined : json['configInSync'],
    lastCommunicationTime: json['lastCommunicationTime'] == null ? undefined : json['lastCommunicationTime'],
    nextCommunicationTime: json['nextCommunicationTime'] == null ? undefined : json['nextCommunicationTime'],
    radioLevelPercent: json['radioLevelPercent'] == null ? undefined : json['radioLevelPercent']
  };
}

export function LoggerLiveStateToJSON(json: any): LoggerLiveState {
  return LoggerLiveStateToJSONTyped(json, false);
}

export function LoggerLiveStateToJSONTyped(value?: LoggerLiveState | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    batteryLevelPercent: value['batteryLevelPercent'],
    configInSync: value['configInSync'],
    lastCommunicationTime: value['lastCommunicationTime'],
    nextCommunicationTime: value['nextCommunicationTime'],
    radioLevelPercent: value['radioLevelPercent']
  };
}
