/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VirtualFridgePermissionsResponse
 */
export interface VirtualFridgePermissionsResponse {
  /**
   *
   * @type {string}
   * @memberof VirtualFridgePermissionsResponse
   */
  upn?: string;
  /**
   *
   * @type {string}
   * @memberof VirtualFridgePermissionsResponse
   */
  virtualFridgeId?: string;
}

/**
 * Check if a given object implements the VirtualFridgePermissionsResponse interface.
 */
export function instanceOfVirtualFridgePermissionsResponse(value: object): value is VirtualFridgePermissionsResponse {
  return true;
}

export function VirtualFridgePermissionsResponseFromJSON(json: any): VirtualFridgePermissionsResponse {
  return VirtualFridgePermissionsResponseFromJSONTyped(json, false);
}

export function VirtualFridgePermissionsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VirtualFridgePermissionsResponse {
  if (json == null) {
    return json;
  }
  return {
    upn: json['upn'] == null ? undefined : json['upn'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function VirtualFridgePermissionsResponseToJSON(json: any): VirtualFridgePermissionsResponse {
  return VirtualFridgePermissionsResponseToJSONTyped(json, false);
}

export function VirtualFridgePermissionsResponseToJSONTyped(
  value?: VirtualFridgePermissionsResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    upn: value['upn'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
