/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LicenseOverviewResponse } from './LicenseOverviewResponse';
import {
    LicenseOverviewResponseFromJSON,
    LicenseOverviewResponseFromJSONTyped,
    LicenseOverviewResponseToJSON,
    LicenseOverviewResponseToJSONTyped,
} from './LicenseOverviewResponse';

/**
 * 
 * @export
 * @interface UnassignmentResultResponse
 */
export interface UnassignmentResultResponse {
    /**
     * 
     * @type {LicenseOverviewResponse}
     * @memberof UnassignmentResultResponse
     */
    licenseOverviewResponse?: LicenseOverviewResponse;
    /**
     * 
     * @type {string}
     * @memberof UnassignmentResultResponse
     */
    result?: UnassignmentResultResponseResultEnum;
}


/**
 * @export
 */
export const UnassignmentResultResponseResultEnum = {
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
} as const;
export type UnassignmentResultResponseResultEnum = typeof UnassignmentResultResponseResultEnum[keyof typeof UnassignmentResultResponseResultEnum];


/**
 * Check if a given object implements the UnassignmentResultResponse interface.
 */
export function instanceOfUnassignmentResultResponse(value: object): value is UnassignmentResultResponse {
    return true;
}

export function UnassignmentResultResponseFromJSON(json: any): UnassignmentResultResponse {
    return UnassignmentResultResponseFromJSONTyped(json, false);
}

export function UnassignmentResultResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnassignmentResultResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'licenseOverviewResponse': json['licenseOverviewResponse'] == null ? undefined : LicenseOverviewResponseFromJSON(json['licenseOverviewResponse']),
        'result': json['result'] == null ? undefined : json['result'],
    };
}

export function UnassignmentResultResponseToJSON(json: any): UnassignmentResultResponse {
    return UnassignmentResultResponseToJSONTyped(json, false);
}

export function UnassignmentResultResponseToJSONTyped(value?: UnassignmentResultResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'licenseOverviewResponse': LicenseOverviewResponseToJSON(value['licenseOverviewResponse']),
        'result': value['result'],
    };
}

