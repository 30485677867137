/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GatewayState
 */
export interface GatewayState {
  /**
   *
   * @type {string}
   * @memberof GatewayState
   */
  gatewayId?: string;
  /**
   *
   * @type {string}
   * @memberof GatewayState
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof GatewayState
   */
  online?: boolean;
  /**
   *
   * @type {string}
   * @memberof GatewayState
   */
  serialNumber?: string;
  /**
   *
   * @type {Date}
   * @memberof GatewayState
   */
  since?: Date;
}

/**
 * Check if a given object implements the GatewayState interface.
 */
export function instanceOfGatewayState(value: object): value is GatewayState {
  return true;
}

export function GatewayStateFromJSON(json: any): GatewayState {
  return GatewayStateFromJSONTyped(json, false);
}

export function GatewayStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): GatewayState {
  if (json == null) {
    return json;
  }
  return {
    gatewayId: json['gatewayId'] == null ? undefined : json['gatewayId'],
    name: json['name'] == null ? undefined : json['name'],
    online: json['online'] == null ? undefined : json['online'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    since: json['since'] == null ? undefined : new Date(json['since'])
  };
}

export function GatewayStateToJSON(json: any): GatewayState {
  return GatewayStateToJSONTyped(json, false);
}

export function GatewayStateToJSONTyped(value?: GatewayState | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    gatewayId: value['gatewayId'],
    name: value['name'],
    online: value['online'],
    serialNumber: value['serialNumber'],
    since: value['since'] == null ? undefined : value['since'].toISOString()
  };
}
