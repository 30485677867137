/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateBsdbRequest
 */
export interface CreateBsdbRequest {
  /**
   *
   * @type {string}
   * @memberof CreateBsdbRequest
   */
  gatewayId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBsdbRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateBsdbRequest
   */
  organizationId: string;
  /**
   *
   * @type {string}
   * @memberof CreateBsdbRequest
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBsdbRequest
   */
  timezone: string;
}

/**
 * Check if a given object implements the CreateBsdbRequest interface.
 */
export function instanceOfCreateBsdbRequest(value: object): value is CreateBsdbRequest {
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
  if (!('timezone' in value) || value['timezone'] === undefined) return false;
  return true;
}

export function CreateBsdbRequestFromJSON(json: any): CreateBsdbRequest {
  return CreateBsdbRequestFromJSONTyped(json, false);
}

export function CreateBsdbRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBsdbRequest {
  if (json == null) {
    return json;
  }
  return {
    gatewayId: json['gatewayId'] == null ? undefined : json['gatewayId'],
    name: json['name'],
    organizationId: json['organizationId'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    timezone: json['timezone']
  };
}

export function CreateBsdbRequestToJSON(json: any): CreateBsdbRequest {
  return CreateBsdbRequestToJSONTyped(json, false);
}

export function CreateBsdbRequestToJSONTyped(
  value?: CreateBsdbRequest | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    gatewayId: value['gatewayId'],
    name: value['name'],
    organizationId: value['organizationId'],
    serialNumber: value['serialNumber'],
    timezone: value['timezone']
  };
}
