/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
  /**
   *
   * @type {boolean}
   * @memberof UserProfile
   */
  email?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserProfile
   */
  sms?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  upn?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserProfile
   */
  voiceCall?: boolean;
}

/**
 * Check if a given object implements the UserProfile interface.
 */
export function instanceOfUserProfile(value: object): value is UserProfile {
  return true;
}

export function UserProfileFromJSON(json: any): UserProfile {
  return UserProfileFromJSONTyped(json, false);
}

export function UserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfile {
  if (json == null) {
    return json;
  }
  return {
    email: json['email'] == null ? undefined : json['email'],
    sms: json['sms'] == null ? undefined : json['sms'],
    upn: json['upn'] == null ? undefined : json['upn'],
    voiceCall: json['voiceCall'] == null ? undefined : json['voiceCall']
  };
}

export function UserProfileToJSON(json: any): UserProfile {
  return UserProfileToJSONTyped(json, false);
}

export function UserProfileToJSONTyped(value?: UserProfile | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    email: value['email'],
    sms: value['sms'],
    upn: value['upn'],
    voiceCall: value['voiceCall']
  };
}
