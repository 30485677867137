/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AlarmCommentsRequest
 */
export interface AlarmCommentsRequest {
  /**
   *
   * @type {string}
   * @memberof AlarmCommentsRequest
   */
  alarmId?: string;
  /**
   *
   * @type {string}
   * @memberof AlarmCommentsRequest
   */
  source?: string;
}

/**
 * Check if a given object implements the AlarmCommentsRequest interface.
 */
export function instanceOfAlarmCommentsRequest(value: object): value is AlarmCommentsRequest {
  return true;
}

export function AlarmCommentsRequestFromJSON(json: any): AlarmCommentsRequest {
  return AlarmCommentsRequestFromJSONTyped(json, false);
}

export function AlarmCommentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlarmCommentsRequest {
  if (json == null) {
    return json;
  }
  return {
    alarmId: json['alarmId'] == null ? undefined : json['alarmId'],
    source: json['source'] == null ? undefined : json['source']
  };
}

export function AlarmCommentsRequestToJSON(json: any): AlarmCommentsRequest {
  return AlarmCommentsRequestToJSONTyped(json, false);
}

export function AlarmCommentsRequestToJSONTyped(
  value?: AlarmCommentsRequest | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    alarmId: value['alarmId'],
    source: value['source']
  };
}
