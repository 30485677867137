/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GatewayNetworkState
 */
export interface GatewayNetworkState {
  /**
   *
   * @type {string}
   * @memberof GatewayNetworkState
   */
  hostName?: string;
  /**
   *
   * @type {string}
   * @memberof GatewayNetworkState
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof GatewayNetworkState
   */
  macAddress?: string;
  /**
   *
   * @type {string}
   * @memberof GatewayNetworkState
   */
  type?: string;
}

/**
 * Check if a given object implements the GatewayNetworkState interface.
 */
export function instanceOfGatewayNetworkState(value: object): value is GatewayNetworkState {
  return true;
}

export function GatewayNetworkStateFromJSON(json: any): GatewayNetworkState {
  return GatewayNetworkStateFromJSONTyped(json, false);
}

export function GatewayNetworkStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): GatewayNetworkState {
  if (json == null) {
    return json;
  }
  return {
    hostName: json['hostName'] == null ? undefined : json['hostName'],
    ipAddress: json['ipAddress'] == null ? undefined : json['ipAddress'],
    macAddress: json['macAddress'] == null ? undefined : json['macAddress'],
    type: json['type'] == null ? undefined : json['type']
  };
}

export function GatewayNetworkStateToJSON(json: any): GatewayNetworkState {
  return GatewayNetworkStateToJSONTyped(json, false);
}

export function GatewayNetworkStateToJSONTyped(
  value?: GatewayNetworkState | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    hostName: value['hostName'],
    ipAddress: value['ipAddress'],
    macAddress: value['macAddress'],
    type: value['type']
  };
}
