/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MessageDescription
 */
export interface MessageDescription {
  /**
   *
   * @type {Date}
   * @memberof MessageDescription
   */
  messageDate?: Date;
  /**
   *
   * @type {string}
   * @memberof MessageDescription
   */
  messageName?: string;
  /**
   *
   * @type {string}
   * @memberof MessageDescription
   */
  messageTitle?: string;
}

/**
 * Check if a given object implements the MessageDescription interface.
 */
export function instanceOfMessageDescription(value: object): value is MessageDescription {
  return true;
}

export function MessageDescriptionFromJSON(json: any): MessageDescription {
  return MessageDescriptionFromJSONTyped(json, false);
}

export function MessageDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageDescription {
  if (json == null) {
    return json;
  }
  return {
    messageDate: json['messageDate'] == null ? undefined : new Date(json['messageDate']),
    messageName: json['messageName'] == null ? undefined : json['messageName'],
    messageTitle: json['messageTitle'] == null ? undefined : json['messageTitle']
  };
}

export function MessageDescriptionToJSON(json: any): MessageDescription {
  return MessageDescriptionToJSONTyped(json, false);
}

export function MessageDescriptionToJSONTyped(
  value?: MessageDescription | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    messageDate: value['messageDate'] == null ? undefined : value['messageDate'].toISOString(),
    messageName: value['messageName'],
    messageTitle: value['messageTitle']
  };
}
