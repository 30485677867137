/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LegalText
 */
export interface LegalText {
  /**
   *
   * @type {string}
   * @memberof LegalText
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof LegalText
   */
  locale?: string;
  /**
   *
   * @type {string}
   * @memberof LegalText
   */
  type?: LegalTextTypeEnum;
}

/**
 * @export
 */
export const LegalTextTypeEnum = {
  ERROR: 'ERROR',
  TERMS_OF_USE: 'TERMS_OF_USE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  LICENSE_CONDITIONS: 'LICENSE_CONDITIONS'
} as const;
export type LegalTextTypeEnum = typeof LegalTextTypeEnum[keyof typeof LegalTextTypeEnum];

/**
 * Check if a given object implements the LegalText interface.
 */
export function instanceOfLegalText(value: object): value is LegalText {
  return true;
}

export function LegalTextFromJSON(json: any): LegalText {
  return LegalTextFromJSONTyped(json, false);
}

export function LegalTextFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalText {
  if (json == null) {
    return json;
  }
  return {
    content: json['content'] == null ? undefined : json['content'],
    locale: json['locale'] == null ? undefined : json['locale'],
    type: json['type'] == null ? undefined : json['type']
  };
}

export function LegalTextToJSON(json: any): LegalText {
  return LegalTextToJSONTyped(json, false);
}

export function LegalTextToJSONTyped(value?: LegalText | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    content: value['content'],
    locale: value['locale'],
    type: value['type']
  };
}
