/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Thing
 */
export interface Thing {
  /**
   *
   * @type {string}
   * @memberof Thing
   */
  modbusAddress?: string;
}

/**
 * Check if a given object implements the Thing interface.
 */
export function instanceOfThing(value: object): value is Thing {
  return true;
}

export function ThingFromJSON(json: any): Thing {
  return ThingFromJSONTyped(json, false);
}

export function ThingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Thing {
  if (json == null) {
    return json;
  }
  return {
    modbusAddress: json['modbusAddress'] == null ? undefined : json['modbusAddress']
  };
}

export function ThingToJSON(json: any): Thing {
  return ThingToJSONTyped(json, false);
}

export function ThingToJSONTyped(value?: Thing | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    modbusAddress: value['modbusAddress']
  };
}
