/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ApplianceZoneReportData
 */
export interface ApplianceZoneReportData {
  /**
   *
   * @type {string}
   * @memberof ApplianceZoneReportData
   */
  thingId?: string;
  /**
   *
   * @type {string}
   * @memberof ApplianceZoneReportData
   */
  zoneName?: string;
}

/**
 * Check if a given object implements the ApplianceZoneReportData interface.
 */
export function instanceOfApplianceZoneReportData(value: object): value is ApplianceZoneReportData {
  return true;
}

export function ApplianceZoneReportDataFromJSON(json: any): ApplianceZoneReportData {
  return ApplianceZoneReportDataFromJSONTyped(json, false);
}

export function ApplianceZoneReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceZoneReportData {
  if (json == null) {
    return json;
  }
  return {
    thingId: json['thingId'] == null ? undefined : json['thingId'],
    zoneName: json['zoneName'] == null ? undefined : json['zoneName']
  };
}

export function ApplianceZoneReportDataToJSON(json: any): ApplianceZoneReportData {
  return ApplianceZoneReportDataToJSONTyped(json, false);
}

export function ApplianceZoneReportDataToJSONTyped(
  value?: ApplianceZoneReportData | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    thingId: value['thingId'],
    zoneName: value['zoneName']
  };
}
