/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserPermissions
 */
export interface UserPermissions {
  /**
   *
   * @type {Array<string>}
   * @memberof UserPermissions
   */
  permittedDevices?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UserPermissions
   */
  upn?: string;
}

/**
 * Check if a given object implements the UserPermissions interface.
 */
export function instanceOfUserPermissions(value: object): value is UserPermissions {
  return true;
}

export function UserPermissionsFromJSON(json: any): UserPermissions {
  return UserPermissionsFromJSONTyped(json, false);
}

export function UserPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPermissions {
  if (json == null) {
    return json;
  }
  return {
    permittedDevices: json['permittedDevices'] == null ? undefined : json['permittedDevices'],
    upn: json['upn'] == null ? undefined : json['upn']
  };
}

export function UserPermissionsToJSON(json: any): UserPermissions {
  return UserPermissionsToJSONTyped(json, false);
}

export function UserPermissionsToJSONTyped(value?: UserPermissions | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    permittedDevices: value['permittedDevices'],
    upn: value['upn']
  };
}
