/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SmartModuleSystemSettingsRequest
 */
export interface SmartModuleSystemSettingsRequest {
  /**
   *
   * @type {boolean}
   * @memberof SmartModuleSystemSettingsRequest
   */
  enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof SmartModuleSystemSettingsRequest
   */
  offlineAlarmDelayInSeconds?: number;
  /**
   *
   * @type {boolean}
   * @memberof SmartModuleSystemSettingsRequest
   */
  offlineAlarmEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof SmartModuleSystemSettingsRequest
   */
  virtualFridgeId?: string;
}

/**
 * Check if a given object implements the SmartModuleSystemSettingsRequest interface.
 */
export function instanceOfSmartModuleSystemSettingsRequest(value: object): value is SmartModuleSystemSettingsRequest {
  return true;
}

export function SmartModuleSystemSettingsRequestFromJSON(json: any): SmartModuleSystemSettingsRequest {
  return SmartModuleSystemSettingsRequestFromJSONTyped(json, false);
}

export function SmartModuleSystemSettingsRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SmartModuleSystemSettingsRequest {
  if (json == null) {
    return json;
  }
  return {
    enabled: json['enabled'] == null ? undefined : json['enabled'],
    offlineAlarmDelayInSeconds:
      json['offlineAlarmDelayInSeconds'] == null ? undefined : json['offlineAlarmDelayInSeconds'],
    offlineAlarmEnabled: json['offlineAlarmEnabled'] == null ? undefined : json['offlineAlarmEnabled'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function SmartModuleSystemSettingsRequestToJSON(json: any): SmartModuleSystemSettingsRequest {
  return SmartModuleSystemSettingsRequestToJSONTyped(json, false);
}

export function SmartModuleSystemSettingsRequestToJSONTyped(
  value?: SmartModuleSystemSettingsRequest | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    enabled: value['enabled'],
    offlineAlarmDelayInSeconds: value['offlineAlarmDelayInSeconds'],
    offlineAlarmEnabled: value['offlineAlarmEnabled'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
