/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TimeZone
 */
export interface TimeZone {
  /**
   *
   * @type {string}
   * @memberof TimeZone
   */
  display?: string;
  /**
   *
   * @type {string}
   * @memberof TimeZone
   */
  iana?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TimeZone
   */
  relatedIanas?: Array<string>;
}

/**
 * Check if a given object implements the TimeZone interface.
 */
export function instanceOfTimeZone(value: object): value is TimeZone {
  return true;
}

export function TimeZoneFromJSON(json: any): TimeZone {
  return TimeZoneFromJSONTyped(json, false);
}

export function TimeZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeZone {
  if (json == null) {
    return json;
  }
  return {
    display: json['display'] == null ? undefined : json['display'],
    iana: json['iana'] == null ? undefined : json['iana'],
    relatedIanas: json['relatedIanas'] == null ? undefined : json['relatedIanas']
  };
}

export function TimeZoneToJSON(json: any): TimeZone {
  return TimeZoneToJSONTyped(json, false);
}

export function TimeZoneToJSONTyped(value?: TimeZone | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    display: value['display'],
    iana: value['iana'],
    relatedIanas: value['relatedIanas']
  };
}
