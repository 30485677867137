/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MeasureSiteAddress
 */
export interface MeasureSiteAddress {
  /**
   *
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  additionalInformation?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  addressLine1?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  countryIsoCode?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  externalId?: string;
  /**
   *
   * @type {number}
   * @memberof MeasureSiteAddress
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof MeasureSiteAddress
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  mappingType?: string;
  /**
   *
   * @type {boolean}
   * @memberof MeasureSiteAddress
   */
  measureSiteAddress?: boolean;
  /**
   *
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  stateRegion?: string;
  /**
   *
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  zipCode?: string;
}

/**
 * Check if a given object implements the MeasureSiteAddress interface.
 */
export function instanceOfMeasureSiteAddress(value: object): value is MeasureSiteAddress {
  return true;
}

export function MeasureSiteAddressFromJSON(json: any): MeasureSiteAddress {
  return MeasureSiteAddressFromJSONTyped(json, false);
}

export function MeasureSiteAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasureSiteAddress {
  if (json == null) {
    return json;
  }
  return {
    additionalInformation: json['additionalInformation'] == null ? undefined : json['additionalInformation'],
    addressLine1: json['addressLine1'] == null ? undefined : json['addressLine1'],
    addressLine2: json['addressLine2'] == null ? undefined : json['addressLine2'],
    city: json['city'] == null ? undefined : json['city'],
    countryIsoCode: json['countryIsoCode'] == null ? undefined : json['countryIsoCode'],
    externalId: json['externalId'] == null ? undefined : json['externalId'],
    latitude: json['latitude'] == null ? undefined : json['latitude'],
    longitude: json['longitude'] == null ? undefined : json['longitude'],
    mappingType: json['mappingType'] == null ? undefined : json['mappingType'],
    measureSiteAddress: json['measureSiteAddress'] == null ? undefined : json['measureSiteAddress'],
    phone: json['phone'] == null ? undefined : json['phone'],
    stateRegion: json['stateRegion'] == null ? undefined : json['stateRegion'],
    zipCode: json['zipCode'] == null ? undefined : json['zipCode']
  };
}

export function MeasureSiteAddressToJSON(json: any): MeasureSiteAddress {
  return MeasureSiteAddressToJSONTyped(json, false);
}

export function MeasureSiteAddressToJSONTyped(
  value?: MeasureSiteAddress | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    additionalInformation: value['additionalInformation'],
    addressLine1: value['addressLine1'],
    addressLine2: value['addressLine2'],
    city: value['city'],
    countryIsoCode: value['countryIsoCode'],
    externalId: value['externalId'],
    latitude: value['latitude'],
    longitude: value['longitude'],
    mappingType: value['mappingType'],
    measureSiteAddress: value['measureSiteAddress'],
    phone: value['phone'],
    stateRegion: value['stateRegion'],
    zipCode: value['zipCode']
  };
}
