/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LoggableContentMetadata
 */
export interface LoggableContentMetadata {
  /**
   *
   * @type {string}
   * @memberof LoggableContentMetadata
   */
  navigator?: string;
  /**
   *
   * @type {string}
   * @memberof LoggableContentMetadata
   */
  upn?: string;
}

/**
 * Check if a given object implements the LoggableContentMetadata interface.
 */
export function instanceOfLoggableContentMetadata(value: object): value is LoggableContentMetadata {
  return true;
}

export function LoggableContentMetadataFromJSON(json: any): LoggableContentMetadata {
  return LoggableContentMetadataFromJSONTyped(json, false);
}

export function LoggableContentMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggableContentMetadata {
  if (json == null) {
    return json;
  }
  return {
    navigator: json['navigator'] == null ? undefined : json['navigator'],
    upn: json['upn'] == null ? undefined : json['upn']
  };
}

export function LoggableContentMetadataToJSON(json: any): LoggableContentMetadata {
  return LoggableContentMetadataToJSONTyped(json, false);
}

export function LoggableContentMetadataToJSONTyped(
  value?: LoggableContentMetadata | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    navigator: value['navigator'],
    upn: value['upn']
  };
}
