/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SupportInfoLicense } from './SupportInfoLicense';
import {
    SupportInfoLicenseFromJSON,
    SupportInfoLicenseFromJSONTyped,
    SupportInfoLicenseToJSON,
    SupportInfoLicenseToJSONTyped,
} from './SupportInfoLicense';
import type { SupportInfoContact } from './SupportInfoContact';
import {
    SupportInfoContactFromJSON,
    SupportInfoContactFromJSONTyped,
    SupportInfoContactToJSON,
    SupportInfoContactToJSONTyped,
} from './SupportInfoContact';

/**
 * 
 * @export
 * @interface SupportInfo
 */
export interface SupportInfo {
    /**
     * 
     * @type {SupportInfoContact}
     * @memberof SupportInfo
     */
    contact?: SupportInfoContact;
    /**
     * 
     * @type {string}
     * @memberof SupportInfo
     */
    countryCode?: string;
    /**
     * 
     * @type {SupportInfoLicense}
     * @memberof SupportInfo
     */
    license?: SupportInfoLicense;
}

/**
 * Check if a given object implements the SupportInfo interface.
 */
export function instanceOfSupportInfo(value: object): value is SupportInfo {
    return true;
}

export function SupportInfoFromJSON(json: any): SupportInfo {
    return SupportInfoFromJSONTyped(json, false);
}

export function SupportInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'contact': json['contact'] == null ? undefined : SupportInfoContactFromJSON(json['contact']),
        'countryCode': json['countryCode'] == null ? undefined : json['countryCode'],
        'license': json['license'] == null ? undefined : SupportInfoLicenseFromJSON(json['license']),
    };
}

export function SupportInfoToJSON(json: any): SupportInfo {
    return SupportInfoToJSONTyped(json, false);
}

export function SupportInfoToJSONTyped(value?: SupportInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'contact': SupportInfoContactToJSON(value['contact']),
        'countryCode': value['countryCode'],
        'license': SupportInfoLicenseToJSON(value['license']),
    };
}

