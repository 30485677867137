<template>
  <div>
    <v-card flat class="mt-4">
      <v-layout row xs12 mb-3 mt-3>
        <v-flex xs7>
          <p style="font-size: 14px; color: var(--v-bus_black-base); margin-bottom: 5px">{{ $t('biz.settings.language.langtitle') }}</p>
          <p style="font-size: 14px; opacity: 0.54; color: var(--v-bus_black-base); margin-top: -2px">
            {{ $t('biz.settings.language.langtext') }}
          </p>
        </v-flex>
        <v-flex xs5>
          <v-layout align-end justify-center column fill-height mt-3>
            <v-layout row align-center>
              <span>{{ languageName }}</span>
              <v-img width="30" style="margin-left: 8px" :src="getLanguageFlag(language)" contain></v-img>
            </v-layout>
            <v-btn
              style="margin-right: 0px; margin-top: 10px"
              outline
              color="secondary"
              depressed
              flat
              @click="showLanguageSelection = true"
              >{{ $t('biz.settings.language.changelang') }}</v-btn
            >
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
    <SimpleDialog
      :dialogShown="showLanguageSelection"
      :title="$t('biz.settings.language.setuptitle')"
      :message="languageChangeMessage"
      track="LanguageSetup::LanguageSelection"
      :buttonExit="false"
    >
      <template v-slot:content>
        <p style="font-size: 14px; color: var(--v-bus_black-base); margin-bottom: 5px; font-weight: bold">
          {{ $t('biz.settings.language.langtitle') }}
        </p>
        <v-layout row class="language-group">
          <v-radio-group v-model="language" :mandatory="false">
            <template v-for="availableLanguage in availableLanguages">
              <v-layout row align-center>
                <v-radio
                  :key="availableLanguage.code"
                  color="secondary"
                  :value="availableLanguage"
                  :disabled="isLanguageMissing(availableLanguage)"
                >
                  <template v-slot:label>
                    <div :class="{ selected: availableLanguage.name === language.name }">
                      {{ availableLanguage.name }}
                    </div>
                  </template>
                </v-radio>
                <v-spacer></v-spacer>
                <v-img
                  width="30"
                  max-width="30"
                  style="margin-top: -10px"
                  :src="getLanguageFlag(availableLanguage)"
                  contain
                ></v-img>
              </v-layout>
            </template>
          </v-radio-group>
        </v-layout>
      </template>
      <template v-slot:buttons>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="primary"
          @click="savePersonalLanguage()"
          v-track="'LanguageSetup::LocalLanguageChange'"
          >{{ $t('general.ok') }}</v-btn
        >
      </template>
    </SimpleDialog>
  </div>
</template>

<script lang="ts" src="./MobileLanguageSetup.ts"></script>
<style lang="css" src="./MobileLanguageSetup.css"></style>
