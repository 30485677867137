/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Granularity } from './Granularity';
import {
    GranularityFromJSON,
    GranularityFromJSONTyped,
    GranularityToJSON,
    GranularityToJSONTyped,
} from './Granularity';

/**
 * 
 * @export
 * @interface AutomatedReportSettingsResponse
 */
export interface AutomatedReportSettingsResponse {
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsResponse
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsResponse
     */
    frequency?: AutomatedReportSettingsResponseFrequencyEnum;
    /**
     * 
     * @type {Granularity}
     * @memberof AutomatedReportSettingsResponse
     */
    granularity?: Granularity;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsResponse
     */
    internalOrganizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsResponse
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsResponse
     */
    profileId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AutomatedReportSettingsResponse
     */
    recipientEmails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsResponse
     */
    reportVariant?: AutomatedReportSettingsResponseReportVariantEnum;
    /**
     * 
     * @type {Date}
     * @memberof AutomatedReportSettingsResponse
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof AutomatedReportSettingsResponse
     */
    timezone?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AutomatedReportSettingsResponse
     */
    virtualFridgeIds?: Array<string>;
}


/**
 * @export
 */
export const AutomatedReportSettingsResponseFrequencyEnum = {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly'
} as const;
export type AutomatedReportSettingsResponseFrequencyEnum = typeof AutomatedReportSettingsResponseFrequencyEnum[keyof typeof AutomatedReportSettingsResponseFrequencyEnum];

/**
 * @export
 */
export const AutomatedReportSettingsResponseReportVariantEnum = {
    HACCP: 'HACCP',
    TEMPCONFORMITY: 'TEMPCONFORMITY'
} as const;
export type AutomatedReportSettingsResponseReportVariantEnum = typeof AutomatedReportSettingsResponseReportVariantEnum[keyof typeof AutomatedReportSettingsResponseReportVariantEnum];


/**
 * Check if a given object implements the AutomatedReportSettingsResponse interface.
 */
export function instanceOfAutomatedReportSettingsResponse(value: object): value is AutomatedReportSettingsResponse {
    return true;
}

export function AutomatedReportSettingsResponseFromJSON(json: any): AutomatedReportSettingsResponse {
    return AutomatedReportSettingsResponseFromJSONTyped(json, false);
}

export function AutomatedReportSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomatedReportSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'frequency': json['frequency'] == null ? undefined : json['frequency'],
        'granularity': json['granularity'] == null ? undefined : GranularityFromJSON(json['granularity']),
        'internalOrganizationId': json['internalOrganizationId'] == null ? undefined : json['internalOrganizationId'],
        'language': json['language'] == null ? undefined : json['language'],
        'name': json['name'] == null ? undefined : json['name'],
        'profileId': json['profileId'] == null ? undefined : json['profileId'],
        'recipientEmails': json['recipientEmails'] == null ? undefined : json['recipientEmails'],
        'reportVariant': json['reportVariant'] == null ? undefined : json['reportVariant'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
        'virtualFridgeIds': json['virtualFridgeIds'] == null ? undefined : json['virtualFridgeIds'],
    };
}

export function AutomatedReportSettingsResponseToJSON(json: any): AutomatedReportSettingsResponse {
    return AutomatedReportSettingsResponseToJSONTyped(json, false);
}

export function AutomatedReportSettingsResponseToJSONTyped(value?: AutomatedReportSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'createdBy': value['createdBy'],
        'frequency': value['frequency'],
        'granularity': GranularityToJSON(value['granularity']),
        'internalOrganizationId': value['internalOrganizationId'],
        'language': value['language'],
        'name': value['name'],
        'profileId': value['profileId'],
        'recipientEmails': value['recipientEmails'],
        'reportVariant': value['reportVariant'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'timezone': value['timezone'],
        'virtualFridgeIds': value['virtualFridgeIds'],
    };
}

