/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotificationSetting } from './NotificationSetting';
import {
    NotificationSettingFromJSON,
    NotificationSettingFromJSONTyped,
    NotificationSettingToJSON,
    NotificationSettingToJSONTyped,
} from './NotificationSetting';
import type { ReminderSetting } from './ReminderSetting';
import {
    ReminderSettingFromJSON,
    ReminderSettingFromJSONTyped,
    ReminderSettingToJSON,
    ReminderSettingToJSONTyped,
} from './ReminderSetting';

/**
 * 
 * @export
 * @interface NotificationSettingsRequest
 */
export interface NotificationSettingsRequest {
    /**
     * 
     * @type {Array<NotificationSetting>}
     * @memberof NotificationSettingsRequest
     */
    notificationSettings?: Array<NotificationSetting>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationSettingsRequest
     */
    recipientUpns?: Array<string>;
    /**
     * 
     * @type {ReminderSetting}
     * @memberof NotificationSettingsRequest
     */
    reminderSetting?: ReminderSetting;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationSettingsRequest
     */
    virtualFridgeIds?: Array<string>;
}

/**
 * Check if a given object implements the NotificationSettingsRequest interface.
 */
export function instanceOfNotificationSettingsRequest(value: object): value is NotificationSettingsRequest {
    return true;
}

export function NotificationSettingsRequestFromJSON(json: any): NotificationSettingsRequest {
    return NotificationSettingsRequestFromJSONTyped(json, false);
}

export function NotificationSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSettingsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'notificationSettings': json['notificationSettings'] == null ? undefined : ((json['notificationSettings'] as Array<any>).map(NotificationSettingFromJSON)),
        'recipientUpns': json['recipientUpns'] == null ? undefined : json['recipientUpns'],
        'reminderSetting': json['reminderSetting'] == null ? undefined : ReminderSettingFromJSON(json['reminderSetting']),
        'virtualFridgeIds': json['virtualFridgeIds'] == null ? undefined : json['virtualFridgeIds'],
    };
}

export function NotificationSettingsRequestToJSON(json: any): NotificationSettingsRequest {
    return NotificationSettingsRequestToJSONTyped(json, false);
}

export function NotificationSettingsRequestToJSONTyped(value?: NotificationSettingsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'notificationSettings': value['notificationSettings'] == null ? undefined : ((value['notificationSettings'] as Array<any>).map(NotificationSettingToJSON)),
        'recipientUpns': value['recipientUpns'],
        'reminderSetting': ReminderSettingToJSON(value['reminderSetting']),
        'virtualFridgeIds': value['virtualFridgeIds'],
    };
}

