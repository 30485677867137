/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AlarmProfileListItem } from './AlarmProfileListItem';
import {
    AlarmProfileListItemFromJSON,
    AlarmProfileListItemFromJSONTyped,
    AlarmProfileListItemToJSON,
    AlarmProfileListItemToJSONTyped,
} from './AlarmProfileListItem';

/**
 * 
 * @export
 * @interface ApplianceAlarmProfileListResponse
 */
export interface ApplianceAlarmProfileListResponse {
    /**
     * 
     * @type {Array<AlarmProfileListItem>}
     * @memberof ApplianceAlarmProfileListResponse
     */
    cloudAlarmProfiles?: Array<AlarmProfileListItem>;
    /**
     * 
     * @type {Array<AlarmProfileListItem>}
     * @memberof ApplianceAlarmProfileListResponse
     */
    gatewayAlarmProfiles?: Array<AlarmProfileListItem>;
    /**
     * 
     * @type {Array<AlarmProfileListItem>}
     * @memberof ApplianceAlarmProfileListResponse
     */
    smartModuleAlarmProfiles?: Array<AlarmProfileListItem>;
}

/**
 * Check if a given object implements the ApplianceAlarmProfileListResponse interface.
 */
export function instanceOfApplianceAlarmProfileListResponse(value: object): value is ApplianceAlarmProfileListResponse {
    return true;
}

export function ApplianceAlarmProfileListResponseFromJSON(json: any): ApplianceAlarmProfileListResponse {
    return ApplianceAlarmProfileListResponseFromJSONTyped(json, false);
}

export function ApplianceAlarmProfileListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceAlarmProfileListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'cloudAlarmProfiles': json['cloudAlarmProfiles'] == null ? undefined : ((json['cloudAlarmProfiles'] as Array<any>).map(AlarmProfileListItemFromJSON)),
        'gatewayAlarmProfiles': json['gatewayAlarmProfiles'] == null ? undefined : ((json['gatewayAlarmProfiles'] as Array<any>).map(AlarmProfileListItemFromJSON)),
        'smartModuleAlarmProfiles': json['smartModuleAlarmProfiles'] == null ? undefined : ((json['smartModuleAlarmProfiles'] as Array<any>).map(AlarmProfileListItemFromJSON)),
    };
}

export function ApplianceAlarmProfileListResponseToJSON(json: any): ApplianceAlarmProfileListResponse {
    return ApplianceAlarmProfileListResponseToJSONTyped(json, false);
}

export function ApplianceAlarmProfileListResponseToJSONTyped(value?: ApplianceAlarmProfileListResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'cloudAlarmProfiles': value['cloudAlarmProfiles'] == null ? undefined : ((value['cloudAlarmProfiles'] as Array<any>).map(AlarmProfileListItemToJSON)),
        'gatewayAlarmProfiles': value['gatewayAlarmProfiles'] == null ? undefined : ((value['gatewayAlarmProfiles'] as Array<any>).map(AlarmProfileListItemToJSON)),
        'smartModuleAlarmProfiles': value['smartModuleAlarmProfiles'] == null ? undefined : ((value['smartModuleAlarmProfiles'] as Array<any>).map(AlarmProfileListItemToJSON)),
    };
}

