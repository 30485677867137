/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MergedAlarm } from './MergedAlarm';
import {
    MergedAlarmFromJSON,
    MergedAlarmFromJSONTyped,
    MergedAlarmToJSON,
    MergedAlarmToJSONTyped,
} from './MergedAlarm';
import type { ContinuationAlarmToken } from './ContinuationAlarmToken';
import {
    ContinuationAlarmTokenFromJSON,
    ContinuationAlarmTokenFromJSONTyped,
    ContinuationAlarmTokenToJSON,
    ContinuationAlarmTokenToJSONTyped,
} from './ContinuationAlarmToken';

/**
 * 
 * @export
 * @interface FilteredMergedAlarmsResponse
 */
export interface FilteredMergedAlarmsResponse {
    /**
     * 
     * @type {number}
     * @memberof FilteredMergedAlarmsResponse
     */
    cutOffDays?: number;
    /**
     * 
     * @type {Array<MergedAlarm>}
     * @memberof FilteredMergedAlarmsResponse
     */
    mergedAlarms?: Array<MergedAlarm>;
    /**
     * 
     * @type {ContinuationAlarmToken}
     * @memberof FilteredMergedAlarmsResponse
     */
    token?: ContinuationAlarmToken;
}

/**
 * Check if a given object implements the FilteredMergedAlarmsResponse interface.
 */
export function instanceOfFilteredMergedAlarmsResponse(value: object): value is FilteredMergedAlarmsResponse {
    return true;
}

export function FilteredMergedAlarmsResponseFromJSON(json: any): FilteredMergedAlarmsResponse {
    return FilteredMergedAlarmsResponseFromJSONTyped(json, false);
}

export function FilteredMergedAlarmsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilteredMergedAlarmsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'cutOffDays': json['cutOffDays'] == null ? undefined : json['cutOffDays'],
        'mergedAlarms': json['mergedAlarms'] == null ? undefined : ((json['mergedAlarms'] as Array<any>).map(MergedAlarmFromJSON)),
        'token': json['token'] == null ? undefined : ContinuationAlarmTokenFromJSON(json['token']),
    };
}

export function FilteredMergedAlarmsResponseToJSON(json: any): FilteredMergedAlarmsResponse {
    return FilteredMergedAlarmsResponseToJSONTyped(json, false);
}

export function FilteredMergedAlarmsResponseToJSONTyped(value?: FilteredMergedAlarmsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'cutOffDays': value['cutOffDays'],
        'mergedAlarms': value['mergedAlarms'] == null ? undefined : ((value['mergedAlarms'] as Array<any>).map(MergedAlarmToJSON)),
        'token': ContinuationAlarmTokenToJSON(value['token']),
    };
}

