/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PatchAutoReport } from './PatchAutoReport';
import {
    PatchAutoReportFromJSON,
    PatchAutoReportFromJSONTyped,
    PatchAutoReportToJSON,
    PatchAutoReportToJSONTyped,
} from './PatchAutoReport';

/**
 * 
 * @export
 * @interface UpdateAutoReportSettings
 */
export interface UpdateAutoReportSettings {
    /**
     * 
     * @type {Array<PatchAutoReport>}
     * @memberof UpdateAutoReportSettings
     */
    reportSettings?: Array<PatchAutoReport>;
}

/**
 * Check if a given object implements the UpdateAutoReportSettings interface.
 */
export function instanceOfUpdateAutoReportSettings(value: object): value is UpdateAutoReportSettings {
    return true;
}

export function UpdateAutoReportSettingsFromJSON(json: any): UpdateAutoReportSettings {
    return UpdateAutoReportSettingsFromJSONTyped(json, false);
}

export function UpdateAutoReportSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAutoReportSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'reportSettings': json['reportSettings'] == null ? undefined : ((json['reportSettings'] as Array<any>).map(PatchAutoReportFromJSON)),
    };
}

export function UpdateAutoReportSettingsToJSON(json: any): UpdateAutoReportSettings {
    return UpdateAutoReportSettingsToJSONTyped(json, false);
}

export function UpdateAutoReportSettingsToJSONTyped(value?: UpdateAutoReportSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'reportSettings': value['reportSettings'] == null ? undefined : ((value['reportSettings'] as Array<any>).map(PatchAutoReportToJSON)),
    };
}

