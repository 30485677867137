/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductSimulationSettingsResponse
 */
export interface ProductSimulationSettingsResponse {
  /**
   *
   * @type {number}
   * @memberof ProductSimulationSettingsResponse
   */
  productSimulationCoefficient?: number;
}

/**
 * Check if a given object implements the ProductSimulationSettingsResponse interface.
 */
export function instanceOfProductSimulationSettingsResponse(value: object): value is ProductSimulationSettingsResponse {
  return true;
}

export function ProductSimulationSettingsResponseFromJSON(json: any): ProductSimulationSettingsResponse {
  return ProductSimulationSettingsResponseFromJSONTyped(json, false);
}

export function ProductSimulationSettingsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductSimulationSettingsResponse {
  if (json == null) {
    return json;
  }
  return {
    productSimulationCoefficient:
      json['productSimulationCoefficient'] == null ? undefined : json['productSimulationCoefficient']
  };
}

export function ProductSimulationSettingsResponseToJSON(json: any): ProductSimulationSettingsResponse {
  return ProductSimulationSettingsResponseToJSONTyped(json, false);
}

export function ProductSimulationSettingsResponseToJSONTyped(
  value?: ProductSimulationSettingsResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    productSimulationCoefficient: value['productSimulationCoefficient']
  };
}
