/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface RegisteredBinderAppliance
 */
export interface RegisteredBinderAppliance {
  /**
   *
   * @type {string}
   * @memberof RegisteredBinderAppliance
   */
  macAddress?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredBinderAppliance
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredBinderAppliance
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredBinderAppliance
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof RegisteredBinderAppliance
   */
  virtualFridgeId?: string;
}

/**
 * Check if a given object implements the RegisteredBinderAppliance interface.
 */
export function instanceOfRegisteredBinderAppliance(value: object): value is RegisteredBinderAppliance {
  return true;
}

export function RegisteredBinderApplianceFromJSON(json: any): RegisteredBinderAppliance {
  return RegisteredBinderApplianceFromJSONTyped(json, false);
}

export function RegisteredBinderApplianceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegisteredBinderAppliance {
  if (json == null) {
    return json;
  }
  return {
    macAddress: json['macAddress'] == null ? undefined : json['macAddress'],
    model: json['model'] == null ? undefined : json['model'],
    name: json['name'] == null ? undefined : json['name'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function RegisteredBinderApplianceToJSON(json: any): RegisteredBinderAppliance {
  return RegisteredBinderApplianceToJSONTyped(json, false);
}

export function RegisteredBinderApplianceToJSONTyped(
  value?: RegisteredBinderAppliance | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    macAddress: value['macAddress'],
    model: value['model'],
    name: value['name'],
    serialNumber: value['serialNumber'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
