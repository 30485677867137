/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AlarmCommentsRequest } from './AlarmCommentsRequest';
import {
    AlarmCommentsRequestFromJSON,
    AlarmCommentsRequestFromJSONTyped,
    AlarmCommentsRequestToJSON,
    AlarmCommentsRequestToJSONTyped,
} from './AlarmCommentsRequest';

/**
 * 
 * @export
 * @interface AlarmsCommentsRequest
 */
export interface AlarmsCommentsRequest {
    /**
     * 
     * @type {Array<AlarmCommentsRequest>}
     * @memberof AlarmsCommentsRequest
     */
    alarmCommentsRequestList: Array<AlarmCommentsRequest>;
}

/**
 * Check if a given object implements the AlarmsCommentsRequest interface.
 */
export function instanceOfAlarmsCommentsRequest(value: object): value is AlarmsCommentsRequest {
    if (!('alarmCommentsRequestList' in value) || value['alarmCommentsRequestList'] === undefined) return false;
    return true;
}

export function AlarmsCommentsRequestFromJSON(json: any): AlarmsCommentsRequest {
    return AlarmsCommentsRequestFromJSONTyped(json, false);
}

export function AlarmsCommentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlarmsCommentsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'alarmCommentsRequestList': ((json['alarmCommentsRequestList'] as Array<any>).map(AlarmCommentsRequestFromJSON)),
    };
}

export function AlarmsCommentsRequestToJSON(json: any): AlarmsCommentsRequest {
    return AlarmsCommentsRequestToJSONTyped(json, false);
}

export function AlarmsCommentsRequestToJSONTyped(value?: AlarmsCommentsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alarmCommentsRequestList': ((value['alarmCommentsRequestList'] as Array<any>).map(AlarmCommentsRequestToJSON)),
    };
}

