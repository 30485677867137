/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PermissionResponse
 */
export interface PermissionResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof PermissionResponse
   */
  permittedDevices?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PermissionResponse
   */
  upn?: string;
}

/**
 * Check if a given object implements the PermissionResponse interface.
 */
export function instanceOfPermissionResponse(value: object): value is PermissionResponse {
  return true;
}

export function PermissionResponseFromJSON(json: any): PermissionResponse {
  return PermissionResponseFromJSONTyped(json, false);
}

export function PermissionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionResponse {
  if (json == null) {
    return json;
  }
  return {
    permittedDevices: json['permittedDevices'] == null ? undefined : json['permittedDevices'],
    upn: json['upn'] == null ? undefined : json['upn']
  };
}

export function PermissionResponseToJSON(json: any): PermissionResponse {
  return PermissionResponseToJSONTyped(json, false);
}

export function PermissionResponseToJSONTyped(
  value?: PermissionResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    permittedDevices: value['permittedDevices'],
    upn: value['upn']
  };
}
