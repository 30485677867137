/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PatchAutoReport
 */
export interface PatchAutoReport {
  /**
   *
   * @type {string}
   * @memberof PatchAutoReport
   */
  op?: string;
  /**
   *
   * @type {string}
   * @memberof PatchAutoReport
   */
  path?: string;
  /**
   *
   * @type {string}
   * @memberof PatchAutoReport
   */
  value?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PatchAutoReport
   */
  vfids?: Array<string>;
}

/**
 * Check if a given object implements the PatchAutoReport interface.
 */
export function instanceOfPatchAutoReport(value: object): value is PatchAutoReport {
  return true;
}

export function PatchAutoReportFromJSON(json: any): PatchAutoReport {
  return PatchAutoReportFromJSONTyped(json, false);
}

export function PatchAutoReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchAutoReport {
  if (json == null) {
    return json;
  }
  return {
    op: json['op'] == null ? undefined : json['op'],
    path: json['path'] == null ? undefined : json['path'],
    value: json['value'] == null ? undefined : json['value'],
    vfids: json['vfids'] == null ? undefined : json['vfids']
  };
}

export function PatchAutoReportToJSON(json: any): PatchAutoReport {
  return PatchAutoReportToJSONTyped(json, false);
}

export function PatchAutoReportToJSONTyped(value?: PatchAutoReport | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    op: value['op'],
    path: value['path'],
    value: value['value'],
    vfids: value['vfids']
  };
}
