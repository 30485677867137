/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdditionalTemperatureAlarmSettings } from './AdditionalTemperatureAlarmSettings';
import {
    AdditionalTemperatureAlarmSettingsFromJSON,
    AdditionalTemperatureAlarmSettingsFromJSONTyped,
    AdditionalTemperatureAlarmSettingsToJSON,
    AdditionalTemperatureAlarmSettingsToJSONTyped,
} from './AdditionalTemperatureAlarmSettings';
import type { DeviceTemperatureAlarmSettingsResponse } from './DeviceTemperatureAlarmSettingsResponse';
import {
    DeviceTemperatureAlarmSettingsResponseFromJSON,
    DeviceTemperatureAlarmSettingsResponseFromJSONTyped,
    DeviceTemperatureAlarmSettingsResponseToJSON,
    DeviceTemperatureAlarmSettingsResponseToJSONTyped,
} from './DeviceTemperatureAlarmSettingsResponse';

/**
 * 
 * @export
 * @interface ZoneAlarmSettingsResponse
 */
export interface ZoneAlarmSettingsResponse {
    /**
     * 
     * @type {AdditionalTemperatureAlarmSettings}
     * @memberof ZoneAlarmSettingsResponse
     */
    additionalTemperatureAlarmSettingsResponse?: AdditionalTemperatureAlarmSettings;
    /**
     * 
     * @type {DeviceTemperatureAlarmSettingsResponse}
     * @memberof ZoneAlarmSettingsResponse
     */
    deviceTemperatureAlarmSettingsResponse?: DeviceTemperatureAlarmSettingsResponse;
    /**
     * 
     * @type {string}
     * @memberof ZoneAlarmSettingsResponse
     */
    thingId?: string;
}

/**
 * Check if a given object implements the ZoneAlarmSettingsResponse interface.
 */
export function instanceOfZoneAlarmSettingsResponse(value: object): value is ZoneAlarmSettingsResponse {
    return true;
}

export function ZoneAlarmSettingsResponseFromJSON(json: any): ZoneAlarmSettingsResponse {
    return ZoneAlarmSettingsResponseFromJSONTyped(json, false);
}

export function ZoneAlarmSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneAlarmSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'additionalTemperatureAlarmSettingsResponse': json['additionalTemperatureAlarmSettingsResponse'] == null ? undefined : AdditionalTemperatureAlarmSettingsFromJSON(json['additionalTemperatureAlarmSettingsResponse']),
        'deviceTemperatureAlarmSettingsResponse': json['deviceTemperatureAlarmSettingsResponse'] == null ? undefined : DeviceTemperatureAlarmSettingsResponseFromJSON(json['deviceTemperatureAlarmSettingsResponse']),
        'thingId': json['thingId'] == null ? undefined : json['thingId'],
    };
}

export function ZoneAlarmSettingsResponseToJSON(json: any): ZoneAlarmSettingsResponse {
    return ZoneAlarmSettingsResponseToJSONTyped(json, false);
}

export function ZoneAlarmSettingsResponseToJSONTyped(value?: ZoneAlarmSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'additionalTemperatureAlarmSettingsResponse': AdditionalTemperatureAlarmSettingsToJSON(value['additionalTemperatureAlarmSettingsResponse']),
        'deviceTemperatureAlarmSettingsResponse': DeviceTemperatureAlarmSettingsResponseToJSON(value['deviceTemperatureAlarmSettingsResponse']),
        'thingId': value['thingId'],
    };
}

