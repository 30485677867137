/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SmartModuleConnectionResponse
 */
export interface SmartModuleConnectionResponse {
  /**
   *
   * @type {boolean}
   * @memberof SmartModuleConnectionResponse
   */
  connected?: boolean;
  /**
   *
   * @type {Date}
   * @memberof SmartModuleConnectionResponse
   */
  connectionStateSince?: Date;
  /**
   *
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  macAddress?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  networkType?: SmartModuleConnectionResponseNetworkTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  smartModuleFridgeSerialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  smartModuleId?: string;
  /**
   *
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  smartModuleSoftwareVersion?: string;
}

/**
 * @export
 */
export const SmartModuleConnectionResponseNetworkTypeEnum = {
  LAN: 'LAN',
  WLAN: 'WLAN',
  WIFI: 'WIFI',
  UNKNOWN: 'UNKNOWN'
} as const;
export type SmartModuleConnectionResponseNetworkTypeEnum = typeof SmartModuleConnectionResponseNetworkTypeEnum[keyof typeof SmartModuleConnectionResponseNetworkTypeEnum];

/**
 * Check if a given object implements the SmartModuleConnectionResponse interface.
 */
export function instanceOfSmartModuleConnectionResponse(value: object): value is SmartModuleConnectionResponse {
  return true;
}

export function SmartModuleConnectionResponseFromJSON(json: any): SmartModuleConnectionResponse {
  return SmartModuleConnectionResponseFromJSONTyped(json, false);
}

export function SmartModuleConnectionResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SmartModuleConnectionResponse {
  if (json == null) {
    return json;
  }
  return {
    connected: json['connected'] == null ? undefined : json['connected'],
    connectionStateSince: json['connectionStateSince'] == null ? undefined : new Date(json['connectionStateSince']),
    ipAddress: json['ipAddress'] == null ? undefined : json['ipAddress'],
    macAddress: json['macAddress'] == null ? undefined : json['macAddress'],
    networkType: json['networkType'] == null ? undefined : json['networkType'],
    smartModuleFridgeSerialNumber:
      json['smartModuleFridgeSerialNumber'] == null ? undefined : json['smartModuleFridgeSerialNumber'],
    smartModuleId: json['smartModuleId'] == null ? undefined : json['smartModuleId'],
    smartModuleSoftwareVersion:
      json['smartModuleSoftwareVersion'] == null ? undefined : json['smartModuleSoftwareVersion']
  };
}

export function SmartModuleConnectionResponseToJSON(json: any): SmartModuleConnectionResponse {
  return SmartModuleConnectionResponseToJSONTyped(json, false);
}

export function SmartModuleConnectionResponseToJSONTyped(
  value?: SmartModuleConnectionResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    connected: value['connected'],
    connectionStateSince:
      value['connectionStateSince'] == null ? undefined : value['connectionStateSince'].toISOString(),
    ipAddress: value['ipAddress'],
    macAddress: value['macAddress'],
    networkType: value['networkType'],
    smartModuleFridgeSerialNumber: value['smartModuleFridgeSerialNumber'],
    smartModuleId: value['smartModuleId'],
    smartModuleSoftwareVersion: value['smartModuleSoftwareVersion']
  };
}
