/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface ApplianceAlarmProfileUpdateRequest
 */
export interface ApplianceAlarmProfileUpdateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ApplianceAlarmProfileUpdateRequest
     */
    alarmActivatedByUser?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileUpdateRequest
     */
    alarmDelay?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplianceAlarmProfileUpdateRequest
     */
    alarmProfileId?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileUpdateRequest
     */
    repetitionTime?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileUpdateRequest
     */
    temperatureHigherLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileUpdateRequest
     */
    temperatureLowerLimit?: number;
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof ApplianceAlarmProfileUpdateRequest
     */
    userProfiles?: Array<UserProfile>;
    /**
     * 
     * @type {string}
     * @memberof ApplianceAlarmProfileUpdateRequest
     */
    virtualFridgeId?: string;
}

/**
 * Check if a given object implements the ApplianceAlarmProfileUpdateRequest interface.
 */
export function instanceOfApplianceAlarmProfileUpdateRequest(value: object): value is ApplianceAlarmProfileUpdateRequest {
    return true;
}

export function ApplianceAlarmProfileUpdateRequestFromJSON(json: any): ApplianceAlarmProfileUpdateRequest {
    return ApplianceAlarmProfileUpdateRequestFromJSONTyped(json, false);
}

export function ApplianceAlarmProfileUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceAlarmProfileUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'alarmActivatedByUser': json['alarmActivatedByUser'] == null ? undefined : json['alarmActivatedByUser'],
        'alarmDelay': json['alarmDelay'] == null ? undefined : json['alarmDelay'],
        'alarmProfileId': json['alarmProfileId'] == null ? undefined : json['alarmProfileId'],
        'repetitionTime': json['repetitionTime'] == null ? undefined : json['repetitionTime'],
        'temperatureHigherLimit': json['temperatureHigherLimit'] == null ? undefined : json['temperatureHigherLimit'],
        'temperatureLowerLimit': json['temperatureLowerLimit'] == null ? undefined : json['temperatureLowerLimit'],
        'userProfiles': json['userProfiles'] == null ? undefined : ((json['userProfiles'] as Array<any>).map(UserProfileFromJSON)),
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
    };
}

export function ApplianceAlarmProfileUpdateRequestToJSON(json: any): ApplianceAlarmProfileUpdateRequest {
    return ApplianceAlarmProfileUpdateRequestToJSONTyped(json, false);
}

export function ApplianceAlarmProfileUpdateRequestToJSONTyped(value?: ApplianceAlarmProfileUpdateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alarmActivatedByUser': value['alarmActivatedByUser'],
        'alarmDelay': value['alarmDelay'],
        'alarmProfileId': value['alarmProfileId'],
        'repetitionTime': value['repetitionTime'],
        'temperatureHigherLimit': value['temperatureHigherLimit'],
        'temperatureLowerLimit': value['temperatureLowerLimit'],
        'userProfiles': value['userProfiles'] == null ? undefined : ((value['userProfiles'] as Array<any>).map(UserProfileToJSON)),
        'virtualFridgeId': value['virtualFridgeId'],
    };
}

