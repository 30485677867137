/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AlarmCommentAddRequest,
  AlarmCommentsRequest,
  AlarmCommentsResponse,
  AlarmReadStateRequest,
  AlarmsCommentsRequest,
  ApplianceAlarmProfileDetailsResponse,
  ApplianceAlarmProfileListResponse,
  ApplianceAlarmProfileUpdateRequest,
  ContinuationAlarmToken,
  ErrorDto,
  FilteredMergedAlarmsResponse,
  GatewayAlarmProfile,
  GatewayAlarmProfileUpdateRequest,
  MergedAlarmStatsResponse,
  MergedAlarmsResponse,
  NotificationSettingsRequest,
  NotificationSettingsResponse,
  SmartModuleAlarmSettingsRequest,
  SmartModuleAlarmSettingsResponse,
  SmartModuleSystemSettingsRequest,
  SmartModuleZoneSettingsRequest,
} from '../models/index';
import {
    AlarmCommentAddRequestFromJSON,
    AlarmCommentAddRequestToJSON,
    AlarmCommentsRequestFromJSON,
    AlarmCommentsRequestToJSON,
    AlarmCommentsResponseFromJSON,
    AlarmCommentsResponseToJSON,
    AlarmReadStateRequestFromJSON,
    AlarmReadStateRequestToJSON,
    AlarmsCommentsRequestFromJSON,
    AlarmsCommentsRequestToJSON,
    ApplianceAlarmProfileDetailsResponseFromJSON,
    ApplianceAlarmProfileDetailsResponseToJSON,
    ApplianceAlarmProfileListResponseFromJSON,
    ApplianceAlarmProfileListResponseToJSON,
    ApplianceAlarmProfileUpdateRequestFromJSON,
    ApplianceAlarmProfileUpdateRequestToJSON,
    ContinuationAlarmTokenFromJSON,
    ContinuationAlarmTokenToJSON,
    ErrorDtoFromJSON,
    ErrorDtoToJSON,
    FilteredMergedAlarmsResponseFromJSON,
    FilteredMergedAlarmsResponseToJSON,
    GatewayAlarmProfileFromJSON,
    GatewayAlarmProfileToJSON,
    GatewayAlarmProfileUpdateRequestFromJSON,
    GatewayAlarmProfileUpdateRequestToJSON,
    MergedAlarmStatsResponseFromJSON,
    MergedAlarmStatsResponseToJSON,
    MergedAlarmsResponseFromJSON,
    MergedAlarmsResponseToJSON,
    NotificationSettingsRequestFromJSON,
    NotificationSettingsRequestToJSON,
    NotificationSettingsResponseFromJSON,
    NotificationSettingsResponseToJSON,
    SmartModuleAlarmSettingsRequestFromJSON,
    SmartModuleAlarmSettingsRequestToJSON,
    SmartModuleAlarmSettingsResponseFromJSON,
    SmartModuleAlarmSettingsResponseToJSON,
    SmartModuleSystemSettingsRequestFromJSON,
    SmartModuleSystemSettingsRequestToJSON,
    SmartModuleZoneSettingsRequestFromJSON,
    SmartModuleZoneSettingsRequestToJSON,
} from '../models/index';

export interface AddAlarmCommentUsingPOSTRequest {
    alarmCommentAddRequest: AlarmCommentAddRequest;
}

export interface CreateApplianceAlarmProfileDetailsUsingPOSTRequest {
    gatewayId: string;
    virtualFridgeId: string;
    applianceAlarmProfileUpdateRequest: ApplianceAlarmProfileUpdateRequest;
}

export interface CreateSmartModuleAlarmProfileUsingPUTRequest {
    virtualFridgeId: string;
    smartModuleAlarmSettingsRequest: SmartModuleAlarmSettingsRequest;
}

export interface GetAlarmCommentsUsingPOSTRequest {
    alarmCommentsRequest: AlarmCommentsRequest;
}

export interface GetAllAlarmCommentsUsingPOSTRequest {
    alarmsCommentsRequest: AlarmsCommentsRequest;
}

export interface GetApplianceAlarmProfileDetailsUsingGETRequest {
    gatewayId: string;
    virtualFridgeId: string;
}

export interface GetFilteredMergedAlarmListUsingPUTRequest {
    filters: Array<string>;
    continuationAlarmToken: ContinuationAlarmToken;
    from?: Date;
    gatewayIds?: Array<string>;
    groupIds?: Array<string>;
    loggerVfIds?: Array<string>;
    selected?: boolean;
    smVfIds?: Array<string>;
    to?: Date;
    vfIds?: Array<string>;
}

export interface GetGatewayProfileDetailsUsingGETRequest {
    gatewayId: string;
}

export interface GetMergedAlarmListUsingGETRequest {
    from: number;
    to: number;
}

export interface GetSmartModuleAlarmsSettingsUsingGETRequest {
    virtualFridgeId: string;
}

export interface GetSmartModuleNotificationSettingsUsingGETRequest {
    virtualFridgeId: string;
}

export interface SetAlarmReadStateUsingPUTRequest {
    alarmReadStateRequest: AlarmReadStateRequest;
}

export interface UpdateApplianceAlarmProfileUsingPUTRequest {
    applianceAlarmProfileUpdateRequest: ApplianceAlarmProfileUpdateRequest;
}

export interface UpdateGatewayProfileUsingPUTRequest {
    gatewayAlarmProfileUpdateRequest: GatewayAlarmProfileUpdateRequest;
}

export interface UpdateSmartModuleCloudAlarmsSettingsUsingPUTRequest {
    virtualFridgeId: string;
    smartModuleZoneSettingsRequest: Array<SmartModuleZoneSettingsRequest>;
}

export interface UpdateSmartModuleNotificationSettingsUsingPUTRequest {
    notificationSettingsRequest: NotificationSettingsRequest;
}

export interface UpdateSmartModuleSystemSettingsUsingPUTRequest {
    virtualFridgeId: string;
    smartModuleSystemSettingsRequest: SmartModuleSystemSettingsRequest;
}

/**
 * AlarmControllerApi - interface
 * 
 * @export
 * @interface AlarmControllerApiInterface
 */
export interface AlarmControllerApiInterface {
    /**
     * addAlarmComment
     * @summary Adds a new comment to an alarm.
     * @param {AlarmCommentAddRequest} alarmCommentAddRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    addAlarmCommentUsingPOSTRaw(requestParameters: AddAlarmCommentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * addAlarmComment
     * Adds a new comment to an alarm.
     */
    addAlarmCommentUsingPOST(requestParameters: AddAlarmCommentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * createApplianceAlarmProfileDetails
     * @param {string} gatewayId 
     * @param {string} virtualFridgeId 
     * @param {ApplianceAlarmProfileUpdateRequest} applianceAlarmProfileUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    createApplianceAlarmProfileDetailsUsingPOSTRaw(requestParameters: CreateApplianceAlarmProfileDetailsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceAlarmProfileDetailsResponse>>;

    /**
     * createApplianceAlarmProfileDetails
     */
    createApplianceAlarmProfileDetailsUsingPOST(requestParameters: CreateApplianceAlarmProfileDetailsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceAlarmProfileDetailsResponse>;

    /**
     * createSmartModuleAlarmProfile
     * @summary Create SmartModule alarm profile.
     * @param {string} virtualFridgeId 
     * @param {SmartModuleAlarmSettingsRequest} smartModuleAlarmSettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    createSmartModuleAlarmProfileUsingPUTRaw(requestParameters: CreateSmartModuleAlarmProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleAlarmSettingsResponse>>;

    /**
     * createSmartModuleAlarmProfile
     * Create SmartModule alarm profile.
     */
    createSmartModuleAlarmProfileUsingPUT(requestParameters: CreateSmartModuleAlarmProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleAlarmSettingsResponse>;

    /**
     * getAlarmComments
     * @summary Gets the existing comments for single alarm.
     * @param {AlarmCommentsRequest} alarmCommentsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    getAlarmCommentsUsingPOSTRaw(requestParameters: GetAlarmCommentsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AlarmCommentsResponse>>;

    /**
     * getAlarmComments
     * Gets the existing comments for single alarm.
     */
    getAlarmCommentsUsingPOST(requestParameters: GetAlarmCommentsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AlarmCommentsResponse>;

    /**
     * getAlarmProfilesForList
     * @summary Gets the alarm profiles for list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    getAlarmProfilesForListUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceAlarmProfileListResponse>>;

    /**
     * getAlarmProfilesForList
     * Gets the alarm profiles for list.
     */
    getAlarmProfilesForListUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceAlarmProfileListResponse>;

    /**
     * getAllAlarmComments
     * @summary Gets the existing comments on a list of alarms.
     * @param {AlarmsCommentsRequest} alarmsCommentsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    getAllAlarmCommentsUsingPOSTRaw(requestParameters: GetAllAlarmCommentsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AlarmCommentsResponse>>;

    /**
     * getAllAlarmComments
     * Gets the existing comments on a list of alarms.
     */
    getAllAlarmCommentsUsingPOST(requestParameters: GetAllAlarmCommentsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AlarmCommentsResponse>;

    /**
     * getApplianceAlarmProfileDetails
     * @summary Gets the alarm profile details.
     * @param {string} gatewayId 
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    getApplianceAlarmProfileDetailsUsingGETRaw(requestParameters: GetApplianceAlarmProfileDetailsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceAlarmProfileDetailsResponse>>;

    /**
     * getApplianceAlarmProfileDetails
     * Gets the alarm profile details.
     */
    getApplianceAlarmProfileDetailsUsingGET(requestParameters: GetApplianceAlarmProfileDetailsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceAlarmProfileDetailsResponse>;

    /**
     * getFilteredMergedAlarmList
     * @summary Gets list with all alarms combined or just for single types. They can be filtered.
     * @param {Array<string>} filters 
     * @param {ContinuationAlarmToken} continuationAlarmToken 
     * @param {Date} [from] 
     * @param {Array<string>} [gatewayIds] 
     * @param {Array<string>} [groupIds] 
     * @param {Array<string>} [loggerVfIds] 
     * @param {boolean} [selected] 
     * @param {Array<string>} [smVfIds] 
     * @param {Date} [to] 
     * @param {Array<string>} [vfIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    getFilteredMergedAlarmListUsingPUTRaw(requestParameters: GetFilteredMergedAlarmListUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilteredMergedAlarmsResponse>>;

    /**
     * getFilteredMergedAlarmList
     * Gets list with all alarms combined or just for single types. They can be filtered.
     */
    getFilteredMergedAlarmListUsingPUT(requestParameters: GetFilteredMergedAlarmListUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilteredMergedAlarmsResponse>;

    /**
     * getGatewayProfileDetails
     * @summary Gets the gateway profile details.
     * @param {string} gatewayId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    getGatewayProfileDetailsUsingGETRaw(requestParameters: GetGatewayProfileDetailsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayAlarmProfile>>;

    /**
     * getGatewayProfileDetails
     * Gets the gateway profile details.
     */
    getGatewayProfileDetailsUsingGET(requestParameters: GetGatewayProfileDetailsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayAlarmProfile>;

    /**
     * getMergedAlarmList
     * @summary Gets a device alarm list by virtualFridgeIds list, the request can specify the number of alarms fetched.
     * @param {number} from 
     * @param {number} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    getMergedAlarmListUsingGETRaw(requestParameters: GetMergedAlarmListUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MergedAlarmsResponse>>;

    /**
     * getMergedAlarmList
     * Gets a device alarm list by virtualFridgeIds list, the request can specify the number of alarms fetched.
     */
    getMergedAlarmListUsingGET(requestParameters: GetMergedAlarmListUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MergedAlarmsResponse>;

    /**
     * getMergedAlarmStats
     * @summary Gets the current device and gateway alarm statistics object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    getMergedAlarmStatsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MergedAlarmStatsResponse>>;

    /**
     * getMergedAlarmStats
     * Gets the current device and gateway alarm statistics object.
     */
    getMergedAlarmStatsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MergedAlarmStatsResponse>;

    /**
     * getSmartModuleAlarmsSettings
     * @summary Gets SmartModule alarm profile.
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    getSmartModuleAlarmsSettingsUsingGETRaw(requestParameters: GetSmartModuleAlarmsSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleAlarmSettingsResponse>>;

    /**
     * getSmartModuleAlarmsSettings
     * Gets SmartModule alarm profile.
     */
    getSmartModuleAlarmsSettingsUsingGET(requestParameters: GetSmartModuleAlarmsSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleAlarmSettingsResponse>;

    /**
     * getSmartModuleNotificationSettings
     * @summary Gets SmartModule notification settings.
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    getSmartModuleNotificationSettingsUsingGETRaw(requestParameters: GetSmartModuleNotificationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationSettingsResponse>>;

    /**
     * getSmartModuleNotificationSettings
     * Gets SmartModule notification settings.
     */
    getSmartModuleNotificationSettingsUsingGET(requestParameters: GetSmartModuleNotificationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationSettingsResponse>;

    /**
     * setAlarmReadState
     * @summary Sets the passed-in alarm state \'read/unread\'.
     * @param {AlarmReadStateRequest} alarmReadStateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    setAlarmReadStateUsingPUTRaw(requestParameters: SetAlarmReadStateUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * setAlarmReadState
     * Sets the passed-in alarm state \'read/unread\'.
     */
    setAlarmReadStateUsingPUT(requestParameters: SetAlarmReadStateUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * updateApplianceAlarmProfile
     * @summary Updates an alarm profile.
     * @param {ApplianceAlarmProfileUpdateRequest} applianceAlarmProfileUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    updateApplianceAlarmProfileUsingPUTRaw(requestParameters: UpdateApplianceAlarmProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * updateApplianceAlarmProfile
     * Updates an alarm profile.
     */
    updateApplianceAlarmProfileUsingPUT(requestParameters: UpdateApplianceAlarmProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * updateGatewayProfile
     * @summary Updates an gateway profile.
     * @param {GatewayAlarmProfileUpdateRequest} gatewayAlarmProfileUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    updateGatewayProfileUsingPUTRaw(requestParameters: UpdateGatewayProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * updateGatewayProfile
     * Updates an gateway profile.
     */
    updateGatewayProfileUsingPUT(requestParameters: UpdateGatewayProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * updateSmartModuleCloudAlarmsSettings
     * @summary Update SmartModule zone additional cloud alarms.
     * @param {string} virtualFridgeId 
     * @param {Array<SmartModuleZoneSettingsRequest>} smartModuleZoneSettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    updateSmartModuleCloudAlarmsSettingsUsingPUTRaw(requestParameters: UpdateSmartModuleCloudAlarmsSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>>;

    /**
     * updateSmartModuleCloudAlarmsSettings
     * Update SmartModule zone additional cloud alarms.
     */
    updateSmartModuleCloudAlarmsSettingsUsingPUT(requestParameters: UpdateSmartModuleCloudAlarmsSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean>;

    /**
     * updateSmartModuleNotificationSettings
     * @summary Create/Update SmartModule notification settings.
     * @param {NotificationSettingsRequest} notificationSettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    updateSmartModuleNotificationSettingsUsingPUTRaw(requestParameters: UpdateSmartModuleNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>>;

    /**
     * updateSmartModuleNotificationSettings
     * Create/Update SmartModule notification settings.
     */
    updateSmartModuleNotificationSettingsUsingPUT(requestParameters: UpdateSmartModuleNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean>;

    /**
     * updateSmartModuleSystemSettings
     * @summary Update SmartModule alarm profile - system unavailability.
     * @param {string} virtualFridgeId 
     * @param {SmartModuleSystemSettingsRequest} smartModuleSystemSettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlarmControllerApiInterface
     */
    updateSmartModuleSystemSettingsUsingPUTRaw(requestParameters: UpdateSmartModuleSystemSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>>;

    /**
     * updateSmartModuleSystemSettings
     * Update SmartModule alarm profile - system unavailability.
     */
    updateSmartModuleSystemSettingsUsingPUT(requestParameters: UpdateSmartModuleSystemSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean>;

}

/**
 * 
 */
export class AlarmControllerApi extends runtime.BaseAPI implements AlarmControllerApiInterface {

    /**
     * addAlarmComment
     * Adds a new comment to an alarm.
     */
    async addAlarmCommentUsingPOSTRaw(requestParameters: AddAlarmCommentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['alarmCommentAddRequest'] == null) {
            throw new runtime.RequiredError(
                'alarmCommentAddRequest',
                'Required parameter "alarmCommentAddRequest" was null or undefined when calling addAlarmCommentUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/new/alarmComment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlarmCommentAddRequestToJSON(requestParameters['alarmCommentAddRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * addAlarmComment
     * Adds a new comment to an alarm.
     */
    async addAlarmCommentUsingPOST(requestParameters: AddAlarmCommentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addAlarmCommentUsingPOSTRaw(requestParameters, initOverrides);
    }

    /**
     * createApplianceAlarmProfileDetails
     */
    async createApplianceAlarmProfileDetailsUsingPOSTRaw(requestParameters: CreateApplianceAlarmProfileDetailsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceAlarmProfileDetailsResponse>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling createApplianceAlarmProfileDetailsUsingPOST().'
            );
        }

        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling createApplianceAlarmProfileDetailsUsingPOST().'
            );
        }

        if (requestParameters['applianceAlarmProfileUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'applianceAlarmProfileUpdateRequest',
                'Required parameter "applianceAlarmProfileUpdateRequest" was null or undefined when calling createApplianceAlarmProfileDetailsUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/alarmprofile/{virtualFridgeId}/{gatewayId}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))).replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplianceAlarmProfileUpdateRequestToJSON(requestParameters['applianceAlarmProfileUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplianceAlarmProfileDetailsResponseFromJSON(jsonValue));
    }

    /**
     * createApplianceAlarmProfileDetails
     */
    async createApplianceAlarmProfileDetailsUsingPOST(requestParameters: CreateApplianceAlarmProfileDetailsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceAlarmProfileDetailsResponse> {
        const response = await this.createApplianceAlarmProfileDetailsUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * createSmartModuleAlarmProfile
     * Create SmartModule alarm profile.
     */
    async createSmartModuleAlarmProfileUsingPUTRaw(requestParameters: CreateSmartModuleAlarmProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleAlarmSettingsResponse>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling createSmartModuleAlarmProfileUsingPUT().'
            );
        }

        if (requestParameters['smartModuleAlarmSettingsRequest'] == null) {
            throw new runtime.RequiredError(
                'smartModuleAlarmSettingsRequest',
                'Required parameter "smartModuleAlarmSettingsRequest" was null or undefined when calling createSmartModuleAlarmProfileUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/smartmodule/alarmssettings/create/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SmartModuleAlarmSettingsRequestToJSON(requestParameters['smartModuleAlarmSettingsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmartModuleAlarmSettingsResponseFromJSON(jsonValue));
    }

    /**
     * createSmartModuleAlarmProfile
     * Create SmartModule alarm profile.
     */
    async createSmartModuleAlarmProfileUsingPUT(requestParameters: CreateSmartModuleAlarmProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleAlarmSettingsResponse> {
        const response = await this.createSmartModuleAlarmProfileUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAlarmComments
     * Gets the existing comments for single alarm.
     */
    async getAlarmCommentsUsingPOSTRaw(requestParameters: GetAlarmCommentsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AlarmCommentsResponse>> {
        if (requestParameters['alarmCommentsRequest'] == null) {
            throw new runtime.RequiredError(
                'alarmCommentsRequest',
                'Required parameter "alarmCommentsRequest" was null or undefined when calling getAlarmCommentsUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/alarm/comment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlarmCommentsRequestToJSON(requestParameters['alarmCommentsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlarmCommentsResponseFromJSON(jsonValue));
    }

    /**
     * getAlarmComments
     * Gets the existing comments for single alarm.
     */
    async getAlarmCommentsUsingPOST(requestParameters: GetAlarmCommentsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AlarmCommentsResponse> {
        const response = await this.getAlarmCommentsUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAlarmProfilesForList
     * Gets the alarm profiles for list.
     */
    async getAlarmProfilesForListUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceAlarmProfileListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/alarmprofilesforlist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplianceAlarmProfileListResponseFromJSON(jsonValue));
    }

    /**
     * getAlarmProfilesForList
     * Gets the alarm profiles for list.
     */
    async getAlarmProfilesForListUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceAlarmProfileListResponse> {
        const response = await this.getAlarmProfilesForListUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllAlarmComments
     * Gets the existing comments on a list of alarms.
     */
    async getAllAlarmCommentsUsingPOSTRaw(requestParameters: GetAllAlarmCommentsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AlarmCommentsResponse>> {
        if (requestParameters['alarmsCommentsRequest'] == null) {
            throw new runtime.RequiredError(
                'alarmsCommentsRequest',
                'Required parameter "alarmsCommentsRequest" was null or undefined when calling getAllAlarmCommentsUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/alarms/comments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlarmsCommentsRequestToJSON(requestParameters['alarmsCommentsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlarmCommentsResponseFromJSON(jsonValue));
    }

    /**
     * getAllAlarmComments
     * Gets the existing comments on a list of alarms.
     */
    async getAllAlarmCommentsUsingPOST(requestParameters: GetAllAlarmCommentsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AlarmCommentsResponse> {
        const response = await this.getAllAlarmCommentsUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getApplianceAlarmProfileDetails
     * Gets the alarm profile details.
     */
    async getApplianceAlarmProfileDetailsUsingGETRaw(requestParameters: GetApplianceAlarmProfileDetailsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceAlarmProfileDetailsResponse>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling getApplianceAlarmProfileDetailsUsingGET().'
            );
        }

        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getApplianceAlarmProfileDetailsUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/alarmprofile/{virtualFridgeId}/{gatewayId}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))).replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplianceAlarmProfileDetailsResponseFromJSON(jsonValue));
    }

    /**
     * getApplianceAlarmProfileDetails
     * Gets the alarm profile details.
     */
    async getApplianceAlarmProfileDetailsUsingGET(requestParameters: GetApplianceAlarmProfileDetailsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceAlarmProfileDetailsResponse> {
        const response = await this.getApplianceAlarmProfileDetailsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getFilteredMergedAlarmList
     * Gets list with all alarms combined or just for single types. They can be filtered.
     */
    async getFilteredMergedAlarmListUsingPUTRaw(requestParameters: GetFilteredMergedAlarmListUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilteredMergedAlarmsResponse>> {
        if (requestParameters['filters'] == null) {
            throw new runtime.RequiredError(
                'filters',
                'Required parameter "filters" was null or undefined when calling getFilteredMergedAlarmListUsingPUT().'
            );
        }

        if (requestParameters['continuationAlarmToken'] == null) {
            throw new runtime.RequiredError(
                'continuationAlarmToken',
                'Required parameter "continuationAlarmToken" was null or undefined when calling getFilteredMergedAlarmListUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filters'] != null) {
            queryParameters['filters'] = requestParameters['filters'];
        }

        if (requestParameters['from'] != null) {
            queryParameters['from'] = (requestParameters['from'] as any).toISOString();
        }

        if (requestParameters['gatewayIds'] != null) {
            queryParameters['gatewayIds'] = requestParameters['gatewayIds'];
        }

        if (requestParameters['groupIds'] != null) {
            queryParameters['groupIds'] = requestParameters['groupIds'];
        }

        if (requestParameters['loggerVfIds'] != null) {
            queryParameters['loggerVfIds'] = requestParameters['loggerVfIds'];
        }

        if (requestParameters['selected'] != null) {
            queryParameters['selected'] = requestParameters['selected'];
        }

        if (requestParameters['smVfIds'] != null) {
            queryParameters['smVfIds'] = requestParameters['smVfIds'];
        }

        if (requestParameters['to'] != null) {
            queryParameters['to'] = (requestParameters['to'] as any).toISOString();
        }

        if (requestParameters['vfIds'] != null) {
            queryParameters['vfIds'] = requestParameters['vfIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/filteredmergedalarms`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContinuationAlarmTokenToJSON(requestParameters['continuationAlarmToken']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilteredMergedAlarmsResponseFromJSON(jsonValue));
    }

    /**
     * getFilteredMergedAlarmList
     * Gets list with all alarms combined or just for single types. They can be filtered.
     */
    async getFilteredMergedAlarmListUsingPUT(requestParameters: GetFilteredMergedAlarmListUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilteredMergedAlarmsResponse> {
        const response = await this.getFilteredMergedAlarmListUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getGatewayProfileDetails
     * Gets the gateway profile details.
     */
    async getGatewayProfileDetailsUsingGETRaw(requestParameters: GetGatewayProfileDetailsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayAlarmProfile>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling getGatewayProfileDetailsUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/gatewayprofile/{gatewayId}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GatewayAlarmProfileFromJSON(jsonValue));
    }

    /**
     * getGatewayProfileDetails
     * Gets the gateway profile details.
     */
    async getGatewayProfileDetailsUsingGET(requestParameters: GetGatewayProfileDetailsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayAlarmProfile> {
        const response = await this.getGatewayProfileDetailsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMergedAlarmList
     * Gets a device alarm list by virtualFridgeIds list, the request can specify the number of alarms fetched.
     */
    async getMergedAlarmListUsingGETRaw(requestParameters: GetMergedAlarmListUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MergedAlarmsResponse>> {
        if (requestParameters['from'] == null) {
            throw new runtime.RequiredError(
                'from',
                'Required parameter "from" was null or undefined when calling getMergedAlarmListUsingGET().'
            );
        }

        if (requestParameters['to'] == null) {
            throw new runtime.RequiredError(
                'to',
                'Required parameter "to" was null or undefined when calling getMergedAlarmListUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mergedalarms/{from}/{to}`.replace(`{${"from"}}`, encodeURIComponent(String(requestParameters['from']))).replace(`{${"to"}}`, encodeURIComponent(String(requestParameters['to']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MergedAlarmsResponseFromJSON(jsonValue));
    }

    /**
     * getMergedAlarmList
     * Gets a device alarm list by virtualFridgeIds list, the request can specify the number of alarms fetched.
     */
    async getMergedAlarmListUsingGET(requestParameters: GetMergedAlarmListUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MergedAlarmsResponse> {
        const response = await this.getMergedAlarmListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMergedAlarmStats
     * Gets the current device and gateway alarm statistics object.
     */
    async getMergedAlarmStatsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MergedAlarmStatsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/mergedalarms/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MergedAlarmStatsResponseFromJSON(jsonValue));
    }

    /**
     * getMergedAlarmStats
     * Gets the current device and gateway alarm statistics object.
     */
    async getMergedAlarmStatsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MergedAlarmStatsResponse> {
        const response = await this.getMergedAlarmStatsUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getSmartModuleAlarmsSettings
     * Gets SmartModule alarm profile.
     */
    async getSmartModuleAlarmsSettingsUsingGETRaw(requestParameters: GetSmartModuleAlarmsSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleAlarmSettingsResponse>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getSmartModuleAlarmsSettingsUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/smartmodule/alarmsettings/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmartModuleAlarmSettingsResponseFromJSON(jsonValue));
    }

    /**
     * getSmartModuleAlarmsSettings
     * Gets SmartModule alarm profile.
     */
    async getSmartModuleAlarmsSettingsUsingGET(requestParameters: GetSmartModuleAlarmsSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleAlarmSettingsResponse> {
        const response = await this.getSmartModuleAlarmsSettingsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSmartModuleNotificationSettings
     * Gets SmartModule notification settings.
     */
    async getSmartModuleNotificationSettingsUsingGETRaw(requestParameters: GetSmartModuleNotificationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationSettingsResponse>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getSmartModuleNotificationSettingsUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/smartmodule/notification/settings/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationSettingsResponseFromJSON(jsonValue));
    }

    /**
     * getSmartModuleNotificationSettings
     * Gets SmartModule notification settings.
     */
    async getSmartModuleNotificationSettingsUsingGET(requestParameters: GetSmartModuleNotificationSettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationSettingsResponse> {
        const response = await this.getSmartModuleNotificationSettingsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * setAlarmReadState
     * Sets the passed-in alarm state \'read/unread\'.
     */
    async setAlarmReadStateUsingPUTRaw(requestParameters: SetAlarmReadStateUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['alarmReadStateRequest'] == null) {
            throw new runtime.RequiredError(
                'alarmReadStateRequest',
                'Required parameter "alarmReadStateRequest" was null or undefined when calling setAlarmReadStateUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/alarm/readstate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AlarmReadStateRequestToJSON(requestParameters['alarmReadStateRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * setAlarmReadState
     * Sets the passed-in alarm state \'read/unread\'.
     */
    async setAlarmReadStateUsingPUT(requestParameters: SetAlarmReadStateUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setAlarmReadStateUsingPUTRaw(requestParameters, initOverrides);
    }

    /**
     * updateApplianceAlarmProfile
     * Updates an alarm profile.
     */
    async updateApplianceAlarmProfileUsingPUTRaw(requestParameters: UpdateApplianceAlarmProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['applianceAlarmProfileUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'applianceAlarmProfileUpdateRequest',
                'Required parameter "applianceAlarmProfileUpdateRequest" was null or undefined when calling updateApplianceAlarmProfileUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/alarmprofile/virtualfridge`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApplianceAlarmProfileUpdateRequestToJSON(requestParameters['applianceAlarmProfileUpdateRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * updateApplianceAlarmProfile
     * Updates an alarm profile.
     */
    async updateApplianceAlarmProfileUsingPUT(requestParameters: UpdateApplianceAlarmProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateApplianceAlarmProfileUsingPUTRaw(requestParameters, initOverrides);
    }

    /**
     * updateGatewayProfile
     * Updates an gateway profile.
     */
    async updateGatewayProfileUsingPUTRaw(requestParameters: UpdateGatewayProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['gatewayAlarmProfileUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'gatewayAlarmProfileUpdateRequest',
                'Required parameter "gatewayAlarmProfileUpdateRequest" was null or undefined when calling updateGatewayProfileUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/alarmprofile/gateway`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GatewayAlarmProfileUpdateRequestToJSON(requestParameters['gatewayAlarmProfileUpdateRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * updateGatewayProfile
     * Updates an gateway profile.
     */
    async updateGatewayProfileUsingPUT(requestParameters: UpdateGatewayProfileUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateGatewayProfileUsingPUTRaw(requestParameters, initOverrides);
    }

    /**
     * updateSmartModuleCloudAlarmsSettings
     * Update SmartModule zone additional cloud alarms.
     */
    async updateSmartModuleCloudAlarmsSettingsUsingPUTRaw(requestParameters: UpdateSmartModuleCloudAlarmsSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling updateSmartModuleCloudAlarmsSettingsUsingPUT().'
            );
        }

        if (requestParameters['smartModuleZoneSettingsRequest'] == null) {
            throw new runtime.RequiredError(
                'smartModuleZoneSettingsRequest',
                'Required parameter "smartModuleZoneSettingsRequest" was null or undefined when calling updateSmartModuleCloudAlarmsSettingsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/smartmodule/alarmsettings/cloudalarms/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['smartModuleZoneSettingsRequest']!.map(SmartModuleZoneSettingsRequestToJSON),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * updateSmartModuleCloudAlarmsSettings
     * Update SmartModule zone additional cloud alarms.
     */
    async updateSmartModuleCloudAlarmsSettingsUsingPUT(requestParameters: UpdateSmartModuleCloudAlarmsSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.updateSmartModuleCloudAlarmsSettingsUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateSmartModuleNotificationSettings
     * Create/Update SmartModule notification settings.
     */
    async updateSmartModuleNotificationSettingsUsingPUTRaw(requestParameters: UpdateSmartModuleNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['notificationSettingsRequest'] == null) {
            throw new runtime.RequiredError(
                'notificationSettingsRequest',
                'Required parameter "notificationSettingsRequest" was null or undefined when calling updateSmartModuleNotificationSettingsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/smartmodule/notification/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationSettingsRequestToJSON(requestParameters['notificationSettingsRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * updateSmartModuleNotificationSettings
     * Create/Update SmartModule notification settings.
     */
    async updateSmartModuleNotificationSettingsUsingPUT(requestParameters: UpdateSmartModuleNotificationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.updateSmartModuleNotificationSettingsUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateSmartModuleSystemSettings
     * Update SmartModule alarm profile - system unavailability.
     */
    async updateSmartModuleSystemSettingsUsingPUTRaw(requestParameters: UpdateSmartModuleSystemSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling updateSmartModuleSystemSettingsUsingPUT().'
            );
        }

        if (requestParameters['smartModuleSystemSettingsRequest'] == null) {
            throw new runtime.RequiredError(
                'smartModuleSystemSettingsRequest',
                'Required parameter "smartModuleSystemSettingsRequest" was null or undefined when calling updateSmartModuleSystemSettingsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/smartmodule/alarmsettings/system/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SmartModuleSystemSettingsRequestToJSON(requestParameters['smartModuleSystemSettingsRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * updateSmartModuleSystemSettings
     * Update SmartModule alarm profile - system unavailability.
     */
    async updateSmartModuleSystemSettingsUsingPUT(requestParameters: UpdateSmartModuleSystemSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.updateSmartModuleSystemSettingsUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
