/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface DoorSensorAlarmSettings
 */
export interface DoorSensorAlarmSettings {
  /**
   *
   * @type {number}
   * @memberof DoorSensorAlarmSettings
   */
  delayInMilliseconds?: number;
  /**
   *
   * @type {boolean}
   * @memberof DoorSensorAlarmSettings
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof DoorSensorAlarmSettings
   */
  sensorType?: DoorSensorAlarmSettingsSensorTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DoorSensorAlarmSettings
   */
  thingId?: string;
}

/**
 * @export
 */
export const DoorSensorAlarmSettingsSensorTypeEnum = {
  DOOR: 'DOOR',
  TEMPERATURE: 'TEMPERATURE',
  PRODUCT_TEMPERATURE: 'PRODUCT_TEMPERATURE'
} as const;
export type DoorSensorAlarmSettingsSensorTypeEnum = typeof DoorSensorAlarmSettingsSensorTypeEnum[keyof typeof DoorSensorAlarmSettingsSensorTypeEnum];

/**
 * Check if a given object implements the DoorSensorAlarmSettings interface.
 */
export function instanceOfDoorSensorAlarmSettings(value: object): value is DoorSensorAlarmSettings {
  return true;
}

export function DoorSensorAlarmSettingsFromJSON(json: any): DoorSensorAlarmSettings {
  return DoorSensorAlarmSettingsFromJSONTyped(json, false);
}

export function DoorSensorAlarmSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoorSensorAlarmSettings {
  if (json == null) {
    return json;
  }
  return {
    delayInMilliseconds: json['delayInMilliseconds'] == null ? undefined : json['delayInMilliseconds'],
    enabled: json['enabled'] == null ? undefined : json['enabled'],
    sensorType: json['sensorType'] == null ? undefined : json['sensorType'],
    thingId: json['thingId'] == null ? undefined : json['thingId']
  };
}

export function DoorSensorAlarmSettingsToJSON(json: any): DoorSensorAlarmSettings {
  return DoorSensorAlarmSettingsToJSONTyped(json, false);
}

export function DoorSensorAlarmSettingsToJSONTyped(
  value?: DoorSensorAlarmSettings | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    delayInMilliseconds: value['delayInMilliseconds'],
    enabled: value['enabled'],
    sensorType: value['sensorType'],
    thingId: value['thingId']
  };
}
