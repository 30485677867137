/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AllMeasureSitesResponse,
  ApplianceGroupsRequest,
  ApplianceGroupsResponse,
  ApplianceResponse,
  ApplianceStatesResponse,
  BinderApplianceInfoResponse,
  ConnectivityResponse,
  ErrorDto,
  GatewayResponse,
  HttpStatusCode,
  MasterDataApplianceInfoResponse,
  RegisteredBinderAppliancesResponse,
  SmartModuleConnectionResponse,
  SmartModuleResponse,
  SmartModuleState,
  UpdateGatewayInfoRequest,
  UpdateMeasureSiteRequest,
} from '../models/index';
import {
    AllMeasureSitesResponseFromJSON,
    AllMeasureSitesResponseToJSON,
    ApplianceGroupsRequestFromJSON,
    ApplianceGroupsRequestToJSON,
    ApplianceGroupsResponseFromJSON,
    ApplianceGroupsResponseToJSON,
    ApplianceResponseFromJSON,
    ApplianceResponseToJSON,
    ApplianceStatesResponseFromJSON,
    ApplianceStatesResponseToJSON,
    BinderApplianceInfoResponseFromJSON,
    BinderApplianceInfoResponseToJSON,
    ConnectivityResponseFromJSON,
    ConnectivityResponseToJSON,
    ErrorDtoFromJSON,
    ErrorDtoToJSON,
    GatewayResponseFromJSON,
    GatewayResponseToJSON,
    HttpStatusCodeFromJSON,
    HttpStatusCodeToJSON,
    MasterDataApplianceInfoResponseFromJSON,
    MasterDataApplianceInfoResponseToJSON,
    RegisteredBinderAppliancesResponseFromJSON,
    RegisteredBinderAppliancesResponseToJSON,
    SmartModuleConnectionResponseFromJSON,
    SmartModuleConnectionResponseToJSON,
    SmartModuleResponseFromJSON,
    SmartModuleResponseToJSON,
    SmartModuleStateFromJSON,
    SmartModuleStateToJSON,
    UpdateGatewayInfoRequestFromJSON,
    UpdateGatewayInfoRequestToJSON,
    UpdateMeasureSiteRequestFromJSON,
    UpdateMeasureSiteRequestToJSON,
} from '../models/index';

export interface AddMeasureSiteToGroupUsingPUTRequest {
    groupId: string;
    virtualFridgeId: string;
}

export interface CheckApplianceUsingGETRequest {
    articleNumber: string;
}

export interface DeleteMeasureSiteAddressUsingDELETERequest {
    virtualFridgeId: string;
}

export interface GetApplianceInfoBySerialNumberUsingGETRequest {
    serialNumber: string;
}

export interface GetBinderApplianceInfoUsingGETRequest {
    macAddress: string;
}

export interface GetMasterDataPictureBySerialNrUsingGETRequest {
    serialNumber: string;
}

export interface GetMeasureSiteAsApplianceResponseUsingGETRequest {
    locale: string;
    virtualFridgeId: string;
}

export interface GetRegisteredBinderAppliancesUsingGETRequest {
    gatewayId: string;
}

export interface GetSmartModuleApplianceUsingGETRequest {
    serialNumber: string;
}

export interface GetSmartModuleConnectionStateUsingGETRequest {
    serialNumber: string;
}

export interface GetSmartModuleStateUsingGETRequest {
    virtualFridgeId: string;
}

export interface SaveGroupsUsingPOSTRequest {
    applianceGroupsRequest: ApplianceGroupsRequest;
}

export interface UpdateGatewayInfoUsingPUTRequest {
    gatewayId: string;
    updateGatewayInfoRequest: UpdateGatewayInfoRequest;
}

export interface UpdateMeasureSiteUsingPUTRequest {
    updateMeasureSiteRequest: UpdateMeasureSiteRequest;
}

/**
 * ApplianceControllerApi - interface
 * 
 * @export
 * @interface ApplianceControllerApiInterface
 */
export interface ApplianceControllerApiInterface {
    /**
     * addMeasureSiteToGroup
     * @summary Add measure site to group.
     * @param {string} groupId 
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    addMeasureSiteToGroupUsingPUTRaw(requestParameters: AddMeasureSiteToGroupUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * addMeasureSiteToGroup
     * Add measure site to group.
     */
    addMeasureSiteToGroupUsingPUT(requestParameters: AddMeasureSiteToGroupUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * checkAppliance
     * @summary Checks whether an appliance with the given article number is compatible with SM.
     * @param {string} articleNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    checkApplianceUsingGETRaw(requestParameters: CheckApplianceUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectivityResponse>>;

    /**
     * checkAppliance
     * Checks whether an appliance with the given article number is compatible with SM.
     */
    checkApplianceUsingGET(requestParameters: CheckApplianceUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectivityResponse>;

    /**
     * deleteMeasureSiteAddress
     * @summary Deletes the address of a measuresite.
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    deleteMeasureSiteAddressUsingDELETERaw(requestParameters: DeleteMeasureSiteAddressUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HttpStatusCode>>;

    /**
     * deleteMeasureSiteAddress
     * Deletes the address of a measuresite.
     */
    deleteMeasureSiteAddressUsingDELETE(requestParameters: DeleteMeasureSiteAddressUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HttpStatusCode>;

    /**
     * getAllGateways
     * @summary Get all gateways for the organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getAllGatewaysUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayResponse>>;

    /**
     * getAllGateways
     * Get all gateways for the organization.
     */
    getAllGatewaysUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayResponse>;

    /**
     * getAllGroups
     * @summary Gets all appliance groups that the user owns.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getAllGroupsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceGroupsResponse>>;

    /**
     * getAllGroups
     * Gets all appliance groups that the user owns.
     */
    getAllGroupsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceGroupsResponse>;

    /**
     * getAllMeasureSites
     * @summary Get the existing measure sites for an organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getAllMeasureSitesUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllMeasureSitesResponse>>;

    /**
     * getAllMeasureSites
     * Get the existing measure sites for an organization.
     */
    getAllMeasureSitesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllMeasureSitesResponse>;

    /**
     * getAllStates
     * @summary Gets states for all appliances that the user owns.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getAllStatesUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceStatesResponse>>;

    /**
     * getAllStates
     * Gets states for all appliances that the user owns.
     */
    getAllStatesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceStatesResponse>;

    /**
     * getApplianceInfoBySerialNumber
     * @summary Get appliance info by serial number. Latter must have the following format: XX.XXX.XXX.X.
     * @param {string} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getApplianceInfoBySerialNumberUsingGETRaw(requestParameters: GetApplianceInfoBySerialNumberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MasterDataApplianceInfoResponse>>;

    /**
     * getApplianceInfoBySerialNumber
     * Get appliance info by serial number. Latter must have the following format: XX.XXX.XXX.X.
     */
    getApplianceInfoBySerialNumberUsingGET(requestParameters: GetApplianceInfoBySerialNumberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MasterDataApplianceInfoResponse>;

    /**
     * getBinderApplianceInfo
     * @summary Get binder appliance info.
     * @param {string} macAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getBinderApplianceInfoUsingGETRaw(requestParameters: GetBinderApplianceInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinderApplianceInfoResponse>>;

    /**
     * getBinderApplianceInfo
     * Get binder appliance info.
     */
    getBinderApplianceInfoUsingGET(requestParameters: GetBinderApplianceInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinderApplianceInfoResponse>;

    /**
     * getDefaultMasterDataPicture
     * @summary Get masterdata default picture.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getDefaultMasterDataPictureUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * getDefaultMasterDataPicture
     * Get masterdata default picture.
     */
    getDefaultMasterDataPictureUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * getMasterDataPictureBySerialNr
     * @summary Get masterdata picture by serial nr.
     * @param {string} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getMasterDataPictureBySerialNrUsingGETRaw(requestParameters: GetMasterDataPictureBySerialNrUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * getMasterDataPictureBySerialNr
     * Get masterdata picture by serial nr.
     */
    getMasterDataPictureBySerialNrUsingGET(requestParameters: GetMasterDataPictureBySerialNrUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * getMeasureSiteAsApplianceResponse
     * @summary Get measure site info by virtualFridgeId.
     * @param {string} locale 
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getMeasureSiteAsApplianceResponseUsingGETRaw(requestParameters: GetMeasureSiteAsApplianceResponseUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceResponse>>;

    /**
     * getMeasureSiteAsApplianceResponse
     * Get measure site info by virtualFridgeId.
     */
    getMeasureSiteAsApplianceResponseUsingGET(requestParameters: GetMeasureSiteAsApplianceResponseUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceResponse>;

    /**
     * getRegisteredBinderAppliances
     * @summary Get registered to a gateway binder appliance.
     * @param {string} gatewayId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getRegisteredBinderAppliancesUsingGETRaw(requestParameters: GetRegisteredBinderAppliancesUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisteredBinderAppliancesResponse>>;

    /**
     * getRegisteredBinderAppliances
     * Get registered to a gateway binder appliance.
     */
    getRegisteredBinderAppliancesUsingGET(requestParameters: GetRegisteredBinderAppliancesUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisteredBinderAppliancesResponse>;

    /**
     * getSmartModuleAppliance
     * @summary Gets Smart module appliance.
     * @param {string} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getSmartModuleApplianceUsingGETRaw(requestParameters: GetSmartModuleApplianceUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleResponse>>;

    /**
     * getSmartModuleAppliance
     * Gets Smart module appliance.
     */
    getSmartModuleApplianceUsingGET(requestParameters: GetSmartModuleApplianceUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleResponse>;

    /**
     * getSmartModuleConnectionState
     * @summary Gets the connection state of a SmartModuleFridge by serial number
     * @param {string} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getSmartModuleConnectionStateUsingGETRaw(requestParameters: GetSmartModuleConnectionStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleConnectionResponse>>;

    /**
     * getSmartModuleConnectionState
     * Gets the connection state of a SmartModuleFridge by serial number
     */
    getSmartModuleConnectionStateUsingGET(requestParameters: GetSmartModuleConnectionStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleConnectionResponse>;

    /**
     * getSmartModuleState
     * @summary Gets state for one SmartModule that the user owns.
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    getSmartModuleStateUsingGETRaw(requestParameters: GetSmartModuleStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleState>>;

    /**
     * getSmartModuleState
     * Gets state for one SmartModule that the user owns.
     */
    getSmartModuleStateUsingGET(requestParameters: GetSmartModuleStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleState>;

    /**
     * saveGroups
     * @summary Stores/creates the groups as specified in the groups request. Replaces the old group editing completely.
     * @param {ApplianceGroupsRequest} applianceGroupsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    saveGroupsUsingPOSTRaw(requestParameters: SaveGroupsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceGroupsResponse>>;

    /**
     * saveGroups
     * Stores/creates the groups as specified in the groups request. Replaces the old group editing completely.
     */
    saveGroupsUsingPOST(requestParameters: SaveGroupsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceGroupsResponse>;

    /**
     * updateGatewayInfo
     * @summary Update bsdb info with given data.
     * @param {string} gatewayId 
     * @param {UpdateGatewayInfoRequest} updateGatewayInfoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    updateGatewayInfoUsingPUTRaw(requestParameters: UpdateGatewayInfoUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * updateGatewayInfo
     * Update bsdb info with given data.
     */
    updateGatewayInfoUsingPUT(requestParameters: UpdateGatewayInfoUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * updateMeasureSite
     * @summary Updates a measure site. The UpdateMeasureSiteRequest contains all data.
     * @param {UpdateMeasureSiteRequest} updateMeasureSiteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplianceControllerApiInterface
     */
    updateMeasureSiteUsingPUTRaw(requestParameters: UpdateMeasureSiteUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>>;

    /**
     * updateMeasureSite
     * Updates a measure site. The UpdateMeasureSiteRequest contains all data.
     */
    updateMeasureSiteUsingPUT(requestParameters: UpdateMeasureSiteUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean>;

}

/**
 * 
 */
export class ApplianceControllerApi extends runtime.BaseAPI implements ApplianceControllerApiInterface {

    /**
     * addMeasureSiteToGroup
     * Add measure site to group.
     */
    async addMeasureSiteToGroupUsingPUTRaw(requestParameters: AddMeasureSiteToGroupUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['groupId'] == null) {
            throw new runtime.RequiredError(
                'groupId',
                'Required parameter "groupId" was null or undefined when calling addMeasureSiteToGroupUsingPUT().'
            );
        }

        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling addMeasureSiteToGroupUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/measuresite/{virtualFridgeId}/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters['groupId']))).replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * addMeasureSiteToGroup
     * Add measure site to group.
     */
    async addMeasureSiteToGroupUsingPUT(requestParameters: AddMeasureSiteToGroupUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addMeasureSiteToGroupUsingPUTRaw(requestParameters, initOverrides);
    }

    /**
     * checkAppliance
     * Checks whether an appliance with the given article number is compatible with SM.
     */
    async checkApplianceUsingGETRaw(requestParameters: CheckApplianceUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConnectivityResponse>> {
        if (requestParameters['articleNumber'] == null) {
            throw new runtime.RequiredError(
                'articleNumber',
                'Required parameter "articleNumber" was null or undefined when calling checkApplianceUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/public/appliance/check/{articleNumber}`.replace(`{${"articleNumber"}}`, encodeURIComponent(String(requestParameters['articleNumber']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectivityResponseFromJSON(jsonValue));
    }

    /**
     * checkAppliance
     * Checks whether an appliance with the given article number is compatible with SM.
     */
    async checkApplianceUsingGET(requestParameters: CheckApplianceUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConnectivityResponse> {
        const response = await this.checkApplianceUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteMeasureSiteAddress
     * Deletes the address of a measuresite.
     */
    async deleteMeasureSiteAddressUsingDELETERaw(requestParameters: DeleteMeasureSiteAddressUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HttpStatusCode>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling deleteMeasureSiteAddressUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['virtualFridgeId'] != null) {
            queryParameters['virtualFridgeId'] = requestParameters['virtualFridgeId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/measuresite/address`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HttpStatusCodeFromJSON(jsonValue));
    }

    /**
     * deleteMeasureSiteAddress
     * Deletes the address of a measuresite.
     */
    async deleteMeasureSiteAddressUsingDELETE(requestParameters: DeleteMeasureSiteAddressUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HttpStatusCode> {
        const response = await this.deleteMeasureSiteAddressUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllGateways
     * Get all gateways for the organization.
     */
    async getAllGatewaysUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/appliance/gateways`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GatewayResponseFromJSON(jsonValue));
    }

    /**
     * getAllGateways
     * Get all gateways for the organization.
     */
    async getAllGatewaysUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayResponse> {
        const response = await this.getAllGatewaysUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllGroups
     * Gets all appliance groups that the user owns.
     */
    async getAllGroupsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceGroupsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/appliance/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplianceGroupsResponseFromJSON(jsonValue));
    }

    /**
     * getAllGroups
     * Gets all appliance groups that the user owns.
     */
    async getAllGroupsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceGroupsResponse> {
        const response = await this.getAllGroupsUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllMeasureSites
     * Get the existing measure sites for an organization.
     */
    async getAllMeasureSitesUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllMeasureSitesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/appliance/measuresites/organization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllMeasureSitesResponseFromJSON(jsonValue));
    }

    /**
     * getAllMeasureSites
     * Get the existing measure sites for an organization.
     */
    async getAllMeasureSitesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllMeasureSitesResponse> {
        const response = await this.getAllMeasureSitesUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllStates
     * Gets states for all appliances that the user owns.
     */
    async getAllStatesUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceStatesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/appliance/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplianceStatesResponseFromJSON(jsonValue));
    }

    /**
     * getAllStates
     * Gets states for all appliances that the user owns.
     */
    async getAllStatesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceStatesResponse> {
        const response = await this.getAllStatesUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getApplianceInfoBySerialNumber
     * Get appliance info by serial number. Latter must have the following format: XX.XXX.XXX.X.
     */
    async getApplianceInfoBySerialNumberUsingGETRaw(requestParameters: GetApplianceInfoBySerialNumberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MasterDataApplianceInfoResponse>> {
        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling getApplianceInfoBySerialNumberUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/masterdata/applianceinfo/{serialNumber}`.replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MasterDataApplianceInfoResponseFromJSON(jsonValue));
    }

    /**
     * getApplianceInfoBySerialNumber
     * Get appliance info by serial number. Latter must have the following format: XX.XXX.XXX.X.
     */
    async getApplianceInfoBySerialNumberUsingGET(requestParameters: GetApplianceInfoBySerialNumberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MasterDataApplianceInfoResponse> {
        const response = await this.getApplianceInfoBySerialNumberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getBinderApplianceInfo
     * Get binder appliance info.
     */
    async getBinderApplianceInfoUsingGETRaw(requestParameters: GetBinderApplianceInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BinderApplianceInfoResponse>> {
        if (requestParameters['macAddress'] == null) {
            throw new runtime.RequiredError(
                'macAddress',
                'Required parameter "macAddress" was null or undefined when calling getBinderApplianceInfoUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/binderappliances/{macAddress}/info`.replace(`{${"macAddress"}}`, encodeURIComponent(String(requestParameters['macAddress']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BinderApplianceInfoResponseFromJSON(jsonValue));
    }

    /**
     * getBinderApplianceInfo
     * Get binder appliance info.
     */
    async getBinderApplianceInfoUsingGET(requestParameters: GetBinderApplianceInfoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BinderApplianceInfoResponse> {
        const response = await this.getBinderApplianceInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDefaultMasterDataPicture
     * Get masterdata default picture.
     */
    async getDefaultMasterDataPictureUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/masterdata/picture/default`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * getDefaultMasterDataPicture
     * Get masterdata default picture.
     */
    async getDefaultMasterDataPictureUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getDefaultMasterDataPictureUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getMasterDataPictureBySerialNr
     * Get masterdata picture by serial nr.
     */
    async getMasterDataPictureBySerialNrUsingGETRaw(requestParameters: GetMasterDataPictureBySerialNrUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling getMasterDataPictureBySerialNrUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/masterdata/picture/{serialNumber}`.replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * getMasterDataPictureBySerialNr
     * Get masterdata picture by serial nr.
     */
    async getMasterDataPictureBySerialNrUsingGET(requestParameters: GetMasterDataPictureBySerialNrUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getMasterDataPictureBySerialNrUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMeasureSiteAsApplianceResponse
     * Get measure site info by virtualFridgeId.
     */
    async getMeasureSiteAsApplianceResponseUsingGETRaw(requestParameters: GetMeasureSiteAsApplianceResponseUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceResponse>> {
        if (requestParameters['locale'] == null) {
            throw new runtime.RequiredError(
                'locale',
                'Required parameter "locale" was null or undefined when calling getMeasureSiteAsApplianceResponseUsingGET().'
            );
        }

        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getMeasureSiteAsApplianceResponseUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['virtualFridgeId'] != null) {
            queryParameters['virtualFridgeId'] = requestParameters['virtualFridgeId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/appliance/measuresite/{locale}`.replace(`{${"locale"}}`, encodeURIComponent(String(requestParameters['locale']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplianceResponseFromJSON(jsonValue));
    }

    /**
     * getMeasureSiteAsApplianceResponse
     * Get measure site info by virtualFridgeId.
     */
    async getMeasureSiteAsApplianceResponseUsingGET(requestParameters: GetMeasureSiteAsApplianceResponseUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceResponse> {
        const response = await this.getMeasureSiteAsApplianceResponseUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getRegisteredBinderAppliances
     * Get registered to a gateway binder appliance.
     */
    async getRegisteredBinderAppliancesUsingGETRaw(requestParameters: GetRegisteredBinderAppliancesUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisteredBinderAppliancesResponse>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling getRegisteredBinderAppliancesUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/gateways/{gatewayId}/registeredbinder`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisteredBinderAppliancesResponseFromJSON(jsonValue));
    }

    /**
     * getRegisteredBinderAppliances
     * Get registered to a gateway binder appliance.
     */
    async getRegisteredBinderAppliancesUsingGET(requestParameters: GetRegisteredBinderAppliancesUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisteredBinderAppliancesResponse> {
        const response = await this.getRegisteredBinderAppliancesUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSmartModuleAppliance
     * Gets Smart module appliance.
     */
    async getSmartModuleApplianceUsingGETRaw(requestParameters: GetSmartModuleApplianceUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleResponse>> {
        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling getSmartModuleApplianceUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['serialNumber'] != null) {
            queryParameters['serialNumber'] = requestParameters['serialNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/appliance/smartmodule`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmartModuleResponseFromJSON(jsonValue));
    }

    /**
     * getSmartModuleAppliance
     * Gets Smart module appliance.
     */
    async getSmartModuleApplianceUsingGET(requestParameters: GetSmartModuleApplianceUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleResponse> {
        const response = await this.getSmartModuleApplianceUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSmartModuleConnectionState
     * Gets the connection state of a SmartModuleFridge by serial number
     */
    async getSmartModuleConnectionStateUsingGETRaw(requestParameters: GetSmartModuleConnectionStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleConnectionResponse>> {
        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling getSmartModuleConnectionStateUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/smartmodule/connection/{serialNumber}`.replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmartModuleConnectionResponseFromJSON(jsonValue));
    }

    /**
     * getSmartModuleConnectionState
     * Gets the connection state of a SmartModuleFridge by serial number
     */
    async getSmartModuleConnectionStateUsingGET(requestParameters: GetSmartModuleConnectionStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleConnectionResponse> {
        const response = await this.getSmartModuleConnectionStateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSmartModuleState
     * Gets state for one SmartModule that the user owns.
     */
    async getSmartModuleStateUsingGETRaw(requestParameters: GetSmartModuleStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleState>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getSmartModuleStateUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/appliance/smartmodule/state/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmartModuleStateFromJSON(jsonValue));
    }

    /**
     * getSmartModuleState
     * Gets state for one SmartModule that the user owns.
     */
    async getSmartModuleStateUsingGET(requestParameters: GetSmartModuleStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleState> {
        const response = await this.getSmartModuleStateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveGroups
     * Stores/creates the groups as specified in the groups request. Replaces the old group editing completely.
     */
    async saveGroupsUsingPOSTRaw(requestParameters: SaveGroupsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplianceGroupsResponse>> {
        if (requestParameters['applianceGroupsRequest'] == null) {
            throw new runtime.RequiredError(
                'applianceGroupsRequest',
                'Required parameter "applianceGroupsRequest" was null or undefined when calling saveGroupsUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/appliance/groups/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplianceGroupsRequestToJSON(requestParameters['applianceGroupsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplianceGroupsResponseFromJSON(jsonValue));
    }

    /**
     * saveGroups
     * Stores/creates the groups as specified in the groups request. Replaces the old group editing completely.
     */
    async saveGroupsUsingPOST(requestParameters: SaveGroupsUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplianceGroupsResponse> {
        const response = await this.saveGroupsUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateGatewayInfo
     * Update bsdb info with given data.
     */
    async updateGatewayInfoUsingPUTRaw(requestParameters: UpdateGatewayInfoUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling updateGatewayInfoUsingPUT().'
            );
        }

        if (requestParameters['updateGatewayInfoRequest'] == null) {
            throw new runtime.RequiredError(
                'updateGatewayInfoRequest',
                'Required parameter "updateGatewayInfoRequest" was null or undefined when calling updateGatewayInfoUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/appliance/gateway/{gatewayId}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGatewayInfoRequestToJSON(requestParameters['updateGatewayInfoRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * updateGatewayInfo
     * Update bsdb info with given data.
     */
    async updateGatewayInfoUsingPUT(requestParameters: UpdateGatewayInfoUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateGatewayInfoUsingPUTRaw(requestParameters, initOverrides);
    }

    /**
     * updateMeasureSite
     * Updates a measure site. The UpdateMeasureSiteRequest contains all data.
     */
    async updateMeasureSiteUsingPUTRaw(requestParameters: UpdateMeasureSiteUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['updateMeasureSiteRequest'] == null) {
            throw new runtime.RequiredError(
                'updateMeasureSiteRequest',
                'Required parameter "updateMeasureSiteRequest" was null or undefined when calling updateMeasureSiteUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/measuresite`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMeasureSiteRequestToJSON(requestParameters['updateMeasureSiteRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * updateMeasureSite
     * Updates a measure site. The UpdateMeasureSiteRequest contains all data.
     */
    async updateMeasureSiteUsingPUT(requestParameters: UpdateMeasureSiteUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.updateMeasureSiteUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
