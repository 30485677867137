/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OnboardingInfo } from './OnboardingInfo';
import {
    OnboardingInfoFromJSON,
    OnboardingInfoFromJSONTyped,
    OnboardingInfoToJSON,
    OnboardingInfoToJSONTyped,
} from './OnboardingInfo';

/**
 * 
 * @export
 * @interface SmartModuleResponse
 */
export interface SmartModuleResponse {
    /**
     * 
     * @type {string}
     * @memberof SmartModuleResponse
     */
    applianceSerialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartModuleResponse
     */
    name?: string;
    /**
     * 
     * @type {OnboardingInfo}
     * @memberof SmartModuleResponse
     */
    onboardingInfo?: OnboardingInfo;
    /**
     * 
     * @type {string}
     * @memberof SmartModuleResponse
     */
    onboardingState?: SmartModuleResponseOnboardingStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof SmartModuleResponse
     */
    timestamp?: Date;
}


/**
 * @export
 */
export const SmartModuleResponseOnboardingStateEnum = {
    VOID: 'VOID',
    AWAIT_ONBOARDING: 'AWAIT_ONBOARDING',
    ONBOARDING_STARTED: 'ONBOARDING_STARTED',
    ONBOARDED: 'ONBOARDED'
} as const;
export type SmartModuleResponseOnboardingStateEnum = typeof SmartModuleResponseOnboardingStateEnum[keyof typeof SmartModuleResponseOnboardingStateEnum];


/**
 * Check if a given object implements the SmartModuleResponse interface.
 */
export function instanceOfSmartModuleResponse(value: object): value is SmartModuleResponse {
    return true;
}

export function SmartModuleResponseFromJSON(json: any): SmartModuleResponse {
    return SmartModuleResponseFromJSONTyped(json, false);
}

export function SmartModuleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmartModuleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'applianceSerialNumber': json['applianceSerialNumber'] == null ? undefined : json['applianceSerialNumber'],
        'name': json['name'] == null ? undefined : json['name'],
        'onboardingInfo': json['onboardingInfo'] == null ? undefined : OnboardingInfoFromJSON(json['onboardingInfo']),
        'onboardingState': json['onboardingState'] == null ? undefined : json['onboardingState'],
        'timestamp': json['timestamp'] == null ? undefined : (new Date(json['timestamp'])),
    };
}

export function SmartModuleResponseToJSON(json: any): SmartModuleResponse {
    return SmartModuleResponseToJSONTyped(json, false);
}

export function SmartModuleResponseToJSONTyped(value?: SmartModuleResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'applianceSerialNumber': value['applianceSerialNumber'],
        'name': value['name'],
        'onboardingInfo': OnboardingInfoToJSON(value['onboardingInfo']),
        'onboardingState': value['onboardingState'],
        'timestamp': value['timestamp'] == null ? undefined : ((value['timestamp']).toISOString()),
    };
}

