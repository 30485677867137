/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BinderApplianceRequest,
  CheckBsdbRequest,
  CheckGatewayResponse,
  CreateBsdbRequest,
  CreateGatewayResponse,
  ErrorDto,
  ExtendedGatewayState,
  FirmwareRestrictionsResponse,
  GatewayConfigurationResponse,
  GatewayStatesResponse,
  HttpStatusCode,
  RegisteredBinderAppliance,
  SaveApplianceRequest,
  SaveApplianceResponse,
  SmartModuleLaunchResponse,
  SmartModuleRequest,
  SmartModuleResponse,
  UUIDListRequest,
} from '../models/index';
import {
    BinderApplianceRequestFromJSON,
    BinderApplianceRequestToJSON,
    CheckBsdbRequestFromJSON,
    CheckBsdbRequestToJSON,
    CheckGatewayResponseFromJSON,
    CheckGatewayResponseToJSON,
    CreateBsdbRequestFromJSON,
    CreateBsdbRequestToJSON,
    CreateGatewayResponseFromJSON,
    CreateGatewayResponseToJSON,
    ErrorDtoFromJSON,
    ErrorDtoToJSON,
    ExtendedGatewayStateFromJSON,
    ExtendedGatewayStateToJSON,
    FirmwareRestrictionsResponseFromJSON,
    FirmwareRestrictionsResponseToJSON,
    GatewayConfigurationResponseFromJSON,
    GatewayConfigurationResponseToJSON,
    GatewayStatesResponseFromJSON,
    GatewayStatesResponseToJSON,
    HttpStatusCodeFromJSON,
    HttpStatusCodeToJSON,
    RegisteredBinderApplianceFromJSON,
    RegisteredBinderApplianceToJSON,
    SaveApplianceRequestFromJSON,
    SaveApplianceRequestToJSON,
    SaveApplianceResponseFromJSON,
    SaveApplianceResponseToJSON,
    SmartModuleLaunchResponseFromJSON,
    SmartModuleLaunchResponseToJSON,
    SmartModuleRequestFromJSON,
    SmartModuleRequestToJSON,
    SmartModuleResponseFromJSON,
    SmartModuleResponseToJSON,
    UUIDListRequestFromJSON,
    UUIDListRequestToJSON,
} from '../models/index';

export interface CheckBsdbUsingPUTRequest {
    checkBsdbRequest: CheckBsdbRequest;
}

export interface CheckFirmwareRestrictionsUsingGETRequest {
    gatewayId: string;
}

export interface CreateBsdbUsingPUTRequest {
    createBsdbRequest: CreateBsdbRequest;
}

export interface DeleteApplianceUsingDELETERequest {
    gatewayId: string;
    virtualFridgeId: string;
}

export interface DeleteBinderApplianceUsingDELETERequest {
    gatewayId: string;
    virtualFridgeId: string;
}

export interface DeleteGatewayUsingDELETERequest {
    gatewayId: string;
}

export interface DeleteVirtualMeasureSiteUsingDELETERequest {
    virtualFridgeId: string;
}

export interface GetBsdbConfigUsingGETRequest {
    gatewayId: string;
}

export interface GetExtendedGatewayStateUsingGETRequest {
    gatewayId: string;
}

export interface GetGatewayStatesForListUsingPOSTRequest {
    uUIDListRequest: UUIDListRequest;
}

export interface OffBoardSmartModuleUsingPOSTRequest {
    serialNumber: string;
}

export interface SaveApplianceUsingPOSTRequest {
    gatewayId: string;
    groupId: string;
    saveApplianceRequest: SaveApplianceRequest;
}

export interface SaveBinderApplianceUsingPOSTRequest {
    gatewayId: string;
    binderApplianceRequest: BinderApplianceRequest;
}

export interface SaveSmartModuleUsingPOSTRequest {
    serialNumber: string;
    smartModuleRequest: SmartModuleRequest;
}

export interface ScanGatewayOldUsingGETRequest {
    gatewayId: string;
    startScan: boolean;
}

export interface ScanGatewayUsingGETRequest {
    gatewayId: string;
    binderAppliance?: boolean;
    ipAddress?: string;
}

export interface StartSmartModuleOnboardingUsingPOSTRequest {
    correlationId: string;
    serialNumber: string;
}

/**
 * BsdbControllerApi - interface
 * 
 * @export
 * @interface BsdbControllerApiInterface
 */
export interface BsdbControllerApiInterface {
    /**
     * checkBsdb
     * @summary Checks a BSDB/gateway by serial number and passcode if given.
     * @param {CheckBsdbRequest} checkBsdbRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    checkBsdbUsingPUTRaw(requestParameters: CheckBsdbUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckGatewayResponse>>;

    /**
     * checkBsdb
     * Checks a BSDB/gateway by serial number and passcode if given.
     */
    checkBsdbUsingPUT(requestParameters: CheckBsdbUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckGatewayResponse>;

    /**
     * checkFirmwareRestrictions
     * @summary Checks whether the current gateway has restrictions in scan and onboarding.
     * @param {string} gatewayId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    checkFirmwareRestrictionsUsingGETRaw(requestParameters: CheckFirmwareRestrictionsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FirmwareRestrictionsResponse>>;

    /**
     * checkFirmwareRestrictions
     * Checks whether the current gateway has restrictions in scan and onboarding.
     */
    checkFirmwareRestrictionsUsingGET(requestParameters: CheckFirmwareRestrictionsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FirmwareRestrictionsResponse>;

    /**
     * createBsdb
     * @summary Creates necessary settings for given BSDB.
     * @param {CreateBsdbRequest} createBsdbRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    createBsdbUsingPUTRaw(requestParameters: CreateBsdbUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateGatewayResponse>>;

    /**
     * createBsdb
     * Creates necessary settings for given BSDB.
     */
    createBsdbUsingPUT(requestParameters: CreateBsdbUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateGatewayResponse>;

    /**
     * deleteAppliance
     * @summary Deletes an appliance
     * @param {string} gatewayId 
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    deleteApplianceUsingDELETERaw(requestParameters: DeleteApplianceUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * deleteAppliance
     * Deletes an appliance
     */
    deleteApplianceUsingDELETE(requestParameters: DeleteApplianceUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * deleteBinderAppliance
     * @summary Deletes an appliance
     * @param {string} gatewayId 
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    deleteBinderApplianceUsingDELETERaw(requestParameters: DeleteBinderApplianceUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HttpStatusCode>>;

    /**
     * deleteBinderAppliance
     * Deletes an appliance
     */
    deleteBinderApplianceUsingDELETE(requestParameters: DeleteBinderApplianceUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HttpStatusCode>;

    /**
     * deleteGateway
     * @summary Deletes an appliance
     * @param {string} gatewayId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    deleteGatewayUsingDELETERaw(requestParameters: DeleteGatewayUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * deleteGateway
     * Deletes an appliance
     */
    deleteGatewayUsingDELETE(requestParameters: DeleteGatewayUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * deleteVirtualMeasureSite
     * @summary Deletes a virtual measure site. Not applicable for connectables.
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    deleteVirtualMeasureSiteUsingDELETERaw(requestParameters: DeleteVirtualMeasureSiteUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * deleteVirtualMeasureSite
     * Deletes a virtual measure site. Not applicable for connectables.
     */
    deleteVirtualMeasureSiteUsingDELETE(requestParameters: DeleteVirtualMeasureSiteUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * getBsdbConfig
     * @summary Gets the config for a given BSDB/gateway.
     * @param {string} gatewayId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    getBsdbConfigUsingGETRaw(requestParameters: GetBsdbConfigUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayConfigurationResponse>>;

    /**
     * getBsdbConfig
     * Gets the config for a given BSDB/gateway.
     */
    getBsdbConfigUsingGET(requestParameters: GetBsdbConfigUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayConfigurationResponse>;

    /**
     * getExtendedGatewayState
     * @summary Gets the extended gateway state for a UUID.
     * @param {string} gatewayId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    getExtendedGatewayStateUsingGETRaw(requestParameters: GetExtendedGatewayStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedGatewayState>>;

    /**
     * getExtendedGatewayState
     * Gets the extended gateway state for a UUID.
     */
    getExtendedGatewayStateUsingGET(requestParameters: GetExtendedGatewayStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedGatewayState>;

    /**
     * getGatewayStatesForList
     * @summary Gets the gateway states for a list of gateway UUIDs.
     * @param {UUIDListRequest} uUIDListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    getGatewayStatesForListUsingPOSTRaw(requestParameters: GetGatewayStatesForListUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayStatesResponse>>;

    /**
     * getGatewayStatesForList
     * Gets the gateway states for a list of gateway UUIDs.
     */
    getGatewayStatesForListUsingPOST(requestParameters: GetGatewayStatesForListUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayStatesResponse>;

    /**
     * offBoardSmartModule
     * @summary Off-Boards a Smart module
     * @param {string} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    offBoardSmartModuleUsingPOSTRaw(requestParameters: OffBoardSmartModuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * offBoardSmartModule
     * Off-Boards a Smart module
     */
    offBoardSmartModuleUsingPOST(requestParameters: OffBoardSmartModuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * saveAppliance
     * @summary Saves an appliance.
     * @param {string} gatewayId 
     * @param {string} groupId 
     * @param {SaveApplianceRequest} saveApplianceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    saveApplianceUsingPOSTRaw(requestParameters: SaveApplianceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SaveApplianceResponse>>;

    /**
     * saveAppliance
     * Saves an appliance.
     */
    saveApplianceUsingPOST(requestParameters: SaveApplianceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SaveApplianceResponse>;

    /**
     * saveBinderAppliance
     * @summary Save binder appliance.
     * @param {string} gatewayId 
     * @param {BinderApplianceRequest} binderApplianceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    saveBinderApplianceUsingPOSTRaw(requestParameters: SaveBinderApplianceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisteredBinderAppliance>>;

    /**
     * saveBinderAppliance
     * Save binder appliance.
     */
    saveBinderApplianceUsingPOST(requestParameters: SaveBinderApplianceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisteredBinderAppliance>;

    /**
     * saveSmartModule
     * @summary Save SmartModule
     * @param {string} serialNumber 
     * @param {SmartModuleRequest} smartModuleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    saveSmartModuleUsingPOSTRaw(requestParameters: SaveSmartModuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleResponse>>;

    /**
     * saveSmartModule
     * Save SmartModule
     */
    saveSmartModuleUsingPOST(requestParameters: SaveSmartModuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleResponse>;

    /**
     * scanGatewayOld
     * @summary Gets the config for a given BSDB/gateway.
     * @param {string} gatewayId 
     * @param {boolean} startScan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    scanGatewayOldUsingGETRaw(requestParameters: ScanGatewayOldUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayConfigurationResponse>>;

    /**
     * scanGatewayOld
     * Gets the config for a given BSDB/gateway.
     */
    scanGatewayOldUsingGET(requestParameters: ScanGatewayOldUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayConfigurationResponse>;

    /**
     * scanGateway
     * @summary Start the scan for a given BSDB/gateway.
     * @param {string} gatewayId 
     * @param {boolean} [binderAppliance] 
     * @param {string} [ipAddress] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    scanGatewayUsingGETRaw(requestParameters: ScanGatewayUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>>;

    /**
     * scanGateway
     * Start the scan for a given BSDB/gateway.
     */
    scanGatewayUsingGET(requestParameters: ScanGatewayUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean>;

    /**
     * startSmartModuleOnboarding
     * @summary Provision a new smart module fridge for given serial number
     * @param {string} correlationId 
     * @param {string} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BsdbControllerApiInterface
     */
    startSmartModuleOnboardingUsingPOSTRaw(requestParameters: StartSmartModuleOnboardingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleLaunchResponse>>;

    /**
     * startSmartModuleOnboarding
     * Provision a new smart module fridge for given serial number
     */
    startSmartModuleOnboardingUsingPOST(requestParameters: StartSmartModuleOnboardingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleLaunchResponse>;

}

/**
 * 
 */
export class BsdbControllerApi extends runtime.BaseAPI implements BsdbControllerApiInterface {

    /**
     * checkBsdb
     * Checks a BSDB/gateway by serial number and passcode if given.
     */
    async checkBsdbUsingPUTRaw(requestParameters: CheckBsdbUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckGatewayResponse>> {
        if (requestParameters['checkBsdbRequest'] == null) {
            throw new runtime.RequiredError(
                'checkBsdbRequest',
                'Required parameter "checkBsdbRequest" was null or undefined when calling checkBsdbUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/bsdb/check`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CheckBsdbRequestToJSON(requestParameters['checkBsdbRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckGatewayResponseFromJSON(jsonValue));
    }

    /**
     * checkBsdb
     * Checks a BSDB/gateway by serial number and passcode if given.
     */
    async checkBsdbUsingPUT(requestParameters: CheckBsdbUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckGatewayResponse> {
        const response = await this.checkBsdbUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * checkFirmwareRestrictions
     * Checks whether the current gateway has restrictions in scan and onboarding.
     */
    async checkFirmwareRestrictionsUsingGETRaw(requestParameters: CheckFirmwareRestrictionsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FirmwareRestrictionsResponse>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling checkFirmwareRestrictionsUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/bsdb/scan/{gatewayId}/check`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirmwareRestrictionsResponseFromJSON(jsonValue));
    }

    /**
     * checkFirmwareRestrictions
     * Checks whether the current gateway has restrictions in scan and onboarding.
     */
    async checkFirmwareRestrictionsUsingGET(requestParameters: CheckFirmwareRestrictionsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FirmwareRestrictionsResponse> {
        const response = await this.checkFirmwareRestrictionsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * createBsdb
     * Creates necessary settings for given BSDB.
     */
    async createBsdbUsingPUTRaw(requestParameters: CreateBsdbUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateGatewayResponse>> {
        if (requestParameters['createBsdbRequest'] == null) {
            throw new runtime.RequiredError(
                'createBsdbRequest',
                'Required parameter "createBsdbRequest" was null or undefined when calling createBsdbUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/bsdb/create`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBsdbRequestToJSON(requestParameters['createBsdbRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateGatewayResponseFromJSON(jsonValue));
    }

    /**
     * createBsdb
     * Creates necessary settings for given BSDB.
     */
    async createBsdbUsingPUT(requestParameters: CreateBsdbUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateGatewayResponse> {
        const response = await this.createBsdbUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteAppliance
     * Deletes an appliance
     */
    async deleteApplianceUsingDELETERaw(requestParameters: DeleteApplianceUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling deleteApplianceUsingDELETE().'
            );
        }

        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling deleteApplianceUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/bsdb/appliance/{gatewayId}/{virtualFridgeId}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))).replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteAppliance
     * Deletes an appliance
     */
    async deleteApplianceUsingDELETE(requestParameters: DeleteApplianceUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteApplianceUsingDELETERaw(requestParameters, initOverrides);
    }

    /**
     * deleteBinderAppliance
     * Deletes an appliance
     */
    async deleteBinderApplianceUsingDELETERaw(requestParameters: DeleteBinderApplianceUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HttpStatusCode>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling deleteBinderApplianceUsingDELETE().'
            );
        }

        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling deleteBinderApplianceUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/bsdb/binder/{gatewayId}/{virtualFridgeId}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))).replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HttpStatusCodeFromJSON(jsonValue));
    }

    /**
     * deleteBinderAppliance
     * Deletes an appliance
     */
    async deleteBinderApplianceUsingDELETE(requestParameters: DeleteBinderApplianceUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HttpStatusCode> {
        const response = await this.deleteBinderApplianceUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteGateway
     * Deletes an appliance
     */
    async deleteGatewayUsingDELETERaw(requestParameters: DeleteGatewayUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling deleteGatewayUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/gateway/{gatewayId}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteGateway
     * Deletes an appliance
     */
    async deleteGatewayUsingDELETE(requestParameters: DeleteGatewayUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteGatewayUsingDELETERaw(requestParameters, initOverrides);
    }

    /**
     * deleteVirtualMeasureSite
     * Deletes a virtual measure site. Not applicable for connectables.
     */
    async deleteVirtualMeasureSiteUsingDELETERaw(requestParameters: DeleteVirtualMeasureSiteUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling deleteVirtualMeasureSiteUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/bsdb/measuresite/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteVirtualMeasureSite
     * Deletes a virtual measure site. Not applicable for connectables.
     */
    async deleteVirtualMeasureSiteUsingDELETE(requestParameters: DeleteVirtualMeasureSiteUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteVirtualMeasureSiteUsingDELETERaw(requestParameters, initOverrides);
    }

    /**
     * getBsdbConfig
     * Gets the config for a given BSDB/gateway.
     */
    async getBsdbConfigUsingGETRaw(requestParameters: GetBsdbConfigUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayConfigurationResponse>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling getBsdbConfigUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/bsdb/config/{gatewayId}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GatewayConfigurationResponseFromJSON(jsonValue));
    }

    /**
     * getBsdbConfig
     * Gets the config for a given BSDB/gateway.
     */
    async getBsdbConfigUsingGET(requestParameters: GetBsdbConfigUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayConfigurationResponse> {
        const response = await this.getBsdbConfigUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getExtendedGatewayState
     * Gets the extended gateway state for a UUID.
     */
    async getExtendedGatewayStateUsingGETRaw(requestParameters: GetExtendedGatewayStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedGatewayState>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling getExtendedGatewayStateUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/gatewaystate/extended/{gatewayId}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedGatewayStateFromJSON(jsonValue));
    }

    /**
     * getExtendedGatewayState
     * Gets the extended gateway state for a UUID.
     */
    async getExtendedGatewayStateUsingGET(requestParameters: GetExtendedGatewayStateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedGatewayState> {
        const response = await this.getExtendedGatewayStateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getGatewayStatesForList
     * Gets the gateway states for a list of gateway UUIDs.
     */
    async getGatewayStatesForListUsingPOSTRaw(requestParameters: GetGatewayStatesForListUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayStatesResponse>> {
        if (requestParameters['uUIDListRequest'] == null) {
            throw new runtime.RequiredError(
                'uUIDListRequest',
                'Required parameter "uUIDListRequest" was null or undefined when calling getGatewayStatesForListUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/gatewaystates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UUIDListRequestToJSON(requestParameters['uUIDListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GatewayStatesResponseFromJSON(jsonValue));
    }

    /**
     * getGatewayStatesForList
     * Gets the gateway states for a list of gateway UUIDs.
     */
    async getGatewayStatesForListUsingPOST(requestParameters: GetGatewayStatesForListUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayStatesResponse> {
        const response = await this.getGatewayStatesForListUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * offBoardSmartModule
     * Off-Boards a Smart module
     */
    async offBoardSmartModuleUsingPOSTRaw(requestParameters: OffBoardSmartModuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling offBoardSmartModuleUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/smartmodule/fridge/offboard/{serialNumber}`.replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * offBoardSmartModule
     * Off-Boards a Smart module
     */
    async offBoardSmartModuleUsingPOST(requestParameters: OffBoardSmartModuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.offBoardSmartModuleUsingPOSTRaw(requestParameters, initOverrides);
    }

    /**
     * saveAppliance
     * Saves an appliance.
     */
    async saveApplianceUsingPOSTRaw(requestParameters: SaveApplianceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SaveApplianceResponse>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling saveApplianceUsingPOST().'
            );
        }

        if (requestParameters['groupId'] == null) {
            throw new runtime.RequiredError(
                'groupId',
                'Required parameter "groupId" was null or undefined when calling saveApplianceUsingPOST().'
            );
        }

        if (requestParameters['saveApplianceRequest'] == null) {
            throw new runtime.RequiredError(
                'saveApplianceRequest',
                'Required parameter "saveApplianceRequest" was null or undefined when calling saveApplianceUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/bsdb/appliance/{gatewayId}/{groupId}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))).replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters['groupId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveApplianceRequestToJSON(requestParameters['saveApplianceRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaveApplianceResponseFromJSON(jsonValue));
    }

    /**
     * saveAppliance
     * Saves an appliance.
     */
    async saveApplianceUsingPOST(requestParameters: SaveApplianceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SaveApplianceResponse> {
        const response = await this.saveApplianceUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveBinderAppliance
     * Save binder appliance.
     */
    async saveBinderApplianceUsingPOSTRaw(requestParameters: SaveBinderApplianceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisteredBinderAppliance>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling saveBinderApplianceUsingPOST().'
            );
        }

        if (requestParameters['binderApplianceRequest'] == null) {
            throw new runtime.RequiredError(
                'binderApplianceRequest',
                'Required parameter "binderApplianceRequest" was null or undefined when calling saveBinderApplianceUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/bsdb/binderappliance/{gatewayId}/binder`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BinderApplianceRequestToJSON(requestParameters['binderApplianceRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisteredBinderApplianceFromJSON(jsonValue));
    }

    /**
     * saveBinderAppliance
     * Save binder appliance.
     */
    async saveBinderApplianceUsingPOST(requestParameters: SaveBinderApplianceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisteredBinderAppliance> {
        const response = await this.saveBinderApplianceUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveSmartModule
     * Save SmartModule
     */
    async saveSmartModuleUsingPOSTRaw(requestParameters: SaveSmartModuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleResponse>> {
        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling saveSmartModuleUsingPOST().'
            );
        }

        if (requestParameters['smartModuleRequest'] == null) {
            throw new runtime.RequiredError(
                'smartModuleRequest',
                'Required parameter "smartModuleRequest" was null or undefined when calling saveSmartModuleUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/smartmodule/fridge/{serialNumber}`.replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SmartModuleRequestToJSON(requestParameters['smartModuleRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmartModuleResponseFromJSON(jsonValue));
    }

    /**
     * saveSmartModule
     * Save SmartModule
     */
    async saveSmartModuleUsingPOST(requestParameters: SaveSmartModuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleResponse> {
        const response = await this.saveSmartModuleUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * scanGatewayOld
     * Gets the config for a given BSDB/gateway.
     */
    async scanGatewayOldUsingGETRaw(requestParameters: ScanGatewayOldUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GatewayConfigurationResponse>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling scanGatewayOldUsingGET().'
            );
        }

        if (requestParameters['startScan'] == null) {
            throw new runtime.RequiredError(
                'startScan',
                'Required parameter "startScan" was null or undefined when calling scanGatewayOldUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/bsdb/scanold/{gatewayId}/{startScan}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))).replace(`{${"startScan"}}`, encodeURIComponent(String(requestParameters['startScan']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GatewayConfigurationResponseFromJSON(jsonValue));
    }

    /**
     * scanGatewayOld
     * Gets the config for a given BSDB/gateway.
     */
    async scanGatewayOldUsingGET(requestParameters: ScanGatewayOldUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GatewayConfigurationResponse> {
        const response = await this.scanGatewayOldUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * scanGateway
     * Start the scan for a given BSDB/gateway.
     */
    async scanGatewayUsingGETRaw(requestParameters: ScanGatewayUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['gatewayId'] == null) {
            throw new runtime.RequiredError(
                'gatewayId',
                'Required parameter "gatewayId" was null or undefined when calling scanGatewayUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['binderAppliance'] != null) {
            queryParameters['binderAppliance'] = requestParameters['binderAppliance'];
        }

        if (requestParameters['ipAddress'] != null) {
            queryParameters['ipAddress'] = requestParameters['ipAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/bsdb/scan/{gatewayId}`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters['gatewayId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * scanGateway
     * Start the scan for a given BSDB/gateway.
     */
    async scanGatewayUsingGET(requestParameters: ScanGatewayUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.scanGatewayUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * startSmartModuleOnboarding
     * Provision a new smart module fridge for given serial number
     */
    async startSmartModuleOnboardingUsingPOSTRaw(requestParameters: StartSmartModuleOnboardingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SmartModuleLaunchResponse>> {
        if (requestParameters['correlationId'] == null) {
            throw new runtime.RequiredError(
                'correlationId',
                'Required parameter "correlationId" was null or undefined when calling startSmartModuleOnboardingUsingPOST().'
            );
        }

        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling startSmartModuleOnboardingUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/smartmodule/fridge/{correlationId}/{serialNumber}`.replace(`{${"correlationId"}}`, encodeURIComponent(String(requestParameters['correlationId']))).replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SmartModuleLaunchResponseFromJSON(jsonValue));
    }

    /**
     * startSmartModuleOnboarding
     * Provision a new smart module fridge for given serial number
     */
    async startSmartModuleOnboardingUsingPOST(requestParameters: StartSmartModuleOnboardingUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SmartModuleLaunchResponse> {
        const response = await this.startSmartModuleOnboardingUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
