/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CheckBsdbRequest
 */
export interface CheckBsdbRequest {
  /**
   *
   * @type {string}
   * @memberof CheckBsdbRequest
   */
  passCode?: string;
  /**
   *
   * @type {string}
   * @memberof CheckBsdbRequest
   */
  serialNumber?: string;
}

/**
 * Check if a given object implements the CheckBsdbRequest interface.
 */
export function instanceOfCheckBsdbRequest(value: object): value is CheckBsdbRequest {
  return true;
}

export function CheckBsdbRequestFromJSON(json: any): CheckBsdbRequest {
  return CheckBsdbRequestFromJSONTyped(json, false);
}

export function CheckBsdbRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckBsdbRequest {
  if (json == null) {
    return json;
  }
  return {
    passCode: json['passCode'] == null ? undefined : json['passCode'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber']
  };
}

export function CheckBsdbRequestToJSON(json: any): CheckBsdbRequest {
  return CheckBsdbRequestToJSONTyped(json, false);
}

export function CheckBsdbRequestToJSONTyped(
  value?: CheckBsdbRequest | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    passCode: value['passCode'],
    serialNumber: value['serialNumber']
  };
}
