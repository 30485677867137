/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MeasureSiteSiteInformation } from './MeasureSiteSiteInformation';
import {
    MeasureSiteSiteInformationFromJSON,
    MeasureSiteSiteInformationFromJSONTyped,
    MeasureSiteSiteInformationToJSON,
    MeasureSiteSiteInformationToJSONTyped,
} from './MeasureSiteSiteInformation';

/**
 * 
 * @export
 * @interface BinderApplianceRequest
 */
export interface BinderApplianceRequest {
    /**
     * 
     * @type {string}
     * @memberof BinderApplianceRequest
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof BinderApplianceRequest
     */
    inventoryNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BinderApplianceRequest
     */
    ipAddress: string;
    /**
     * 
     * @type {string}
     * @memberof BinderApplianceRequest
     */
    macAddress: string;
    /**
     * 
     * @type {string}
     * @memberof BinderApplianceRequest
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof BinderApplianceRequest
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof BinderApplianceRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BinderApplianceRequest
     */
    serialNumber: string;
    /**
     * 
     * @type {MeasureSiteSiteInformation}
     * @memberof BinderApplianceRequest
     */
    siteInformation?: MeasureSiteSiteInformation;
}

/**
 * Check if a given object implements the BinderApplianceRequest interface.
 */
export function instanceOfBinderApplianceRequest(value: object): value is BinderApplianceRequest {
    if (!('ipAddress' in value) || value['ipAddress'] === undefined) return false;
    if (!('macAddress' in value) || value['macAddress'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('serialNumber' in value) || value['serialNumber'] === undefined) return false;
    return true;
}

export function BinderApplianceRequestFromJSON(json: any): BinderApplianceRequest {
    return BinderApplianceRequestFromJSONTyped(json, false);
}

export function BinderApplianceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BinderApplianceRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'] == null ? undefined : json['comment'],
        'inventoryNumber': json['inventoryNumber'] == null ? undefined : json['inventoryNumber'],
        'ipAddress': json['ipAddress'],
        'macAddress': json['macAddress'],
        'manufacturer': json['manufacturer'] == null ? undefined : json['manufacturer'],
        'model': json['model'] == null ? undefined : json['model'],
        'name': json['name'],
        'serialNumber': json['serialNumber'],
        'siteInformation': json['siteInformation'] == null ? undefined : MeasureSiteSiteInformationFromJSON(json['siteInformation']),
    };
}

export function BinderApplianceRequestToJSON(json: any): BinderApplianceRequest {
    return BinderApplianceRequestToJSONTyped(json, false);
}

export function BinderApplianceRequestToJSONTyped(value?: BinderApplianceRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'comment': value['comment'],
        'inventoryNumber': value['inventoryNumber'],
        'ipAddress': value['ipAddress'],
        'macAddress': value['macAddress'],
        'manufacturer': value['manufacturer'],
        'model': value['model'],
        'name': value['name'],
        'serialNumber': value['serialNumber'],
        'siteInformation': MeasureSiteSiteInformationToJSON(value['siteInformation']),
    };
}

