/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ServiceEmailRequest
 */
export interface ServiceEmailRequest {
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  backendVersion?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  customerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  customerName?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  customerTelephone?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  errorMessage?: string;
  /**
   *
   * @type {Date}
   * @memberof ServiceEmailRequest
   */
  errorTime?: Date;
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  frontendVersion?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  gatewayId?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  recipientCountryCode?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  requestType?: ServiceEmailRequestRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  virtualFridgeId?: string;
}

/**
 * @export
 */
export const ServiceEmailRequestRequestTypeEnum = {
  TECHNICAL_SUPPORT: 'TECHNICAL_SUPPORT',
  SPARE_PARTS: 'SPARE_PARTS',
  PRODUCT_INFORMATION: 'PRODUCT_INFORMATION',
  DIAGNOSIS: 'DIAGNOSIS',
  OTHER: 'OTHER'
} as const;
export type ServiceEmailRequestRequestTypeEnum = typeof ServiceEmailRequestRequestTypeEnum[keyof typeof ServiceEmailRequestRequestTypeEnum];

/**
 * Check if a given object implements the ServiceEmailRequest interface.
 */
export function instanceOfServiceEmailRequest(value: object): value is ServiceEmailRequest {
  return true;
}

export function ServiceEmailRequestFromJSON(json: any): ServiceEmailRequest {
  return ServiceEmailRequestFromJSONTyped(json, false);
}

export function ServiceEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceEmailRequest {
  if (json == null) {
    return json;
  }
  return {
    backendVersion: json['backendVersion'] == null ? undefined : json['backendVersion'],
    customerEmail: json['customerEmail'] == null ? undefined : json['customerEmail'],
    customerName: json['customerName'] == null ? undefined : json['customerName'],
    customerTelephone: json['customerTelephone'] == null ? undefined : json['customerTelephone'],
    errorMessage: json['errorMessage'] == null ? undefined : json['errorMessage'],
    errorTime: json['errorTime'] == null ? undefined : new Date(json['errorTime']),
    frontendVersion: json['frontendVersion'] == null ? undefined : json['frontendVersion'],
    gatewayId: json['gatewayId'] == null ? undefined : json['gatewayId'],
    message: json['message'] == null ? undefined : json['message'],
    recipientCountryCode: json['recipientCountryCode'] == null ? undefined : json['recipientCountryCode'],
    requestType: json['requestType'] == null ? undefined : json['requestType'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function ServiceEmailRequestToJSON(json: any): ServiceEmailRequest {
  return ServiceEmailRequestToJSONTyped(json, false);
}

export function ServiceEmailRequestToJSONTyped(
  value?: ServiceEmailRequest | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    backendVersion: value['backendVersion'],
    customerEmail: value['customerEmail'],
    customerName: value['customerName'],
    customerTelephone: value['customerTelephone'],
    errorMessage: value['errorMessage'],
    errorTime: value['errorTime'] == null ? undefined : value['errorTime'].toISOString(),
    frontendVersion: value['frontendVersion'],
    gatewayId: value['gatewayId'],
    message: value['message'],
    recipientCountryCode: value['recipientCountryCode'],
    requestType: value['requestType'],
    serialNumber: value['serialNumber'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
