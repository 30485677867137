/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CheckGatewayResponse
 */
export interface CheckGatewayResponse {
  /**
   *
   * @type {string}
   * @memberof CheckGatewayResponse
   */
  configurationState?: CheckGatewayResponseConfigurationStateEnum;
  /**
   *
   * @type {string}
   * @memberof CheckGatewayResponse
   */
  gatewayId?: string;
}

/**
 * @export
 */
export const CheckGatewayResponseConfigurationStateEnum = {
  UNKNOWN: 'UNKNOWN',
  PASSCODE_WRONG: 'PASSCODE_WRONG',
  AVAILABLE: 'AVAILABLE',
  CONFIGURED: 'CONFIGURED'
} as const;
export type CheckGatewayResponseConfigurationStateEnum = typeof CheckGatewayResponseConfigurationStateEnum[keyof typeof CheckGatewayResponseConfigurationStateEnum];

/**
 * Check if a given object implements the CheckGatewayResponse interface.
 */
export function instanceOfCheckGatewayResponse(value: object): value is CheckGatewayResponse {
  return true;
}

export function CheckGatewayResponseFromJSON(json: any): CheckGatewayResponse {
  return CheckGatewayResponseFromJSONTyped(json, false);
}

export function CheckGatewayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckGatewayResponse {
  if (json == null) {
    return json;
  }
  return {
    configurationState: json['configurationState'] == null ? undefined : json['configurationState'],
    gatewayId: json['gatewayId'] == null ? undefined : json['gatewayId']
  };
}

export function CheckGatewayResponseToJSON(json: any): CheckGatewayResponse {
  return CheckGatewayResponseToJSONTyped(json, false);
}

export function CheckGatewayResponseToJSONTyped(
  value?: CheckGatewayResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    configurationState: value['configurationState'],
    gatewayId: value['gatewayId']
  };
}
