/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateProductSimulationRequest
 */
export interface UpdateProductSimulationRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateProductSimulationRequest
   */
  productSimulationCoefficient?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateProductSimulationRequest
   */
  thingId?: string;
}

/**
 * Check if a given object implements the UpdateProductSimulationRequest interface.
 */
export function instanceOfUpdateProductSimulationRequest(value: object): value is UpdateProductSimulationRequest {
  return true;
}

export function UpdateProductSimulationRequestFromJSON(json: any): UpdateProductSimulationRequest {
  return UpdateProductSimulationRequestFromJSONTyped(json, false);
}

export function UpdateProductSimulationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateProductSimulationRequest {
  if (json == null) {
    return json;
  }
  return {
    productSimulationCoefficient:
      json['productSimulationCoefficient'] == null ? undefined : json['productSimulationCoefficient'],
    thingId: json['thingId'] == null ? undefined : json['thingId']
  };
}

export function UpdateProductSimulationRequestToJSON(json: any): UpdateProductSimulationRequest {
  return UpdateProductSimulationRequestToJSONTyped(json, false);
}

export function UpdateProductSimulationRequestToJSONTyped(
  value?: UpdateProductSimulationRequest | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    productSimulationCoefficient: value['productSimulationCoefficient'],
    thingId: value['thingId']
  };
}
