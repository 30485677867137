/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface NotificationSetting
 */
export interface NotificationSetting {
  /**
   *
   * @type {boolean}
   * @memberof NotificationSetting
   */
  mail?: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationSetting
   */
  notificationType?: NotificationSettingNotificationTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof NotificationSetting
   */
  sms?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotificationSetting
   */
  voiceCall?: boolean;
}

/**
 * @export
 */
export const NotificationSettingNotificationTypeEnum = {
  TEMPERATURE_ALARM: 'TEMPERATURE_ALARM',
  CLOUD_ALARM: 'CLOUD_ALARM',
  DOOR_ALARM: 'DOOR_ALARM',
  UNAVAILABILITY_ALARM: 'UNAVAILABILITY_ALARM',
  DEVICE_ALARM: 'DEVICE_ALARM'
} as const;
export type NotificationSettingNotificationTypeEnum = typeof NotificationSettingNotificationTypeEnum[keyof typeof NotificationSettingNotificationTypeEnum];

/**
 * Check if a given object implements the NotificationSetting interface.
 */
export function instanceOfNotificationSetting(value: object): value is NotificationSetting {
  return true;
}

export function NotificationSettingFromJSON(json: any): NotificationSetting {
  return NotificationSettingFromJSONTyped(json, false);
}

export function NotificationSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSetting {
  if (json == null) {
    return json;
  }
  return {
    mail: json['mail'] == null ? undefined : json['mail'],
    notificationType: json['notificationType'] == null ? undefined : json['notificationType'],
    sms: json['sms'] == null ? undefined : json['sms'],
    voiceCall: json['voiceCall'] == null ? undefined : json['voiceCall']
  };
}

export function NotificationSettingToJSON(json: any): NotificationSetting {
  return NotificationSettingToJSONTyped(json, false);
}

export function NotificationSettingToJSONTyped(
  value?: NotificationSetting | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    mail: value['mail'],
    notificationType: value['notificationType'],
    sms: value['sms'],
    voiceCall: value['voiceCall']
  };
}
