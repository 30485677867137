/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SmartModuleSystemSettingsRequest } from './SmartModuleSystemSettingsRequest';
import {
    SmartModuleSystemSettingsRequestFromJSON,
    SmartModuleSystemSettingsRequestFromJSONTyped,
    SmartModuleSystemSettingsRequestToJSON,
    SmartModuleSystemSettingsRequestToJSONTyped,
} from './SmartModuleSystemSettingsRequest';
import type { SmartModuleZoneSettingsRequest } from './SmartModuleZoneSettingsRequest';
import {
    SmartModuleZoneSettingsRequestFromJSON,
    SmartModuleZoneSettingsRequestFromJSONTyped,
    SmartModuleZoneSettingsRequestToJSON,
    SmartModuleZoneSettingsRequestToJSONTyped,
} from './SmartModuleZoneSettingsRequest';
import type { ReminderSetting } from './ReminderSetting';
import {
    ReminderSettingFromJSON,
    ReminderSettingFromJSONTyped,
    ReminderSettingToJSON,
    ReminderSettingToJSONTyped,
} from './ReminderSetting';

/**
 * 
 * @export
 * @interface SmartModuleAlarmSettingsRequest
 */
export interface SmartModuleAlarmSettingsRequest {
    /**
     * 
     * @type {ReminderSetting}
     * @memberof SmartModuleAlarmSettingsRequest
     */
    reminderSetting?: ReminderSetting;
    /**
     * 
     * @type {Array<SmartModuleZoneSettingsRequest>}
     * @memberof SmartModuleAlarmSettingsRequest
     */
    smartModuleZoneSettingsRequests?: Array<SmartModuleZoneSettingsRequest>;
    /**
     * 
     * @type {SmartModuleSystemSettingsRequest}
     * @memberof SmartModuleAlarmSettingsRequest
     */
    systemSettingsRequest?: SmartModuleSystemSettingsRequest;
}

/**
 * Check if a given object implements the SmartModuleAlarmSettingsRequest interface.
 */
export function instanceOfSmartModuleAlarmSettingsRequest(value: object): value is SmartModuleAlarmSettingsRequest {
    return true;
}

export function SmartModuleAlarmSettingsRequestFromJSON(json: any): SmartModuleAlarmSettingsRequest {
    return SmartModuleAlarmSettingsRequestFromJSONTyped(json, false);
}

export function SmartModuleAlarmSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmartModuleAlarmSettingsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'reminderSetting': json['reminderSetting'] == null ? undefined : ReminderSettingFromJSON(json['reminderSetting']),
        'smartModuleZoneSettingsRequests': json['smartModuleZoneSettingsRequests'] == null ? undefined : ((json['smartModuleZoneSettingsRequests'] as Array<any>).map(SmartModuleZoneSettingsRequestFromJSON)),
        'systemSettingsRequest': json['systemSettingsRequest'] == null ? undefined : SmartModuleSystemSettingsRequestFromJSON(json['systemSettingsRequest']),
    };
}

export function SmartModuleAlarmSettingsRequestToJSON(json: any): SmartModuleAlarmSettingsRequest {
    return SmartModuleAlarmSettingsRequestToJSONTyped(json, false);
}

export function SmartModuleAlarmSettingsRequestToJSONTyped(value?: SmartModuleAlarmSettingsRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'reminderSetting': ReminderSettingToJSON(value['reminderSetting']),
        'smartModuleZoneSettingsRequests': value['smartModuleZoneSettingsRequests'] == null ? undefined : ((value['smartModuleZoneSettingsRequests'] as Array<any>).map(SmartModuleZoneSettingsRequestToJSON)),
        'systemSettingsRequest': SmartModuleSystemSettingsRequestToJSON(value['systemSettingsRequest']),
    };
}

