/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AlarmProfileListItem
 */
export interface AlarmProfileListItem {
  /**
   *
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  alarmProfileId?: string;
  /**
   *
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  alarmProfileType?: AlarmProfileListItemAlarmProfileTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  cloudFridgeType?: AlarmProfileListItemCloudFridgeTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  gatewayId?: string;
  /**
   *
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  thingId?: string;
  /**
   *
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  virtualFridgeId?: string;
}

/**
 * @export
 */
export const AlarmProfileListItemAlarmProfileTypeEnum = {
  GATEWAYPROFILE: 'GATEWAYPROFILE',
  CLOUDPROFILE: 'CLOUDPROFILE',
  SMARTMODULEPROFILE: 'SMARTMODULEPROFILE'
} as const;
export type AlarmProfileListItemAlarmProfileTypeEnum = typeof AlarmProfileListItemAlarmProfileTypeEnum[keyof typeof AlarmProfileListItemAlarmProfileTypeEnum];

/**
 * @export
 */
export const AlarmProfileListItemCloudFridgeTypeEnum = {
  MODBUS_FRIDGE: 'MODBUS_FRIDGE',
  BINDER_FRIDGE: 'BINDER_FRIDGE',
  SMART_MODULE_FRIDGE: 'SMART_MODULE_FRIDGE',
  LOGGER: 'LOGGER'
} as const;
export type AlarmProfileListItemCloudFridgeTypeEnum = typeof AlarmProfileListItemCloudFridgeTypeEnum[keyof typeof AlarmProfileListItemCloudFridgeTypeEnum];

/**
 * Check if a given object implements the AlarmProfileListItem interface.
 */
export function instanceOfAlarmProfileListItem(value: object): value is AlarmProfileListItem {
  return true;
}

export function AlarmProfileListItemFromJSON(json: any): AlarmProfileListItem {
  return AlarmProfileListItemFromJSONTyped(json, false);
}

export function AlarmProfileListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlarmProfileListItem {
  if (json == null) {
    return json;
  }
  return {
    alarmProfileId: json['alarmProfileId'] == null ? undefined : json['alarmProfileId'],
    alarmProfileType: json['alarmProfileType'] == null ? undefined : json['alarmProfileType'],
    cloudFridgeType: json['cloudFridgeType'] == null ? undefined : json['cloudFridgeType'],
    gatewayId: json['gatewayId'] == null ? undefined : json['gatewayId'],
    model: json['model'] == null ? undefined : json['model'],
    name: json['name'] == null ? undefined : json['name'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    thingId: json['thingId'] == null ? undefined : json['thingId'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function AlarmProfileListItemToJSON(json: any): AlarmProfileListItem {
  return AlarmProfileListItemToJSONTyped(json, false);
}

export function AlarmProfileListItemToJSONTyped(
  value?: AlarmProfileListItem | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    alarmProfileId: value['alarmProfileId'],
    alarmProfileType: value['alarmProfileType'],
    cloudFridgeType: value['cloudFridgeType'],
    gatewayId: value['gatewayId'],
    model: value['model'],
    name: value['name'],
    serialNumber: value['serialNumber'],
    thingId: value['thingId'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
