/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VersionInformation
 */
export interface VersionInformation {
  /**
   *
   * @type {string}
   * @memberof VersionInformation
   */
  backendVersion?: string;
  /**
   *
   * @type {string}
   * @memberof VersionInformation
   */
  frontendVersion?: string;
}

/**
 * Check if a given object implements the VersionInformation interface.
 */
export function instanceOfVersionInformation(value: object): value is VersionInformation {
  return true;
}

export function VersionInformationFromJSON(json: any): VersionInformation {
  return VersionInformationFromJSONTyped(json, false);
}

export function VersionInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionInformation {
  if (json == null) {
    return json;
  }
  return {
    backendVersion: json['backendVersion'] == null ? undefined : json['backendVersion'],
    frontendVersion: json['frontendVersion'] == null ? undefined : json['frontendVersion']
  };
}

export function VersionInformationToJSON(json: any): VersionInformation {
  return VersionInformationToJSONTyped(json, false);
}

export function VersionInformationToJSONTyped(
  value?: VersionInformation | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    backendVersion: value['backendVersion'],
    frontendVersion: value['frontendVersion']
  };
}
