/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AlarmCommentAddRequest
 */
export interface AlarmCommentAddRequest {
  /**
   *
   * @type {string}
   * @memberof AlarmCommentAddRequest
   */
  alarmId: string;
  /**
   *
   * @type {string}
   * @memberof AlarmCommentAddRequest
   */
  alarmType: string;
  /**
   *
   * @type {string}
   * @memberof AlarmCommentAddRequest
   */
  comment: string;
  /**
   *
   * @type {string}
   * @memberof AlarmCommentAddRequest
   */
  commentType: string;
  /**
   *
   * @type {string}
   * @memberof AlarmCommentAddRequest
   */
  commentator: string;
  /**
   *
   * @type {string}
   * @memberof AlarmCommentAddRequest
   */
  reason: string;
  /**
   *
   * @type {string}
   * @memberof AlarmCommentAddRequest
   */
  source: string;
}

/**
 * Check if a given object implements the AlarmCommentAddRequest interface.
 */
export function instanceOfAlarmCommentAddRequest(value: object): value is AlarmCommentAddRequest {
  if (!('alarmId' in value) || value['alarmId'] === undefined) return false;
  if (!('alarmType' in value) || value['alarmType'] === undefined) return false;
  if (!('comment' in value) || value['comment'] === undefined) return false;
  if (!('commentType' in value) || value['commentType'] === undefined) return false;
  if (!('commentator' in value) || value['commentator'] === undefined) return false;
  if (!('reason' in value) || value['reason'] === undefined) return false;
  if (!('source' in value) || value['source'] === undefined) return false;
  return true;
}

export function AlarmCommentAddRequestFromJSON(json: any): AlarmCommentAddRequest {
  return AlarmCommentAddRequestFromJSONTyped(json, false);
}

export function AlarmCommentAddRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlarmCommentAddRequest {
  if (json == null) {
    return json;
  }
  return {
    alarmId: json['alarmId'],
    alarmType: json['alarmType'],
    comment: json['comment'],
    commentType: json['commentType'],
    commentator: json['commentator'],
    reason: json['reason'],
    source: json['source']
  };
}

export function AlarmCommentAddRequestToJSON(json: any): AlarmCommentAddRequest {
  return AlarmCommentAddRequestToJSONTyped(json, false);
}

export function AlarmCommentAddRequestToJSONTyped(
  value?: AlarmCommentAddRequest | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    alarmId: value['alarmId'],
    alarmType: value['alarmType'],
    comment: value['comment'],
    commentType: value['commentType'],
    commentator: value['commentator'],
    reason: value['reason'],
    source: value['source']
  };
}
