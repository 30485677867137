/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ConnectivityResponse
 */
export interface ConnectivityResponse {
  /**
   *
   * @type {string}
   * @memberof ConnectivityResponse
   */
  applianceType?: ConnectivityResponseApplianceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ConnectivityResponse
   */
  articleNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof ConnectivityResponse
   */
  connectivity?: boolean;
  /**
   *
   * @type {string}
   * @memberof ConnectivityResponse
   */
  networkConnectivity?: ConnectivityResponseNetworkConnectivityEnum;
  /**
   *
   * @type {string}
   * @memberof ConnectivityResponse
   */
  networkInterface?: ConnectivityResponseNetworkInterfaceEnum;
  /**
   *
   * @type {string}
   * @memberof ConnectivityResponse
   */
  networkingSolution?: ConnectivityResponseNetworkingSolutionEnum;
}

/**
 * @export
 */
export const ConnectivityResponseApplianceTypeEnum = {
  MODBUS_FRIDGE: 'MODBUS_FRIDGE',
  BINDER_FRIDGE: 'BINDER_FRIDGE',
  SMART_MODULE_FRIDGE: 'SMART_MODULE_FRIDGE',
  LCV_FRIDGE: 'LCV_FRIDGE',
  LOGGER: 'LOGGER'
} as const;
export type ConnectivityResponseApplianceTypeEnum = typeof ConnectivityResponseApplianceTypeEnum[keyof typeof ConnectivityResponseApplianceTypeEnum];

/**
 * @export
 */
export const ConnectivityResponseNetworkConnectivityEnum = {
  EMPTY: 'EMPTY',
  SMART_DEVICE_BOX: 'SMART_DEVICE_BOX',
  SMART_COOLING_HUB: 'SMART_COOLING_HUB',
  UNDEFINED: 'UNDEFINED',
  SMART_MODULE: 'SMART_MODULE'
} as const;
export type ConnectivityResponseNetworkConnectivityEnum = typeof ConnectivityResponseNetworkConnectivityEnum[keyof typeof ConnectivityResponseNetworkConnectivityEnum];

/**
 * @export
 */
export const ConnectivityResponseNetworkInterfaceEnum = {
  EMPTY: 'EMPTY',
  UNDEFINED: 'UNDEFINED',
  RS_485: 'RS_485',
  CAN_BUS: 'CAN_BUS',
  RS_485_OPTIONAL: 'RS_485_OPTIONAL',
  LAN: 'LAN',
  MODBUS: 'MODBUS',
  WIFI_LAN: 'WIFI_LAN',
  WIFI_LAN_OPTIONAL: 'WIFI_LAN_OPTIONAL',
  RJ45: 'RJ45',
  GSM_WI_FI: 'GSM_WiFi'
} as const;
export type ConnectivityResponseNetworkInterfaceEnum = typeof ConnectivityResponseNetworkInterfaceEnum[keyof typeof ConnectivityResponseNetworkInterfaceEnum];

/**
 * @export
 */
export const ConnectivityResponseNetworkingSolutionEnum = {
  EMPTY: 'EMPTY',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  RETROFITTABLE: 'RETROFITTABLE',
  INTEGRATED_BUILT_IN: 'INTEGRATED_BUILT_IN',
  INTEGRATED_REMOVABLE: 'INTEGRATED_REMOVABLE'
} as const;
export type ConnectivityResponseNetworkingSolutionEnum = typeof ConnectivityResponseNetworkingSolutionEnum[keyof typeof ConnectivityResponseNetworkingSolutionEnum];

/**
 * Check if a given object implements the ConnectivityResponse interface.
 */
export function instanceOfConnectivityResponse(value: object): value is ConnectivityResponse {
  return true;
}

export function ConnectivityResponseFromJSON(json: any): ConnectivityResponse {
  return ConnectivityResponseFromJSONTyped(json, false);
}

export function ConnectivityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectivityResponse {
  if (json == null) {
    return json;
  }
  return {
    applianceType: json['applianceType'] == null ? undefined : json['applianceType'],
    articleNumber: json['articleNumber'] == null ? undefined : json['articleNumber'],
    connectivity: json['connectivity'] == null ? undefined : json['connectivity'],
    networkConnectivity: json['networkConnectivity'] == null ? undefined : json['networkConnectivity'],
    networkInterface: json['networkInterface'] == null ? undefined : json['networkInterface'],
    networkingSolution: json['networkingSolution'] == null ? undefined : json['networkingSolution']
  };
}

export function ConnectivityResponseToJSON(json: any): ConnectivityResponse {
  return ConnectivityResponseToJSONTyped(json, false);
}

export function ConnectivityResponseToJSONTyped(
  value?: ConnectivityResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    applianceType: value['applianceType'],
    articleNumber: value['articleNumber'],
    connectivity: value['connectivity'],
    networkConnectivity: value['networkConnectivity'],
    networkInterface: value['networkInterface'],
    networkingSolution: value['networkingSolution']
  };
}
