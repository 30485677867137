/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationImageResponse
 */
export interface OrganizationImageResponse {
  /**
   *
   * @type {string}
   * @memberof OrganizationImageResponse
   */
  fileExtension?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationImageResponse
   */
  fileFormat?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationImageResponse
   */
  imageBase64Encoded?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationImageResponse
   */
  uri?: string;
}

/**
 * Check if a given object implements the OrganizationImageResponse interface.
 */
export function instanceOfOrganizationImageResponse(value: object): value is OrganizationImageResponse {
  return true;
}

export function OrganizationImageResponseFromJSON(json: any): OrganizationImageResponse {
  return OrganizationImageResponseFromJSONTyped(json, false);
}

export function OrganizationImageResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationImageResponse {
  if (json == null) {
    return json;
  }
  return {
    fileExtension: json['fileExtension'] == null ? undefined : json['fileExtension'],
    fileFormat: json['fileFormat'] == null ? undefined : json['fileFormat'],
    imageBase64Encoded: json['imageBase64Encoded'] == null ? undefined : json['imageBase64Encoded'],
    uri: json['uri'] == null ? undefined : json['uri']
  };
}

export function OrganizationImageResponseToJSON(json: any): OrganizationImageResponse {
  return OrganizationImageResponseToJSONTyped(json, false);
}

export function OrganizationImageResponseToJSONTyped(
  value?: OrganizationImageResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    fileExtension: value['fileExtension'],
    fileFormat: value['fileFormat'],
    imageBase64Encoded: value['imageBase64Encoded'],
    uri: value['uri']
  };
}
