/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Gateway
 */
export interface Gateway {
  /**
   *
   * @type {string}
   * @memberof Gateway
   */
  configId?: string;
  /**
   *
   * @type {string}
   * @memberof Gateway
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Gateway
   */
  gatewayId?: string;
  /**
   *
   * @type {boolean}
   * @memberof Gateway
   */
  hasOutdatedSoftware?: boolean;
  /**
   *
   * @type {string}
   * @memberof Gateway
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Gateway
   */
  organizationId?: string;
  /**
   *
   * @type {string}
   * @memberof Gateway
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Gateway
   */
  timezone?: string;
}

/**
 * Check if a given object implements the Gateway interface.
 */
export function instanceOfGateway(value: object): value is Gateway {
  return true;
}

export function GatewayFromJSON(json: any): Gateway {
  return GatewayFromJSONTyped(json, false);
}

export function GatewayFromJSONTyped(json: any, ignoreDiscriminator: boolean): Gateway {
  if (json == null) {
    return json;
  }
  return {
    configId: json['configId'] == null ? undefined : json['configId'],
    description: json['description'] == null ? undefined : json['description'],
    gatewayId: json['gatewayId'] == null ? undefined : json['gatewayId'],
    hasOutdatedSoftware: json['hasOutdatedSoftware'] == null ? undefined : json['hasOutdatedSoftware'],
    name: json['name'] == null ? undefined : json['name'],
    organizationId: json['organizationId'] == null ? undefined : json['organizationId'],
    serialNumber: json['serialNumber'] == null ? undefined : json['serialNumber'],
    timezone: json['timezone'] == null ? undefined : json['timezone']
  };
}

export function GatewayToJSON(json: any): Gateway {
  return GatewayToJSONTyped(json, false);
}

export function GatewayToJSONTyped(value?: Gateway | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    configId: value['configId'],
    description: value['description'],
    gatewayId: value['gatewayId'],
    hasOutdatedSoftware: value['hasOutdatedSoftware'],
    name: value['name'],
    organizationId: value['organizationId'],
    serialNumber: value['serialNumber'],
    timezone: value['timezone']
  };
}
