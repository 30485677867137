/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface HttpStatusCode
 */
export interface HttpStatusCode {
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  error?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  is1xxInformational?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  is2xxSuccessful?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  is3xxRedirection?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  is4xxClientError?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HttpStatusCode
   */
  is5xxServerError?: boolean;
}

/**
 * Check if a given object implements the HttpStatusCode interface.
 */
export function instanceOfHttpStatusCode(value: object): value is HttpStatusCode {
  return true;
}

export function HttpStatusCodeFromJSON(json: any): HttpStatusCode {
  return HttpStatusCodeFromJSONTyped(json, false);
}

export function HttpStatusCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HttpStatusCode {
  if (json == null) {
    return json;
  }
  return {
    error: json['error'] == null ? undefined : json['error'],
    is1xxInformational: json['is1xxInformational'] == null ? undefined : json['is1xxInformational'],
    is2xxSuccessful: json['is2xxSuccessful'] == null ? undefined : json['is2xxSuccessful'],
    is3xxRedirection: json['is3xxRedirection'] == null ? undefined : json['is3xxRedirection'],
    is4xxClientError: json['is4xxClientError'] == null ? undefined : json['is4xxClientError'],
    is5xxServerError: json['is5xxServerError'] == null ? undefined : json['is5xxServerError']
  };
}

export function HttpStatusCodeToJSON(json: any): HttpStatusCode {
  return HttpStatusCodeToJSONTyped(json, false);
}

export function HttpStatusCodeToJSONTyped(value?: HttpStatusCode | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    error: value['error'],
    is1xxInformational: value['is1xxInformational'],
    is2xxSuccessful: value['is2xxSuccessful'],
    is3xxRedirection: value['is3xxRedirection'],
    is4xxClientError: value['is4xxClientError'],
    is5xxServerError: value['is5xxServerError']
  };
}
