/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LoggerOnboardingResponse
 */
export interface LoggerOnboardingResponse {
  /**
   *
   * @type {string}
   * @memberof LoggerOnboardingResponse
   */
  error?: LoggerOnboardingResponseErrorEnum;
  /**
   *
   * @type {boolean}
   * @memberof LoggerOnboardingResponse
   */
  successfulLaunch?: boolean;
  /**
   *
   * @type {string}
   * @memberof LoggerOnboardingResponse
   */
  virtualFridgeId?: string;
}

/**
 * @export
 */
export const LoggerOnboardingResponseErrorEnum = {
  ALREADY_ONBOARDED_ERROR: 'LOGGER_ALREADY_ONBOARDED_ERROR',
  NOT_ONLINE_ERROR: 'LOGGER_NOT_ONLINE_ERROR',
  MODEL_NOT_SUPPORTED_ERROR: 'LOGGER_MODEL_NOT_SUPPORTED_ERROR'
} as const;
export type LoggerOnboardingResponseErrorEnum = typeof LoggerOnboardingResponseErrorEnum[keyof typeof LoggerOnboardingResponseErrorEnum];

/**
 * Check if a given object implements the LoggerOnboardingResponse interface.
 */
export function instanceOfLoggerOnboardingResponse(value: object): value is LoggerOnboardingResponse {
  return true;
}

export function LoggerOnboardingResponseFromJSON(json: any): LoggerOnboardingResponse {
  return LoggerOnboardingResponseFromJSONTyped(json, false);
}

export function LoggerOnboardingResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LoggerOnboardingResponse {
  if (json == null) {
    return json;
  }
  return {
    error: json['error'] == null ? undefined : json['error'],
    successfulLaunch: json['successfulLaunch'] == null ? undefined : json['successfulLaunch'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function LoggerOnboardingResponseToJSON(json: any): LoggerOnboardingResponse {
  return LoggerOnboardingResponseToJSONTyped(json, false);
}

export function LoggerOnboardingResponseToJSONTyped(
  value?: LoggerOnboardingResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    error: value['error'],
    successfulLaunch: value['successfulLaunch'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
