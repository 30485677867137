/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplianceZoneReportData } from './ApplianceZoneReportData';
import {
    ApplianceZoneReportDataFromJSON,
    ApplianceZoneReportDataFromJSONTyped,
    ApplianceZoneReportDataToJSON,
    ApplianceZoneReportDataToJSONTyped,
} from './ApplianceZoneReportData';

/**
 * 
 * @export
 * @interface ReportRequestData
 */
export interface ReportRequestData {
    /**
     * 
     * @type {string}
     * @memberof ReportRequestData
     */
    applianceName?: string;
    /**
     * 
     * @type {Array<ApplianceZoneReportData>}
     * @memberof ReportRequestData
     */
    applianceZones?: Array<ApplianceZoneReportData>;
    /**
     * 
     * @type {string}
     * @memberof ReportRequestData
     */
    measureSiteType?: ReportRequestDataMeasureSiteTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportRequestData
     */
    virtualFridgeId?: string;
}


/**
 * @export
 */
export const ReportRequestDataMeasureSiteTypeEnum = {
    MODBUS_FRIDGE: 'MODBUS_FRIDGE',
    BINDER_FRIDGE: 'BINDER_FRIDGE',
    SMART_MODULE_FRIDGE: 'SMART_MODULE_FRIDGE',
    LOGGER: 'LOGGER'
} as const;
export type ReportRequestDataMeasureSiteTypeEnum = typeof ReportRequestDataMeasureSiteTypeEnum[keyof typeof ReportRequestDataMeasureSiteTypeEnum];


/**
 * Check if a given object implements the ReportRequestData interface.
 */
export function instanceOfReportRequestData(value: object): value is ReportRequestData {
    return true;
}

export function ReportRequestDataFromJSON(json: any): ReportRequestData {
    return ReportRequestDataFromJSONTyped(json, false);
}

export function ReportRequestDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportRequestData {
    if (json == null) {
        return json;
    }
    return {
        
        'applianceName': json['applianceName'] == null ? undefined : json['applianceName'],
        'applianceZones': json['applianceZones'] == null ? undefined : ((json['applianceZones'] as Array<any>).map(ApplianceZoneReportDataFromJSON)),
        'measureSiteType': json['measureSiteType'] == null ? undefined : json['measureSiteType'],
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
    };
}

export function ReportRequestDataToJSON(json: any): ReportRequestData {
    return ReportRequestDataToJSONTyped(json, false);
}

export function ReportRequestDataToJSONTyped(value?: ReportRequestData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'applianceName': value['applianceName'],
        'applianceZones': value['applianceZones'] == null ? undefined : ((value['applianceZones'] as Array<any>).map(ApplianceZoneReportDataToJSON)),
        'measureSiteType': value['measureSiteType'],
        'virtualFridgeId': value['virtualFridgeId'],
    };
}

