/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplianceInfo } from './ApplianceInfo';
import {
    ApplianceInfoFromJSON,
    ApplianceInfoFromJSONTyped,
    ApplianceInfoToJSON,
    ApplianceInfoToJSONTyped,
} from './ApplianceInfo';

/**
 * 
 * @export
 * @interface Manuals
 */
export interface Manuals {
    /**
     * 
     * @type {ApplianceInfo}
     * @memberof Manuals
     */
    applianceInfo?: ApplianceInfo;
    /**
     * 
     * @type {Array<string>}
     * @memberof Manuals
     */
    installationManuals?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Manuals
     */
    instructionManuals?: Array<string>;
}

/**
 * Check if a given object implements the Manuals interface.
 */
export function instanceOfManuals(value: object): value is Manuals {
    return true;
}

export function ManualsFromJSON(json: any): Manuals {
    return ManualsFromJSONTyped(json, false);
}

export function ManualsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Manuals {
    if (json == null) {
        return json;
    }
    return {
        
        'applianceInfo': json['applianceInfo'] == null ? undefined : ApplianceInfoFromJSON(json['applianceInfo']),
        'installationManuals': json['installationManuals'] == null ? undefined : json['installationManuals'],
        'instructionManuals': json['instructionManuals'] == null ? undefined : json['instructionManuals'],
    };
}

export function ManualsToJSON(json: any): Manuals {
    return ManualsToJSONTyped(json, false);
}

export function ManualsToJSONTyped(value?: Manuals | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'applianceInfo': ApplianceInfoToJSON(value['applianceInfo']),
        'installationManuals': value['installationManuals'],
        'instructionManuals': value['instructionManuals'],
    };
}

