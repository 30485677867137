/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DisplayTemperature } from './DisplayTemperature';
import {
    DisplayTemperatureFromJSON,
    DisplayTemperatureFromJSONTyped,
    DisplayTemperatureToJSON,
    DisplayTemperatureToJSONTyped,
} from './DisplayTemperature';

/**
 * 
 * @export
 * @interface TemperatureSensorAlarmSettings
 */
export interface TemperatureSensorAlarmSettings {
    /**
     * 
     * @type {boolean}
     * @memberof TemperatureSensorAlarmSettings
     */
    enabled?: boolean;
    /**
     * 
     * @type {DisplayTemperature}
     * @memberof TemperatureSensorAlarmSettings
     */
    lowerTemperatureAlarmLimit?: DisplayTemperature;
    /**
     * 
     * @type {number}
     * @memberof TemperatureSensorAlarmSettings
     */
    lowerTemperatureAlarmLimitDelayInMilliseconds?: number;
    /**
     * 
     * @type {string}
     * @memberof TemperatureSensorAlarmSettings
     */
    sensorType?: TemperatureSensorAlarmSettingsSensorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TemperatureSensorAlarmSettings
     */
    thingId?: string;
    /**
     * 
     * @type {DisplayTemperature}
     * @memberof TemperatureSensorAlarmSettings
     */
    upperTemperatureAlarmLimit?: DisplayTemperature;
    /**
     * 
     * @type {number}
     * @memberof TemperatureSensorAlarmSettings
     */
    upperTemperatureAlarmLimitDelayInMilliseconds?: number;
}


/**
 * @export
 */
export const TemperatureSensorAlarmSettingsSensorTypeEnum = {
    DOOR: 'DOOR',
    TEMPERATURE: 'TEMPERATURE',
    PRODUCT_TEMPERATURE: 'PRODUCT_TEMPERATURE'
} as const;
export type TemperatureSensorAlarmSettingsSensorTypeEnum = typeof TemperatureSensorAlarmSettingsSensorTypeEnum[keyof typeof TemperatureSensorAlarmSettingsSensorTypeEnum];


/**
 * Check if a given object implements the TemperatureSensorAlarmSettings interface.
 */
export function instanceOfTemperatureSensorAlarmSettings(value: object): value is TemperatureSensorAlarmSettings {
    return true;
}

export function TemperatureSensorAlarmSettingsFromJSON(json: any): TemperatureSensorAlarmSettings {
    return TemperatureSensorAlarmSettingsFromJSONTyped(json, false);
}

export function TemperatureSensorAlarmSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemperatureSensorAlarmSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'lowerTemperatureAlarmLimit': json['lowerTemperatureAlarmLimit'] == null ? undefined : DisplayTemperatureFromJSON(json['lowerTemperatureAlarmLimit']),
        'lowerTemperatureAlarmLimitDelayInMilliseconds': json['lowerTemperatureAlarmLimitDelayInMilliseconds'] == null ? undefined : json['lowerTemperatureAlarmLimitDelayInMilliseconds'],
        'sensorType': json['sensorType'] == null ? undefined : json['sensorType'],
        'thingId': json['thingId'] == null ? undefined : json['thingId'],
        'upperTemperatureAlarmLimit': json['upperTemperatureAlarmLimit'] == null ? undefined : DisplayTemperatureFromJSON(json['upperTemperatureAlarmLimit']),
        'upperTemperatureAlarmLimitDelayInMilliseconds': json['upperTemperatureAlarmLimitDelayInMilliseconds'] == null ? undefined : json['upperTemperatureAlarmLimitDelayInMilliseconds'],
    };
}

export function TemperatureSensorAlarmSettingsToJSON(json: any): TemperatureSensorAlarmSettings {
    return TemperatureSensorAlarmSettingsToJSONTyped(json, false);
}

export function TemperatureSensorAlarmSettingsToJSONTyped(value?: TemperatureSensorAlarmSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'enabled': value['enabled'],
        'lowerTemperatureAlarmLimit': DisplayTemperatureToJSON(value['lowerTemperatureAlarmLimit']),
        'lowerTemperatureAlarmLimitDelayInMilliseconds': value['lowerTemperatureAlarmLimitDelayInMilliseconds'],
        'sensorType': value['sensorType'],
        'thingId': value['thingId'],
        'upperTemperatureAlarmLimit': DisplayTemperatureToJSON(value['upperTemperatureAlarmLimit']),
        'upperTemperatureAlarmLimitDelayInMilliseconds': value['upperTemperatureAlarmLimitDelayInMilliseconds'],
    };
}

