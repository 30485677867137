/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TemperatureSensorAlarmSettings } from './TemperatureSensorAlarmSettings';
import {
    TemperatureSensorAlarmSettingsFromJSON,
    TemperatureSensorAlarmSettingsFromJSONTyped,
    TemperatureSensorAlarmSettingsToJSON,
    TemperatureSensorAlarmSettingsToJSONTyped,
} from './TemperatureSensorAlarmSettings';
import type { DoorSensorAlarmSettings } from './DoorSensorAlarmSettings';
import {
    DoorSensorAlarmSettingsFromJSON,
    DoorSensorAlarmSettingsFromJSONTyped,
    DoorSensorAlarmSettingsToJSON,
    DoorSensorAlarmSettingsToJSONTyped,
} from './DoorSensorAlarmSettings';

/**
 * 
 * @export
 * @interface LoggerAlarmSettings
 */
export interface LoggerAlarmSettings {
    /**
     * 
     * @type {boolean}
     * @memberof LoggerAlarmSettings
     */
    connectionTimeoutAlarmEnabled?: boolean;
    /**
     * 
     * @type {Array<DoorSensorAlarmSettings>}
     * @memberof LoggerAlarmSettings
     */
    doorSensorAlarmSettings?: Array<DoorSensorAlarmSettings>;
    /**
     * 
     * @type {boolean}
     * @memberof LoggerAlarmSettings
     */
    enabled?: boolean;
    /**
     * 
     * @type {Array<TemperatureSensorAlarmSettings>}
     * @memberof LoggerAlarmSettings
     */
    temperatureSensorAlarmSettings?: Array<TemperatureSensorAlarmSettings>;
    /**
     * 
     * @type {string}
     * @memberof LoggerAlarmSettings
     */
    virtualFridgeId?: string;
}

/**
 * Check if a given object implements the LoggerAlarmSettings interface.
 */
export function instanceOfLoggerAlarmSettings(value: object): value is LoggerAlarmSettings {
    return true;
}

export function LoggerAlarmSettingsFromJSON(json: any): LoggerAlarmSettings {
    return LoggerAlarmSettingsFromJSONTyped(json, false);
}

export function LoggerAlarmSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggerAlarmSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'connectionTimeoutAlarmEnabled': json['connectionTimeoutAlarmEnabled'] == null ? undefined : json['connectionTimeoutAlarmEnabled'],
        'doorSensorAlarmSettings': json['doorSensorAlarmSettings'] == null ? undefined : ((json['doorSensorAlarmSettings'] as Array<any>).map(DoorSensorAlarmSettingsFromJSON)),
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'temperatureSensorAlarmSettings': json['temperatureSensorAlarmSettings'] == null ? undefined : ((json['temperatureSensorAlarmSettings'] as Array<any>).map(TemperatureSensorAlarmSettingsFromJSON)),
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
    };
}

export function LoggerAlarmSettingsToJSON(json: any): LoggerAlarmSettings {
    return LoggerAlarmSettingsToJSONTyped(json, false);
}

export function LoggerAlarmSettingsToJSONTyped(value?: LoggerAlarmSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'connectionTimeoutAlarmEnabled': value['connectionTimeoutAlarmEnabled'],
        'doorSensorAlarmSettings': value['doorSensorAlarmSettings'] == null ? undefined : ((value['doorSensorAlarmSettings'] as Array<any>).map(DoorSensorAlarmSettingsToJSON)),
        'enabled': value['enabled'],
        'temperatureSensorAlarmSettings': value['temperatureSensorAlarmSettings'] == null ? undefined : ((value['temperatureSensorAlarmSettings'] as Array<any>).map(TemperatureSensorAlarmSettingsToJSON)),
        'virtualFridgeId': value['virtualFridgeId'],
    };
}

