/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrganizationAppliance
 */
export interface OrganizationAppliance {
  /**
   *
   * @type {string}
   * @memberof OrganizationAppliance
   */
  virtualFridgeId?: string;
}

/**
 * Check if a given object implements the OrganizationAppliance interface.
 */
export function instanceOfOrganizationAppliance(value: object): value is OrganizationAppliance {
  return true;
}

export function OrganizationApplianceFromJSON(json: any): OrganizationAppliance {
  return OrganizationApplianceFromJSONTyped(json, false);
}

export function OrganizationApplianceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationAppliance {
  if (json == null) {
    return json;
  }
  return {
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function OrganizationApplianceToJSON(json: any): OrganizationAppliance {
  return OrganizationApplianceToJSONTyped(json, false);
}

export function OrganizationApplianceToJSONTyped(
  value?: OrganizationAppliance | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    virtualFridgeId: value['virtualFridgeId']
  };
}
