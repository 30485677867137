<template>
  <v-dialog v-model="menuShown" fullscreen>
    <v-card class="pa-3">
      <BaseWindowItem @evtCancel="closeMenu()" :trackCancel="'MobileUserMenu::Cancel'">
        <template v-slot:title>
          {{ $t('general.home.profile.title') }}
        </template>
        <template v-slot:content>
          <v-layout row my-2>
            <v-list three-line subheader>
              <p style="font-size: 14px; color: var(--v-bus_black-base); margin-bottom: 5px">
                {{ $t('general.menus.usermenu.account') }}
              </p>
              <p style="font-size: 14px; opacity: 0.54; color: var(--v-bus_black-base); margin-top: -2px">
                {{ $t('general.menus.usermenu.accounttext') }}<br />
                {{ getCurrentUserName() }}
              </p>
            </v-list>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="logout()" v-track="'MobileUserMenu::Logout'">{{
              $t('general.home.profile.logout')
            }}</v-btn>
          </v-layout>
          <v-layout row my-3 v-if="showSessionRenewalCheck">
            <v-list three-line subheader>
              <p style="font-size: 14px; color: var(--v-bus_black-base); margin-bottom: 5px">{{ $t('general.home.profile.session') }}</p>
              <div
                style="font-size: 14px; opacity: 0.54; color: var(--v-bus_black-base); margin-top: -2px"
                v-html="$t('general.home.profile.sessionrenew')"
              ></div>
            </v-list>
            <v-spacer></v-spacer>
            <v-switch
              color="secondary"
              class="justify-end"
              v-model="sessionRenewal"
              @change="toggleSessionRenewal()"
            ></v-switch>
          </v-layout>
          <div class="pr-2"><MobileLanguageSetup :userSettings="userSettings" /></div>
          <v-layout row my-3 v-if="$ppt.isLiebherr()" wrap style="padding-top: 30px">
            <v-list three-line subheader>
              <p style="font-size: 14px; color: var(--v-bus_black-base); margin-bottom: 5px">
                {{ $t('general.home.profile.optouttitle') }}
              </p>
              <div
                style="font-size: 14px; opacity: 0.54; color: var(--v-bus_black-base); margin-top: -2px"
                v-html="$t('general.home.profile.optoutdescription')"
              ></div>
            </v-list>
            <v-flex style="display: flex; justify-content: end">
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="secondary"
                @click="showCookieSettingsDialog()"
                outline
                target="_blank"
                v-track="'MobileUserMenu::ShowCookieDialog'"
                >{{ $t('general.home.profile.optoutbutton') }}</v-btn
              >
            </v-flex>
          </v-layout>
        </template>
        <template v-slot:helplink>
          <v-layout row ml-4 mr-2 wrap style="padding-top: 20px">
            <v-list three-line subheader>
              <p style="font-size: 14px; color: var(--v-bus_black-base); margin-bottom: 5px">{{ $t('general.home.profile.settings') }}</p>
              <div
                style="font-size: 14px; opacity: 0.54; color: var(--v-bus_black-base); margin-top: -2px"
                v-if="isAdminUser()"
                v-html="$t('general.home.profile.settingsdescadmin')"
              ></div>
              <div
                style="font-size: 14px; opacity: 0.54; color: var(--v-bus_black-base); margin-top: -2px"
                v-else
                v-html="$t('general.home.profile.settingsdescuser')"
              ></div>
            </v-list>
            <v-flex style="display: flex; justify-content: end">
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="secondary"
                :href="newPasswordLink"
                outline
                target="_blank"
                v-track="'MobileUserMenu::ChangePassword'"
              >
                <v-icon class="mr-2">{{ rightArrowIcon }}</v-icon>
                {{ $t('general.userprofile') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </template>
      </BaseWindowItem>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" src="./MobileUserMenu.ts"></script>
