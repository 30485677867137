/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SensorInfo } from './SensorInfo';
import {
    SensorInfoFromJSON,
    SensorInfoFromJSONTyped,
    SensorInfoToJSON,
    SensorInfoToJSONTyped,
} from './SensorInfo';

/**
 * 
 * @export
 * @interface LoggerInfoResponse
 */
export interface LoggerInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof LoggerInfoResponse
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggerInfoResponse
     */
    deviceUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggerInfoResponse
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggerInfoResponse
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggerInfoResponse
     */
    name?: string;
    /**
     * 
     * @type {Array<SensorInfo>}
     * @memberof LoggerInfoResponse
     */
    sensorInfos?: Array<SensorInfo>;
    /**
     * 
     * @type {string}
     * @memberof LoggerInfoResponse
     */
    serialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LoggerInfoResponse
     */
    timeZone?: string;
    /**
     * 
     * @type {Date}
     * @memberof LoggerInfoResponse
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof LoggerInfoResponse
     */
    virtualFridgeId?: string;
}

/**
 * Check if a given object implements the LoggerInfoResponse interface.
 */
export function instanceOfLoggerInfoResponse(value: object): value is LoggerInfoResponse {
    return true;
}

export function LoggerInfoResponseFromJSON(json: any): LoggerInfoResponse {
    return LoggerInfoResponseFromJSONTyped(json, false);
}

export function LoggerInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoggerInfoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'] == null ? undefined : json['comment'],
        'deviceUuid': json['deviceUuid'] == null ? undefined : json['deviceUuid'],
        'manufacturer': json['manufacturer'] == null ? undefined : json['manufacturer'],
        'model': json['model'] == null ? undefined : json['model'],
        'name': json['name'] == null ? undefined : json['name'],
        'sensorInfos': json['sensorInfos'] == null ? undefined : ((json['sensorInfos'] as Array<any>).map(SensorInfoFromJSON)),
        'serialNumber': json['serialNumber'] == null ? undefined : json['serialNumber'],
        'timeZone': json['timeZone'] == null ? undefined : json['timeZone'],
        'updated': json['updated'] == null ? undefined : (new Date(json['updated'])),
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
    };
}

export function LoggerInfoResponseToJSON(json: any): LoggerInfoResponse {
    return LoggerInfoResponseToJSONTyped(json, false);
}

export function LoggerInfoResponseToJSONTyped(value?: LoggerInfoResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'comment': value['comment'],
        'deviceUuid': value['deviceUuid'],
        'manufacturer': value['manufacturer'],
        'model': value['model'],
        'name': value['name'],
        'sensorInfos': value['sensorInfos'] == null ? undefined : ((value['sensorInfos'] as Array<any>).map(SensorInfoToJSON)),
        'serialNumber': value['serialNumber'],
        'timeZone': value['timeZone'],
        'updated': value['updated'] == null ? undefined : ((value['updated']).toISOString()),
        'virtualFridgeId': value['virtualFridgeId'],
    };
}

