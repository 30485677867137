/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddonLicenseStatus } from './AddonLicenseStatus';
import {
    AddonLicenseStatusFromJSON,
    AddonLicenseStatusFromJSONTyped,
    AddonLicenseStatusToJSON,
    AddonLicenseStatusToJSONTyped,
} from './AddonLicenseStatus';
import type { LicenseInfo } from './LicenseInfo';
import {
    LicenseInfoFromJSON,
    LicenseInfoFromJSONTyped,
    LicenseInfoToJSON,
    LicenseInfoToJSONTyped,
} from './LicenseInfo';
import type { LicenseProduct } from './LicenseProduct';
import {
    LicenseProductFromJSON,
    LicenseProductFromJSONTyped,
    LicenseProductToJSON,
    LicenseProductToJSONTyped,
} from './LicenseProduct';

/**
 * 
 * @export
 * @interface LicenseOverviewResponse
 */
export interface LicenseOverviewResponse {
    /**
     * 
     * @type {AddonLicenseStatus}
     * @memberof LicenseOverviewResponse
     */
    addonLicenseStatus?: AddonLicenseStatus;
    /**
     * 
     * @type {number}
     * @memberof LicenseOverviewResponse
     */
    applianceCount?: number;
    /**
     * 
     * @type {Array<LicenseInfo>}
     * @memberof LicenseOverviewResponse
     */
    assignedLicenses?: Array<LicenseInfo>;
    /**
     * 
     * @type {string}
     * @memberof LicenseOverviewResponse
     */
    licenseOverviewState?: LicenseOverviewResponseLicenseOverviewStateEnum;
    /**
     * 
     * @type {number}
     * @memberof LicenseOverviewResponse
     */
    licensedCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LicenseOverviewResponse
     */
    lockedMeasureSites?: Array<string>;
    /**
     * 
     * @type {Array<LicenseProduct>}
     * @memberof LicenseOverviewResponse
     */
    products?: Array<LicenseProduct>;
    /**
     * 
     * @type {number}
     * @memberof LicenseOverviewResponse
     */
    unassignedLicenseCount?: number;
    /**
     * 
     * @type {Array<LicenseInfo>}
     * @memberof LicenseOverviewResponse
     */
    unassignedLicenses?: Array<LicenseInfo>;
    /**
     * 
     * @type {Set<string>}
     * @memberof LicenseOverviewResponse
     */
    unlicensedApplianceIds?: Set<string>;
    /**
     * 
     * @type {number}
     * @memberof LicenseOverviewResponse
     */
    unlicensedCount?: number;
}


/**
 * @export
 */
export const LicenseOverviewResponseLicenseOverviewStateEnum = {
    CURRENT: 'CURRENT',
    FAILED: 'FAILED',
    FAKED: 'FAKED'
} as const;
export type LicenseOverviewResponseLicenseOverviewStateEnum = typeof LicenseOverviewResponseLicenseOverviewStateEnum[keyof typeof LicenseOverviewResponseLicenseOverviewStateEnum];


/**
 * Check if a given object implements the LicenseOverviewResponse interface.
 */
export function instanceOfLicenseOverviewResponse(value: object): value is LicenseOverviewResponse {
    return true;
}

export function LicenseOverviewResponseFromJSON(json: any): LicenseOverviewResponse {
    return LicenseOverviewResponseFromJSONTyped(json, false);
}

export function LicenseOverviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenseOverviewResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'addonLicenseStatus': json['addonLicenseStatus'] == null ? undefined : AddonLicenseStatusFromJSON(json['addonLicenseStatus']),
        'applianceCount': json['applianceCount'] == null ? undefined : json['applianceCount'],
        'assignedLicenses': json['assignedLicenses'] == null ? undefined : ((json['assignedLicenses'] as Array<any>).map(LicenseInfoFromJSON)),
        'licenseOverviewState': json['licenseOverviewState'] == null ? undefined : json['licenseOverviewState'],
        'licensedCount': json['licensedCount'] == null ? undefined : json['licensedCount'],
        'lockedMeasureSites': json['lockedMeasureSites'] == null ? undefined : json['lockedMeasureSites'],
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(LicenseProductFromJSON)),
        'unassignedLicenseCount': json['unassignedLicenseCount'] == null ? undefined : json['unassignedLicenseCount'],
        'unassignedLicenses': json['unassignedLicenses'] == null ? undefined : ((json['unassignedLicenses'] as Array<any>).map(LicenseInfoFromJSON)),
        'unlicensedApplianceIds': json['unlicensedApplianceIds'] == null ? undefined : new Set(json['unlicensedApplianceIds']),
        'unlicensedCount': json['unlicensedCount'] == null ? undefined : json['unlicensedCount'],
    };
}

export function LicenseOverviewResponseToJSON(json: any): LicenseOverviewResponse {
    return LicenseOverviewResponseToJSONTyped(json, false);
}

export function LicenseOverviewResponseToJSONTyped(value?: LicenseOverviewResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'addonLicenseStatus': AddonLicenseStatusToJSON(value['addonLicenseStatus']),
        'applianceCount': value['applianceCount'],
        'assignedLicenses': value['assignedLicenses'] == null ? undefined : ((value['assignedLicenses'] as Array<any>).map(LicenseInfoToJSON)),
        'licenseOverviewState': value['licenseOverviewState'],
        'licensedCount': value['licensedCount'],
        'lockedMeasureSites': value['lockedMeasureSites'],
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(LicenseProductToJSON)),
        'unassignedLicenseCount': value['unassignedLicenseCount'],
        'unassignedLicenses': value['unassignedLicenses'] == null ? undefined : ((value['unassignedLicenses'] as Array<any>).map(LicenseInfoToJSON)),
        'unlicensedApplianceIds': value['unlicensedApplianceIds'] == null ? undefined : Array.from(value['unlicensedApplianceIds'] as Set<any>),
        'unlicensedCount': value['unlicensedCount'],
    };
}

