<template>
  <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      transform="matrix(1.272727, 0, 0, 1.272727, -1.272727, -1.272727)"
    >
      <path
        style="fill: var(--v-secondary-base)"
        d="M9.33892471,7.00704963 C13.4876106,7.17995766 16.8200423,10.5123894 16.9929504,14.6610753 C19.3270169,13.8393962 21,11.6150712 21,9 C21,8.44771525 21.4477153,8 22,8 C22.5522847,8 23,8.44771525 23,9 C23,12.8003069 20.3501388,15.9817055 16.7973061,16.7973061 C15.9817055,20.3501388 12.8003069,23 9,23 C8.44771525,23 8,22.5522847 8,22 C8,21.4477153 8.44771525,21 9,21 C11.6150712,21 13.8393962,19.3270169 14.6610753,16.9929504 C10.5123894,16.8200423 7.17995766,13.4876106 7.00704963,9.33892471 C4.67298315,10.1606038 3,12.3849288 3,15 C3,15.5522847 2.55228475,16 2,16 C1.44771525,16 1,15.5522847 1,15 C1,11.1996931 3.64986122,8.01829453 7.20269391,7.20269391 C8.01829453,3.64986122 11.1996931,1 15,1 C15.5522847,1 16,1.44771525 16,2 C16,2.55228475 15.5522847,3 15,3 C12.3849288,3 10.1606038,4.67298315 9.33892471,7.00704963 Z M9,9 C9,12.3137085 11.6862915,15 15,15 C15,11.6862915 12.3137085,9 9,9 Z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
