/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LicenseOverviewResponse } from './LicenseOverviewResponse';
import {
    LicenseOverviewResponseFromJSON,
    LicenseOverviewResponseFromJSONTyped,
    LicenseOverviewResponseToJSON,
    LicenseOverviewResponseToJSONTyped,
} from './LicenseOverviewResponse';

/**
 * 
 * @export
 * @interface AssignmentResultResponse
 */
export interface AssignmentResultResponse {
    /**
     * 
     * @type {LicenseOverviewResponse}
     * @memberof AssignmentResultResponse
     */
    licenseOverviewResponse?: LicenseOverviewResponse;
    /**
     * 
     * @type {string}
     * @memberof AssignmentResultResponse
     */
    result?: AssignmentResultResponseResultEnum;
}


/**
 * @export
 */
export const AssignmentResultResponseResultEnum = {
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
} as const;
export type AssignmentResultResponseResultEnum = typeof AssignmentResultResponseResultEnum[keyof typeof AssignmentResultResponseResultEnum];


/**
 * Check if a given object implements the AssignmentResultResponse interface.
 */
export function instanceOfAssignmentResultResponse(value: object): value is AssignmentResultResponse {
    return true;
}

export function AssignmentResultResponseFromJSON(json: any): AssignmentResultResponse {
    return AssignmentResultResponseFromJSONTyped(json, false);
}

export function AssignmentResultResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignmentResultResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'licenseOverviewResponse': json['licenseOverviewResponse'] == null ? undefined : LicenseOverviewResponseFromJSON(json['licenseOverviewResponse']),
        'result': json['result'] == null ? undefined : json['result'],
    };
}

export function AssignmentResultResponseToJSON(json: any): AssignmentResultResponse {
    return AssignmentResultResponseToJSONTyped(json, false);
}

export function AssignmentResultResponseToJSONTyped(value?: AssignmentResultResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'licenseOverviewResponse': LicenseOverviewResponseToJSON(value['licenseOverviewResponse']),
        'result': value['result'],
    };
}

