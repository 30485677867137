/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OnboardedAppliance } from './OnboardedAppliance';
import {
    OnboardedApplianceFromJSON,
    OnboardedApplianceFromJSONTyped,
    OnboardedApplianceToJSON,
    OnboardedApplianceToJSONTyped,
} from './OnboardedAppliance';
import type { Thing } from './Thing';
import {
    ThingFromJSON,
    ThingFromJSONTyped,
    ThingToJSON,
    ThingToJSONTyped,
} from './Thing';

/**
 * 
 * @export
 * @interface GatewayConfigurationResponse
 */
export interface GatewayConfigurationResponse {
    /**
     * 
     * @type {Array<OnboardedAppliance>}
     * @memberof GatewayConfigurationResponse
     */
    configuredAppliances?: Array<OnboardedAppliance>;
    /**
     * 
     * @type {Array<OnboardedAppliance>}
     * @memberof GatewayConfigurationResponse
     */
    disconnectedAppliances?: Array<OnboardedAppliance>;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationResponse
     */
    gatewayId?: string;
    /**
     * 
     * @type {Array<Thing>}
     * @memberof GatewayConfigurationResponse
     */
    newAppliances?: Array<Thing>;
    /**
     * 
     * @type {Date}
     * @memberof GatewayConfigurationResponse
     */
    scannedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GatewayConfigurationResponse
     */
    scanning?: boolean;
}

/**
 * Check if a given object implements the GatewayConfigurationResponse interface.
 */
export function instanceOfGatewayConfigurationResponse(value: object): value is GatewayConfigurationResponse {
    return true;
}

export function GatewayConfigurationResponseFromJSON(json: any): GatewayConfigurationResponse {
    return GatewayConfigurationResponseFromJSONTyped(json, false);
}

export function GatewayConfigurationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GatewayConfigurationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'configuredAppliances': json['configuredAppliances'] == null ? undefined : ((json['configuredAppliances'] as Array<any>).map(OnboardedApplianceFromJSON)),
        'disconnectedAppliances': json['disconnectedAppliances'] == null ? undefined : ((json['disconnectedAppliances'] as Array<any>).map(OnboardedApplianceFromJSON)),
        'gatewayId': json['gatewayId'] == null ? undefined : json['gatewayId'],
        'newAppliances': json['newAppliances'] == null ? undefined : ((json['newAppliances'] as Array<any>).map(ThingFromJSON)),
        'scannedAt': json['scannedAt'] == null ? undefined : (new Date(json['scannedAt'])),
        'scanning': json['scanning'] == null ? undefined : json['scanning'],
    };
}

export function GatewayConfigurationResponseToJSON(json: any): GatewayConfigurationResponse {
    return GatewayConfigurationResponseToJSONTyped(json, false);
}

export function GatewayConfigurationResponseToJSONTyped(value?: GatewayConfigurationResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'configuredAppliances': value['configuredAppliances'] == null ? undefined : ((value['configuredAppliances'] as Array<any>).map(OnboardedApplianceToJSON)),
        'disconnectedAppliances': value['disconnectedAppliances'] == null ? undefined : ((value['disconnectedAppliances'] as Array<any>).map(OnboardedApplianceToJSON)),
        'gatewayId': value['gatewayId'],
        'newAppliances': value['newAppliances'] == null ? undefined : ((value['newAppliances'] as Array<any>).map(ThingToJSON)),
        'scannedAt': value['scannedAt'] == null ? undefined : ((value['scannedAt']).toISOString()),
        'scanning': value['scanning'],
    };
}

