import './init';

import { createApp } from 'vue';
import App from '@/App.vue';
import MobileApp from '@/mobile/MobileApp.vue';

// Used to detect mobile devices
import MobileHelper from './router/MobileHelper';

const app = createApp(MobileHelper.isMobile ? MobileApp : App);

import penguify from '@/plugins/penguify';

// Router controls the app
import router from '@/router/router';
import VueApexCharts from 'vue3-apexcharts';

// Looks unused, but needed to get the trick above going
import CubeLogger from '@/Logger';

import Formatters from '@/Formatters';

import { createPinia } from 'pinia';
export const pinia = createPinia();

// Language control and switching
import { createI18n, I18n } from 'vue-i18n';
import { useI18nStore } from '@/I18nStore';

// Initialize i18n
const i18n: I18n = createI18n({
  legacy: false,
  locale: 'en-US',
  fallbackLocale: 'de-DE',
  globalInjection: true
});

import VueGtm from '@gtm-support/vue-gtm';

const LIEBHERR = import.meta.env.VITE_PRODUCT === 'liebherr';

const analyticsDebug: boolean = LIEBHERR ? import.meta.env.VITE_ANALYTICS_DEBUG === 'true' : null;
const analyticsId: string = LIEBHERR ? import.meta.env.VITE_GOOGLE_TAG_MANAGER : null;

if (LIEBHERR) {
  app.use(VueGtm, {
    id: analyticsId,
    enabled: true,
    debug: analyticsDebug,
    vueRouter: router
  });
}

// VueJS directive stuffs
import { track, shorttext, resizer } from '@/directives';
import { vMaska } from 'maska/vue';
app.directive('track', track);
app.directive('shorttext', shorttext);
app.directive('resizer', resizer);
app.directive('mask', vMaska);

// General CSS, valid for all companies
import '@/assets/common.css';
// Company-specific CSS, copied by vue.config.js
import '@/assets/global.css';

import EnvironmentHandler from './EnvironmentHandler';
import Puppeteer from './Puppeteer';

// Animation files
import Vue3Lottie from 'vue3-lottie';

// We add a "?date=1619675159013" (example) to "bust" caching
const environmentUrl: string = window.location.origin + '/environment.json?date=' + new Date().getTime().toString();

window
  .fetch(environmentUrl)
  .then(response => {
    return response.json();
  })
  .then(json => {
    app.use(i18n);
    app.use(router);
    app.use(pinia);

    app.use(penguify);
    app.use(Puppeteer);
    app.use(Formatters);
    app.use(CubeLogger);
    app.use(VueApexCharts);
    app.use(Vue3Lottie);

    EnvironmentHandler.init(json);

    const i18nStore = useI18nStore();
    i18nStore.init();

    app.mount('#app');
  });

export default i18n.global;
