/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SmartModuleLaunchResponse
 */
export interface SmartModuleLaunchResponse {
  /**
   *
   * @type {string}
   * @memberof SmartModuleLaunchResponse
   */
  error?: SmartModuleLaunchResponseErrorEnum;
  /**
   *
   * @type {boolean}
   * @memberof SmartModuleLaunchResponse
   */
  successfulLaunch?: boolean;
}

/**
 * @export
 */
export const SmartModuleLaunchResponseErrorEnum = {
  ALREADY_ONBOARDED_ERROR: 'SMART_MODULE_FRIDGE_ALREADY_ONBOARDED_ERROR',
  NOT_ONLINE_ERROR: 'SMART_MODULE_FRIDGE_NOT_ONLINE_ERROR',
  ONLINE_SINCE_PERIOD_EXCEEDED_ERROR: 'SMART_MODULE_FRIDGE_ONLINE_SINCE_PERIOD_EXCEEDED_ERROR'
} as const;
export type SmartModuleLaunchResponseErrorEnum = typeof SmartModuleLaunchResponseErrorEnum[keyof typeof SmartModuleLaunchResponseErrorEnum];

/**
 * Check if a given object implements the SmartModuleLaunchResponse interface.
 */
export function instanceOfSmartModuleLaunchResponse(value: object): value is SmartModuleLaunchResponse {
  return true;
}

export function SmartModuleLaunchResponseFromJSON(json: any): SmartModuleLaunchResponse {
  return SmartModuleLaunchResponseFromJSONTyped(json, false);
}

export function SmartModuleLaunchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SmartModuleLaunchResponse {
  if (json == null) {
    return json;
  }
  return {
    error: json['error'] == null ? undefined : json['error'],
    successfulLaunch: json['successfulLaunch'] == null ? undefined : json['successfulLaunch']
  };
}

export function SmartModuleLaunchResponseToJSON(json: any): SmartModuleLaunchResponse {
  return SmartModuleLaunchResponseToJSONTyped(json, false);
}

export function SmartModuleLaunchResponseToJSONTyped(
  value?: SmartModuleLaunchResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    error: value['error'],
    successfulLaunch: value['successfulLaunch']
  };
}
