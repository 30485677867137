/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AccessCheckResponse
 */
export interface AccessCheckResponse {
  /**
   *
   * @type {string}
   * @memberof AccessCheckResponse
   */
  context?: string;
  /**
   *
   * @type {string}
   * @memberof AccessCheckResponse
   */
  role?: AccessCheckResponseRoleEnum;
}

/**
 * @export
 */
export const AccessCheckResponseRoleEnum = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  ERROR_COMPANYVERIFICATION: 'ERROR_COMPANYVERIFICATION',
  ERROR_USERVERIFICATION: 'ERROR_USERVERIFICATION',
  ERROR_B2_CUSER: 'ERROR_B2CUSER',
  ERROR_NOACCESS: 'ERROR_NOACCESS',
  ERROR_WRONGCOMPANY: 'ERROR_WRONGCOMPANY'
} as const;
export type AccessCheckResponseRoleEnum = typeof AccessCheckResponseRoleEnum[keyof typeof AccessCheckResponseRoleEnum];

/**
 * Check if a given object implements the AccessCheckResponse interface.
 */
export function instanceOfAccessCheckResponse(value: object): value is AccessCheckResponse {
  return true;
}

export function AccessCheckResponseFromJSON(json: any): AccessCheckResponse {
  return AccessCheckResponseFromJSONTyped(json, false);
}

export function AccessCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessCheckResponse {
  if (json == null) {
    return json;
  }
  return {
    context: json['context'] == null ? undefined : json['context'],
    role: json['role'] == null ? undefined : json['role']
  };
}

export function AccessCheckResponseToJSON(json: any): AccessCheckResponse {
  return AccessCheckResponseToJSONTyped(json, false);
}

export function AccessCheckResponseToJSONTyped(
  value?: AccessCheckResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    context: value['context'],
    role: value['role']
  };
}
