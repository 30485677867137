/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Granularity,
  HistoryResponse,
} from '../models/index';
import {
    GranularityFromJSON,
    GranularityToJSON,
    HistoryResponseFromJSON,
    HistoryResponseToJSON,
} from '../models/index';

export interface GetHistoryDataPointsUsingPUTRequest {
    applianceType: GetHistoryDataPointsUsingPUTApplianceTypeEnum;
    end: Date;
    start: Date;
    virtualFridgeId: string;
    zoneThingId: string;
    granularity: Granularity;
}

/**
 * HistoryControllerApi - interface
 * 
 * @export
 * @interface HistoryControllerApiInterface
 */
export interface HistoryControllerApiInterface {
    /**
     * getHistoryDataPoints
     * @summary Gets the datapoints for the chart
     * @param {'MODBUS_FRIDGE' | 'BINDER_FRIDGE' | 'SMART_MODULE_FRIDGE' | 'LCV_FRIDGE' | 'LOGGER'} applianceType 
     * @param {Date} end 
     * @param {Date} start 
     * @param {string} virtualFridgeId 
     * @param {string} zoneThingId 
     * @param {Granularity} granularity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryControllerApiInterface
     */
    getHistoryDataPointsUsingPUTRaw(requestParameters: GetHistoryDataPointsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryResponse>>;

    /**
     * getHistoryDataPoints
     * Gets the datapoints for the chart
     */
    getHistoryDataPointsUsingPUT(requestParameters: GetHistoryDataPointsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryResponse>;

}

/**
 * 
 */
export class HistoryControllerApi extends runtime.BaseAPI implements HistoryControllerApiInterface {

    /**
     * getHistoryDataPoints
     * Gets the datapoints for the chart
     */
    async getHistoryDataPointsUsingPUTRaw(requestParameters: GetHistoryDataPointsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryResponse>> {
        if (requestParameters['applianceType'] == null) {
            throw new runtime.RequiredError(
                'applianceType',
                'Required parameter "applianceType" was null or undefined when calling getHistoryDataPointsUsingPUT().'
            );
        }

        if (requestParameters['end'] == null) {
            throw new runtime.RequiredError(
                'end',
                'Required parameter "end" was null or undefined when calling getHistoryDataPointsUsingPUT().'
            );
        }

        if (requestParameters['start'] == null) {
            throw new runtime.RequiredError(
                'start',
                'Required parameter "start" was null or undefined when calling getHistoryDataPointsUsingPUT().'
            );
        }

        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getHistoryDataPointsUsingPUT().'
            );
        }

        if (requestParameters['zoneThingId'] == null) {
            throw new runtime.RequiredError(
                'zoneThingId',
                'Required parameter "zoneThingId" was null or undefined when calling getHistoryDataPointsUsingPUT().'
            );
        }

        if (requestParameters['granularity'] == null) {
            throw new runtime.RequiredError(
                'granularity',
                'Required parameter "granularity" was null or undefined when calling getHistoryDataPointsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['applianceType'] != null) {
            queryParameters['applianceType'] = requestParameters['applianceType'];
        }

        if (requestParameters['end'] != null) {
            queryParameters['end'] = (requestParameters['end'] as any).toISOString();
        }

        if (requestParameters['start'] != null) {
            queryParameters['start'] = (requestParameters['start'] as any).toISOString();
        }

        if (requestParameters['virtualFridgeId'] != null) {
            queryParameters['virtualFridgeId'] = requestParameters['virtualFridgeId'];
        }

        if (requestParameters['zoneThingId'] != null) {
            queryParameters['zoneThingId'] = requestParameters['zoneThingId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/history/datapoints`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GranularityToJSON(requestParameters['granularity']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseFromJSON(jsonValue));
    }

    /**
     * getHistoryDataPoints
     * Gets the datapoints for the chart
     */
    async getHistoryDataPointsUsingPUT(requestParameters: GetHistoryDataPointsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryResponse> {
        const response = await this.getHistoryDataPointsUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetHistoryDataPointsUsingPUTApplianceTypeEnum = {
    MODBUS_FRIDGE: 'MODBUS_FRIDGE',
    BINDER_FRIDGE: 'BINDER_FRIDGE',
    SMART_MODULE_FRIDGE: 'SMART_MODULE_FRIDGE',
    LCV_FRIDGE: 'LCV_FRIDGE',
    LOGGER: 'LOGGER'
} as const;
export type GetHistoryDataPointsUsingPUTApplianceTypeEnum = typeof GetHistoryDataPointsUsingPUTApplianceTypeEnum[keyof typeof GetHistoryDataPointsUsingPUTApplianceTypeEnum];
