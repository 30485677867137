/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportRequestData } from './ReportRequestData';
import {
    ReportRequestDataFromJSON,
    ReportRequestDataFromJSONTyped,
    ReportRequestDataToJSON,
    ReportRequestDataToJSONTyped,
} from './ReportRequestData';
import type { Granularity } from './Granularity';
import {
    GranularityFromJSON,
    GranularityFromJSONTyped,
    GranularityToJSON,
    GranularityToJSONTyped,
} from './Granularity';

/**
 * 
 * @export
 * @interface MultipleReportRequest
 */
export interface MultipleReportRequest {
    /**
     * 
     * @type {Date}
     * @memberof MultipleReportRequest
     */
    end?: Date;
    /**
     * 
     * @type {Granularity}
     * @memberof MultipleReportRequest
     */
    granularity?: Granularity;
    /**
     * 
     * @type {boolean}
     * @memberof MultipleReportRequest
     */
    isManuallyCreated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MultipleReportRequest
     */
    language?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MultipleReportRequest
     */
    recipientEmails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MultipleReportRequest
     */
    reportName?: string;
    /**
     * 
     * @type {Array<ReportRequestData>}
     * @memberof MultipleReportRequest
     */
    reportRequestDataList?: Array<ReportRequestData>;
    /**
     * 
     * @type {Date}
     * @memberof MultipleReportRequest
     */
    start?: Date;
    /**
     * 
     * @type {string}
     * @memberof MultipleReportRequest
     */
    timezone?: string;
}

/**
 * Check if a given object implements the MultipleReportRequest interface.
 */
export function instanceOfMultipleReportRequest(value: object): value is MultipleReportRequest {
    return true;
}

export function MultipleReportRequestFromJSON(json: any): MultipleReportRequest {
    return MultipleReportRequestFromJSONTyped(json, false);
}

export function MultipleReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipleReportRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'end': json['end'] == null ? undefined : (new Date(json['end'])),
        'granularity': json['granularity'] == null ? undefined : GranularityFromJSON(json['granularity']),
        'isManuallyCreated': json['isManuallyCreated'] == null ? undefined : json['isManuallyCreated'],
        'language': json['language'] == null ? undefined : json['language'],
        'recipientEmails': json['recipientEmails'] == null ? undefined : json['recipientEmails'],
        'reportName': json['reportName'] == null ? undefined : json['reportName'],
        'reportRequestDataList': json['reportRequestDataList'] == null ? undefined : ((json['reportRequestDataList'] as Array<any>).map(ReportRequestDataFromJSON)),
        'start': json['start'] == null ? undefined : (new Date(json['start'])),
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
    };
}

export function MultipleReportRequestToJSON(json: any): MultipleReportRequest {
    return MultipleReportRequestToJSONTyped(json, false);
}

export function MultipleReportRequestToJSONTyped(value?: MultipleReportRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'end': value['end'] == null ? undefined : ((value['end']).toISOString()),
        'granularity': GranularityToJSON(value['granularity']),
        'isManuallyCreated': value['isManuallyCreated'],
        'language': value['language'],
        'recipientEmails': value['recipientEmails'],
        'reportName': value['reportName'],
        'reportRequestDataList': value['reportRequestDataList'] == null ? undefined : ((value['reportRequestDataList'] as Array<any>).map(ReportRequestDataToJSON)),
        'start': value['start'] == null ? undefined : ((value['start']).toISOString()),
        'timezone': value['timezone'],
    };
}

