/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Appliance } from './Appliance';
import {
    ApplianceFromJSON,
    ApplianceFromJSONTyped,
    ApplianceToJSON,
    ApplianceToJSONTyped,
} from './Appliance';

/**
 * 
 * @export
 * @interface ApplianceGroup
 */
export interface ApplianceGroup {
    /**
     * 
     * @type {Array<Appliance>}
     * @memberof ApplianceGroup
     */
    appliances?: Array<Appliance>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplianceGroup
     */
    defaultGroup?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplianceGroup
     */
    groupId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceGroup
     */
    name?: string;
}

/**
 * Check if a given object implements the ApplianceGroup interface.
 */
export function instanceOfApplianceGroup(value: object): value is ApplianceGroup {
    return true;
}

export function ApplianceGroupFromJSON(json: any): ApplianceGroup {
    return ApplianceGroupFromJSONTyped(json, false);
}

export function ApplianceGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'appliances': json['appliances'] == null ? undefined : ((json['appliances'] as Array<any>).map(ApplianceFromJSON)),
        'defaultGroup': json['defaultGroup'] == null ? undefined : json['defaultGroup'],
        'groupId': json['groupId'] == null ? undefined : json['groupId'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function ApplianceGroupToJSON(json: any): ApplianceGroup {
    return ApplianceGroupToJSONTyped(json, false);
}

export function ApplianceGroupToJSONTyped(value?: ApplianceGroup | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'appliances': value['appliances'] == null ? undefined : ((value['appliances'] as Array<any>).map(ApplianceToJSON)),
        'defaultGroup': value['defaultGroup'],
        'groupId': value['groupId'],
        'name': value['name'],
    };
}

