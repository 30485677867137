/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateGatewayResponse
 */
export interface CreateGatewayResponse {
  /**
   *
   * @type {string}
   * @memberof CreateGatewayResponse
   */
  error?: CreateGatewayResponseErrorEnum;
  /**
   *
   * @type {boolean}
   * @memberof CreateGatewayResponse
   */
  successful?: boolean;
}

/**
 * @export
 */
export const CreateGatewayResponseErrorEnum = {
  GATEWAY_IS_NOT_REACHABLE: 'GATEWAY_IS_NOT_REACHABLE',
  GATEWAY_IS_NOT_RECOGNIZED: 'GATEWAY_IS_NOT_RECOGNIZED',
  GATEWAY_PASSWORD_WRONG: 'GATEWAY_PASSWORD_WRONG',
  CREATE_GATEWAY_ALARM_PROFILE_ERROR: 'CREATE_GATEWAY_ALARM_PROFILE_ERROR',
  REMOVE_GATEWAY_ALARM_PROFILE_ERROR: 'REMOVE_GATEWAY_ALARM_PROFILE_ERROR',
  CONFIGURE_GATEWAY_ERROR: 'CONFIGURE_GATEWAY_ERROR',
  GATEWAY_BELONGS_TO_ANOTHER_COMPANY: 'GATEWAY_BELONGS_TO_ANOTHER_COMPANY',
  GATEWAY_ALREADY_REGISTERED: 'GATEWAY_ALREADY_REGISTERED'
} as const;
export type CreateGatewayResponseErrorEnum = typeof CreateGatewayResponseErrorEnum[keyof typeof CreateGatewayResponseErrorEnum];

/**
 * Check if a given object implements the CreateGatewayResponse interface.
 */
export function instanceOfCreateGatewayResponse(value: object): value is CreateGatewayResponse {
  return true;
}

export function CreateGatewayResponseFromJSON(json: any): CreateGatewayResponse {
  return CreateGatewayResponseFromJSONTyped(json, false);
}

export function CreateGatewayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateGatewayResponse {
  if (json == null) {
    return json;
  }
  return {
    error: json['error'] == null ? undefined : json['error'],
    successful: json['successful'] == null ? undefined : json['successful']
  };
}

export function CreateGatewayResponseToJSON(json: any): CreateGatewayResponse {
  return CreateGatewayResponseToJSONTyped(json, false);
}

export function CreateGatewayResponseToJSONTyped(
  value?: CreateGatewayResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    error: value['error'],
    successful: value['successful']
  };
}
