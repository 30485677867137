/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OnboardingInfoZone } from './OnboardingInfoZone';
import {
    OnboardingInfoZoneFromJSON,
    OnboardingInfoZoneFromJSONTyped,
    OnboardingInfoZoneToJSON,
    OnboardingInfoZoneToJSONTyped,
} from './OnboardingInfoZone';

/**
 * 
 * @export
 * @interface OnboardingInfo
 */
export interface OnboardingInfo {
    /**
     * 
     * @type {string}
     * @memberof OnboardingInfo
     */
    timeZone?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingInfo
     */
    timeZonePosix?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingInfo
     */
    virtualFridgeId?: string;
    /**
     * 
     * @type {Array<OnboardingInfoZone>}
     * @memberof OnboardingInfo
     */
    zoneInfos?: Array<OnboardingInfoZone>;
}

/**
 * Check if a given object implements the OnboardingInfo interface.
 */
export function instanceOfOnboardingInfo(value: object): value is OnboardingInfo {
    return true;
}

export function OnboardingInfoFromJSON(json: any): OnboardingInfo {
    return OnboardingInfoFromJSONTyped(json, false);
}

export function OnboardingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'timeZone': json['timeZone'] == null ? undefined : json['timeZone'],
        'timeZonePosix': json['timeZonePosix'] == null ? undefined : json['timeZonePosix'],
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
        'zoneInfos': json['zoneInfos'] == null ? undefined : ((json['zoneInfos'] as Array<any>).map(OnboardingInfoZoneFromJSON)),
    };
}

export function OnboardingInfoToJSON(json: any): OnboardingInfo {
    return OnboardingInfoToJSONTyped(json, false);
}

export function OnboardingInfoToJSONTyped(value?: OnboardingInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'timeZone': value['timeZone'],
        'timeZonePosix': value['timeZonePosix'],
        'virtualFridgeId': value['virtualFridgeId'],
        'zoneInfos': value['zoneInfos'] == null ? undefined : ((value['zoneInfos'] as Array<any>).map(OnboardingInfoZoneToJSON)),
    };
}

