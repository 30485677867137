/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OnboardingInfoZone
 */
export interface OnboardingInfoZone {
  /**
   *
   * @type {string}
   * @memberof OnboardingInfoZone
   */
  thingId?: string;
  /**
   *
   * @type {number}
   * @memberof OnboardingInfoZone
   */
  zoneIndex?: number;
}

/**
 * Check if a given object implements the OnboardingInfoZone interface.
 */
export function instanceOfOnboardingInfoZone(value: object): value is OnboardingInfoZone {
  return true;
}

export function OnboardingInfoZoneFromJSON(json: any): OnboardingInfoZone {
  return OnboardingInfoZoneFromJSONTyped(json, false);
}

export function OnboardingInfoZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingInfoZone {
  if (json == null) {
    return json;
  }
  return {
    thingId: json['thingId'] == null ? undefined : json['thingId'],
    zoneIndex: json['zoneIndex'] == null ? undefined : json['zoneIndex']
  };
}

export function OnboardingInfoZoneToJSON(json: any): OnboardingInfoZone {
  return OnboardingInfoZoneToJSONTyped(json, false);
}

export function OnboardingInfoZoneToJSONTyped(
  value?: OnboardingInfoZone | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    thingId: value['thingId'],
    zoneIndex: value['zoneIndex']
  };
}
