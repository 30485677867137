/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HttpStatusCode,
  OrganizationImageResponse,
  OrganizationSettings,
  PermissionResponse,
  UpdatePermissionsRequest,
  VirtualFridgePermissionsResponse,
} from '../models/index';
import {
    HttpStatusCodeFromJSON,
    HttpStatusCodeToJSON,
    OrganizationImageResponseFromJSON,
    OrganizationImageResponseToJSON,
    OrganizationSettingsFromJSON,
    OrganizationSettingsToJSON,
    PermissionResponseFromJSON,
    PermissionResponseToJSON,
    UpdatePermissionsRequestFromJSON,
    UpdatePermissionsRequestToJSON,
    VirtualFridgePermissionsResponseFromJSON,
    VirtualFridgePermissionsResponseToJSON,
} from '../models/index';

export interface GetPermissionsByUpnUsingGETRequest {
    upn: string;
}

export interface GetPermissionsForVirtualFridgeIdUsingGETRequest {
    virtualFridgeId: string;
}

export interface StoreOrganizationAreaUsingPUTRequest {
    area: string;
}

export interface StoreOrganizationLanguageUsingPUTRequest {
    language: string;
}

export interface StoreOrganizationSettingsUsingPUTRequest {
    organizationSettings: OrganizationSettings;
}

export interface UpdateOrganizationImageUsingPOSTRequest {
    body: string;
}

export interface UpdatePermissionsUsingPUTRequest {
    updatePermissionsRequest: UpdatePermissionsRequest;
}

/**
 * OrganizationControllerApi - interface
 * 
 * @export
 * @interface OrganizationControllerApiInterface
 */
export interface OrganizationControllerApiInterface {
    /**
     * deleteOrganizationImageUrl
     * @summary Deletes organization image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApiInterface
     */
    deleteOrganizationImageUrlUsingDELETERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * deleteOrganizationImageUrl
     * Deletes organization image
     */
    deleteOrganizationImageUrlUsingDELETE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * getOrganizationAreaAction
     * @summary Gets the business area action of an organization.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApiInterface
     */
    getOrganizationAreaActionUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * getOrganizationAreaAction
     * Gets the business area action of an organization.
     */
    getOrganizationAreaActionUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * getOrganizationImageUrl
     * @summary Gets metadata (link, image type ...) for a given Organization (taken from the user context).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApiInterface
     */
    getOrganizationImageUrlUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationImageResponse>>;

    /**
     * getOrganizationImageUrl
     * Gets metadata (link, image type ...) for a given Organization (taken from the user context).
     */
    getOrganizationImageUrlUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationImageResponse>;

    /**
     * getOrganizationSettings
     * @summary Gets the Organization settings for the user specified in the UserContext.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApiInterface
     */
    getOrganizationSettingsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationSettings>>;

    /**
     * getOrganizationSettings
     * Gets the Organization settings for the user specified in the UserContext.
     */
    getOrganizationSettingsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationSettings>;

    /**
     * getPermissionsByUpn
     * @summary Gets permissions for admin
     * @param {string} upn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApiInterface
     */
    getPermissionsByUpnUsingGETRaw(requestParameters: GetPermissionsByUpnUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionResponse>>;

    /**
     * getPermissionsByUpn
     * Gets permissions for admin
     */
    getPermissionsByUpnUsingGET(requestParameters: GetPermissionsByUpnUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionResponse>;

    /**
     * getPermissionsForVirtualFridgeId
     * @summary Gets permissions for a virtualFridgeId
     * @param {string} virtualFridgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApiInterface
     */
    getPermissionsForVirtualFridgeIdUsingGETRaw(requestParameters: GetPermissionsForVirtualFridgeIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VirtualFridgePermissionsResponse>>>;

    /**
     * getPermissionsForVirtualFridgeId
     * Gets permissions for a virtualFridgeId
     */
    getPermissionsForVirtualFridgeIdUsingGET(requestParameters: GetPermissionsForVirtualFridgeIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VirtualFridgePermissionsResponse>>;

    /**
     * storeOrganizationArea
     * @summary Stores the business area for an organization.
     * @param {string} area 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApiInterface
     */
    storeOrganizationAreaUsingPUTRaw(requestParameters: StoreOrganizationAreaUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HttpStatusCode>>;

    /**
     * storeOrganizationArea
     * Stores the business area for an organization.
     */
    storeOrganizationAreaUsingPUT(requestParameters: StoreOrganizationAreaUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HttpStatusCode>;

    /**
     * storeOrganizationLanguage
     * @summary Stores the language for an organization.
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApiInterface
     */
    storeOrganizationLanguageUsingPUTRaw(requestParameters: StoreOrganizationLanguageUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * storeOrganizationLanguage
     * Stores the language for an organization.
     */
    storeOrganizationLanguageUsingPUT(requestParameters: StoreOrganizationLanguageUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * storeOrganizationSettings
     * @summary Stores the settings for the organization.
     * @param {OrganizationSettings} organizationSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApiInterface
     */
    storeOrganizationSettingsUsingPUTRaw(requestParameters: StoreOrganizationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * storeOrganizationSettings
     * Stores the settings for the organization.
     */
    storeOrganizationSettingsUsingPUT(requestParameters: StoreOrganizationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * updateOrganizationImage
     * @summary Updates the Image of the Organization of the currently logged in User
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApiInterface
     */
    updateOrganizationImageUsingPOSTRaw(requestParameters: UpdateOrganizationImageUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * updateOrganizationImage
     * Updates the Image of the Organization of the currently logged in User
     */
    updateOrganizationImageUsingPOST(requestParameters: UpdateOrganizationImageUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * updatePermissions
     * @summary Updates permissions. Only admin can call.
     * @param {UpdatePermissionsRequest} updatePermissionsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApiInterface
     */
    updatePermissionsUsingPUTRaw(requestParameters: UpdatePermissionsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * updatePermissions
     * Updates permissions. Only admin can call.
     */
    updatePermissionsUsingPUT(requestParameters: UpdatePermissionsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class OrganizationControllerApi extends runtime.BaseAPI implements OrganizationControllerApiInterface {

    /**
     * deleteOrganizationImageUrl
     * Deletes organization image
     */
    async deleteOrganizationImageUrlUsingDELETERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/organization/organizationImage`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteOrganizationImageUrl
     * Deletes organization image
     */
    async deleteOrganizationImageUrlUsingDELETE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOrganizationImageUrlUsingDELETERaw(initOverrides);
    }

    /**
     * getOrganizationAreaAction
     * Gets the business area action of an organization.
     */
    async getOrganizationAreaActionUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/organization/area`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getOrganizationAreaAction
     * Gets the business area action of an organization.
     */
    async getOrganizationAreaActionUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getOrganizationAreaActionUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getOrganizationImageUrl
     * Gets metadata (link, image type ...) for a given Organization (taken from the user context).
     */
    async getOrganizationImageUrlUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationImageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/organization/organizationImage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationImageResponseFromJSON(jsonValue));
    }

    /**
     * getOrganizationImageUrl
     * Gets metadata (link, image type ...) for a given Organization (taken from the user context).
     */
    async getOrganizationImageUrlUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationImageResponse> {
        const response = await this.getOrganizationImageUrlUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getOrganizationSettings
     * Gets the Organization settings for the user specified in the UserContext.
     */
    async getOrganizationSettingsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/organization/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationSettingsFromJSON(jsonValue));
    }

    /**
     * getOrganizationSettings
     * Gets the Organization settings for the user specified in the UserContext.
     */
    async getOrganizationSettingsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationSettings> {
        const response = await this.getOrganizationSettingsUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getPermissionsByUpn
     * Gets permissions for admin
     */
    async getPermissionsByUpnUsingGETRaw(requestParameters: GetPermissionsByUpnUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionResponse>> {
        if (requestParameters['upn'] == null) {
            throw new runtime.RequiredError(
                'upn',
                'Required parameter "upn" was null or undefined when calling getPermissionsByUpnUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/organization/permissions/users/{upn}`.replace(`{${"upn"}}`, encodeURIComponent(String(requestParameters['upn']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionResponseFromJSON(jsonValue));
    }

    /**
     * getPermissionsByUpn
     * Gets permissions for admin
     */
    async getPermissionsByUpnUsingGET(requestParameters: GetPermissionsByUpnUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionResponse> {
        const response = await this.getPermissionsByUpnUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPermissionsForVirtualFridgeId
     * Gets permissions for a virtualFridgeId
     */
    async getPermissionsForVirtualFridgeIdUsingGETRaw(requestParameters: GetPermissionsForVirtualFridgeIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VirtualFridgePermissionsResponse>>> {
        if (requestParameters['virtualFridgeId'] == null) {
            throw new runtime.RequiredError(
                'virtualFridgeId',
                'Required parameter "virtualFridgeId" was null or undefined when calling getPermissionsForVirtualFridgeIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/organization/permissions/{virtualFridgeId}`.replace(`{${"virtualFridgeId"}}`, encodeURIComponent(String(requestParameters['virtualFridgeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VirtualFridgePermissionsResponseFromJSON));
    }

    /**
     * getPermissionsForVirtualFridgeId
     * Gets permissions for a virtualFridgeId
     */
    async getPermissionsForVirtualFridgeIdUsingGET(requestParameters: GetPermissionsForVirtualFridgeIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VirtualFridgePermissionsResponse>> {
        const response = await this.getPermissionsForVirtualFridgeIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * storeOrganizationArea
     * Stores the business area for an organization.
     */
    async storeOrganizationAreaUsingPUTRaw(requestParameters: StoreOrganizationAreaUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HttpStatusCode>> {
        if (requestParameters['area'] == null) {
            throw new runtime.RequiredError(
                'area',
                'Required parameter "area" was null or undefined when calling storeOrganizationAreaUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/organization/area/{area}`.replace(`{${"area"}}`, encodeURIComponent(String(requestParameters['area']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HttpStatusCodeFromJSON(jsonValue));
    }

    /**
     * storeOrganizationArea
     * Stores the business area for an organization.
     */
    async storeOrganizationAreaUsingPUT(requestParameters: StoreOrganizationAreaUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HttpStatusCode> {
        const response = await this.storeOrganizationAreaUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * storeOrganizationLanguage
     * Stores the language for an organization.
     */
    async storeOrganizationLanguageUsingPUTRaw(requestParameters: StoreOrganizationLanguageUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling storeOrganizationLanguageUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/organization/language/{language}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters['language']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * storeOrganizationLanguage
     * Stores the language for an organization.
     */
    async storeOrganizationLanguageUsingPUT(requestParameters: StoreOrganizationLanguageUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.storeOrganizationLanguageUsingPUTRaw(requestParameters, initOverrides);
    }

    /**
     * storeOrganizationSettings
     * Stores the settings for the organization.
     */
    async storeOrganizationSettingsUsingPUTRaw(requestParameters: StoreOrganizationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['organizationSettings'] == null) {
            throw new runtime.RequiredError(
                'organizationSettings',
                'Required parameter "organizationSettings" was null or undefined when calling storeOrganizationSettingsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/organization/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationSettingsToJSON(requestParameters['organizationSettings']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * storeOrganizationSettings
     * Stores the settings for the organization.
     */
    async storeOrganizationSettingsUsingPUT(requestParameters: StoreOrganizationSettingsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.storeOrganizationSettingsUsingPUTRaw(requestParameters, initOverrides);
    }

    /**
     * updateOrganizationImage
     * Updates the Image of the Organization of the currently logged in User
     */
    async updateOrganizationImageUsingPOSTRaw(requestParameters: UpdateOrganizationImageUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling updateOrganizationImageUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/organization/organizationImage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * updateOrganizationImage
     * Updates the Image of the Organization of the currently logged in User
     */
    async updateOrganizationImageUsingPOST(requestParameters: UpdateOrganizationImageUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOrganizationImageUsingPOSTRaw(requestParameters, initOverrides);
    }

    /**
     * updatePermissions
     * Updates permissions. Only admin can call.
     */
    async updatePermissionsUsingPUTRaw(requestParameters: UpdatePermissionsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['updatePermissionsRequest'] == null) {
            throw new runtime.RequiredError(
                'updatePermissionsRequest',
                'Required parameter "updatePermissionsRequest" was null or undefined when calling updatePermissionsUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/organization/permissions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePermissionsRequestToJSON(requestParameters['updatePermissionsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * updatePermissions
     * Updates permissions. Only admin can call.
     */
    async updatePermissionsUsingPUT(requestParameters: UpdatePermissionsUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePermissionsUsingPUTRaw(requestParameters, initOverrides);
    }

}
