/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ReportDetails
 */
export interface ReportDetails {
  /**
   *
   * @type {Date}
   * @memberof ReportDetails
   */
  createdAt?: Date;
  /**
   *
   * @type {string}
   * @memberof ReportDetails
   */
  deviceName?: string;
  /**
   *
   * @type {string}
   * @memberof ReportDetails
   */
  downloadUri?: string;
  /**
   *
   * @type {Date}
   * @memberof ReportDetails
   */
  endDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof ReportDetails
   */
  isManuallyCreated?: boolean;
  /**
   *
   * @type {string}
   * @memberof ReportDetails
   */
  reportId?: string;
  /**
   *
   * @type {string}
   * @memberof ReportDetails
   */
  reportName?: string;
  /**
   *
   * @type {Date}
   * @memberof ReportDetails
   */
  requestedAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof ReportDetails
   */
  startDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ReportDetails
   */
  status?: ReportDetailsStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ReportDetails
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof ReportDetails
   */
  userName?: string;
  /**
   *
   * @type {string}
   * @memberof ReportDetails
   */
  virtualFridgeId?: string;
}

/**
 * @export
 */
export const ReportDetailsStatusEnum = {
  REQUESTED: 'REQUESTED',
  CREATED: 'CREATED',
  FAILED: 'FAILED',
  DELETED: 'DELETED'
} as const;
export type ReportDetailsStatusEnum = typeof ReportDetailsStatusEnum[keyof typeof ReportDetailsStatusEnum];

/**
 * Check if a given object implements the ReportDetails interface.
 */
export function instanceOfReportDetails(value: object): value is ReportDetails {
  return true;
}

export function ReportDetailsFromJSON(json: any): ReportDetails {
  return ReportDetailsFromJSONTyped(json, false);
}

export function ReportDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportDetails {
  if (json == null) {
    return json;
  }
  return {
    createdAt: json['createdAt'] == null ? undefined : new Date(json['createdAt']),
    deviceName: json['deviceName'] == null ? undefined : json['deviceName'],
    downloadUri: json['downloadUri'] == null ? undefined : json['downloadUri'],
    endDate: json['endDate'] == null ? undefined : new Date(json['endDate']),
    isManuallyCreated: json['isManuallyCreated'] == null ? undefined : json['isManuallyCreated'],
    reportId: json['reportId'] == null ? undefined : json['reportId'],
    reportName: json['reportName'] == null ? undefined : json['reportName'],
    requestedAt: json['requestedAt'] == null ? undefined : new Date(json['requestedAt']),
    startDate: json['startDate'] == null ? undefined : new Date(json['startDate']),
    status: json['status'] == null ? undefined : json['status'],
    timezone: json['timezone'] == null ? undefined : json['timezone'],
    userName: json['userName'] == null ? undefined : json['userName'],
    virtualFridgeId: json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId']
  };
}

export function ReportDetailsToJSON(json: any): ReportDetails {
  return ReportDetailsToJSONTyped(json, false);
}

export function ReportDetailsToJSONTyped(value?: ReportDetails | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    createdAt: value['createdAt'] == null ? undefined : value['createdAt'].toISOString(),
    deviceName: value['deviceName'],
    downloadUri: value['downloadUri'],
    endDate: value['endDate'] == null ? undefined : value['endDate'].toISOString(),
    isManuallyCreated: value['isManuallyCreated'],
    reportId: value['reportId'],
    reportName: value['reportName'],
    requestedAt: value['requestedAt'] == null ? undefined : value['requestedAt'].toISOString(),
    startDate: value['startDate'] == null ? undefined : value['startDate'].toISOString(),
    status: value['status'],
    timezone: value['timezone'],
    userName: value['userName'],
    virtualFridgeId: value['virtualFridgeId']
  };
}
