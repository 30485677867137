/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
    PointToJSONTyped,
} from './Point';
import type { HistoryDeviceAlarm } from './HistoryDeviceAlarm';
import {
    HistoryDeviceAlarmFromJSON,
    HistoryDeviceAlarmFromJSONTyped,
    HistoryDeviceAlarmToJSON,
    HistoryDeviceAlarmToJSONTyped,
} from './HistoryDeviceAlarm';
import type { HistoryDoorOpening } from './HistoryDoorOpening';
import {
    HistoryDoorOpeningFromJSON,
    HistoryDoorOpeningFromJSONTyped,
    HistoryDoorOpeningToJSON,
    HistoryDoorOpeningToJSONTyped,
} from './HistoryDoorOpening';

/**
 * 
 * @export
 * @interface DataPointsObject
 */
export interface DataPointsObject {
    /**
     * 
     * @type {Array<Point>}
     * @memberof DataPointsObject
     */
    alarmCount?: Array<Point>;
    /**
     * 
     * @type {Array<Point>}
     * @memberof DataPointsObject
     */
    averageTemperature?: Array<Point>;
    /**
     * 
     * @type {Array<Point>}
     * @memberof DataPointsObject
     */
    defrostState?: Array<Point>;
    /**
     * 
     * @type {Array<Array<HistoryDeviceAlarm>>}
     * @memberof DataPointsObject
     */
    deviceAlarms?: Array<Array<HistoryDeviceAlarm>>;
    /**
     * 
     * @type {Array<Point>}
     * @memberof DataPointsObject
     */
    doorOpenings?: Array<Point>;
    /**
     * 
     * @type {Array<Array<HistoryDoorOpening>>}
     * @memberof DataPointsObject
     */
    doorOpeningsArray?: Array<Array<HistoryDoorOpening>>;
    /**
     * 
     * @type {Array<Point>}
     * @memberof DataPointsObject
     */
    highTemperature?: Array<Point>;
    /**
     * 
     * @type {Array<Point>}
     * @memberof DataPointsObject
     */
    lowTemperature?: Array<Point>;
    /**
     * 
     * @type {Array<Point>}
     * @memberof DataPointsObject
     */
    maxTemperature?: Array<Point>;
    /**
     * 
     * @type {Array<Point>}
     * @memberof DataPointsObject
     */
    minTemperature?: Array<Point>;
    /**
     * 
     * @type {string}
     * @memberof DataPointsObject
     */
    sensorType?: DataPointsObjectSensorTypeEnum;
    /**
     * 
     * @type {Array<Point>}
     * @memberof DataPointsObject
     */
    setTemperature?: Array<Point>;
}


/**
 * @export
 */
export const DataPointsObjectSensorTypeEnum = {
    DOOR: 'DOOR',
    TEMPERATURE: 'TEMPERATURE',
    PRODUCT_TEMPERATURE: 'PRODUCT_TEMPERATURE'
} as const;
export type DataPointsObjectSensorTypeEnum = typeof DataPointsObjectSensorTypeEnum[keyof typeof DataPointsObjectSensorTypeEnum];


/**
 * Check if a given object implements the DataPointsObject interface.
 */
export function instanceOfDataPointsObject(value: object): value is DataPointsObject {
    return true;
}

export function DataPointsObjectFromJSON(json: any): DataPointsObject {
    return DataPointsObjectFromJSONTyped(json, false);
}

export function DataPointsObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPointsObject {
    if (json == null) {
        return json;
    }
    return {
        
        'alarmCount': json['alarmCount'] == null ? undefined : ((json['alarmCount'] as Array<any>).map(PointFromJSON)),
        'averageTemperature': json['averageTemperature'] == null ? undefined : ((json['averageTemperature'] as Array<any>).map(PointFromJSON)),
        'defrostState': json['defrostState'] == null ? undefined : ((json['defrostState'] as Array<any>).map(PointFromJSON)),
        'deviceAlarms': json['deviceAlarms'] == null ? undefined : json['deviceAlarms'],
        'doorOpenings': json['doorOpenings'] == null ? undefined : ((json['doorOpenings'] as Array<any>).map(PointFromJSON)),
        'doorOpeningsArray': json['doorOpeningsArray'] == null ? undefined : json['doorOpeningsArray'],
        'highTemperature': json['highTemperature'] == null ? undefined : ((json['highTemperature'] as Array<any>).map(PointFromJSON)),
        'lowTemperature': json['lowTemperature'] == null ? undefined : ((json['lowTemperature'] as Array<any>).map(PointFromJSON)),
        'maxTemperature': json['maxTemperature'] == null ? undefined : ((json['maxTemperature'] as Array<any>).map(PointFromJSON)),
        'minTemperature': json['minTemperature'] == null ? undefined : ((json['minTemperature'] as Array<any>).map(PointFromJSON)),
        'sensorType': json['sensorType'] == null ? undefined : json['sensorType'],
        'setTemperature': json['setTemperature'] == null ? undefined : ((json['setTemperature'] as Array<any>).map(PointFromJSON)),
    };
}

export function DataPointsObjectToJSON(json: any): DataPointsObject {
    return DataPointsObjectToJSONTyped(json, false);
}

export function DataPointsObjectToJSONTyped(value?: DataPointsObject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alarmCount': value['alarmCount'] == null ? undefined : ((value['alarmCount'] as Array<any>).map(PointToJSON)),
        'averageTemperature': value['averageTemperature'] == null ? undefined : ((value['averageTemperature'] as Array<any>).map(PointToJSON)),
        'defrostState': value['defrostState'] == null ? undefined : ((value['defrostState'] as Array<any>).map(PointToJSON)),
        'deviceAlarms': value['deviceAlarms'],
        'doorOpenings': value['doorOpenings'] == null ? undefined : ((value['doorOpenings'] as Array<any>).map(PointToJSON)),
        'doorOpeningsArray': value['doorOpeningsArray'],
        'highTemperature': value['highTemperature'] == null ? undefined : ((value['highTemperature'] as Array<any>).map(PointToJSON)),
        'lowTemperature': value['lowTemperature'] == null ? undefined : ((value['lowTemperature'] as Array<any>).map(PointToJSON)),
        'maxTemperature': value['maxTemperature'] == null ? undefined : ((value['maxTemperature'] as Array<any>).map(PointToJSON)),
        'minTemperature': value['minTemperature'] == null ? undefined : ((value['minTemperature'] as Array<any>).map(PointToJSON)),
        'sensorType': value['sensorType'],
        'setTemperature': value['setTemperature'] == null ? undefined : ((value['setTemperature'] as Array<any>).map(PointToJSON)),
    };
}

