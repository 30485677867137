/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Granularity
 */
export interface Granularity {
  /**
   *
   * @type {number}
   * @memberof Granularity
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof Granularity
   */
  unit?: GranularityUnitEnum;
}

/**
 * @export
 */
export const GranularityUnitEnum = {
  MINUTE: 'MINUTE',
  HOUR: 'HOUR',
  DAY: 'DAY'
} as const;
export type GranularityUnitEnum = typeof GranularityUnitEnum[keyof typeof GranularityUnitEnum];

/**
 * Check if a given object implements the Granularity interface.
 */
export function instanceOfGranularity(value: object): value is Granularity {
  return true;
}

export function GranularityFromJSON(json: any): Granularity {
  return GranularityFromJSONTyped(json, false);
}

export function GranularityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Granularity {
  if (json == null) {
    return json;
  }
  return {
    quantity: json['quantity'] == null ? undefined : json['quantity'],
    unit: json['unit'] == null ? undefined : json['unit']
  };
}

export function GranularityToJSON(json: any): Granularity {
  return GranularityToJSONTyped(json, false);
}

export function GranularityToJSONTyped(value?: Granularity | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    quantity: value['quantity'],
    unit: value['unit']
  };
}
