/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FirmwareRestrictionsResponse
 */
export interface FirmwareRestrictionsResponse {
  /**
   *
   * @type {boolean}
   * @memberof FirmwareRestrictionsResponse
   */
  needsFirmwareUpdate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FirmwareRestrictionsResponse
   */
  newModbusScan?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FirmwareRestrictionsResponse
   */
  online?: boolean;
}

/**
 * Check if a given object implements the FirmwareRestrictionsResponse interface.
 */
export function instanceOfFirmwareRestrictionsResponse(value: object): value is FirmwareRestrictionsResponse {
  return true;
}

export function FirmwareRestrictionsResponseFromJSON(json: any): FirmwareRestrictionsResponse {
  return FirmwareRestrictionsResponseFromJSONTyped(json, false);
}

export function FirmwareRestrictionsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FirmwareRestrictionsResponse {
  if (json == null) {
    return json;
  }
  return {
    needsFirmwareUpdate: json['needsFirmwareUpdate'] == null ? undefined : json['needsFirmwareUpdate'],
    newModbusScan: json['newModbusScan'] == null ? undefined : json['newModbusScan'],
    online: json['online'] == null ? undefined : json['online']
  };
}

export function FirmwareRestrictionsResponseToJSON(json: any): FirmwareRestrictionsResponse {
  return FirmwareRestrictionsResponseToJSONTyped(json, false);
}

export function FirmwareRestrictionsResponseToJSONTyped(
  value?: FirmwareRestrictionsResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    needsFirmwareUpdate: value['needsFirmwareUpdate'],
    newModbusScan: value['newModbusScan'],
    online: value['online']
  };
}
