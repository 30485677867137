/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LegalText,
  Manuals,
} from '../models/index';
import {
    LegalTextFromJSON,
    LegalTextToJSON,
    ManualsFromJSON,
    ManualsToJSON,
} from '../models/index';

export interface GetLegalTextUsingGETRequest {
    locale: string;
    type: GetLegalTextUsingGETTypeEnum;
}

export interface GetManualsUsingGETRequest {
    locale: string;
    serialNumber: string;
}

/**
 * DocumentationControllerApi - interface
 * 
 * @export
 * @interface DocumentationControllerApiInterface
 */
export interface DocumentationControllerApiInterface {
    /**
     * getLegalText
     * @summary Gets the array of legal texts for the application.
     * @param {string} locale 
     * @param {'ERROR' | 'TERMS_OF_USE' | 'PRIVACY_POLICY' | 'LICENSE_CONDITIONS'} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationControllerApiInterface
     */
    getLegalTextUsingGETRaw(requestParameters: GetLegalTextUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LegalText>>;

    /**
     * getLegalText
     * Gets the array of legal texts for the application.
     */
    getLegalTextUsingGET(requestParameters: GetLegalTextUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LegalText>;

    /**
     * getManuals
     * @summary Gets the link to the manual based on the serial number and a locale.
     * @param {string} locale 
     * @param {string} serialNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentationControllerApiInterface
     */
    getManualsUsingGETRaw(requestParameters: GetManualsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Manuals>>;

    /**
     * getManuals
     * Gets the link to the manual based on the serial number and a locale.
     */
    getManualsUsingGET(requestParameters: GetManualsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Manuals>;

}

/**
 * 
 */
export class DocumentationControllerApi extends runtime.BaseAPI implements DocumentationControllerApiInterface {

    /**
     * getLegalText
     * Gets the array of legal texts for the application.
     */
    async getLegalTextUsingGETRaw(requestParameters: GetLegalTextUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LegalText>> {
        if (requestParameters['locale'] == null) {
            throw new runtime.RequiredError(
                'locale',
                'Required parameter "locale" was null or undefined when calling getLegalTextUsingGET().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getLegalTextUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/documentation/legal/{locale}/{type}`.replace(`{${"locale"}}`, encodeURIComponent(String(requestParameters['locale']))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LegalTextFromJSON(jsonValue));
    }

    /**
     * getLegalText
     * Gets the array of legal texts for the application.
     */
    async getLegalTextUsingGET(requestParameters: GetLegalTextUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LegalText> {
        const response = await this.getLegalTextUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getManuals
     * Gets the link to the manual based on the serial number and a locale.
     */
    async getManualsUsingGETRaw(requestParameters: GetManualsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Manuals>> {
        if (requestParameters['locale'] == null) {
            throw new runtime.RequiredError(
                'locale',
                'Required parameter "locale" was null or undefined when calling getManualsUsingGET().'
            );
        }

        if (requestParameters['serialNumber'] == null) {
            throw new runtime.RequiredError(
                'serialNumber',
                'Required parameter "serialNumber" was null or undefined when calling getManualsUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/documentation/manuals/{locale}/{serialNumber}`.replace(`{${"locale"}}`, encodeURIComponent(String(requestParameters['locale']))).replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters['serialNumber']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManualsFromJSON(jsonValue));
    }

    /**
     * getManuals
     * Gets the link to the manual based on the serial number and a locale.
     */
    async getManualsUsingGET(requestParameters: GetManualsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Manuals> {
        const response = await this.getManualsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetLegalTextUsingGETTypeEnum = {
    ERROR: 'ERROR',
    TERMS_OF_USE: 'TERMS_OF_USE',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    LICENSE_CONDITIONS: 'LICENSE_CONDITIONS'
} as const;
export type GetLegalTextUsingGETTypeEnum = typeof GetLegalTextUsingGETTypeEnum[keyof typeof GetLegalTextUsingGETTypeEnum];
