/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Message
 */
export interface Message {
  /**
   *
   * @type {string}
   * @memberof Message
   */
  body?: string;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  footer?: string;
  /**
   *
   * @type {number}
   * @memberof Message
   */
  image?: number;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  name?: string;
  /**
   *
   * @type {Date}
   * @memberof Message
   */
  senddate?: Date;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  sendername?: string;
  /**
   *
   * @type {string}
   * @memberof Message
   */
  title?: string;
}

/**
 * Check if a given object implements the Message interface.
 */
export function instanceOfMessage(value: object): value is Message {
  return true;
}

export function MessageFromJSON(json: any): Message {
  return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
  if (json == null) {
    return json;
  }
  return {
    body: json['body'] == null ? undefined : json['body'],
    footer: json['footer'] == null ? undefined : json['footer'],
    image: json['image'] == null ? undefined : json['image'],
    name: json['name'] == null ? undefined : json['name'],
    senddate: json['senddate'] == null ? undefined : new Date(json['senddate']),
    sendername: json['sendername'] == null ? undefined : json['sendername'],
    title: json['title'] == null ? undefined : json['title']
  };
}

export function MessageToJSON(json: any): Message {
  return MessageToJSONTyped(json, false);
}

export function MessageToJSONTyped(value?: Message | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    body: value['body'],
    footer: value['footer'],
    image: value['image'],
    name: value['name'],
    senddate: value['senddate'] == null ? undefined : value['senddate'].toISOString(),
    sendername: value['sendername'],
    title: value['title']
  };
}
