/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MobileNumberResponse
 */
export interface MobileNumberResponse {
  /**
   *
   * @type {string}
   * @memberof MobileNumberResponse
   */
  mobile?: string;
  /**
   *
   * @type {boolean}
   * @memberof MobileNumberResponse
   */
  mobileSet?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MobileNumberResponse
   */
  mobileValid?: boolean;
}

/**
 * Check if a given object implements the MobileNumberResponse interface.
 */
export function instanceOfMobileNumberResponse(value: object): value is MobileNumberResponse {
  return true;
}

export function MobileNumberResponseFromJSON(json: any): MobileNumberResponse {
  return MobileNumberResponseFromJSONTyped(json, false);
}

export function MobileNumberResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileNumberResponse {
  if (json == null) {
    return json;
  }
  return {
    mobile: json['mobile'] == null ? undefined : json['mobile'],
    mobileSet: json['mobileSet'] == null ? undefined : json['mobileSet'],
    mobileValid: json['mobileValid'] == null ? undefined : json['mobileValid']
  };
}

export function MobileNumberResponseToJSON(json: any): MobileNumberResponse {
  return MobileNumberResponseToJSONTyped(json, false);
}

export function MobileNumberResponseToJSONTyped(
  value?: MobileNumberResponse | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    mobile: value['mobile'],
    mobileSet: value['mobileSet'],
    mobileValid: value['mobileValid']
  };
}
