/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface ApplianceAlarmProfileDetailsResponse
 */
export interface ApplianceAlarmProfileDetailsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    alarmActivatedByUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    alarmProfileId?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    cloudAlarmDelay?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    deviceAlarmDelay?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    gatewayAlarmActivated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    gatewayAlarmDelay?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    gatewayModel?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    gatewayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    gatewaySerialNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    maxAlarmTemp?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    maxLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    measurementUnit?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    minAlarmTemp?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    minLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    repetitionTime?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    setTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    temperatureHigherLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    temperatureLowerLimit?: number;
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    userProfiles?: Array<UserProfile>;
    /**
     * 
     * @type {string}
     * @memberof ApplianceAlarmProfileDetailsResponse
     */
    virtualFridgeId?: string;
}

/**
 * Check if a given object implements the ApplianceAlarmProfileDetailsResponse interface.
 */
export function instanceOfApplianceAlarmProfileDetailsResponse(value: object): value is ApplianceAlarmProfileDetailsResponse {
    return true;
}

export function ApplianceAlarmProfileDetailsResponseFromJSON(json: any): ApplianceAlarmProfileDetailsResponse {
    return ApplianceAlarmProfileDetailsResponseFromJSONTyped(json, false);
}

export function ApplianceAlarmProfileDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplianceAlarmProfileDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'alarmActivatedByUser': json['alarmActivatedByUser'] == null ? undefined : json['alarmActivatedByUser'],
        'alarmProfileId': json['alarmProfileId'] == null ? undefined : json['alarmProfileId'],
        'cloudAlarmDelay': json['cloudAlarmDelay'] == null ? undefined : json['cloudAlarmDelay'],
        'deviceAlarmDelay': json['deviceAlarmDelay'] == null ? undefined : json['deviceAlarmDelay'],
        'gatewayAlarmActivated': json['gatewayAlarmActivated'] == null ? undefined : json['gatewayAlarmActivated'],
        'gatewayAlarmDelay': json['gatewayAlarmDelay'] == null ? undefined : json['gatewayAlarmDelay'],
        'gatewayModel': json['gatewayModel'] == null ? undefined : json['gatewayModel'],
        'gatewayName': json['gatewayName'] == null ? undefined : json['gatewayName'],
        'gatewaySerialNumber': json['gatewaySerialNumber'] == null ? undefined : json['gatewaySerialNumber'],
        'maxAlarmTemp': json['maxAlarmTemp'] == null ? undefined : json['maxAlarmTemp'],
        'maxLimit': json['maxLimit'] == null ? undefined : json['maxLimit'],
        'measurementUnit': json['measurementUnit'] == null ? undefined : json['measurementUnit'],
        'minAlarmTemp': json['minAlarmTemp'] == null ? undefined : json['minAlarmTemp'],
        'minLimit': json['minLimit'] == null ? undefined : json['minLimit'],
        'repetitionTime': json['repetitionTime'] == null ? undefined : json['repetitionTime'],
        'setTemperature': json['setTemperature'] == null ? undefined : json['setTemperature'],
        'temperatureHigherLimit': json['temperatureHigherLimit'] == null ? undefined : json['temperatureHigherLimit'],
        'temperatureLowerLimit': json['temperatureLowerLimit'] == null ? undefined : json['temperatureLowerLimit'],
        'userProfiles': json['userProfiles'] == null ? undefined : ((json['userProfiles'] as Array<any>).map(UserProfileFromJSON)),
        'virtualFridgeId': json['virtualFridgeId'] == null ? undefined : json['virtualFridgeId'],
    };
}

export function ApplianceAlarmProfileDetailsResponseToJSON(json: any): ApplianceAlarmProfileDetailsResponse {
    return ApplianceAlarmProfileDetailsResponseToJSONTyped(json, false);
}

export function ApplianceAlarmProfileDetailsResponseToJSONTyped(value?: ApplianceAlarmProfileDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alarmActivatedByUser': value['alarmActivatedByUser'],
        'alarmProfileId': value['alarmProfileId'],
        'cloudAlarmDelay': value['cloudAlarmDelay'],
        'deviceAlarmDelay': value['deviceAlarmDelay'],
        'gatewayAlarmActivated': value['gatewayAlarmActivated'],
        'gatewayAlarmDelay': value['gatewayAlarmDelay'],
        'gatewayModel': value['gatewayModel'],
        'gatewayName': value['gatewayName'],
        'gatewaySerialNumber': value['gatewaySerialNumber'],
        'maxAlarmTemp': value['maxAlarmTemp'],
        'maxLimit': value['maxLimit'],
        'measurementUnit': value['measurementUnit'],
        'minAlarmTemp': value['minAlarmTemp'],
        'minLimit': value['minLimit'],
        'repetitionTime': value['repetitionTime'],
        'setTemperature': value['setTemperature'],
        'temperatureHigherLimit': value['temperatureHigherLimit'],
        'temperatureLowerLimit': value['temperatureLowerLimit'],
        'userProfiles': value['userProfiles'] == null ? undefined : ((value['userProfiles'] as Array<any>).map(UserProfileToJSON)),
        'virtualFridgeId': value['virtualFridgeId'],
    };
}

