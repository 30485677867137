/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SiteInformation
 */
export interface SiteInformation {
  /**
   *
   * @type {string}
   * @memberof SiteInformation
   */
  building?: string;
  /**
   *
   * @type {string}
   * @memberof SiteInformation
   */
  department?: string;
  /**
   *
   * @type {string}
   * @memberof SiteInformation
   */
  floor?: string;
  /**
   *
   * @type {string}
   * @memberof SiteInformation
   */
  room?: string;
}

/**
 * Check if a given object implements the SiteInformation interface.
 */
export function instanceOfSiteInformation(value: object): value is SiteInformation {
  return true;
}

export function SiteInformationFromJSON(json: any): SiteInformation {
  return SiteInformationFromJSONTyped(json, false);
}

export function SiteInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteInformation {
  if (json == null) {
    return json;
  }
  return {
    building: json['building'] == null ? undefined : json['building'],
    department: json['department'] == null ? undefined : json['department'],
    floor: json['floor'] == null ? undefined : json['floor'],
    room: json['room'] == null ? undefined : json['room']
  };
}

export function SiteInformationToJSON(json: any): SiteInformation {
  return SiteInformationToJSONTyped(json, false);
}

export function SiteInformationToJSONTyped(value?: SiteInformation | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    building: value['building'],
    department: value['department'],
    floor: value['floor'],
    room: value['room']
  };
}
