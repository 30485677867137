/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AddonLicenseStatus
 */
export interface AddonLicenseStatus {
  /**
   *
   * @type {string}
   * @memberof AddonLicenseStatus
   */
  assigneeType?: string;
  /**
   *
   * @type {Date}
   * @memberof AddonLicenseStatus
   */
  endDate?: Date;
  /**
   *
   * @type {string}
   * @memberof AddonLicenseStatus
   */
  licenseKey?: string;
  /**
   *
   * @type {boolean}
   * @memberof AddonLicenseStatus
   */
  licenseValid?: boolean;
  /**
   *
   * @type {string}
   * @memberof AddonLicenseStatus
   */
  productId?: string;
  /**
   *
   * @type {Date}
   * @memberof AddonLicenseStatus
   */
  startDate?: Date;
}

/**
 * Check if a given object implements the AddonLicenseStatus interface.
 */
export function instanceOfAddonLicenseStatus(value: object): value is AddonLicenseStatus {
  return true;
}

export function AddonLicenseStatusFromJSON(json: any): AddonLicenseStatus {
  return AddonLicenseStatusFromJSONTyped(json, false);
}

export function AddonLicenseStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddonLicenseStatus {
  if (json == null) {
    return json;
  }
  return {
    assigneeType: json['assigneeType'] == null ? undefined : json['assigneeType'],
    endDate: json['endDate'] == null ? undefined : new Date(json['endDate']),
    licenseKey: json['licenseKey'] == null ? undefined : json['licenseKey'],
    licenseValid: json['licenseValid'] == null ? undefined : json['licenseValid'],
    productId: json['productId'] == null ? undefined : json['productId'],
    startDate: json['startDate'] == null ? undefined : new Date(json['startDate'])
  };
}

export function AddonLicenseStatusToJSON(json: any): AddonLicenseStatus {
  return AddonLicenseStatusToJSONTyped(json, false);
}

export function AddonLicenseStatusToJSONTyped(
  value?: AddonLicenseStatus | null,
  ignoreDiscriminator: boolean = false
): any {
  if (value == null) {
    return value;
  }

  return {
    assigneeType: value['assigneeType'],
    endDate: value['endDate'] == null ? undefined : value['endDate'].toISOString(),
    licenseKey: value['licenseKey'],
    licenseValid: value['licenseValid'],
    productId: value['productId'],
    startDate: value['startDate'] == null ? undefined : value['startDate'].toISOString()
  };
}
