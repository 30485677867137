/* tslint:disable */
/* eslint-disable */
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserResponse } from './UserResponse';
import {
    UserResponseFromJSON,
    UserResponseFromJSONTyped,
    UserResponseToJSON,
    UserResponseToJSONTyped,
} from './UserResponse';
import type { OrganizationResponse } from './OrganizationResponse';
import {
    OrganizationResponseFromJSON,
    OrganizationResponseFromJSONTyped,
    OrganizationResponseToJSON,
    OrganizationResponseToJSONTyped,
} from './OrganizationResponse';

/**
 * 
 * @export
 * @interface UserInfoResponse
 */
export interface UserInfoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserInfoResponse
     */
    hasFoodLicense?: boolean;
    /**
     * 
     * @type {OrganizationResponse}
     * @memberof UserInfoResponse
     */
    organization?: OrganizationResponse;
    /**
     * 
     * @type {UserResponse}
     * @memberof UserInfoResponse
     */
    user?: UserResponse;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponse
     */
    userContext?: string;
}

/**
 * Check if a given object implements the UserInfoResponse interface.
 */
export function instanceOfUserInfoResponse(value: object): value is UserInfoResponse {
    return true;
}

export function UserInfoResponseFromJSON(json: any): UserInfoResponse {
    return UserInfoResponseFromJSONTyped(json, false);
}

export function UserInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfoResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hasFoodLicense': json['hasFoodLicense'] == null ? undefined : json['hasFoodLicense'],
        'organization': json['organization'] == null ? undefined : OrganizationResponseFromJSON(json['organization']),
        'user': json['user'] == null ? undefined : UserResponseFromJSON(json['user']),
        'userContext': json['userContext'] == null ? undefined : json['userContext'],
    };
}

export function UserInfoResponseToJSON(json: any): UserInfoResponse {
    return UserInfoResponseToJSONTyped(json, false);
}

export function UserInfoResponseToJSONTyped(value?: UserInfoResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hasFoodLicense': value['hasFoodLicense'],
        'organization': OrganizationResponseToJSON(value['organization']),
        'user': UserResponseToJSON(value['user']),
        'userContext': value['userContext'],
    };
}

